import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import OutsideAlerter from "./OutsideAlerter.js";
import "./css/Sort.css";

function Sort(props) {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div className="Sort">
      <div className="Sort-button" onClick={() => setShowMenu(!showMenu)}>
        <span>
          {props.queryIds && props.queryIds.s === "n"
            ? props.choices[0]
            : props.choices[1]}
        </span>
        <span>
          <svg
            className="Sort-button-icon "
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            width="14"
            height="14"
          >
            <path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z" />
          </svg>
        </span>
      </div>
      {showMenu ? (
        <OutsideAlerter onOutsideClick={() => setShowMenu(false)}>
          <div className="Sort-menu" onClick={() => setShowMenu(false)}>
            <Link
              className={
                props.queryIds && props.queryIds.s === "n"
                  ? "Sort-menu-link"
                  : "Sort-menu-link Sort-menu-link-active"
              }
              to={removeSortParams(
                props.location.pathname,
                props.location.search
              )}
            >
              {props.choices[1]}
            </Link>
            <Link
              className={
                props.queryIds && props.queryIds.s === "n"
                  ? "Sort-menu-link Sort-menu-link-active"
                  : "Sort-menu-link"
              }
              to={addSortParams(props.location.pathname, props.location.search)}
            >
              {props.choices[0]}
            </Link>
          </div>
        </OutsideAlerter>
      ) : null}
    </div>
  );
}

function removeSortParams(oldUrl, oldParams) {
  if (oldParams.includes("s=n")) {
    return oldUrl + oldParams.replace("&s=n", "").replace("?s=n", "");
  } else {
    return oldUrl.replace("s=n", "") + oldParams;
  }
}

function addSortParams(oldUrl, oldParams) {
  if (oldParams.includes("s=n")) {
    return oldUrl + oldParams;
  } else if (oldParams) {
    return oldUrl + oldParams + "&s=n";
  } else {
    return oldUrl + "?s=n";
  }
}

export default withRouter(Sort);
