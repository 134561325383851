import React, { useState, useEffect } from "react";
import querystring from "querystring";
// import UserContext from "./UserContext";
import Frame from "./Frame";
import { Link, withRouter } from "react-router-dom";
import { Types } from "mongoose";
import EditPieceBasic from "./EditPieceBasic";
import EditMovements from "./EditMovements";
import EditLoadInfo from "./EditLoadInfo";
import EditLoadMovements from "./EditLoadMovements";
import EditArrangements from "./EditArrangements";
import EditVersions from "./EditVersions";
import EditExternalLinks from "./EditExternalLinks";
import Windows from "./Windows";
import "./css/Edit.css";

function EditPiece(props) {
  return (
    <Windows>
      <EditPieceContent {...props} />
    </Windows>
  );
}

function EditPieceContent(props) {
  // const user = useContext(UserContext);
  const { handleShowMessageBox, location } = props;
  let queryIds = null;
  if (location.search)
    queryIds = querystring.parse(location.search.substring(1));

  const pieceId = props.match.params.pieceId;
  const artistId = props.match.params.artistId;
  const [page, setPage] = useState("basic");
  const [piece, setPiece] = useState({});
  const [artist, setArtist] = useState({});

  useEffect(() => {
    if (queryIds && queryIds.page) setPage(queryIds.page);
  }, [queryIds]);

  async function fetchPiece(pieceId) {
    if (pieceId)
      try {
        const resp = await fetch("/api/pieces/" + pieceId);
        const piece = await resp.json();
        setPiece(piece);
        return resp;
      } catch (err) {
        console.log(err);
      }
    else return null;
  }

  useEffect(() => {
    if (pieceId) fetchPiece(pieceId);
  }, [pieceId]);

  useEffect(() => {
    if (!pieceId && artistId) {
      async function fetchArtist(artistId) {
        try {
          const resp = await fetch("/api/artists/" + artistId);
          const artist = await resp.json();
          setArtist(artist);
          return resp;
        } catch (err) {
          console.log(err);
        }
      }
      fetchArtist(artistId);
    }
  }, [artistId, pieceId]);

  useEffect(() => {
    if (artist && artist._id)
      setPiece({
        _id: new Types.ObjectId(),
        composers: [
          {
            id: artist._id.toString(),
            name: artist.name,
            invertedName: artist.invertedName
          }
        ]
      });
  }, [artist]);

  useEffect(() => {
    if (!pieceId && !artistId)
      setPiece({
        _id: new Types.ObjectId()
      });
  }, [pieceId, artistId]);

  return (
    <Frame>
      <div className="Edit">
        {renderButtons()}
        {piece && piece._id ? renderSection() : null}
      </div>
    </Frame>
  );

  function renderButtons() {
    return (
      <div>
        <Link
          className={"Button Button-margin-right"}
          to={`/piece/${piece._id}`}
        >
          Return to piece
        </Link>
      </div>
    );
  }

  function renderSection() {
    if (page === "basic") {
      return (
        <EditPieceBasic
          piece={piece}
          page={page}
          onSubmit={updatedPiece =>
            upsertPiece(piece._id, updatedPiece, updatedPieceId => {
              if (
                updatedPiece.parent &&
                window.confirm("Update title in parent piece ?")
              ) {
                updateMovementInParent(updatedPiece, () =>
                  reload(updatedPieceId)
                );
              } else {
                reload(updatedPieceId);
              }
            })
          }
        />
      );
    } else if (page === "movements") {
      return (
        <EditMovements
          piece={piece}
          upsertPiece={upsertPiece}
          reload={reload}
          handleShowMessageBox={handleShowMessageBox}
        />
      );
    } else if (page === "loadInfo") {
      return (
        // <EditLoadInfo
        //   piece={piece}
        //   upsertPiece={upsertPiece}
        //   reload={reload}
        //   handleShowMessageBox={handleShowMessageBox}
        //   queryIds={queryIds}
        // />
        <EditExternalLinks
          piece={piece}
          reload={reload}
          handleShowMessageBox={handleShowMessageBox}
          queryIds={queryIds}
        />
      );
    } else if (page === "loadMovements") {
      return (
        <EditLoadMovements
          piece={piece}
          upsertPiece={upsertPiece}
          reload={reload}
          handleShowMessageBox={handleShowMessageBox}
        />
      );
    } else if (page === "versions") {
      return (
        <EditVersions
          piece={piece}
          upsertPiece={upsertPiece}
          reload={reload}
          handleShowMessageBox={handleShowMessageBox}
        />
      );
    } else {
      return (
        <EditArrangements
          piece={piece}
          page={page}
          upsertPiece={upsertPiece}
          reload={reload}
          handleShowMessageBox={handleShowMessageBox}
        />
      );
    }
  }

  function reload(updatedPieceId) {
    if (updatedPieceId) {
      fetchPiece(updatedPieceId);
      props.history.push(`/piece/${updatedPieceId}/edit`);
    } else {
      fetchPiece(pieceId);
    }
  }

  async function upsertPiece(updatedPieceId, updatedPiece, callback) {
    const body = JSON.stringify(updatedPiece);
    try {
      const resp = await fetch("/api/pieces/" + updatedPieceId + "/edit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body
      });
      if (resp.status === 201) {
        handleShowMessageBox("Updated", 1000);
        callback(updatedPieceId);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function updateMovementInParent(updatedPiece, callback) {
    if (updatedPiece && updatedPiece.parent) {
      const body = JSON.stringify({
        movementNumber: updatedPiece.movementNumber,
        movementTitle: updatedPiece.movementTitle
      });
      try {
        const resp = await fetch(
          "/api/pieces/" + updatedPiece.parent.id + "/editMovement",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body
          }
        );
        if (resp.status === 201) {
          handleShowMessageBox("Parent updated", 1000);
          callback();
        }
      } catch (err) {
        console.log(err);
      }
    }
  }
}

export default withRouter(EditPiece);
