import React, { useState, useEffect } from "react";
import EditText from "./EditText";
import "./css/Edit.css";

export default function EditLoadInfo(props) {
  const { piece, handleShowMessageBox, queryIds } = props;
  const [id, setId] = useState("");
  const [updatedPiece, setUpdatedPiece] = useState(null);

  useEffect(() => {
    if (queryIds.id) {
      setId(queryIds.id);
    }
  }, [queryIds]);

  return (
    <div className="Edit-sections">
      <div>
        <div className="Edit-section-left">
          <div>
            <EditText value={id} setValue={setId} placeholder="id" />
            <button
              className="Button Edit-button"
              onClick={e => {
                e.preventDefault();
                fetchInfo(id);
              }}
            >
              Update
            </button>
          </div>
          <button
            className="Button Edit-button"
            onClick={e => {
              e.preventDefault();
              updatePiece(piece._id, updatedPiece);
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );

  async function fetchInfo(id) {
    try {
      const resp = await fetch("/api/pieces/info/" + id);
      const pieceJSON = await resp.json();
      if (pieceJSON) {
        setUpdatedPiece(pieceJSON);
        handleShowMessageBox("Updated", 1000);
        return resp;
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function updatePiece(updatedPieceId, updatedPiece) {
    const body = JSON.stringify(updatedPiece);
    try {
      const resp = await fetch("/api/pieces/" + updatedPieceId + "/edit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body
      });
      if (resp.status === 201) {
        handleShowMessageBox("Saved", 1000);
      }
    } catch (err) {
      console.log(err);
    }
  }
}
