import React from "react";
import "./css/SignInButton.css";

export default function SignInButton(props) {
  const { setShowLoginWindow } = props;

  return (
    <div className={"SignInButton"}>
      <div
        className="SignInButton-button"
        onClick={() => {
          setShowLoginWindow(true);
        }}
      >
        <div>Log in</div>
      </div>
    </div>
  );
}
