import React from "react";

export default function EditEnsemblesItem(props) {
  const { handleRemoveEnsemble, i, ensemble } = props;

  return (
    <div key={i}>
      {`${i + 1}. ${ensemble.name}`}
      <button
        className="EditSearch-button"
        onClick={() => handleRemoveEnsemble(i)}
        type="button"
      >
        Remove
      </button>
    </div>
  );
}
