import React from "react";
import Ensembles from './Ensembles';
import querystring from 'querystring';

export default class EnsemblesQueryBuilder extends React.Component {

  render() {
    let queryString = "?";

    // Add query strings
    const search = this.props.location.search;
    queryString = search ? queryString += search.substr(1) : queryString;

    // Create object from query strings
    const queryIds = querystring.parse(queryString.substring(1))

    document.title = 'Ensembles - Lyra digital music library';

    return <Ensembles
      queryString={queryString}
      queryIds={queryIds}
      paramsURL={this.props.match.params}
      history={this.props.history}
      />
  }
}
