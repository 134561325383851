import React from "react";
import { Link } from "react-router-dom";
import "./css/Navigation.css";

export default class FavoritesPageNavigation extends React.Component {
  render() {
    const { path } = this.props;
    // if (contentIsLoaded) {
      return (
        <div className="Navigation">
          <div className="Navigation-row">
            <Link
              to="/favorites"
              className={
                path === "/favorites"
                  ? "Navigation-button Navigation-button-active"
                  : "Navigation-button"
              }
            >
              Albums
            </Link>
            {/* <Link
              to="/favorites/artists"
              className={
                path === "/favorites/artists"
                  ? "Navigation-button Navigation-button-active"
                  : "Navigation-button"
              }
            >
              Artists
            </Link>
            <Link
              to="/favorites/pieces"
              className={
                path === "/favorites/pieces"
                  ? "Navigation-button Navigation-button-active"
                  : "Navigation-button"
              }
            >
              Pieces
            </Link> */}
          </div>
        </div>
      );
    // } else {
    //   return null;
    // }
  }
}
