import React, { useState, useEffect } from "react";
import EditInstruments from "./EditInstruments";
import EditText from "./EditText";
import EditProfile from "./EditProfile";
import EditTonalities from "./EditTonalities";
import EditCategory from "./EditCategory";
import EditCatalogues from "./EditCatalogues";
import EditStatus from "./EditStatus";
import EditSubcategory from "./EditSubcategory";
import EditPeople from "./EditPeople";
import { createTitle, createSearchTitle } from "./helpers/naming";
import "./css/Edit.css";

export default function EditPiece(props) {
  const { piece, onSubmit, page } = props;
  const [composers, setComposers] = useState([]);
  const [arrangers, setArrangers] = useState([]);
  const [title, setTitle] = useState("");
  const [shortTitle, setShortTitle] = useState("");
  const [englishShortTitle, setEnglishShortTitle] = useState("");
  const [movementTitle, setMovementTitle] = useState("");
  const [profile, setProfile] = useState("");
  const [updatedPiece, setUpdatedPiece] = useState({});
  const [arrangementDetails, setArrangementDetails] = useState("");
  const [nickname, setNickname] = useState("");
  const [englishNickname, setEnglishNickname] = useState("");
  const [dedication, setDedication] = useState("");
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [musicalForm, setMusicalForm] = useState("");
  const [tempo, setTempo] = useState("");
  const [section, setSection] = useState("");
  const [tonalities, setTonalities] = useState([]);
  const [catalogues, setCatalogues] = useState([]);
  const [instruments, setInstruments] = useState([]);
  const [versionTitle, setVersionTitle] = useState("");
  const [status, setStatus] = useState("");
  const [notes, setNotes] = useState("");
  const [parent, setParent] = useState(null);

  // Default value
  useEffect(() => {
    if (piece.composers) setComposers(piece.composers);
    if (piece.arrangers) setArrangers(piece.arrangers);
    if (piece.title) setTitle(piece.title);
    else setTitle("");
    if (piece.shortTitle) setShortTitle(piece.shortTitle);
    if (piece.movementTitle) setMovementTitle(piece.movementTitle);
    else setMovementTitle("");
    if (piece.englishShortTitle) setEnglishShortTitle(piece.englishShortTitle);
    if (piece.profile) setProfile(piece.profile);
    if (piece.parent) setParent(piece.parent);
    if (piece.nickname) setNickname(piece.nickname);
    if (piece.englishNickname) setEnglishNickname(piece.englishNickname);
    if (piece.dedication) setDedication(piece.dedication);
    if (piece.musicalForm) setMusicalForm(piece.musicalForm);
    if (piece.tempo) setTempo(piece.tempo);
    if (piece.tonalities) setTonalities(piece.tonalities);
    if (piece.catalogues) setCatalogues(piece.catalogues);
    if (piece.instruments) setInstruments(piece.instruments);
    if (piece.section) setSection(piece.section);
    if (piece.category) setCategory(piece.category);
    if (piece.subcategory) setSubcategory(piece.subcategory);
    if (piece.versionTitle) setVersionTitle(piece.versionTitle);
    if (piece.status) setStatus(piece.status);
    if (piece.notes) setNotes(piece.notes);
    if (piece.arrangementDetails)
      setArrangementDetails(piece.arrangementDetails);
  }, [piece]);

  // Update piece when input change
  useEffect(() => {
    const newPiece = {};
    if (composers) newPiece.composers = composers;
    if (arrangers) newPiece.arrangers = arrangers;
    if (title) newPiece.title = title;
    if (shortTitle) newPiece.shortTitle = shortTitle;
    if (englishShortTitle) newPiece.englishShortTitle = englishShortTitle;
    if (movementTitle) newPiece.movementTitle = movementTitle;
    if (profile) newPiece.profile = profile;
    if (nickname) newPiece.nickname = nickname;
    if (englishNickname) newPiece.englishNickname = englishNickname;
    if (dedication) newPiece.dedication = dedication;
    if (status) newPiece.status = status;
    else newPiece.status = "";
    if (notes) newPiece.notes = notes;
    else newPiece.notes = "";
    newPiece.arrangementDetails = arrangementDetails;
    newPiece.versionTitle = versionTitle;
    if (category) newPiece.category = category;
    if (subcategory) newPiece.subcategory = subcategory;
    if (musicalForm) newPiece.musicalForm = musicalForm;
    if (tempo) newPiece.tempo = tempo;
    if (tonalities) newPiece.tonalities = tonalities;
    if (catalogues) newPiece.catalogues = catalogues;
    if (section) newPiece.section = section;
    if (instruments) newPiece.instruments = instruments;
    if (piece.movementNumber) newPiece.movementNumber = piece.movementNumber;
    if (piece.parent) newPiece.parent = piece.parent;
    if (piece.arrangementOf) newPiece.arrangementOf = piece.arrangementOf;
    // if (piece.catalogues) newPiece.catalogues = piece.catalogues;
    newPiece.searchTitle = createSearchTitle(newPiece);
    newPiece.searchTitles = [createSearchTitle(newPiece)];
    if (piece && piece._id) newPiece.searchTitles.push(piece._id.toString());
    setUpdatedPiece(newPiece);
  }, [
    composers,
    arrangers,
    title,
    shortTitle,
    movementTitle,
    englishShortTitle,
    profile,
    nickname,
    englishNickname,
    dedication,
    status,
    notes,
    arrangementDetails,
    category,
    subcategory,
    musicalForm,
    tempo,
    tonalities,
    catalogues,
    section,
    instruments,
    versionTitle,
    setUpdatedPiece,
    piece
  ]);

  useEffect(() => {
    if (parent && movementTitle) {
      setTitle(
        createTitle({
          parent,
          movementTitle
        })
      );
    }
  }, [parent, movementTitle]);

  return (
    <div>
      <form
        onSubmit={e => {
          e.preventDefault();
          onSubmit(updatedPiece);
        }}
      >
        {parent ? (
          <EditText
            value={movementTitle}
            setValue={setMovementTitle}
            placeholder="Movement title"
          />
        ) : null}
        <EditText value={title} setValue={setTitle} placeholder="Title" />
        {page === "arrangements" ? renderArrangement() : null}
        <EditPeople
          people={composers}
          setPeople={setComposers}
          placeholder="Composers"
        />
        <EditPeople
          people={arrangers}
          setPeople={setArrangers}
          placeholder="Arrangers"
        />
        <EditProfile profile={profile} setProfile={setProfile} />
        <EditTonalities tonalities={tonalities} setTonalities={setTonalities} />
        <EditCategory category={category} setCategory={setCategory} />
        <EditSubcategory
          subcategory={subcategory}
          setSubcategory={setSubcategory}
        />
        <EditInstruments
          instruments={instruments}
          setInstruments={setInstruments}
        />
        <EditCatalogues
          catalogues={catalogues}
          setCatalogues={setCatalogues}
        />
        <EditText
          value={musicalForm}
          setValue={setMusicalForm}
          placeholder="Musical form"
        />
        <EditText value={tempo} setValue={setTempo} placeholder="Tempo" />
        <EditText
          value={versionTitle}
          setValue={setVersionTitle}
          placeholder="Version title"
        />
        <div
          onClick={e => {
            console.log("do");
            e.preventDefault();
            if (versionTitle) setTitle(`${title} (${versionTitle})`);
          }}
        >
          Add in title
        </div>
        {page !== "arrangements" ? renderArrangement() : null}
        <EditText
          value={englishShortTitle}
          setValue={setEnglishShortTitle}
          placeholder="English title"
        />
        <EditText
          value={nickname}
          setValue={setNickname}
          placeholder="Nickname"
        />
        <EditText
          value={englishNickname}
          setValue={setEnglishNickname}
          placeholder="English nickname"
        />
        <EditText
          value={dedication}
          setValue={setDedication}
          placeholder="Dedication"
        />
        <EditStatus status={status} setStatus={setStatus} />
        <EditText value={notes} setValue={setNotes} placeholder="Notes" />
        <EditText value={section} setValue={setSection} placeholder="Section" />
        <div>SearchTitle</div>
        <div>{updatedPiece.searchTitle}</div>
        <br />
        <div>id</div>
        <div>{piece && piece._id ? piece._id.toString() : null}</div>
        <br />
        {updatedPiece.parent ? (
          <div>
            <div>Parent piece</div>
            <div>{updatedPiece.parent.title}</div>
          </div>
        ) : null}
        <br />
        {updatedPiece.arrangementOf ? (
          <div>
            <div>Arrangement of</div>
            <div>{updatedPiece.arrangementOf.title}</div>
          </div>
        ) : null}
        <button className="Button Edit-button">Update</button>
      </form>
    </div>
  );

  function renderArrangement() {
    return (
      <div>
        <EditText
          value={arrangementDetails}
          setValue={setArrangementDetails}
          placeholder="Arrangement title"
        />
        <button
          className="EditSearch-button"
          type="button"
          onClick={e => {
            e.preventDefault();
            if (arrangementDetails)
              setTitle(`${title} (${arrangementDetails})`);
          }}
        >
          Add in title
        </button>
      </div>
    );
  }
}
