import React, { useState } from "react";
import Review from "./Review.js";
import EditReview from "./EditReview";
import "./css/Reviews.css";

export default function Reviews(props) {
  const [showEditReview, setShowEditReview] = useState(false);

  function renderReviews(reviews) {
    if (reviews) {
      return reviews.map((review, i) => {
        if (review.text) {
          return (
            <div key={i}>
              <Review
                album={props.album}
                setAlbum={props.setAlbum}
                user={props.user || null}
                review={review}
                userReview={props.userReview}
                setUserReview={props.setUserReview}
                showEditReview={showEditReview}
                setShowEditReview={setShowEditReview}
                fetchAlbumReviews={props.fetchAlbumReviews}
              />
            </div>
          );
        } else {
          return null;
        }
      });
    }
  }

  return (
    <div className="Reviews">
      <div className="Reviews-content">
        {renderCreateReview()}
        {renderReviews(props.reviews)}
      </div>
    </div>
  );

  function renderCreateReview() {
    if (showEditReview) {
      return (
        <EditReview
          album={props.album}
          user={props.user}
          userReview={props.userReview}
          setUserReview={props.setUserReview}
          showEditReview={showEditReview}
          setShowEditReview={setShowEditReview}
          fetchAlbumReviews={props.fetchAlbumReviews}
        />
      );
    } else if (!(props.userReview && props.userReview.text)) {
      return (
        <div
          className="Reviews-add"
          onClick={() => {
            props.user._id
              ? setShowEditReview(true)
              : props.handleShowLoginWindow(true);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="16"
            height="16"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
              className="Reviews-add-icon"
            />
          </svg>
          <span className="Reviews-add-text">Add a review</span>
        </div>
      );
    }
  }
  // }
}
