import React, { useState, useEffect } from "react";
import { Types } from "mongoose";
import EditPieceBasic from "./EditPieceBasic";
import "./css/Edit.css";

export default function InsertArrangement(props) {
  const { piece, upsertPiece, reload, page } = props;

  const [updatedPiece, setUpdatedPiece] = useState({});
  const [id, setId] = useState(null);

  // Default values copied from parent piece
  useEffect(() => {
    const newArrangement = {};
    newArrangement.arrangementOf = {
      id: piece._id.toString(),
      title: piece.title
    };
    if (piece.title) newArrangement.title = piece.title;
    if (piece.englishTitle) newArrangement.englishTitle = piece.englishTitle;
    if (piece.composers) newArrangement.composers = piece.composers;
    if (piece.eras) newArrangement.eras = piece.eras;
    if (piece.number) newArrangement.number = piece.number;
    if (piece.tonalities) newArrangement.tonalities = piece.tonalities;
    if (piece.showTonalities)
      newArrangement.showTonalities = piece.showTonalities;
    if (piece.catalogues) newArrangement.catalogues = piece.catalogues;
    if (piece.musicalForm) newArrangement.musicalForm = piece.musicalForm;
    if (piece.showMusicalForm)
      newArrangement.showMusicalForm = piece.showMusicalForm;
    if (piece.creation) newArrangement.creation = piece.creation;
    if (piece.numberAlbums) newArrangement.numberAlbums = piece.numberAlbums;
    if (piece.wikipedia) newArrangement.wikipedia = piece.wikipedia;
    if (piece.imslp) newArrangement.imslp = piece.imslp;
    if (piece.groupsPieces && piece.groupsPieces.length > 0)
      newArrangement.groupsPieces = piece.groupsPieces;

    setUpdatedPiece(newArrangement);
    setId(new Types.ObjectId());
  }, [piece, setUpdatedPiece]);

  return (
    <div className="Edit-input-container">
      <div>Insert new arrangement</div>
      <EditPieceBasic
        page={page}
        piece={updatedPiece}
        onSubmit={newPieceBasic => handleInsertArrangement(newPieceBasic)}
      />
    </div>
  );

  function handleInsertArrangement(newPieceBasic) {
    const newArrangementsInParent = [...piece.arrangements];
    const newArrangementInParent = {
      id: id.toString(),
      title: newPieceBasic.title
    };
    newArrangementsInParent.push(newArrangementInParent);
    // insert new arrangement
    upsertPiece(id, { ...updatedPiece, ...newPieceBasic }, () => {
      // update parent piece
      upsertPiece(piece._id, { arrangements: newArrangementsInParent }, () =>
        reload()
      );
    });
  }
}
