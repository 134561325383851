import React from "react";
import Select from "./Select";

export default function Status(props) {
  const { status, setStatus } = props;

  return (
    <div className="Edit-input-container">
      <label>Status</label>
      <Select
        placeholder="Status"
        options={[
          "Incomplete",
          "Lost",
          "Fragment",
          "Doubtful",
          "Destroyed",
          "Partially lost",
          "Spurious",
          "Project",
          "Unfinished",
          "Sketch",
          "Sketches",
          "Destroyed or lost",
          "Reconstructed",
          "Unknown",
          "Sketches destroyed",
          "Doubtful and incomplete",
          "Incomplete and spurious",
          "Lost, possibly spurious",
          "Not orchestrated",
          "Probably spurious"
        ]}
        isSearchable={true}
        isMultiple={false}
        value={status}
        onClear={e => setStatus("")}
        onChange={e => setStatus(e)}
      />
    </div>
  );
}
