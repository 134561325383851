import React, { useState, useEffect } from "react";
import "./css/EditDate.css";

export default function EditDate(props) {
  const { value, setValue } = props;
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");

  useEffect(() => {
    if (value) {
      if (value.length === 10) {
        setYear(value.substring(0, 4));
        setMonth(value.substring(5, 2));
        setDay(value.substring(8, 2));
      } else if (value.length === 7) {
        setYear(value.substring(0, 4));
        setMonth(value.substring(5, 2));
      } else if (value.length === 4) {
        setYear(value.substring(0, 4));
      }
    }
  }, [value]);

  useEffect(() => {
    let date = "";
    if (year) {
      date += year;
      if (month) {
        date += "-" + month;
        if (day) {
          date += "-" + day;
        }
      }
      setValue(date);
    } else {
      setValue("");
    }
  }, [year, month, day]);

  useEffect(() => {
    if (value === "") {
      setYear("");
      setMonth("");
      setDay("");
    }
  }, [value]);

  return (
    <div className="EditDate">
      {/* <label className="Edit-input-label">{placeholder}</label> */}
      <input
        type="text"
        placeholder="YYYY"
        className="EditDate-input"
        value={year}
        onChange={e => setYear(e.currentTarget.value)}
      />
      <input
        type="text"
        placeholder="MM (optional)"
        className="EditDate-input"
        value={month}
        onChange={e => setMonth(e.currentTarget.value)}
      />
      <input
        type="text"
        placeholder="DD (optional)"
        className="EditDate-input"
        value={day}
        onChange={e => setDay(e.currentTarget.value)}
      />
    </div>
  );
}
