import React from "react";
import Filter from "./Filter.js";
// import SwitchFilter from "./SwitchFilter.js";
import "./css/Filters.css";

export default function PerformerRecordingsFilters(props) {
  return (
    <div className="Filters">
      <Filter
        queryString={props.queryString}
        queryIds={props.queryIds}
        history={props.history}
        name="Composer"
        queryParameter="composer"
        apiURL="/api/recordings/distinct/composers.invertedName"
        key="composer"
        isSearchable={true}
        paramsURL={props.paramsURL}
      />
      {/* <Filter
        queryString={props.queryString}
        queryIds={props.queryIds}
        history={props.history}
        name="Performer"
        queryParameter="performers"
        apiURL="/api/recordings/distinct/performers.invertedName"
        key="performer"
        isSearchable={true}
        isMultiple={true}
        paramsURL={props.paramsURL}
      /> */}
      <Filter
        queryString={props.queryString}
        queryIds={props.queryIds}
        history={props.history}
        name="Ensemble"
        queryParameter="ensemble"
        apiURL="/api/recordings/distinct/ensembles.name"
        key="ensemble"
        isSearchable={true}
        paramsURL={props.paramsURL}
      />
      {/* <Filter
        queryString={props.queryString}
        queryIds={props.queryIds}
        history={props.history}
        name="Instruments"
        queryParameter="instruments"
        apiURL="/api/recordings/distinct/instruments.name"
        key="instrument"
        isSearchable={true}
        paramsURL={props.paramsURL}
        isMultiple={true}
      /> */}
      <Filter
        queryString={props.queryString}
        queryIds={props.queryIds}
        history={props.history}
        name="Label"
        queryParameter="label"
        apiURL="/api/recordings/distinct/albums.label.name"
        key="label"
        isSearchable={true}
        paramsURL={props.paramsURL}
      />
      <Filter
        queryString={props.queryString}
        queryIds={props.queryIds}
        history={props.history}
        name="Period"
        queryParameter="era"
        apiURL="/api/recordings/distinct/eras"
        key="era"
        isSearchable={true}
        paramsURL={props.paramsURL}
      />
      {/* <SwitchFilter
        queryString={props.queryString}
        queryIds={props.queryIds}
        history={props.history}
        name="Group"
        queryParameter="group"
        key="group"
        paramsURL={props.paramsURL}
      /> */}
    </div>
  );
}
