import React from "react";
import './css/AlbumCover.css';

export default function AlbumCover(props) {
  const { album } = props;
  if (album) {
    return (
      // <a className="AlbumCover" href={`https://d31zararll8aqx.cloudfront.net/albums/300/${album._id}.jpg`} target="_blank" rel="noopener noreferrer">
        <div className="AlbumCover">
          <img className="AlbumCover-image" src={`https://d31zararll8aqx.cloudfront.net/albums/300/${album._id}.jpg`} alt="cover" />
        </div>
      // </a>
    )
  } else {
    return null;
  }
}