import React from "react";
import "./css/HeaderNavigation.css";

export default function AlbumHeaderNavigation(props) {
  const { album, page, setPage, pressReviews } = props;
  if (album && album._id) {
    return (
      <div className="HeaderNavigation">
        {renderButtonTracks()}
        {renderButtonArtists()}
        {album.description ? renderButtonDescription() : null}
        {pressReviews.length > 0 || album.edit
          ? renderButtonPressReviews()
          : null}
        {renderButtonUsersReviews()}
      </div>
    );
  } else {
    return null;
  }

  function renderButtonTracks() {
    return (
      <span
        onClick={() => setPage("tracks")}
        className={
          page === "tracks"
            ? "HeaderNavigation-button HeaderNavigation-button-active"
            : "HeaderNavigation-button"
        }
      >
        Tracks
      </span>
    );
  }

  function renderButtonArtists() {
    return (
      <span
        onClick={() => setPage("artists")}
        className={
          page === "artists"
            ? "HeaderNavigation-button HeaderNavigation-button-active"
            : "HeaderNavigation-button"
        }
      >
        Artists
      </span>
    );
  }

  function renderButtonDescription() {
    return (
      <span
        onClick={() => setPage("description")}
        className={
          page === "description"
            ? "HeaderNavigation-button HeaderNavigation-button-active"
            : "HeaderNavigation-button"
        }
      >
        Description
      </span>
    );
  }

  function renderButtonUsersReviews() {
    return (
      <span
        onClick={() => setPage("usersReviews")}
        className={
          page === "usersReviews"
            ? "HeaderNavigation-button HeaderNavigation-button-active"
            : "HeaderNavigation-button"
        }
      >
        Users Reviews
      </span>
    );
  }

  function renderButtonPressReviews() {
    return (
      <span
        onClick={() => setPage("pressReviews")}
        className={
          page === "pressReviews"
            ? "HeaderNavigation-button HeaderNavigation-button-active"
            : "HeaderNavigation-button"
        }
      >
        Press Reviews
        {/* <span> ({pressReviews.length})</span> */}
      </span>
    );
  }
}
