import React, { useState, useEffect } from "react";
// import querystring from "querystring";
import Frame from "./Frame";
import { withRouter } from "react-router-dom";
import EditArtistBasic from "./EditArtistBasic";
import Windows from "./Windows";
import "./css/Edit.css";

function EditArtist(props) {
  return (
    <Windows>
      <EditArtistContent {...props} />
    </Windows>
  );
}

function EditArtistContent(props) {
  const { handleShowMessageBox } = props;
  const artistId = props.match.params.artistId;

  const [artist, setArtist] = useState({});

  async function fetchArtist(artistId) {
    if (artistId)
      try {
        const resp = await fetch("/api/artists/" + artistId);
        const artist = await resp.json();
        setArtist(artist);
        return resp;
      } catch (err) {
        console.log(err);
      }
    else return null;
  }

  useEffect(() => {
    if (artistId) fetchArtist(artistId);
  }, [artistId]);

  return (
    <Frame>
      <div className="Edit">
        {artist && artist._id ? (
          <EditArtistBasic
            artist={artist}
            onSubmit={async updatedArtist => {
              const updatedartistId = await upsertArtist(
                artist._id,
                updatedArtist
              );
              reload(updatedartistId);
            }}
          />
        ) : null}
      </div>
    </Frame>
  );

  function reload(updatedartistId) {
    if (updatedartistId) {
      fetchArtist(updatedartistId);
      props.history.push(`/artist/${updatedartistId}/edit`);
    } else {
      fetchArtist(artistId);
    }
  }

  async function upsertArtist(updatedartistId, updatedArtist) {
    const body = JSON.stringify(updatedArtist);
    try {
      const resp = await fetch("/api/artists/update/" + updatedartistId, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body
      });
      if (resp.status === 201) {
        handleShowMessageBox("Updated", 1000);
        return updatedartistId;
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export default withRouter(EditArtist);
