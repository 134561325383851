import React, { useState, useEffect } from "react";
import SearchResults from "./SearchResults";
import "./css/Search.css";

export default function Search() {
  const [q, setQ] = useState("");
  const [searchPieces, setSearchPieces] = useState([]);
  const [searchPeople, setSearchPeople] = useState([]);
  const [searchEnsembles, setSearchEnsembles] = useState([]);
  const [searchPiecesIsLoaded, setSearchPiecesIsLoaded] = useState(false);
  const [searchPeopleIsLoaded, setSearchPeopleIsLoaded] = useState(false);
  const [searchEnsemblesIsLoaded, setSearchEnsemblesIsLoaded] = useState(false);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const searchResultsIsLoaded =
    searchPeopleIsLoaded && searchPiecesIsLoaded && searchEnsemblesIsLoaded;
  const numberResults =
    searchPeople.length + searchPieces.length + searchEnsembles.length;

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setSearchPiecesIsLoaded(false);
      setSearchPeopleIsLoaded(false);
      setSearchEnsemblesIsLoaded(false);
      fetchSearchPieces(q);
      fetchSearchPeople(q);
      fetchSearchEnsembles(q);
    }, 300);
    return () => clearTimeout(timeOutId);
  }, [q]);

  return (
    <div className="Search-container">
      <div className="Search-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="16"
          height="16"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z"
            fill="var(--text-2)"
          />
        </svg>
      </div>
      <input
        id="search"
        type="text"
        placeholder="Search"
        className="Search"
        autoComplete="off"
        value={q}
        onClick={() => {
          if (q && searchResultsIsLoaded && numberResults > 0) {
            setShowSearchResults(true);
          } else {
            setShowSearchResults(false);
          }
        }}
        onChange={e => {
          setQ(e.target.value);
        }}
      />
      <SearchResults
        q={q}
        searchPeople={searchPeople}
        searchPieces={searchPieces}
        searchEnsembles={searchEnsembles}
        searchPeopleIsLoaded={searchPeopleIsLoaded}
        searchPiecesIsLoaded={searchPiecesIsLoaded}
        searchEnsemblesIsLoaded={searchEnsemblesIsLoaded}
        searchResultsIsLoaded={searchResultsIsLoaded}
        showSearchResults={showSearchResults}
        setShowSearchResults={setShowSearchResults}
        numberResults={numberResults}
        // searchAlbums={searchAlbums}
      />
    </div>
  );

  async function fetchSearchPeople(q) {
    try {
      const resp = await fetch("/api/search/people?q=" + q);
      const searchPeople = await resp.json();
      setSearchPeople(searchPeople);
      setSearchPeopleIsLoaded(true);
      return resp;
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchSearchEnsembles(q) {
    try {
      const resp = await fetch("/api/search/ensembles?q=" + q);
      const searchEnsemblesJSON = await resp.json();
      setSearchEnsembles(searchEnsemblesJSON);
      setSearchEnsemblesIsLoaded(true);
      return resp;
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchSearchPieces(q) {
    try {
      const resp = await fetch("/api/search/pieces?q=" + q);
      const searchPiecesJSON = await resp.json();
      setSearchPieces(searchPiecesJSON);
      setSearchPiecesIsLoaded(true);
      return resp;
    } catch (err) {
      console.log(err);
    }
  }
}
