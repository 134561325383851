import React, { useContext } from "react";
import UserContext from "./UserContext";
import Navbar from "./Navbar.js";
import SidebarLinks from "./SidebarLinks.js";
import "./css/Account.css";
import "./css/Sidebar.css";

export default function Account() {
  const user = useContext(UserContext);
  if (user && user.user) {
    return (
      <div>
        <div className="Sidebar">
          <SidebarLinks />
        </div>
        <div className="Account-content">
          <Navbar title="Account" />
          <div className="Account-form">
            <h2>Delete your account</h2>
            <span>
              Once you delete your account, there is no going back.
              <br />
              <br />
            </span>
            <button
              className="Account-button Account-button-delete"
              onClick={() => handleDeleteAccount()}
            >
              Delete your Account
            </button>
          </div>
        </div>
      </div>
    );
  }

  async function deleteAccount() {
    try {
      await fetch("/users/delete", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        }
      });
      window.location = "/";
    } catch (err) {
      console.log(err);
    }
  }

  function handleDeleteAccount() {
    if (window.confirm("Are you sure you want to delete your account?")) {
      deleteAccount();
    }
  }
}
