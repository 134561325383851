import React, { useState } from "react";
import { Link } from "react-router-dom";
import EditSearch from "./EditSearch";
import EditPiecesItem from "./EditPiecesItem";

export default function Editpieces(props) {
  const { pieces, setPieces, placeholder, title } = props;
  const [copyTitle, setCopyTitle] = useState(false);

  return (
    <div className="Edit-input-container">
      <label>{placeholder}</label>
      <button
        className="Edit-button-small"
        type="button"
        onClick={() => {
          setCopyTitle(true);
        }}
      >
        Copy title
      </button>
      {pieces && pieces.length > 0
        ? pieces.map((piece, i) => (
            <EditPiecesItem
              piece={piece}
              handleRemovePiece={handleRemovePiece}
              i={i}
              key={i}
            />
          ))
        : null}
      <EditSearch
        collection={"pieces"}
        handleClick={piece => addPiece(piece)}
        placeholder="Add piece"
        title={copyTitle ? title : null}
      />
      <Link to="/insert/piece">Insert new piece</Link>
    </div>
  );

  function addPiece(piece) {
    const newpieces = [...pieces];
    const newPiece = {
      id: piece._id.toString(),
      title: piece.title
    };
    if (piece.movements && piece.movements.length > 0) {
      newPiece.isParent = true;
    }
    if (piece.parent) {
      newPiece.isMovement = true;
    }
    newpieces.push(newPiece);
    setPieces(newpieces);
  }

  function handleRemovePiece(i) {
    const newpieces = [...pieces];
    newpieces.splice(i, 1);
    setPieces(newpieces);
  }
}
