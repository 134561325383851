import React, { useState } from "react";
import EditCatalogue from "./EditCatalogue";
import EditText from "./EditText";

export default function EditCatalogues(props) {
  const { catalogues, setCatalogues } = props;
  const [catalogue, setCatalogue] = useState("");

  return (
    <div className="Edit-input-container">
      <label>Catalogues</label>
      {catalogues && catalogues.length > 0
        ? catalogues.map((catalogue, i) => (
            <EditCatalogue
              catalogue={catalogue}
              removeCatalogue={removeCatalogue}
              i={i}
              key={i}
            />
          ))
        : null}
      <EditText
        value={catalogue}
        setValue={setCatalogue}
        placeholder="Catalogue"
        hideLabel={true}
      />
      <button
        className="Edit-button-small"
        onClick={() => {
          addCatalogue();
          setCatalogue("");
        }}
        type="button"
      >
        Add
      </button>
    </div>
  );

  function addCatalogue() {
    const newCatalogues = [...catalogues];
    newCatalogues.push(catalogue);
    setCatalogues(newCatalogues);
  }

  function removeCatalogue(i) {
    const newCatalogues = [...catalogues];
    newCatalogues.splice(i, 1);
    setCatalogues(newCatalogues);
  }
}
