import React, { useState } from "react";
import EditOccupations from "./EditOccupations";
import { convertListToStringWithComma } from "./helpers/helpers";

export default function EditPeopleItem(props) {
  const { handleRemovePerson, handleUpdateOccupations, i, person } = props;
  const [showEditOccupations, setShowEditOccupations] = useState(false);

  return (
    <div key={i}>
      {`${i + 1}. ${person.name}`}
      {renderOccupations(person, i)}
      <button
        className="EditSearch-button"
        onClick={() => handleRemovePerson(i)}
        type="button"
      >
        Remove
      </button>
      {showEditOccupations ? (
        <EditOccupations
          occupations={person.occupations}
          setOccupations={occupations => {
            const newPerson = { ...person };
            newPerson.occupations = occupations;
            handleUpdateOccupations(newPerson, i);
          }}
        />
      ) : null}
    </div>
  );

  function renderOccupations(person, i) {
    if (person.occupations && person.occupations.length > 0) {
      return (
        <span>
          {` (${convertListToStringWithComma(person.occupations)})`}
          <button
            className="EditSearch-button"
            onClick={() => setShowEditOccupations(!showEditOccupations)}
            type="button"
          >
            {showEditOccupations ? "Hide occupations" : "Edit occupations"}
          </button>
        </span>
      );
    }
  }
}
