import React from "react";
import { cutString } from "./helpers/helpers.js";
import "./css/SelectInput.css";

export default function SelectInput(props) {
  const {
    handleClickClear,
    showOptions,
    placeholder,
    handleShowOptions,
    value
  } = props;

  if (!value || value.length === 0) {
    return (
      <button
        onClick={() => handleShowOptions()}
        className={
          showOptions ? "SelectInput SelectInput-active" : "SelectInput"
        }
        type="button"
      >
        <div className="SelectInput-placeholder">{placeholder}</div>
        <div className="SelectInput-icon-container">
          <svg
            className="SelectInput-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            width="16"
            height="16"
          >
            <path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z" />
          </svg>
        </div>
      </button>
    );
  } else {
    return (
      <button
        className="SelectInput"
        onClick={() => {
          handleShowOptions();
        }}
        type="button"
      >
        <div className="SelectInput-selected">
          {stringifySelectedValue(cutString(value, 15))}
        </div>
        <div
          className="SelectInput-icon-container SelectInput-icon-container-erase"
          onClick={() => handleClickClear()}
        >
          <svg
            className="SelectInput-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            width="24"
            height="24"
          >
            <path d="M278.6 256l68.2-68.2c6.2-6.2 6.2-16.4 0-22.6-6.2-6.2-16.4-6.2-22.6 0L256 233.4l-68.2-68.2c-6.2-6.2-16.4-6.2-22.6 0-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3l68.2 68.2-68.2 68.2c-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3 6.2 6.2 16.4 6.2 22.6 0l68.2-68.2 68.2 68.2c6.2 6.2 16.4 6.2 22.6 0 6.2-6.2 6.2-16.4 0-22.6L278.6 256z" />
          </svg>
        </div>
      </button>
    );
  }

  function stringifySelectedValue(value, numberOfChar) {
    if (Array.isArray(value) && value.length > 0) {
      return value.map((singleValue, i) => {
        return (
          <span key={i}>
            {singleValue}
            {i + 1 < value.length ? ", " : ""}
            {/* {cutString(value + (value.length > 1 ? ",..." : ""), numberOfChar)} */}
          </span>
        );
      });
    } else {
      return value;
    }
  }
}
