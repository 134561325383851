import React from "react";
import ArtistsTable from "./ArtistsTable.js";
import Navbar from "./Navbar.js";
import PerformersNavigation from "./PerformersNavigation.js";
import ComposersNavigation from "./ComposersNavigation.js";
import BrowseNavigation from "./BrowseNavigation.js";
import FavoritesPageNavigation from "./FavoritesPageNavigation.js";
// import Sort from "./Sort";
import SidebarLinks from "./SidebarLinks.js";
import SidebarFooter from "./SidebarFooter.js";
import { throttle } from "./helpers/helpers.js";
import "./css/Artists.css";
import "./css/Sidebar.css";

export default class Artists extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      artistsIsLoaded: false,
      artists: [],
      artistsCounter: null,
      page: 1,
      limit: 35,
      showFilters: false
    };
  }

  async fetchArtists(q = null) {
    try {
      let url =
        "/api/artists" +
        this.props.queryString +
        "&limit=" +
        this.state.limit +
        "&page=" +
        this.state.page;
      if (q) {
        url += "&q=" + q;
      }
      const resp = await fetch(url);
      const artists = await resp.json();
      const artistsCounter = await resp.headers.get("Result-Count");
      this.setState({
        artistsIsLoaded: true,
        artists: this.state.artists.concat(artists),
        artistsCounter
      });
      return resp;
    } catch (err) {
      console.log(err);
    }
  }

  handleOnScroll() {
    if (
      window.innerHeight + window.scrollY + 2000 >=
      document.body.offsetHeight
    ) {
      if (
        this.state.page * this.state.limit <
        parseInt(this.state.artistsCounter) + 35
      ) {
        this.loadMore();
      }
    }
  }

  handleShowFilters() {
    if (this.state.showFilters) window.scrollTo(0, 0);
    this.setState({ showFilters: !this.state.showFilters });
  }

  loadMore() {
    if (this._isMounted) {
      this.setState({ page: this.state.page + 1 });
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchArtists();
    window.addEventListener(
      "scroll",
      throttle(this.handleOnScroll.bind(this), 300)
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.queryString !== this.props.queryString) {
      this.setState({ artists: [], page: 1 }); // Restart at page one after filters update

      this.setState(
        {
          artists: [],
          artistsIsLoaded: false
        },
        () => this.fetchArtists()
      );
    } else if (
      prevState.page !== this.state.page &&
      this.state.artists.length !== 0
    ) {
      this.fetchArtists();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  renderBrowseNavigation() {
    const { path, queryIds } = this.props;
    const { albumsIsLoaded } = this.state;
    if (path !== "/favorites/artists") {
      return (
        <BrowseNavigation
          path={path}
          albumsIsLoaded={albumsIsLoaded}
          queryIds={queryIds}
        />
      );
    }
  }

  renderPerformersNavigation() {
    const { path, queryIds, history, queryString } = this.props;
    const { artistsIsLoaded } = this.state;
    if (path !== "/favorites/artists" && path !== "/composers") {
      return (
        <PerformersNavigation
          history={history}
          queryString={queryString}
          path={path}
          artistsIsLoaded={artistsIsLoaded}
          queryIds={queryIds}
        />
      );
    }
  }

  renderComposersNavigation() {
    const { path, queryIds } = this.props;
    const { artistsIsLoaded } = this.state;
    if (path !== "/favorites/artists" && path === "/composers") {
      return (
        <ComposersNavigation
          path={path}
          artistsIsLoaded={artistsIsLoaded}
          queryIds={queryIds}
        />
      );
    }
  }

  renderFavoritesPageNavigation() {
    const { path } = this.props;
    if (path === "/favorites/artists") {
      return <FavoritesPageNavigation path={path} contentIsLoaded={true} />;
    }
  }

  render() {
    const { path } = this.props;
    const { error, artistsIsLoaded, artistsCounter, artists } = this.state;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else {
      return (
        <div className="Artists">
          <div className="Sidebar">
            <SidebarLinks />
            <SidebarFooter />
          </div>
          <div className="Artists-content">
            <Navbar className="hidden-mobile" />
            {this.renderFavoritesPageNavigation()}
            {this.renderBrowseNavigation()}
            {this.renderComposersNavigation()}
            {this.renderPerformersNavigation()}
            {/* <div className="Artists-sort-search"> */}
            {/* <SearchFilter fetchData={this.fetchArtists.bind(this)} /> */}
            {/* <Sort
              queryIds={queryIds}
              choices={["Sort by number of albums", "Sort by name"]}
            /> */}
            {/* </div> */}
            <ArtistsTable
              artists={artists}
              artistsIsLoaded={artistsIsLoaded}
              artistsCounter={artistsCounter}
              path={path}
            />
          </div>
        </div>
      );
    }
  }
}
