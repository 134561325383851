import React, { useContext } from "react";
import UserContext from "./UserContext";
import "./css/ButtonFavorite.css";

export default function ButtonFavorite(props) {
  const {
    albumId,
    recordingId,
    type,
    isInFavorites,
    callbackAfterAdd,
    callbackAfterRemove,
    small,
    handleShowLoginWindow
  } = props;
  const user = useContext(UserContext);

  // if (user && user.userId) {
  if (isInFavorites) {
    return (
      <div
        className={
          "Button Button-margin-right Button-active ButtonFavorite " +
          (small ? "Button-small Button-small-active" : "")
        }
        onTouchStart={() => null}
        onClick={() => removeFromFavorites(albumId, recordingId, type)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="Button-icon ButtonFavorite-icon"
          viewBox="0 0 512 512"
        >
          <path
            d="M352,48H160a48,48,0,0,0-48,48V464L256,336,400,464V96A48,48,0,0,0,352,48Z"
            className="Button-icon Button-icon-full"
          />
        </svg>
        {small ? null : <span className="Button-text">In Collection</span>}
      </div>
    );
  } else {
    return (
      <div
        className={
          "Button Button-margin-right ButtonFavorite " +
          (small ? "Button-small" : "")
        }
        onTouchStart={() => null}
        onClick={() => addToFavorites(albumId, recordingId, type)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          className="Button-icon ButtonFavorite-icon"
        >
          <path d="M352,48H160a48,48,0,0,0-48,48V464L256,336,400,464V96A48,48,0,0,0,352,48Z" />
        </svg>
        {small ? null : <span className="Button-text">Add to Collection</span>}
      </div>
    );
  }

  async function addToFavorites(albumId, recordingId, type) {
    if (user && user.userId) {
      const body = { type };
      if (albumId) body.albumId = albumId;
      if (recordingId) body.recordingId = recordingId;
      try {
        const resp = await fetch("/favorites/add", {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json"
          }
        });
        if (resp.status === 401) {
          handleShowLoginWindow(true);
        } else if (resp.status === 201) {
          callbackAfterAdd();
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      handleShowLoginWindow(true);
    }
  }

  async function removeFromFavorites(albumId, recordingId, type) {
    if (user && user.userId) {
      const body = { type };
      if (albumId) body.albumId = albumId;
      if (recordingId) body.recordingId = recordingId;
      try {
        const resp = await fetch("/favorites/remove", {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json"
          }
        });
        if (resp.status === 401) {
          handleShowLoginWindow(true);
        }
        callbackAfterRemove();
      } catch (err) {
        console.log(err);
      }
    } else {
      handleShowLoginWindow(true);
    }
  }
}
