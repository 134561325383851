import React from "react";
import { Link } from "react-router-dom";
import Frame from "./Frame";
import "./css/Frame.css";
import "./css/Artist.css";

export default function Artist(props) {

    return (
        <Frame>
            <div className="Artist">
                <div className="Artist-content">
                    <div className="Navigation-row Navigation-row-small">
                        <Link
                            to="/works"
                            className="Navigation-button-small"
                        >
                            All works</Link>
                        <Link
                            to="/catalogues"
                            className="Navigation-button-small Navigation-button-small-active"
                        >
                            Catalogues
                            </Link>
                    </div>
                    {renderCatalogues()}
                </div>
            </div>
        </Frame>
    );

    function renderCatalogues() {
        return (
            <div>
                <div>Alexander Scriabin: Opus number</div>
                <div>Anton Bruckner: Werkverzeichnis Anton Bruckner</div>
                <div>Antonín Dvořák: Antonín Dvořák: Thematicky Katalog, 2nd edition</div>
                <div>Antonín Dvořák: Opus number</div>
                <div>Antonio Vivaldi: Antonio Vivaldi. Thematisch-systematisches Verzeichnis seiner Werke</div>
                <div>Antonio Vivaldi: Opus number</div>
                <div>Béla Bartók: Opus number</div>
                <div>Béla Bartók: Bartok : sa vie et son œuvre</div>
                <div>Béla Bartók: </div>
                <div>Béla Bartók: Thematisches Verzeichnis der Jugendwerke Béla Bartoks : 1890-1904</div>
                <div>Benjamin Britten: Opus number</div>
                <div>Camille Saint-Saëns: Opus number</div>
                <div>Camille Saint-Saëns: Camille Saint-Saëns 1835–1921: A Thematic Catalogue of His Complete Works</div>
                <div>César Franck: Opus number</div>
                <div>César Franck: Caesar Franck. – 2. ergänzte Auflage</div>
                <div>César Franck: César Franck</div>
                <div>Claude Debussy: Lesure</div>
                <div>Claude Debussy: Lesure</div>
                <div>Dmitri Shostakovich: Opus number</div>
                <div>Domenico Scarlatti: Domenico Scarlatti</div>
                <div>Domenico Scarlatti: 545 Scarlatti Sonatas in XI Volumes</div>
                <div>Domenico Scarlatti: Le Sonate di Domenico Scarlatti proposta di un ordinamento cronologico</div>
                <div>Edvard Grieg: Opus number</div>
                <div>Edvard Grieg: Edvard Grieg (1843-1907) : thematisch-bibliographisches Werkverzeichnis</div>
                <div>Edward Elgar: Opus number</div>
                <div>Felix Mendelssohn: Opus number</div>
                <div>Felix Mendelssohn: Felix Mendelssohn Bartholdy. Thematisch-systematisches Verzeichnis der musikalischen Werke</div>
                <div>Francis Poulenc: The Music of Francis Poulenc (1899-1963) : A Catalogue</div>
                <div>Franz Liszt: Ferenc Liszt (1811–1886): List of works : Comprehensively Expanded from the Catalogue of Humphrey Searle as Revised by Sharon Winklhofer</div>
                <div>Franz Liszt: "Franz Liszt, Works". The New Grove Dictionary of Music and Musicians</div>
                <div>Franz Schubert: Thematisches Verzeichnis seiner Werke in chronologischer Folge</div>
                <div>Franz Schubert: Opus number</div>
                <div>Frédéric Chopin: Opus number</div>
                <div>Frédéric Chopin: Frédéric Chopin: Thematisch-bibliographisches Werkverzeichnis</div>
                <div>Frédéric Chopin: Katalog dzieł Fryderyka Chopina</div>
                <div>Frédéric Chopin: Chopin: an index of his works in chronological order</div>
                <div>Gabriel Fauré: Opus number</div>
                <div>George Frideric Handel: Thematisch-sustematisches Verzeichnis, in: Händel-Handbuch</div>
                <div>George Frideric Handel: Opus number</div>
                <div>Georges Bizet: Opus number</div>
                <div>Georges Bizet: </div>
                <div>Hector Berlioz: Catalogue of the works of Hector Berlioz</div>
                <div>Hector Berlioz: Opus number</div>
                <div>Igor Stravinsky: Opus number</div>
                <div>Igor Stravinsky: Igor Stravinsky, A Complete Catalogue</div>
                <div>Igor Stravinsky: Igor Stravinsky</div>
                <div>Igor Stravinsky: Stravinsky: The Composer and His Works</div>
                <div>Isaac Albéniz: Opus number</div>
                <div>Jean Sibelius: Opus number</div>
                <div>Jean-Baptiste Lully: Chronologisch-thematisches Verzeichnis sämtlicher Werke von Jean-Baptiste Lully</div>
                <div>Johann Sebastian Bach: Thematisch-systematisches Verzeichnis der musikalischen Werke von Johann Sebastian Bach (1950)</div>
                <div>Johann Sebastian Bach: Thematisch-systematisches Verzeichnis der musikalischen Werke von Johann Sebastian Bach (1990)</div>
                <div>Johannes Brahms: Johannes Brahms thematisch-bibliographisches Werkverzeichnis</div>
                <div>Joseph Haydn: Opus number</div>
                <div>Joseph Haydn: Joseph Haydn thematisch-bibliographisches Werkverzeichnis</div>
                <div>Leoš Janáček: Janáček's Works: A Catalogue of the Music and Writings of Leoš Janáček</div>
                <div>Ludwig van Beethoven: Das Werk Beethovens: thematisch-bibliographisches Verzeichnis seiner sämtlichen vollendeten Kompositionen</div>
                <div>Ludwig van Beethoven: Verzeichnis der nicht in der Gesamtausgabe veröffentlichen Werke Ludwig van Beethovens</div>
                <div>Ludwig van Beethoven: Catologo cronologico e tematico delle opere di Beethoven</div>
                <div>Maurice Ravel: Marnat</div>
                <div>Pyotr Ilyich Tchaikovsky: The Tchaikovsky Handbook</div>
                <div>Pyotr Ilyich Tchaikovsky: Тематико-библиографический указатель сочинений П. И. Чайковского</div>
                <div>Pyotr Ilyich Tchaikovsky: Opus number</div>
                <div>Richard Strauss: Opus number</div>
                <div>Richard Strauss: Richard Strauss: Thematisches Verzeichnis</div>
                <div>Richard Strauss: Richard Strauss Werkverzeichnis (TrV) : zweite, überarbeitete Auflage</div>
                <div>Richard Wagner: Opus number</div>
                <div>Richard Wagner: Wagner Werk-Verzeichnis (WWV): Verzeichnis der musikalischen Werke Richard Wagners und ihrer Quellen</div>
                <div>Robert Schumann: Thematisches Verzeichnis der Werker von Robert Schumann</div>
                <div>Satie, Erik: Chronological</div>
                <div>Sergei Prokofiev: Opus number</div>
                <div>Sergei Rachmaninov: Opus number</div>
                <div>Sergei Rachmaninov: A Catalogue of the Compositions of S. Rachmaninoff</div>
                <div>Sigismond Thalberg: Opus number</div>
                <div>Wolfgang Amadeus Mozart: Chronologisch-thematisches Verzeichnis sämmtlicher Tonwerke Wolfgang Amadeus Mozart’s</div>
                <div>Wolfgang Amadeus Mozart: Chronologisch-thematisches Verzeichnis sämmtlicher Tonwerke Wolfgang Amadeus Mozart’s</div>
                <div>Wolfgang Amadeus Mozart: Chronologisch-thematisches Verzeichnis sämmtlicher Tonwerke Wolfgang Amadeus Mozart’s</div>
                <div>Wolfgang Amadeus Mozart: Chronologisch-thematisches Verzeichnis sämmtlicher Tonwerke Wolfgang Amadeus Mozart’s</div>
            </div>
        );
    }

}
