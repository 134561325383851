import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function ListEnsembles(props) {
  const { composers, performers, ensembles, link } = props;
  const limit = 5;
  const [showAllArtists, setShowAllArtists] = useState(false);
  const artists = [];
  if (composers) {
    composers.forEach(composer => {
      if (link === true) {
        artists.push(
          <Link key={composer.id} to={"/artist/" + composer.id}>
            {composer.name}
          </Link>
        );
      } else {
        artists.push(<span key={composer.id}>{composer.name}</span>);
      }
    });
  }
  if (performers) {
    performers.forEach(performer => {
      if (link === true) {
        artists.push(
          <Link key={performer.id} to={"/artist/" + performer.id}>
            {performer.name}
          </Link>
        );
      } else {
        artists.push(<span key={performer.id}>{performer.name}</span>);
      }
    });
  }

  if (ensembles) {
    ensembles.forEach(ensemble => {
      if (link === true) {
        artists.push(
          <Link key={ensemble.id} to={"/ensemble/" + ensemble.id}>
            {ensemble.name}
          </Link>
        );
      } else {
        artists.push(<span key={ensemble.id}>{ensemble.name}</span>);
      }
    });
  }

  if (artists && artists.length > 0) {
    if (artists.length <= limit) {
      return (
        <div>
          {artists.map((artist, i) => {
            return showArtist(artist, i, artists.length);
          })}
        </div>
      );
    } else if (limit < artists.length && !showAllArtists) {
      return (
        <div>
          {artists.map((artist, i) => {
            if (i < limit) {
              return showArtist(artist, i, artists.length);
            } else {
              return null;
            }
          })}
          <span onClick={() => setShowAllArtists(true)}>(more)</span>
        </div>
      );
    } else {
      return (
        <div>
          {artists.map((artist, i) => {
            return showArtist(artist, i, artists.length);
          })}
          {/* <span onClick={() => setShowAllArtists(false)}>, (less)</span> */}
        </div>
      );
    }
  } else {
    return null;
  }

  function showArtist(artist, i, length) {
    return (
      <span key={i}>
        {artist}
        {i + 1 < length ? <span>, </span> : null}
      </span>
    );
  }
}
