import React from "react";
import SelectMenuSearch from "./SelectMenuSearch.js";
import { withRouter } from "react-router-dom";
import SelectOptions from "./SelectOptions.js";
import "./css/SelectMenu.css";

function SelectMenu(props) {
  const {
    options,
    optionsAfterSearch,
    queryIds,
    handleSearchInOptions,
    search,
    isSearchable,
    queryParameter,
    optionKey,
    handleClickValue,
    value
  } = props;
  return (
    <div className="SelectMenu">
      {isSearchable ? (
        <SelectMenuSearch
          search={search}
          onChange={e => handleSearchInOptions(e)}
        />
      ) : null}
      <SelectOptions
        options={options}
        optionsAfterSearch={optionsAfterSearch}
        queryIds={queryIds}
        queryParameter={queryParameter}
        optionKey={optionKey}
        handleClickValue={handleClickValue}
        value={value}
      />
    </div>
  );
}

export default withRouter(SelectMenu);
