import { BrowserRouter as Router } from "react-router-dom";
import React from "react";
import Main from "./Main";
import ScrollToTop from "./ScrollToTop";
import UserContext from "./UserContext";
import GA from "./GoogleAnalytics.js";
import "./css/App.css";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: null,
      firstVisit: false,
      showCookieBanner: true,
      showCookieSettings: false,
      cookiesActivated: false,
      isUserLoaded: false,
      isUserLoggedIn: this.isUserLoggedIn.bind(this)
    };
  }

  componentDidMount() {
    if (window.location.host === "www.classicalmusicdatabase.com") {
      const newUrl = window.location.href.replace(
        "classicalmusicdatabase.com",
        "lyradb.org"
      );
      window.location.assign(newUrl);
    }

    window["ga-disable-" + process.env.REACT_APP_GA_TRACKING_ID] = true;

    // Check if the client already visited the website
    var alreadyVisited = localStorage.getItem("_v");
    if (!alreadyVisited) {
      // alert('Welcome to Lyra digital music library')
      this.setState({ firstVisit: true });
      localStorage.setItem("_v", "1");
    }

    // Check if the user is logged in
    this.isUserLoggedIn();
  }

  async isUserLoggedIn() {
    try {
      const resp = await fetch("/users/current");
      const user = await resp.json();
      if (user) {
        this.setState({
          userId: user._id,
          user,
          isUserLoaded: true
        });
      } else {
        this.setState({
          isUserLoaded: true
        });
      }
    } catch (err) {
      console.log(err);
    }
  }
  render() {
    const {
      userId,
      isUserLoaded,
      showCookieBanner,
      showCookieSettings,
      cookiesActivated
    } = this.state;
    // console.log(cookiesActivated);
    if (isUserLoaded) {
      return (
        <UserContext.Provider value={this.state}>
          <Router>
            {cookiesActivated ? GA.init() && <GA.RouteTracker /> : null}
            <ScrollToTop />
            <Main userId={userId} />
            {showCookieBanner && !this.getCookie("_c")
              ? this.renderCookieBanner()
              : null}
            {showCookieSettings ? this.renderCookieSettings() : null}
          </Router>
        </UserContext.Provider>
      );
    } else {
      return null;
    }
  }

  renderCookieBanner() {
    return (
      <div className="App-cookies">
        <div className="App-cookies-text">
          We use cookies to offer you a better browsing experience and analyse
          site traffic. Read how we use cookies and how you can control them by
          clicking on the "Cookie settings" button.
        </div>
        <div className="App-cookies-buttons">
          <button
            className="App-cookies-button"
            onClick={() => {
              window[
                "ga-disable-" + process.env.REACT_APP_GA_TRACKING_ID
              ] = false;
              this.setPreferenceCookie(1);
              this.setState({
                showCookieBanner: false,
                cookiesActivated: true
              });
            }}
          >
            Accept
          </button>
          <button
            className="App-cookies-button App-cookies-button-settings"
            onClick={() => {
              this.setState({
                showCookieBanner: false,
                showCookieSettings: true
              });
            }}
          >
            Cookie settings
          </button>
        </div>
      </div>
    );
  }

  renderCookieSettings() {
    const { cookiesActivated } = this.state;
    return (
      <div className="App-cookies">
        <div className="App-cookies-text">
          <span>We use two types of cookies :</span>
          <br />
          <br />
          <strong>Strictly Necessary Cookies</strong>
          <br />
          <span>
            These cookies are necessary for the website to function and cannot
            be switched off in our systems. They are usually only set in
            response to actions made by you which amount to a request for
            services, such as setting your privacy preferences, logging in or
            filling in forms. You can set your browser to block or alert you
            about these cookies, but some parts of the site will not then work.
          </span>
          <br />
          <br />
          <strong>Performance & Analytics Cookies</strong>
          <input
            type="checkbox"
            checked={cookiesActivated}
            onChange={this.handleChangeCookiesActivated.bind(this)}
          />
          <br />
          <span>
            These cookies allow us to count visits and traffic sources so we can
            measure and improve the performance of our site. They help us to
            know which pages are the most and least popular and see how visitors
            move around the site. All information these cookies collect is
            aggregated and therefore anonymous. If you do not allow these
            cookies we will not know when you have visited our site, and will
            not be able to monitor its performance.
          </span>
          <br />
          <button
            className="App-cookies-button"
            onClick={() => {
              if (cookiesActivated === false) {
                this.setPreferenceCookie(0);
                this.deleteCookie("_ga");
                this.deleteCookie("_gat");
                this.deleteCookie("_gid");
                window.location.reload();
              } else {
                this.setPreferenceCookie(1);
              }
              this.setState({ showCookieSettings: false });
            }}
          >
            Save settings
          </button>
        </div>
      </div>
    );
  }

  /**
   * This function set a "_c" cookie for 1 month with the user's preferences:
   * - "1" if he has accepted cookies
   * - "0" if he has refused
   * @param {String} value
   */
  setPreferenceCookie(value) {
    var now = new Date();
    now.setTime(now.getTime() + 100 * 24 * 60 * 60 * 1000);
    document.cookie =
      "_c=" + value + "; expires=" + now.toUTCString() + "; path=/";
  }

  handleChangeCookiesActivated(e) {
    const { cookiesActivated } = this.state;
    this.setState({ cookiesActivated: !cookiesActivated });
  }

  deleteCookie(name) {
    document.cookie =
      name +
      "=; Path=/; Domain=.lyradb.org; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }

  getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parseInt(parts.length) === 2)
      return parts
        .pop()
        .split(";")
        .shift();
  }
}
