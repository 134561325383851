import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AlbumCard from "./AlbumCard";
import "./css/Home.css";

export default function LastAlbumsLabel(props) {
  const [albums, setAlbums] = useState([]);
  // const [albumsIsLoaded, setAlbumsIsLoaded] = useState(false);

  const { label } = props;

  useEffect(() => {
    async function fetchAlbums() {
      try {
        const resp = await fetch(
          "/api/albums?label=" + label.name + "&limit=4"
        );
        const albums = await resp.json();
        setAlbums(albums);
        // setAlbumsIsLoaded(true);
        // handleRandomLabelIsLoaded();
        return resp;
      } catch (err) {
        console.log(err);
      }
    }
    fetchAlbums();
  }, [label]);

  if (albums.length > 0) {
    return (
      <div className="Home-list-container">
        <div className="Home-list-link">
          <Link to={"/label/" + label._id}>Last albums by {label.name}</Link>
        </div>
        <div className="Home-list">
          {albums.map((album, i) => {
            return (
              <AlbumCard
                key={i}
                album={album}
                albumId={album._id}
                className={i === 4 ? "hidden-mobile" : ""}
              />
            );
          })}
        </div>
      </div>
    );
  } else {
    return null;
  }
}
