import React, { useState, useEffect } from "react";
import OutsideAlerter from "./OutsideAlerter";
import "./css/EditSearchResults.css";

export default function EditSearchResults(props) {
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [selectedResult, setSelectedResult] = useState(0);

  const { q, searchResults, searchResultsIsLoaded, handleClick } = props;
  const numberResults = searchResults.length;

  useEffect(() => {
    setSelectedResult(0);
    if (q && searchResultsIsLoaded && numberResults > 0) {
      setShowSearchResults(true);
    } else {
      setShowSearchResults(false);
    }
  }, [q, searchResultsIsLoaded, numberResults]);

  document.onkeydown = function(e) {
    e = e || window.event;
    switch (e.which || e.keyCode) {
      case 38: // up
        if (selectedResult === 1 || selectedResult === 0) {
          setSelectedResult(numberResults);
          if (document.getElementById(numberResults))
            document.getElementById(numberResults).focus();
        } else {
          setSelectedResult(selectedResult - 1);
          if (document.getElementById(selectedResult - 1))
            document.getElementById(selectedResult - 1).focus();
        }
        break;

      case 40: // down
        if (selectedResult < searchResults.length) {
          setSelectedResult(selectedResult + 1);
          if (document.getElementById(selectedResult + 1))
            document.getElementById(selectedResult + 1).focus();
        } else {
          setSelectedResult(1);
          if (document.getElementById(1)) document.getElementById(1).focus();
        }
        break;

      default:
        return; // exit this handler for other keys
    }
    e.preventDefault(); // prevent the default action (scroll / move caret)
  };

  if (showSearchResults) {
    return (
      <OutsideAlerter onOutsideClick={() => setShowSearchResults(false)}>
        <div className="EditSearchResults">{results()}</div>
      </OutsideAlerter>
    );
  } else {
    return null;
  }

  function results() {
    const { q, searchResults } = props;
    if (q && searchResults.length > 0) {
      return (
        <div>
          <div className="EditSearchResults-suggestions">
            {searchResults.map((searchResult, i) => {
              return (
                <button
                  key={i}
                  id={i + 1}
                  className="EditSearchResults-suggestion"
                  onClick={() => {
                    handleClick(searchResult);
                    setShowSearchResults(false);
                  }}
                >
                  <span>{searchResult.title || searchResult.name}</span>
                  <span className="EditSearchResults-suggestion-grey">
                    <span className="EditSearchResults-suggestion-white">
                      {searchResult && searchResult.parent
                        ? searchResult.parent.title
                        : null}
                    </span>
                    <span>
                      {searchResult && searchResult.parent
                        ? ` (mvt. ${searchResult.movementNumber})`
                        : null}
                    </span>
                    <div>
                      {searchResult && searchResult.composers
                        ? searchResult.composers[0].name
                        : null}
                    </div>
                  </span>
                </button>
              );
            })}
          </div>
        </div>
      );
    }
  }
}
