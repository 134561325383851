import React from "react"; // , { useEffect, useState }
import { Link } from "react-router-dom";
import Frame from "./Frame";
import "./css/Page.css";

export default function Privacy(props) {
  // const [numberRecordings, setNumberRecordings] = useState("");
  // const [numberPeople, setNumberPeople] = useState("");
  // const [numberPieces, setNumberPieces] = useState("");

  // useEffect(() => {
  //   fetchCounter();
  // }, []);

  // if (numberRecordings) {
  return (
    <Frame>
      <div className="Page">
        <h1 className="Page-title">About</h1>
        <p>
          Lyra digital music library gathers metadata about classical and
          contemporary music recordings, works and artists.
        </p>
        <p>
          Lyra is completely free and collaborative. Here are some of the
          features we offer:
        </p>
        <p>
          <ul>
            <li>
              Metadata for 100,000+ recordings, works, albums, artists,
              ensembles, labels, etc. New metadata added on a daily basis.
            </li>
            <li>Integration with Spotify API.</li>
            <li>
              Possibility to filter and sort works, albums, recordings, etc. on
              many fields (e.g. filter works and recordings on instruments,
              performers, musical form, tonality, labels...).
            </li>
            <li>
              Currently more than 70 works catalogues to help navigate the works
              of all main composers (
              <Link
                to={
                  "/artist/21f25beb05ed52e3fd69f89f/works/2009a6fb1b89fd8f3696411b"
                }
              >
                Bach-Werke-Verzeichnis
              </Link>{" "}
              for Bach,{" "}
              <Link
                to={
                  "/artist/78fe93eb960f4a18ade3f444/works/0cf17f39873283c874af3b67"
                }
              >
                Köchel catalogue
              </Link>{" "}
              for Mozart,{" "}
              <Link
                to={
                  "/artist/2c2134ee1556454afe18f08a/works/a0b0b2f50015be0516dea909"
                }
              >
                Deutsch catalogue
              </Link>{" "}
              for Schubert...), and new added every weeks
            </li>
            <li>Press and users reviews</li>
          </ul>
        </p>
        <p>
          If you are interested in the project or wish to collaborate, do not
          hesitate to <Link to={"/contact"}>contact us</Link>, we would be glad
          to hear from you !
        </p>
        {/* <p>
            The platform currently contains {numberRecordings} recordings, {numberPieces} pieces and {numberPeople} artists. 
          </p> */}
      </div>
    </Frame>
  );
  // } else {
  //   return null;
  // }

  // async function fetchCounter(limit, page) {
  //   try {
  //     const resp = await fetch("/api/counters");
  //     if (resp.status === 200) {
  //       const respJSON = await resp.json();
  //       setNumberPeople(respJSON.numberPeople);
  //       setNumberRecordings(respJSON.numberRecordings);
  //       setNumberPieces(respJSON.numberPieces);
  //     }
  //     // setNewAlbums(newAlbums);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }
}
