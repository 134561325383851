import React, { useState, useEffect } from "react";
import EditText from "./EditText";
import EditPeople from "./EditPeople";
import EditEnsembles from "./EditEnsembles";
import EditPieces from "./EditPieces";
import "./css/Edit.css";

export default function EditRecordingbasic(props) {
  const { recording, onSubmit } = props;

  const [title, setTitle] = useState("");
  const [composers, setComposers] = useState([]);
  const [performers, setPerformers] = useState([]);
  const [ensembles, setEnsembles] = useState([]);
  const [pieces, setPieces] = useState([]);
  const [updatedRecording, setUpdatedRecording] = useState({});

  // Default value
  useEffect(() => {
    if (recording.title) setTitle(recording.title);
    if (recording.composers) setComposers(recording.composers);
    if (recording.performers) setPerformers(recording.performers);
    if (recording.ensembles) setEnsembles(recording.ensembles);
    if (recording.pieces) setPieces(recording.pieces);
    else setTitle("");
  }, [recording]);

  // Update recording when input change
  useEffect(() => {
    const newRecording = {};
    if (title) newRecording.title = title;
    if (composers) newRecording.composers = composers;
    if (performers) newRecording.performers = performers;
    if (ensembles) newRecording.ensembles = ensembles;
    if (pieces) newRecording.pieces = pieces;
    setUpdatedRecording(newRecording);
  }, [composers, performers, pieces, title, ensembles, setUpdatedRecording]);

  return (
    <div>
      <form
        onSubmit={e => {
          e.preventDefault();
          onSubmit(updatedRecording);
        }}
      >
        <EditText
          value={title}
          setValue={setTitle}
          placeholder="Title"
          comment="Original title of the recording, should not be modified unless obvious mistake"
        />
        <EditPieces
          pieces={pieces}
          setPieces={setPieces}
          placeholder="Pieces"
          title={
            recording.composers && recording.composers.length > 0
              ? `${recording.composers[0].name}: ${title}`
              : title
          }
        />
        <EditPeople
          people={composers}
          setPeople={setComposers}
          placeholder="Composers"
        />
        <EditPeople
          people={performers}
          setPeople={setPerformers}
          placeholder="Performers"
          editOccupations={true}
        />
        <EditEnsembles
          ensembles={ensembles}
          setEnsembles={setEnsembles}
          placeholder="Ensembles"
        />
        <button className="Button Edit-button">Update</button>
      </form>
    </div>
  );
}
