import React, { useState } from "react";
import { Link } from "react-router-dom";
import InsertEnsemble from "./InsertEnsemble";
import EditSearch from "./EditSearch";
import "./css/Edit.css";

export default function EnsembleToBeAdded(props) {
  const {
    defaultName,
    idSpotify,
    handleShowMessageBox,
    refetchAlbums,
    setType,
    searchResults
  } = props;
  const [additionalDataOptions, setAdditionalDataOptions] = useState([]);
  const [ensemble, setEnsemble] = useState(null);
  const [showSearch, setShowSearch] = useState(false);

  return (
    <div>
      {!ensemble ? (
        <div>
          <button
            className="Button Edit-button"
            onClick={() => fetchAdditionalDataOptions()}
          >
            Search on Wikidata
          </button>
          <button
            className="Button Edit-button"
            onClick={() => setShowSearch(true)}
          >
            Search ensemble
          </button>
        </div>
      ) : null}
      {additionalDataOptions &&
      additionalDataOptions.length > 0 &&
      !ensemble &&
      !showSearch ? (
        <div>
          <button
            className="Button Edit-button"
            onClick={() => setEnsemble({})}
          >
            Insert new ensemble
          </button>
          <div>or load data and insert from one of the below:</div>
          {renderAdditionalDataOptions()}
        </div>
      ) : searchResults &&
        searchResults.length > 0 &&
        !ensemble &&
        !showSearch ? (
        <div>
          <div>or select existing ensemble in database</div>
          {renderSearchResults()}
        </div>
      ) : null}
      {ensemble ? (
        <InsertEnsemble
          defaultEnsemble={ensemble}
          defaultName={defaultName}
          idSpotify={idSpotify}
          handleShowMessageBox={handleShowMessageBox}
          refetchAlbums={refetchAlbums}
          setEnsemble={setEnsemble}
          setAdditionalDataOptions={setAdditionalDataOptions}
          setType={setType}
        />
      ) : null}
      {showSearch ? (
        <div>
          <EditSearch
            collection={"ensembles"}
            handleClick={async ensemble => {
              const newEnsemble = await handleAddSpotify(
                ensemble._id,
                idSpotify
              );
              console.log(newEnsemble);
              refetchAlbums();
            }}
          />
        </div>
      ) : null}
    </div>
  );

  function renderAdditionalDataOptions() {
    if (additionalDataOptions && additionalDataOptions.length > 0) {
      return additionalDataOptions.map((additionalDataOption, i) => {
        return (
          <div key={i}>
            <button
              className="Button Edit-button Edit-button-small"
              onClick={() => fetchAdditionalData(additionalDataOption.value)}
            >
              Add
            </button>
            <a
              href={`https://www.wikidata.org/wiki/${additionalDataOption.value}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {`${additionalDataOption.name} (${additionalDataOption.description})`}
            </a>
          </div>
        );
      });
    }
  }

  function renderSearchResults() {
    if (searchResults && searchResults.length > 0) {
      return searchResults.map((searchResult, i) => {
        return (
          <div key={i}>
            <button
              className="Button Edit-button Edit-button-small"
              onClick={async () => {
                const newEnsemble = await handleAddSpotify(
                  searchResult._id,
                  idSpotify
                );
                console.log(newEnsemble);
                refetchAlbums();
              }}
            >
              Match ?
            </button>
            <Link
              to={
                searchResult.invertedName
                  ? "/artist/" + searchResult._id.toString()
                  : "/ensemble/" + searchResult._id.toString()
              }
              target="_blank"
            >
              {searchResult.name}
            </Link>
          </div>
        );
      });
    }
  }
  async function fetchAdditionalDataOptions() {
    try {
      const resp = await fetch("/api/ensembles/search/data/" + defaultName);
      const respJSON = await resp.json();
      if (respJSON && respJSON.length > 0) {
        setAdditionalDataOptions(respJSON);
      } else {
        setEnsemble({});
        handleShowMessageBox("No option to show", 3000);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchAdditionalData(id) {
    try {
      const resp = await fetch("/api/ensembles/data/" + id);
      const respJSON = await resp.json();
      if (respJSON) setEnsemble(respJSON);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleAddSpotify(id, idSpotify) {
    const body = JSON.stringify({ "externalLinks.spotify": idSpotify });
    try {
      const resp = await fetch("/api/ensembles/update/" + id, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body
      });
      const respJSON = await resp.json();
      if (resp.status === 201 && respJSON) {
        return respJSON;
      } else {
        return null;
      }
    } catch (err) {
      console.log(err);
    }
  }
}
