import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Navbar from "./Navbar.js";
import Search from "./Search.js";
import SidebarLinks from "./SidebarLinks.js";
import SidebarFooter from "./SidebarFooter.js";
import NewReleases from "./NewReleases.js";
// import LastAlbumsArtist from "./LastAlbumsArtist.js";
import LastAlbumsLabel from "./LastAlbumsLabel.js";
import Settings from "./Settings.js";
import "./css/Home.css";
import "./css/Sidebar.css";

function Home(props) {
  // const [randomArtists, setRandomArtists] = useState([]);
  const [randomLabels, setRandomLabels] = useState([]);
  const [showSettings, setShowSettings] = useState(false);
  const [newReleases, setNewReleases] = useState(false);
  const [newReleasesIsLoaded, setNewReleasesIsLoaded] = useState(false);

  document.title = "Home - Lyra digital music library";

  useEffect(() => {
    async function fetchAlbums() {
      try {
        const resp = await fetch("/api/albums/newreleases");
        const albums = await resp.json();
        setNewReleases(albums);
        setNewReleasesIsLoaded(true);
        return resp;
      } catch (err) {
        console.log(err);
      }
    }

    // async function fetchRandomArtists() {
    //   try {
    //     const resp = await fetch("/api/random/performers");
    //     const performers = await resp.json();
    //     setRandomArtists(performers);
    //     return resp;
    //   } catch (err) {
    //     console.log(err);
    //   }
    // }
    async function fetchRandomLabels() {
      try {
        const resp = await fetch("/api/random/labels");
        const labels = await resp.json();
        setRandomLabels(labels);
        return resp;
      } catch (err) {
        console.log(err);
      }
    }
    fetchAlbums();
    // fetchRandomArtists();
    fetchRandomLabels();
  }, []);

  return (
    <div className="Home">
      <div className="Sidebar">
        <SidebarLinks />
        <SidebarFooter />
      </div>
      <div className="Home-content">
        <Navbar />
        <div className="hidden-desktop">
          <Search />
        </div>
        {renderContent()}
      </div>
      <div className="Home-right"></div>
    </div>
  );

  function renderContent() {
    if (newReleasesIsLoaded) {
      return (
        <div>
          <div className="Home-title">New releases</div>
          {showSettings ? (
            <Settings handleShowSettings={() => setShowSettings(false)} />
          ) : null}
          <NewReleases newReleases={newReleases} />
          <div className="Home-title">Focus</div>
          {randomLabels.map((randomLabel, i) => {
            return <LastAlbumsLabel key={i} label={randomLabel} />;
          })}
          {/* {randomArtists.map((randomArtist, i) => {
            return <LastAlbumsArtist key={i} artist={randomArtist} />;
          })} */}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default withRouter(Home);
