import React, { useState, useEffect, useContext } from "react";
import querystring from "querystring";
import Frame from "./Frame";
import ArtistMoreInfo from "./ArtistMoreInfo";
import OfficialLinks from "./OfficialLinks";
import ArtistHeaderNavigation from "./ArtistHeaderNavigation";
import AlbumsList from "./AlbumsList";
import Sort from "./Sort";
import PiecesTable from "./PiecesTable";
import Windows from "./Windows";
import ArtistRecordingsTable from "./ArtistRecordingsTable";
import ComposerRecordingsFilters from "./ComposerRecordingsFilters";
import PerformerRecordingsFilters from "./PerformerRecordingsFilters";
import PiecesListsNavigation from "./PiecesListsNavigation";
import ComposerPiecesFilters from "./ComposerPiecesFilters";
import ComposerAlbumsFilters from "./ComposerAlbumsFilters";
import PerformerAlbumsFilters from "./PerformerAlbumsFilters";
import { format, setQueryString } from "./helpers/helpers.js";
import UserContext from "./UserContext";
import "./css/Frame.css";
import "./css/Artist.css";

export default function Artist(props) {
  const { artistId, catalogueId } = props.match.params;
  const user = useContext(UserContext);

  let page = "albums";
  if (props.match.path === "/artist/:artistId/recordings") page = "recordings";
  else if (
    props.match.path === "/artist/:artistId/works" ||
    props.match.path === "/artist/:artistId/works/:catalogueId"
  )
    page = "works";
  else if (props.match.path === "/artist/:artistId/about") page = "about";

  const [artist, setArtist] = useState(null);
  const queryString = setQueryString(props.location.search, props.match.params);

  useEffect(() => {
    async function fetchArtist(artistId) {
      try {
        const resp = await fetch("/api/artists/" + artistId);
        const artist = await resp.json();
        setArtist(artist);
        return resp;
      } catch (err) {
        console.log(err);
      }
    }
    fetchArtist(artistId);
  }, [artistId, setArtist]);

  useEffect(() => {
    if (artist && artist.name) {
      document.title = artist.name + " - Lyra digital music library";
    }
  }, [artist]);

  const queryIds = querystring.parse(queryString.substring(1));

  return (
    <Frame>
      {artist ? (
        <div className="Artist">
          <div className="Artist-header Header">
            <div className="Header-category">Artist</div>
            <div className="Header-big-title">{artist.name}</div>
            <div className="Header-subtitle">{format(artist.profile)}</div>
            <OfficialLinks externalLinks={artist.externalLinks} />
            <ArtistHeaderNavigation artist={artist} page={page} />
          </div>
          <div className="Artist-content">
            {page === "albums" ? renderAlbums() : null}
            {page === "recordings" ? renderRecordings() : null}
            {page === "works" ? renderWorks() : null}
            {page === "about" ? renderAbout() : null}
          </div>
          <div className="Artist-info"></div>
        </div>
      ) : null}
    </Frame>
  );

  function renderAlbums() {
    return (
      <div>
        {artist &&
        artist.occupations &&
        artist.occupations.includes("Composer") ? (
          <ComposerAlbumsFilters
            history={props.history}
            paramsURL={props.match.params}
            queryIds={queryIds}
            queryString={queryString}
          />
        ) : (
          <PerformerAlbumsFilters
            history={props.history}
            paramsURL={props.match.params}
            queryIds={queryIds}
            queryString={queryString}
          />
        )}
        <AlbumsList queryString={queryString} headerContent={Artist} />
      </div>
    );
  }

  function renderRecordings() {
    return (
      <div>
        {artist &&
        artist.occupations &&
        artist.occupations.includes("Composer") ? (
          <ComposerRecordingsFilters
            history={props.history}
            paramsURL={props.match.params}
            queryIds={queryIds}
            queryString={queryString}
          />
        ) : (
          <PerformerRecordingsFilters
            history={props.history}
            paramsURL={props.match.params}
            queryIds={queryIds}
            queryString={queryString}
          />
        )}
        <Windows>
          <ArtistRecordingsTable queryString={queryString} />
        </Windows>
      </div>
    );
  }

  function renderWorks() {
    return (
      <div>
        <PiecesListsNavigation
          artist={artist}
          catalogueId={catalogueId}
          path={props.match.path}
        />
        <ComposerPiecesFilters
          history={props.history}
          paramsURL={props.match.params}
          queryIds={queryIds}
          queryString={queryString}
        />
        {props.match.path !== "/artist/:artistId/works/:catalogueId" ? (
          <Sort
            queryIds={queryIds}
            choices={["Sort by name", "Sort by number of albums"]}
          />
        ) : null}
        <Windows>
          <PiecesTable
            queryString={queryString}
            headerContent={Artist}
            catalogueId={catalogueId}
          />
        </Windows>
      </div>
    );
  }

  function renderAbout() {
    return (
      <div>
        <ArtistMoreInfo artist={artist} user={user} />
      </div>
    );
  }
}
