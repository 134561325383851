import React, { useState, useEffect, useRef } from "react";
// import FavoritesEmpty from "./FavoritesEmpty.js";
import InfiniteScrolling from "./InfiniteScrolling.js";
import RecordingsTableItemUpdate from "./RecordingsTableItemUpdate.js";
import "./css/TracksTable.css";

export default function RecordingTableUpdate(props) {
  const [recordings, setRecordings] = useState([]);
  const [newRecordings, setNewRecordings] = useState([]);
  const [recordingsCounter, setRecordingsCounter] = useState(0);
  const { queryString } = props;
  const [selectedResult, setSelectedResult] = useState(0);

  async function fetchRecordings(limit, page) {
    try {
      let url = `/api/recordings/update${queryString}&limit=${limit}&page=${page}`;
      const resp = await fetch(url);
      const newRecordings = await resp.json();
      const newRecordingsCounter = await resp.headers.get("Result-Count");
      setRecordingsCounter(newRecordingsCounter);
      setNewRecordings(newRecordings);
      return resp;
    } catch (err) {
      console.log(err);
    }
  }

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevNewRecordings = usePrevious(newRecordings);

  // Show first items
  useEffect(() => {
    if (recordings.length === 0 && newRecordings.length !== 0)
      setRecordings(newRecordings);
  }, [newRecordings, recordings]);

  // add when changes
  useEffect(() => {
    if (newRecordings !== prevNewRecordings) {
      setRecordings(recordings.concat(newRecordings));
    }
  }, [newRecordings, prevNewRecordings, recordings]);

  useEffect(() => {
    setRecordings([]);
    setNewRecordings([]);
    setRecordingsCounter(0);
    async function fetchFirstRecordings() {
      try {
        let url = `/api/recordings/update${queryString}&limit=50&page=1`;
        const resp = await fetch(url);
        const newRecordings = await resp.json();
        const newRecordingsCounter = await resp.headers.get("Result-Count");
        setRecordingsCounter(newRecordingsCounter);
        setNewRecordings(newRecordings);
        return resp;
      } catch (err) {
        console.log(err);
      }
    }
    fetchFirstRecordings();
  }, [queryString]);

  document.onkeydown = function(e) {
    e = e || window.event;
    switch (e.which || e.keyCode) {
      case 38: // up
        setSelectedResult(selectedResult - 1);
        if (document.getElementById(selectedResult - 1))
          document.getElementById(selectedResult - 1).focus();
        break;

      case 40: // down
        setSelectedResult(selectedResult + 1);
        if (document.getElementById(selectedResult + 1))
          document.getElementById(selectedResult + 1).focus();
        break;

      default:
        return; // exit this handler for other keys
    }
    e.preventDefault(); // prevent the default action (scroll / move caret)
  };

  if (recordings && recordings.length > 0) {
    return (
      <InfiniteScrolling
        items={recordings}
        counter={recordingsCounter}
        limit={50}
        queryString={queryString}
        onScrollBottom={(limit, page) => {
          fetchRecordings(limit, page);
        }}
      >
        <div className="RecordingsTableUpdate">
          {`${recordingsCounter} recordings`}
          {recordings.map((recording, i) => {
            return <RecordingsTableItemUpdate key={i} i={i} recording={recording} />;
          })}
        </div>
      </InfiniteScrolling>
    );
  } else {
    return <div className="TracksTable-empty"></div>;
  }
}
