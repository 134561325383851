import React, { useState } from "react";
import PersonToBeAdded from "./PersonToBeAdded";
import EnsembleToBeAdded from "./EnsembleToBeAdded";
import "./css/Edit.css";

export default function ArtistToBeAdded(props) {
  const {
    defaultName,
    idSpotify,
    searchResults,
    handleShowMessageBox,
    refetchAlbums,
    setToBeAdded
  } = props;
  const [type, setType] = useState(null);

  return (
    <div className="Edit-box">
      <div className="Edit-box-title-small">Add artist</div>
      <div className="Edit-box-title">
        <a
          href={`https://www.google.com/search?q=${defaultName}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {defaultName}
        </a>
      </div>
      {!type ? renderIsPersonOrEnsemble() : null}
      {type === "person" ? (
        <PersonToBeAdded
          defaultName={defaultName}
          idSpotify={idSpotify}
          searchResults={searchResults}
          refetchAlbums={refetchAlbums}
          handleShowMessageBox={handleShowMessageBox}
          setType={setType}
        />
      ) : null}
      {type === "ensemble" ? (
        <EnsembleToBeAdded
          defaultName={defaultName}
          idSpotify={idSpotify}
          searchResults={searchResults}
          refetchAlbums={refetchAlbums}
          handleShowMessageBox={handleShowMessageBox}
          setType={setType}
        />
      ) : null}
    </div>
  );

  function renderIsPersonOrEnsemble() {
    return (
      <div>
        <button
          className="Button Edit-button Edit-button-small"
          onClick={() => setType("person")}
        >
          Person
        </button>
        <button
          className="Button Edit-button Edit-button-small"
          onClick={() => setType("ensemble")}
        >
          Ensemble
        </button>
        <button
          className="Button Edit-button Edit-button-small"
          onClick={() => setToBeAdded([])}
        >
          Ignore
        </button>
      </div>
    );
  }
}
