import React from "react";
import { throttle } from "./helpers/helpers.js";

export default class InfiniteScrolling extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: props.limit
    };
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener(
      "scroll",
      throttle(this.handleOnScroll.bind(this), 300)
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.queryString !== this.props.queryString) {
      // this.setState({ recordings: [], page: 1 }); // Restart at page one after filters update
      // this.setState(
      //   // {
      //   //   recordings: []
      //   // },
      //   () => {
      //     this.props.onScrollBottom(this.state.limit, this.state.page);
      //   }
      // );
    } else if (
      prevState.page !== this.state.page &&
      this.props.items.length !== 0
    ) {
      this.props.onScrollBottom(this.state.limit, this.state.page);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleOnScroll() {
    if (
      window.innerHeight + window.scrollY + 2000 >=
      document.body.offsetHeight
    ) {
      if (
        this.state.page * this.state.limit <
        parseInt(this.props.counter) + this.props.limit
      ) {
        this.loadMore();
      }
    }
  }

  loadMore() {
    if (this._isMounted) {
      this.setState({ page: this.state.page + 1 });
    }
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}
