import React, { useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import "./css/Navbar.css";
import AccountButton from "./AccountButton";
import AccountMenu from "./AccountMenu";
import SignInButton from "./SignInButton";
import LoginWindow from "./LoginWindow";
import Search from "./Search";
import UserContext from "./UserContext";

function Navbar(props) {
  const [showLoginWindow, setShowLoginWindow] = useState(false);
  const [showAccountMenu, setShowAccountMenu] = useState(false);

  const user = useContext(UserContext);
  return (
    <header className="Navbar hidden-mobile">
      <Search />
      {user && user.userId ? (
        <div className="Navbar-account">
          <AccountButton
            username={user.user.username}
            setShowAccountMenu={setShowAccountMenu}
          />
          {showAccountMenu ? (
            <AccountMenu setShowAccountMenu={setShowAccountMenu} user={user} />
          ) : null}
        </div>
      ) : (
        <div className="Navbar-buttons">
          <SignInButton
            className="hidden-mobile"
            setShowLoginWindow={setShowLoginWindow}
          />
        </div>
      )}
      {showLoginWindow ? (
        <LoginWindow setShowLoginWindow={setShowLoginWindow} />
      ) : null}
    </header>
  );
}

export default withRouter(Navbar);
