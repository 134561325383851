import React from "react";
import { Link } from "react-router-dom";
import "./css/BrowsePeriods.css";
import "./css/Sidebar.css";

export default function BrowseInstruments(props) {
  return (
    <div className="BrowsePeriods">
      <Link to="/instrument/Piano" className="BrowsePeriods-button">
        Piano
      </Link>
      <Link to="/instrument/Violin" className="BrowsePeriods-button">
        Violin
      </Link>
      <Link to="/instrument/Cello" className="BrowsePeriods-button">
        Cello
      </Link>
      <Link to="/instrument/Clarinet" className="BrowsePeriods-button">
        Clarinet
      </Link>
      <Link to="/instrument/Organ" className="BrowsePeriods-button">
        Organ
      </Link>
      <Link to="/instrument/Harpsichord" className="BrowsePeriods-button">
        Harpsichord
      </Link>
    </div>
  );
}
