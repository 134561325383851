import React, { useState } from "react";
import "./css/Review.css";
import OutsideAlerter from "./OutsideAlerter";
import ReportReview from "./ReportReview";
import MessageBox from "./MessageBox";
import EditReview from "./EditReview";

export default function Review(props) {
  const [showMenu, setShowMenu] = useState(false);
  const [showReportReview, setShowReportReview] = useState(false);
  const [showEditReview, setShowEditReview] = useState(false);
  const [message, setMessage] = useState(null);
  // const [likes, setLikes] = useState(props.review.likes);
  // const [liked, setLiked] = useState(
  //   sessionStorage.getItem(props.review._id) ? true : false
  // );
  return (
    <div className="Review">
      {showEditReview ? renderEditReview() : renderReview()}
      <MessageBox message={message} />
    </div>
  );

  function renderReview() {
    return (
      <div>
        <div className="Review-header">
          {props.review.rating ? renderRating() : null}
        </div>
        <div className="Review-subheader">
          {renderUser()}
          {renderDates()}
          {renderMore()}
        </div>
        <div className="Review-text">{props.review.text}</div>
        {/* {renderLikes()} */}
        {showReportReview ? (
          <ReportReview
            reviewId={props.review._id}
            setShowReportReview={setShowReportReview}
            setMessage={setMessage}
          />
        ) : null}
      </div>
    );
  }

  function renderRating() {
    return (
      <div className="Review-rating-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 512 512"
        >
          <path
            d="M480,208H308L256,48,204,208H32l140,96L118,464,256,364,394,464,340,304Z"
            className="Review-rating-icon"
          />
        </svg>
        <div className="Review-rating">
          <span className="Review-rating-1">{props.review.rating}</span>
          <span className="Review-rating-2">/10</span>
        </div>
      </div>
    );
  }

  function renderMore() {
    return (
      <div className="Review-header-more">
        <OutsideAlerter onOutsideClick={() => setShowMenu(false)}>
          <svg
            className="Review-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            width="16"
            height="16"
            onClick={() => setShowMenu(!showMenu)}
          >
            <path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z" />
          </svg>
          {showMenu ? renderReviewMenu() : null}
        </OutsideAlerter>
      </div>
    );
  }

  function renderUser() {
    return (
      <div className="Review-user">
        {props.review.user ? (
          <span>{props.review.user.name}</span>
        ) : (
          ""
        )}
      </div>
    );
  }

  function renderDates() {
    const updatedAt = new Date(props.review.updatedAt);
    return (
      <div className="Review-date">
        {updatedAt.toLocaleDateString("en-EN", {
          year: "numeric",
          month: "long",
          day: "numeric"
        })}
      </div>
    );
  }

  function renderReviewMenu() {
    return (
      <div className="Review-menu">
        {props.review &&
        props.review.user &&
        props.review.user.id === props.user._id ? (
          <div
            className="Review-menu-item"
            onClick={() => {
              setShowMenu(false);
              setShowEditReview(true);
            }}
          >
            Edit
          </div>
        ) : null}
        {props.review &&
        props.review.user &&
        props.review.user.id === props.user._id ? (
          <div
            className="Review-menu-item"
            onClick={() => deleteReview(props.review._id)}
          >
            Delete
          </div>
        ) : null}
        <div
          className="Review-menu-item"
          onClick={() => setShowReportReview(true)}
        >
          Report
        </div>
      </div>
    );
  }

  async function deleteReview(reviewId) {
    try {
      const resp = await fetch("/api/reviews/" + reviewId + "/delete", {
        method: "POST"
      });

      const respJSON = await resp.json();
      if (resp.status === 200) {
        props.setUserReview(null);
        // setLikes(0);
        // setLiked(false);
        sessionStorage.removeItem(reviewId);
        props.fetchAlbumReviews(props.review.album.id, () => null);
        updateAlbumUsersRating(respJSON.usersRating);
      }
    } catch (err) {
      console.log(err);
    }
  }

  // function renderLikes() {
  //   return (
  //     <div
  //       className={liked ? "Review-like Review-like-active" : "Review-like"}
  //       onClick={() => {
  //         liked ? unlikeReview(props.review._id) : likeReview(props.review._id);
  //       }}
  //     >
  //       <svg
  //         xmlns="http://www.w3.org/2000/svg"
  //         viewBox="0 0 24 24"
  //         width="16"
  //         height="16"
  //       >
  //         <path fill="none" d="M0 0h24v24H0z" />
  //         <path
  //           className="Review-like-icon"
  //           d="M2 9h3v12H2a1 1 0 0 1-1-1V10a1 1 0 0 1 1-1zm5.293-1.293l6.4-6.4a.5.5 0 0 1 .654-.047l.853.64a1.5 1.5 0 0 1 .553 1.57L14.6 8H21a2 2 0 0 1 2 2v2.104a2 2 0 0 1-.15.762l-3.095 7.515a1 1 0 0 1-.925.619H8a1 1 0 0 1-1-1V8.414a1 1 0 0 1 .293-.707z"
  //         />
  //       </svg>
  //       {likes > 0 ? <span className="Review-like-text">{likes}</span> : null}
  //     </div>
  //   );
  // }

  // async function likeReview(reviewId) {
  //   try {
  //     const resp = await fetch("/api/reviews/" + reviewId + "/like", {
  //       method: "POST"
  //     });
  //     const respJSON = await resp.json();
  //     if (resp.status === 200) {
  //       setLikes(respJSON.likes);
  //       setLiked(true);
  //       sessionStorage.setItem(reviewId, "1");
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  // async function unlikeReview(reviewId) {
  //   try {
  //     const resp = await fetch("/api/reviews/" + reviewId + "/unlike", {
  //       method: "POST"
  //     });
  //     const respJSON = await resp.json();
  //     if (resp.status === 200) {
  //       setLikes(respJSON.likes);
  //       setLiked(false);
  //       sessionStorage.removeItem(reviewId);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  function renderEditReview() {
    return (
      <EditReview
        album={props.album}
        setAlbum={props.setAlbum}
        user={props.user}
        userReview={props.userReview}
        setUserReview={props.setUserReview}
        showEditReview={showEditReview}
        setShowEditReview={setShowEditReview}
        fetchAlbumReviews={props.fetchAlbumReviews}
      />
    );
  }
  
  function updateAlbumUsersRating(usersRating) {
    let newAlbum = Object.assign({}, props.album);
    newAlbum.usersRating = usersRating;
    props.setAlbum(newAlbum);
  }
}
