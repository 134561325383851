import React from "react";

export default function EditText(props) {
  const { value, setValue, placeholder, comment, hideLabel } = props;

  return (
    <div className={hideLabel ? "" : "Edit-input-container"}>
      {hideLabel ? null : (
        <label className="Edit-input-label">{placeholder}</label>
      )}
      <input
        type="text"
        placeholder={placeholder}
        className="Edit-input"
        value={value}
        onChange={e => setValue(e.currentTarget.value)}
      />
      <div className="Edit-input-comment">{comment}</div>
    </div>
  );
}
