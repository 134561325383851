import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ListArtists from "./ListArtists";
import ButtonPlayOnSpotify from "./ButtonPlayOnSpotify.js";
import { convertDateToYear } from "./helpers/helpers";
import "./css/RecordingsTable.css";

export default function ArtistRecordingsTableItem(props) {
  const [recordings, setRecordings] = useState(null);
  const [showRecordings, setShowRecordings] = useState(false);
  const {
    recording,
    handleShowLoginToSpotify,
    handleShowMessageBox,
    handleShowSpotifyDevicesWindow
  } = props;

  useEffect(() => {
    if (showRecordings) {
      async function fetchAllRecordings() {
        if (recording && recording.piece && recording.piece.id) {
          const recordingAlbum = recording.albums[0];
          try {
            let url = `/api/recordings?albumId=${recordingAlbum.id}&pieceId=${recording.piece.id}`;
            const resp = await fetch(url);
            const recordings = await resp.json();
            setRecordings(recordings);
            return resp;
          } catch (err) {
            console.log(err);
          }
        }
      }
      fetchAllRecordings();
    }
  }, [showRecordings, setShowRecordings]);

  if (recording && recording.piece && recording.piece.isParent) {
    return (
      <div className="RecordingsTable-table-row">
        <div className="RecordingsTable-table-row-data">
          {/* {renderPlayButton(recording)} */}
          {renderAlbumCover(recording)}
          <div
            className="RecordingsTable-table-row-right"
            onClick={() => {
              setShowRecordings(!showRecordings);
            }}
          >
            {renderText(recording)}
            {renderDropdown()}
          </div>
        </div>
        {showRecordings && recordings && recordings.length > 0 ? (
          <div className="RecordingsTable-table-row-list">
            {renderAllRecordings()}
          </div>
        ) : null}
      </div>
    );
  } else {
    return (
      <div className="RecordingsTable-table-row">
        <div className="RecordingsTable-table-row-data">
          {/* {renderPlayButton(recording)} */}
          {renderAlbumCover(recording)}
          <Link 
          className="RecordingsTable-table-row-right"
          to={`/recording/${recording.recordingId}`}>{renderText(recording)}</Link>
        </div>
        {showRecordings && recordings && recordings.length > 0 ? (
          <div className="RecordingsTable-table-row-list">
            {renderAllRecordings()}
          </div>
        ) : null}
      </div>
    );
  }

  function renderAlbumCover(recording) {
    if (recording && recording.albums && recording.albums.length > 0) {
      return (
        <div className="RecordingsTable-table-row-data-image">
          <Link to={`/album/${recording.albums[0].id}`}>
            <img
              width="50px"
              src={`https://d31zararll8aqx.cloudfront.net/albums/300/${recording.albums[0].id}.jpg`}
              alt="cover"
            />
          </Link>
        </div>
      );
    }
  }

  function renderText(recording) {
    const { piece, composers } = recording;
    if (recording && recording.albums && recording.albums.length > 0) {
      return (
        <div className="RecordingsTable-table-row-data-text">
          <div className="RecordingsTable-table-row-data-text-composer">
            {composers && composers.length > 0 ? composers[0].name : null}
          </div>
          <div className="RecordingsTable-table-row-data-text-title">
            {piece ? piece.title : null}
          </div>
          <div className="RecordingsTable-table-row-data-text-date">
            {recording.albums[0] && recording.albums[0].releaseDate
              ? convertDateToYear(recording.albums[0].releaseDate)
              : null}
          </div>
        </div>
      );
    }
  }

  // function renderPlayButton(recordingItem) {
  //   if (
  //     recordingItem &&
  //     recordingItem.albums &&
  //     recordingItem.albums.length > 0
  //   ) {
  //     const recordingAlbum = recordingItem.albums[0];
  //     if (
  //       recordingAlbum.externalLinks &&
  //       recordingAlbum.externalLinks.spotify
  //     ) {
  //       return (
  //         <div className="RecordingsTable-table-row-data-play">
  //           <ButtonPlayOnSpotify
  //             albumIdSpotify={recordingAlbum.externalLinks.spotify}
  //             trackTitle={recordingItem.title}
  //             trackPosition={recordingAlbum.trackPosition}
  //             handleShowMessageBox={handleShowMessageBox}
  //             handleShowSpotifyDevicesWindow={handleShowSpotifyDevicesWindow}
  //             handleShowLoginToSpotify={handleShowLoginToSpotify}
  //             small={true}
  //           />
  //         </div>
  //       );
  //     }
  //   }
  // }

  function renderAllRecordings() {
    if (recordings && recordings.length > 0)
      return recordings.map((recordingItem, i) => (
        <div className="RecordingsTable-recording" key={i}>
          {/* {renderPlayButton(recordingItem)} */}
          <Link
            to={`/recording/${recordingItem._id}`}
            className="RecordingsTable-recording-link"
          >
            {`${i + 1}. ${recordingItem.title}`}
          </Link>
          {/* <div className="RecordingsTable-recording-button"></div> */}
        </div>
      ));
  }

  function renderDropdown() {
    return (
      <div className="RecordingsTable-table-row-data-dropdown">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="32"
          height="32"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M12 15l-4.243-4.243 1.415-1.414L12 12.172l2.828-2.829 1.415 1.414z"
            fill="rgba(255,255,255,1)"
          />
        </svg>
      </div>
    );
  }
}
