import React, { useState, useEffect } from "react";
import { Types } from "mongoose";
import EditPieceBasic from "./EditPieceBasic";
import "./css/Edit.css";
import { copyParentData } from "./helpers/helpers";

export default function InsertMovement(props) {
  const { piece, upsertPiece, reload } = props;

  const [updatedPiece, setUpdatedPiece] = useState({});
  const [id, setId] = useState(null);

  // Default values copied from parent piece
  useEffect(() => {
    const newMovement = copyParentData({}, piece);
    setUpdatedPiece(newMovement);
    setId(new Types.ObjectId());
  }, [piece, setUpdatedPiece]);

  return (
    <div className="Edit-input-container">
      <h2>Insert new movement</h2>
      <EditPieceBasic
        parentPiece={piece}
        piece={updatedPiece}
        onSubmit={newPieceBasic => handleInsertMovement(newPieceBasic)}
      />
    </div>
  );

  function handleInsertMovement(newPieceBasic) {
    const newMovementsInParent = [...piece.movements];
    const newMovementInParent = {
      id: id.toString(),
      title: newPieceBasic.movementTitle,
      number: newPieceBasic.movementNumber
    };
    newMovementsInParent.push(newMovementInParent);
    // insert new movement
    upsertPiece(id, { ...updatedPiece, ...newPieceBasic }, () => {
      // update parent piece
      upsertPiece(
        piece._id,
        { movements: newMovementsInParent },
        newPieceId => {
          setId(null);
          setUpdatedPiece({});
          reload(newPieceId);
        }
      );
    });
  }
}
