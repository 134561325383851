import React, { useState } from "react";
import SetOccupationsFromList from "./SetOccupationsFromList";
import "./css/Edit.css";

export default function OccupationToSet(props) {
  const {
    occupationToSet,
    // handleSetOccupationsAll,
    handleSetOccupationsThisTrack,
    handleSetOccupationsThisAlbum,
    i
  } = props;
  const [occupations, setOccupations] = useState(null);

  return (
    <div className="Edit-box">
      <div className="Edit-box-title-small">Select occupation</div>
      {occupationToSet.albumTitle ? (
        <div className="Edit-box-title-small">{`Album ${occupationToSet.indexAlbum +
          1}: ${occupationToSet.albumTitle}`}</div>
      ) : null}
      {occupationToSet.trackTitle ? (
        <div className="Edit-box-title-small">{`Track ${occupationToSet.indexTrack +
          1}: ${occupationToSet.trackTitle}`}</div>
      ) : null}
      <div className="Edit-box-title">{occupationToSet.name}</div>
      <SetOccupationsFromList
        occupations={occupations}
        setOccupations={setOccupations}
        list={occupationToSet.occupations}
      />
      <button
        className="Button Edit-button"
        onClick={() =>
          handleSetOccupationsThisAlbum(occupationToSet, occupations, i)
        }
      >
        Update this album
      </button>
      {occupationToSet.trackId ? (
        <button
          className="Button Edit-button"
          onClick={() =>
            handleSetOccupationsThisTrack(occupationToSet, occupations, i)
          }
        >
          Update this track
        </button>
      ) : null}
    </div>
  );
}
