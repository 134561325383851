import React from "react";
import PeriodHeaderNavigation from "./PeriodHeaderNavigation.js";
import "./css/Header.css";

export default function ArtistHeader(props) {
  const { period, page } = props;
  if (period) {
    return (
      <div className="Header">
          <div className="Header-big-title">{period}</div>
        <PeriodHeaderNavigation period={period} page={page} />
      </div>
    );
  } else {
    return null;
  }
}
