import React from "react";
import { Link } from "react-router-dom";
import "./css/Navigation.css";

export default function BrowseNavigation(props) {
  const {
    path
  } = props;

  return (
    <div className="Navigation">
      <div className="Navigation-row hidden-desktop">
        <Link
          to="/composers"
          className={
            path === "/composers"
              ? "Navigation-button Navigation-button-active"
              : "Navigation-button"
          }
        >
          Composers
        </Link>
        <Link
          to="/performers?occupation=Conductor"
          className={
            path === "/performers"
              ? "Navigation-button Navigation-button-active"
              : "Navigation-button"
          }
        >
          Performers
        </Link>
        <Link
          to="/periods"
          className={
            path === "/periods"
              ? "Navigation-button Navigation-button-active"
              : "Navigation-button"
          }
        >
          Periods
        </Link>
        <Link
          to="/genres"
          className={
            path === "/genres"
              ? "Navigation-button Navigation-button-active"
              : "Navigation-button"
          }
        >
          Genres
        </Link>
        {/* <Link
          to="/pieces?category=Orchestral"
          className={
            path === "/pieces"
              ? "Navigation-button Navigation-button-active"
              : "Navigation-button"
          }
        >
          Pieces
        </Link> */}
      </div>
    </div>
  );
}
