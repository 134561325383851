import React from "react";
import BrowseNavigation from "./BrowseNavigation.js";
import BrowsePeriods from "./BrowsePeriods.js";
import BrowseGenres from "./BrowseGenres.js";
import BrowseInstruments from "./BrowseInstruments.js";
import "./css/Browse.css";
import "./css/Sidebar.css";
import Navbar from "./Navbar.js";
import SidebarFooter from "./SidebarFooter.js";
import SidebarLinks from "./SidebarLinks.js";

export default function Browse(props) {
  return (
    <div className="Browse">
      <div className="Sidebar">
        <SidebarLinks />
        <SidebarFooter />
      </div>
      <div className="Browse-content">
        <Navbar />
        <BrowseNavigation path={props.location.pathname} />
        {renderContent()}
      </div>
    </div>
  );

  function renderContent() {
    if (props.location.pathname === "/periods") {
    document.title = 'Periods - Lyra digital music library';
    return <BrowsePeriods />;
  } else if (props.location.pathname === "/genres") {
    document.title = 'Genres - Lyra digital music library';
    return <BrowseGenres />;
  } else if (props.location.pathname === "/instruments") {
    document.title = 'Instruments - Lyra digital music library';
      return <BrowseInstruments />;
    }
  }
}
