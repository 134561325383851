import React from "react";
import InsertVersion from "./InsertVersion";
import "./css/Edit.css";

export default function EditVersions(props) {
  const { piece, upsertPiece, reload, handleShowMessageBox } = props;

  return (
    <div>
      {showVersions()}
      <InsertVersion
        piece={piece}
        upsertPiece={upsertPiece}
        reload={reload}
      />
    </div>
  );

  function showVersions() {
    if (piece.versions && piece.versions.length > 0) {
      return (
        <div className="Edit-input-container">
          {piece.versions.map((version, i) => {
            return (
              <div key={i}>
                <div className="Edit-input-container">
                  <label>{`#${i + 1}`}</label>
                  <input
                    type="text"
                    placeholder="Title"
                    className="Edit-input"
                    value={version.title}
                    onChange={e => {
                      const newVersionsInParent = [...piece.versions];
                      newVersionsInParent[i].title = e.currentTarget.value;
                    }}
                  />
                </div>
                <button
                  onClick={() => {
                    upsertPiece(
                      piece._id,
                      { versions: piece.versions },
                      () => handleShowMessageBox("Updated", 1000)
                    );
                  }}
                >
                  Update
                </button>
                <span> | </span>
                <a
                  href={`/piece/${version.id}/edit`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Edit
                </a>
                <span> | </span>
                <button
                  onClick={e => {
                    e.preventDefault();
                    removeVersion(i);
                  }}
                >
                  {" "}
                  Remove
                </button>
              </div>
            );
          })}
        </div>
      );
    } else {
      return null;
    }
  }

  function removeVersion(i) {
    if (window.confirm("Delete piece ?")) {
      const newVersions = [...piece.versions];
      newVersions.splice(i, 1);

      upsertPiece(piece._id, { versions: newVersions }, async () => {
        try {
          const resp = await fetch(
            "/api/pieces/" + piece.versions[i].id + "/delete",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              }
            }
          );
          if (resp.status === 200) {
            reload()
          }
        } catch (err) {
          console.log(err);
        }
      });
    }
  }
}
