import React from "react";
import { Link } from "react-router-dom";
import "./css/HeaderNavigation.css";

export default function PeriodHeaderNavigation(props) {
  const { period, page } = props;
  if (period) {
    return (
      <div className="HeaderNavigation">
        {renderButtonAlbums()}
        {renderButtonRecordings()}
      </div>
    );
  } else {
    return null;
  }

  function renderButtonAlbums() {
    return (
      <Link
        to={"/period/" + period}
        className={
          page === "albums"
            ? "HeaderNavigation-button HeaderNavigation-button-active"
            : "HeaderNavigation-button"
        }
      >
        Albums
      </Link>
    );
  }

  function renderButtonRecordings() {
    return (
      <Link
        to={"/period/" + period + "/recordings"}
        className={
          page === "recordings"
            ? "HeaderNavigation-button HeaderNavigation-button-active"
            : "HeaderNavigation-button"
        }
      >
        Recordings
      </Link>
    );
  }
}
