import React, { useState } from "react";
import EditText from "./EditText";
import "./css/Edit.css";
import { copyParentData } from "./helpers/helpers";

export default function EditLoadMovements(props) {
  const { piece, handleShowMessageBox } = props;
  const [replace, setReplace] = useState("");
  const [replaceBy, setReplaceBy] = useState("");
  const [ids, setIds] = useState([""]);
  const [movements, setMovements] = useState([]);
  const [updatedPiece, setUpdatedPiece] = useState({});

  return (
    <div className="Edit-sections">
      <div>
        <div className="Edit-section-left">
          {showIdInputs()}
          {showReplace()}
          {showMovements()}
          <button
            className="Button Edit-button"
            onClick={e => {
              e.preventDefault();
              if (movements && movements.length > 0) {
                saveMovements(() => {
                  updatePiece();
                });
              }
            }}
          >
            Update
          </button>
          {movements && movements.length > 0 ? (
            <button
              className="Button Edit-button"
              onClick={e => {
                e.preventDefault();
                changeTonalities();
              }}
            >
              Change tonalities
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );

  function showIdInputs() {
    if (ids && ids.length > 0) {
      return (
        <div>
          {ids.map((id, i) => {
            return (
              <div key={i}>
                <EditText
                  value={id}
                  setValue={newId => {
                    const newIds = [...ids];
                    newIds[i] = newId;
                    newIds[i + 1] = "";
                    setIds(newIds);
                  }}
                  placeholder={`id ${i + 1}`}
                />
                <button
                  className="Button Edit-button"
                  onClick={e => {
                    e.preventDefault();
                    fetchMovements(id);
                  }}
                >
                  Update
                </button>
              </div>
            );
          })}
        </div>
      );
    } else {
      return null;
    }
  }

  function showReplace() {
    return (
      <div>
        <EditText value={replace} setValue={setReplace} placeholder="Replace" />
        <EditText
          value={replaceBy}
          setValue={setReplaceBy}
          placeholder="Replace by"
        />
        <button
          className="Button Edit-button"
          onClick={e => {
            e.preventDefault();
            if (replace && movements && movements.length > 0) {
              const newMovements = [...movements];
              newMovements.map(newMovement => {
                newMovement.movementTitle = newMovement.movementTitle.replace(
                  replace,
                  replaceBy
                );
                return newMovement;
              });
              setMovements(newMovements);
            }
          }}
        >
          Replace
        </button>
      </div>
    );
  }

  function showMovements() {
    if (movements && movements.length > 0) {
      return (
        <div>
          {movements.map((movement, i) => {
            return (
              <div key={i}>
                <EditText
                  value={movement.movementTitle}
                  setValue={newTitle => updateMovementTitle(i, newTitle)}
                  placeholder={`Movement #${i + 1}`}
                />
                <button onClick={() => removeMovement(i)}>remove</button>
              </div>
            );
          })}
        </div>
      );
    } else {
      return null;
    }
  }

  function removeMovement(i) {
    const newMovements = [...movements];
    newMovements.splice(i, 1);
    newMovements.map((movement, i) => (movement.movementNumber = i + 1));
    setMovements(newMovements);
  }

  function updateMovementTitle(i, newTitle) {
    const newMovements = [...movements];
    newMovements[i].movementTitle = newTitle;
    setMovements(newMovements);
  }

  function changeTonalities() {
    const newMovements = [...movements];
    newMovements.map(newMovement => {
      newMovement.movementTitle = newMovement.movementTitle
        .replace("-sharp", "♯")
        .replace("-flat", "♭");
      return newMovement;
    });
    setMovements(newMovements);
  }

  async function fetchMovements(id) {
    try {
      const resp = await fetch("/api/pieces/movements/" + id);
      const body = await resp.json();

      const newMovements = [...movements];
      setMovements(newMovements.concat(body.movements));

      const updatedPiece = body;
      delete updatedPiece.movements;
      setUpdatedPiece(updatedPiece);

      return resp;
    } catch (err) {
      console.log(err);
    }
  }

  async function saveMovements(callback) {
    const newMovements = [];
    movements.forEach((newMovement, i) => {
      const newMovement2 = copyParentData(newMovement, piece);
      newMovement2.movementNumber = i + 1;
      newMovement2.title = piece.title + ": " + newMovement.movementTitle;
      newMovement2.searchTitle =
        piece.composers[0].name + ": " + newMovement2.title;
      newMovement2.searchTitles = [newMovement2.searchTitle];
      newMovements.push(newMovement2);
    });
    const body = JSON.stringify({ movements: newMovements });

    try {
      const resp = await fetch(
        "/api/pieces/movements/save/" + piece._id.toString(),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body
        }
      );
      if (resp.status === 201) {
        callback();
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function updatePiece() {
    const body = JSON.stringify(updatedPiece);
    try {
      const resp = await fetch("/api/pieces/" + piece._id + "/edit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body
      });
      if (resp.status === 201) {
        handleShowMessageBox("Saved", 1000);
      }
    } catch (err) {
      console.log(err);
    }
  }
}
