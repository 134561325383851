import React from "react";

export default function EditMovementTitle(props) {
  const { profile, setProfile } = props;

  return (
    <div className="Edit-input-container">
      <label>Profile</label>
      <textarea
        placeholder="Profile"
        onChange={e => setProfile(e.currentTarget.value)}
        defaultValue={profile}
        className="Edit-textarea"
      />
    </div>
  );
}
