import React, { useState, useEffect, useRef } from "react";
import AlbumCard from "./AlbumCard.js";
import InfiniteScrolling from "./InfiniteScrolling.js";
import "./css/AlbumsList.css";

export default function AlbumsList(props) {
  const { queryString } = props;
  const [albums, setAlbums] = useState([]);
  const [newAlbums, setNewAlbums] = useState([]);
  const [albumsCounter, setAlbumsCounter] = useState(0);

  async function fetchAlbums(limit, page) {
    try {
      let url = `/api/albums${queryString}&limit=${limit}&page=${page}`;
      const resp = await fetch(url);
      if (resp.status !== 401) {
        const newAlbums = await resp.json();
        const newAlbumsCounter = resp.headers.get("Result-Count");
        setAlbumsCounter(newAlbumsCounter);
        setNewAlbums(newAlbums);
      }
    } catch (err) {
      console.log(err);
    }
  }

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevNewAlbums = usePrevious(newAlbums);

  // Show first items
  useEffect(() => {
    if (albums.length === 0 && newAlbums.length !== 0) setAlbums(newAlbums);
  }, [newAlbums, albums]);

  // add when changes
  useEffect(() => {
    if (newAlbums !== prevNewAlbums) {
      setAlbums(albums.concat(newAlbums));
    }
  }, [newAlbums, prevNewAlbums, albums]);

  // Reset values when queryStrings changes
  useEffect(() => {
    setAlbums([]);
    setNewAlbums([]);
    setAlbumsCounter(0);
    async function fetchFirstAlbums() {
      try {
        let url = `/api/albums${queryString}&limit=35&page=1`;
        const resp = await fetch(url);
        if (resp.status !== 401) {
          const newAlbums = await resp.json();
          const newAlbumsCounter = resp.headers.get("Result-Count");
          setAlbumsCounter(newAlbumsCounter);
          setNewAlbums(newAlbums);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchFirstAlbums();
  }, [queryString]);

  if (albums && albums.length > 0) {
    return (
      <InfiniteScrolling
        items={albums}
        counter={albumsCounter}
        limit={35}
        queryString={queryString}
        onScrollBottom={(limit, page) => {
          fetchAlbums(limit, page);
        }}
      >
        <div className="AlbumsList-counter">
          {albumsCounter > 0 ? albumsCounter + " albums" : ""}
        </div>
        <div className="AlbumsList">
          {albums.map((album, i) => (
            <AlbumCard key={i} album={album} albumId={album._id} />
          ))}
        </div>
      </InfiniteScrolling>
    );
  } else {
    return <div className="TracksTable-empty"></div>;
  }
}
