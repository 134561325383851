import React from "react";
import EditSearch from "./EditSearch";
import EditEnsemblesItem from "./EditEnsemblesItem";

export default function EditEnsembles(props) {
  const { ensembles, setEnsembles, placeholder, editOccupations } = props;

  return (
    <div className="Edit-input-container">
      <label>{placeholder}</label>
      {ensembles && ensembles.length > 0
        ? ensembles.map((ensemble, i) => (
            <EditEnsemblesItem
              ensemble={ensemble}
              handleRemoveEnsemble={handleRemoveEnsemble}
              i={i}
              key={i}
            />
          ))
        : null}
      <EditSearch
        collection={"ensembles"}
        handleClick={ensemble => addEnsemble(ensemble)}
        placeholder="Add ensemble"
      />
    </div>
  );

  function addEnsemble(ensemble) {
    const newEnsembles = [...ensembles];
    const newEnsemble = {
      id: ensemble._id.toString(),
      name: ensemble.englishName || ensemble.name
    };
    if (editOccupations) newEnsemble.occupations = ensemble.occupations;

    newEnsembles.push(newEnsemble);
    setEnsembles(newEnsembles);
  }

  function handleRemoveEnsemble(i) {
    const newEnsembles = [...ensembles];
    newEnsembles.splice(i, 1);
    setEnsembles(newEnsembles);
  }
}
