import React from "react";
import Select from "./Select";

export default function EditOccupations(props) {
  const { occupations, setOccupations } = props;

  return (
    <div className="Edit-input-container">
      <label>Occupations</label>
      <Select
        placeholder="Occupations"
        options={[
          "Composer",
          "Conductor",
          "Pianist",
          "Violinist",
          "Violist",
          "Cellist",
          "Flutist",
          "Soprano",
          "Mezzo-soprano",
          "Alto",
          "Contralto",
          "Countertenor",
          "Tenor",
          "Bandoneon player",
          "Baritone",
          "Bass-baritone",
          "Bass",
          "Biwa player",
          "Accordionist",
          "Arranger",
          "Bassoonist",
          "Bass clarinetist",
          "Cadenzor",
          "Celesta player",
          "Chitarrone player",
          "Choirmaster",
          "Choral conductor",
          "Clarinetist",
          "Claviorgan player",
          "Concertmaster",
          "Cornettist",
          "Double-bassist",
          "Drummer",
          "Fortepianist",
          "Guitarist",
          "Koto player",
          "Harmonica player",
          "Harpist",
          "Harpsichordist",
          "Horn player",
          "Keyboardist",
          "Librettist",
          "Lutenist",
          "Lyricist",
          "Mandolinist",
          "Music director",
          "Musicologist",
          "Narrator",
          "Oboist",
          "Ondist",
          "Opera singer",
          "Organist",
          "Percussionist",
          "Recorder player",
          "Saxophonist",
          "Serpent player",
          "Shakuhachi player",
          "Shō player",
          "Singer",
          "Songwriter",
          "Speaker",
          "Theorbist",
          "Theorist",
          "Transcriber",
          "Trombonist",
          "Trumpeter",
          "Vibraphonist",
          "Viol player",
          "Violone player",
          "Zither player",
          "Dessus",
          "Bas-dessus",
          "Haute-contre",
          "Taille",
          "Basse-taille",
          "Basse-contre",
          "Editor",
          "Sound engineer",
          "Producer"
        ]}
        isSearchable={true}
        isMultiple={true}
        value={occupations}
        onClear={e => setOccupations([])}
        onChange={e => {
          setOccupations(e);
        }}
      />
    </div>
  );
}
