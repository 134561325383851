import React from "react";
import { Link, withRouter } from "react-router-dom";
import "./css/AlbumCard.css";

class AlbumCard extends React.Component {
  render() {
    const { album, albumId } = this.props;
    if (album && album.title) {
      return (
        <div className={"AlbumCard " + (this.props.className || "")}>
          <div className="AlbumCard-cover-container">
            <Link to={this.redirectLink(album)}>
              <img
                className="AlbumCard-cover"
                onTouchStart={() => null}
                src={`https://d31zararll8aqx.cloudfront.net/albums/300/${albumId ||
                  album._id}.jpg`}
                alt={albumId}
              />
            </Link>
          </div>
          <div className="AlbumCard-infos-container">
            <Link className="AlbumCard-title" to={`/album/${albumId}`}>
              {album.title.length > 20
                ? album.title.substr(0, 16) + "..."
                : album.title}
            </Link>
            <br />
            <div className="AlbumCard-performers-container" id={albumId}>
              {this.artists(album)}
              {/* {this.composers(album)} */}
              {/* {this.performers(album)} */}
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  artists(album) {
    if (album) {
      let artists = [];
      let length = 0;
      if (album.composers && album.composers.length > 0) {
        artists.push(album.composers[0]);
        length += album.composers.length;
      }
      if (album.performers && album.performers.length > 0) {
        artists.push(album.performers[0]);
        length += album.performers.length;
      } else if (album.ensembles && album.ensembles.length > 0) {
        artists.push(album.ensembles[0]);
        length += album.ensembles.length;
      }
      return (
        <div>
          {artists.map((artist, i) => {
            return (
              <span key={i}>
                {i !== 0 ? ", " : ""}
                <Link
                  className="AlbumCard-performers"
                  onClick={this.clearState}
                  to={"/artist/" + artist.id}
                >
                  {artist.name}
                </Link>
              </span>
            );
          })}
          {length > 2 ? ",..." : ""}
        </div>
      );
    } else {
      return null;
    }
  }

  composers(album) {
    if (album.composers) {
      return album.composers.slice(0, 1).map((composer, i) => {
        return (
          <span key={i}>
            <Link
              className="AlbumCard-performers"
              onClick={this.clearState}
              to={"/artist/" + composer.id}
            >
              {composer.name}
            </Link>
            {i === 0 && album.performers && album.performers.length > 0 ? (
              <br />
            ) : (
              ""
            )}
          </span>
        );
      });
    }
  }

  performers(album) {
    if (album.performers) {
      return album.performers.slice(0, 1).map((performer, i) => {
        return (
          <span key={i}>
            <Link
              className="AlbumCard-performers"
              onClick={this.clearState}
              to={"/artist/" + performer.id}
            >
              {performer.name}
            </Link>
            {/* {i === 0 && album.performers.length > 1 ? ", " : ""} */}
            {i === 1 && album.performers.length > 1 ? "..." : ""}
          </span>
        );
      });
    }
  }

  redirectLink(album) {
    const { match, albumId } = this.props;
    let redirect = `/album/${albumId}`;
    if (match && match.params && match.params.pieceId)
      redirect = `${redirect}?piece=${match.params.pieceId}`;
    return redirect;
  }
}

export default withRouter(AlbumCard);
