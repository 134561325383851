import React from "react";
import EditSearch from "./EditSearch";
import "./css/Edit.css";

export default function EditRecordingQuick(props) {
  const { recording, callbackAfterClick } = props;

  return <div className="Edit">{renderPieces(recording)}</div>;

  function renderPieces(recording) {
    if (recording.pieces) {
      return (
        <div>
          <span>Pieces</span>
          {recording.pieces.map((piece, i) => {
            return (
              <div key={i}>
                {piece.title}
                <button onClick={() => handleRemovePiece(piece)}>Remove</button>
              </div>
            );
          })}
          <EditSearch collection={"pieces"} handleClick={handleClick} />
        </div>
      );
    }
  }

  async function handleRemovePiece(piece) {
    try {
      const pieces = [];
      recording.pieces.forEach(recordingPiece => {
        if (recordingPiece.id !== piece.id) {
          pieces.push({
            id: recordingPiece.id,
            title: piece.title
          });
        }
      });
      const newRecording = { pieces };
      const resp = await fetch(`/api/recordings/update/${recording._id}`, {
        method: "POST",
        body: JSON.stringify(newRecording),
        headers: {
          "Content-Type": "application/json"
        }
      });
      if (resp.status === 201) {
        // fetchRecording(recording._id);
        callbackAfterClick();
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function handleClick(pieceDB) {
    try {
      const pieces = [...recording.pieces];
      const newPiece = {
        id: pieceDB._id,
        title: pieceDB.title
      };
      if (pieceDB.movements && pieceDB.movements.length > 0) {
        newPiece.isParent = true;
      }
      if (pieceDB.parent) {
        newPiece.isMovement = true;
      }
      pieces.push(newPiece);
      if (pieceDB.parent)
        pieces.push({
          id: pieceDB.parent.id,
          title: pieceDB.parent.title,
          isParent: true
        });
      const newRecording = {};
      newRecording.pieces = pieces;
      if (pieceDB.composers) {
        newRecording.composers = pieceDB.composers;
      }

      const resp = await fetch(`/api/recordings/update/${recording._id}`, {
        method: "POST",
        body: JSON.stringify(newRecording),
        headers: {
          "Content-Type": "application/json"
        }
      });
      if (resp.status === 201) {
        // fetchRecording(recording._id);
        callbackAfterClick();
      }
    } catch (err) {
      console.log(err);
    }
  }
}
