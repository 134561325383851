import React from "react";
import { Link } from "react-router-dom";
import "./css/AlbumsTableItemUpdateRecording.css";

export default function Album(props) {
  const { recording, trackM, mapped } = props;

  return (
    <div
      className={
        mapped
          ? "AlbumsTableItemUpdateRecording AlbumsTableItemUpdateRecording-green"
          : "AlbumsTableItemUpdateRecording"
      }
    >
      <div className="AlbumsTableItemUpdateRecording-row">{trackM.number}</div>
      <Link to={"/recording/"+recording._id} className="AlbumsTableItemUpdateRecording-row">
        {recording.title}
      </Link>
      <div className="AlbumsTableItemUpdateRecording-row">{trackM.title}</div>
      <br />
    </div>
  );
}
