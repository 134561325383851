import React, { useState, useEffect } from "react";
import "./css/SelectOptions.css";

export default function SelectOptions(props) {
  const {
    options,
    optionsAfterSearch,
    optionKey,
    handleClickValue,
    value
  } = props;

  const [selectedResult, setSelectedResult] = useState(1);
  const numberResults = optionsAfterSearch
    ? optionsAfterSearch.length
    : options
    ? options.length
    : 1;

  useEffect(() => {
    setSelectedResult(1);
    if (document.getElementById(1)) {
      document.getElementById(1).focus();
    }
  }, [numberResults]);

  document.onkeydown = function(e) {
    e = e || window.event;
    switch (e.which || e.keyCode) {
      case 38: // up
        if (selectedResult === 1) {
          setSelectedResult(numberResults + 1);
          if (document.getElementById(numberResults + 1))
            document.getElementById(numberResults + 1).focus();
        } else {
          setSelectedResult(selectedResult - 1);
          if (document.getElementById(selectedResult - 1))
            document.getElementById(selectedResult - 1).focus();
        }
        break;

      case 40: // down
        if (selectedResult < numberResults + 1) {
          setSelectedResult(selectedResult + 1);
          if (document.getElementById(selectedResult + 1))
            document.getElementById(selectedResult + 1).focus();
        } else {
          setSelectedResult(1);
          if (document.getElementById(1)) document.getElementById(1).focus();
        }
        break;

      default:
        return; // exit this handler for other keys
    }
    e.preventDefault(); // prevent the default action (scroll / move caret)
  };

  return (
    <div className="SelectOptions-container">
      {(optionsAfterSearch || options).map((option, i) => {
        if (option) {
          return (
            <button
              className={
                isOptionSelected(option, value)
                  ? "SelectOptions SelectOptions-active"
                  : "SelectOptions"
              }
              key={i + 2}
              id={i + 2}
              onClick={e => {
                e.preventDefault();
                handleClickValue(option);
              }}
            >
              <span>{optionKey ? option[optionKey] : option}</span>
            </button>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
}

function isOptionSelected(option, values) {
  if (values && values.includes(option)) {
    return true;
  } else {
    return false;
  }
}
