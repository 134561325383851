import React from "react";
import Filter from "./Filter.js";
// import FilterSearch from "./FilterSearch.js";
import "./css/Filters.css";

export default function ComposerRecordingsFilters(props) {
  return (
    <div className="Filters">
      {/* <FilterSearch
        queryString={props.queryString}
        queryIds={props.queryIds}
        history={props.history}
        paramsURL={props.paramsURL}
      /> */}
      <Filter
        queryString={props.queryString}
        queryIds={props.queryIds}
        history={props.history}
        name="Performer"
        queryParameter="performers"
        apiURL="/api/recordings/distinct/performers.invertedName"
        key="performer"
        isSearchable={true}
        isMultiple={true}
        paramsURL={props.paramsURL}
      />
      <Filter
        queryString={props.queryString}
        queryIds={props.queryIds}
        history={props.history}
        name="Ensemble"
        queryParameter="ensemble"
        apiURL="/api/recordings/distinct/ensembles.name"
        key="ensemble"
        isSearchable={true}
        paramsURL={props.paramsURL}
      />
      {/* <Filter
        queryString={props.queryString}
        queryIds={props.queryIds}
        history={props.history}
        name="Pieces"
        queryParameter="pieceTitle"
        apiURL="/api/recordings/distinct/pieces.title"
        key="piece"
        isSearchable={true}
        paramsURL={props.paramsURL}
        isMultiple={false}
      /> */}
      <Filter
        queryString={props.queryString}
        queryIds={props.queryIds}
        history={props.history}
        name="Label"
        queryParameter="label"
        apiURL="/api/recordings/distinct/albums.label.name"
        key="label"
        isSearchable={true}
        paramsURL={props.paramsURL}
      />
      {/* <SwitchFilter
        queryString={props.queryString}
        queryIds={props.queryIds}
        history={props.history}
        name="Group"
        queryParameter="group"
        key="group"
        paramsURL={props.paramsURL}
      /> */}
    </div>
  );
}
