import React from "react";
import querystring from "querystring";
// import { Link } from "react-router-dom";
import Frame from "./Frame";
// import Sort from "./Sort";
import PiecesTable from "./PiecesTable";
import Windows from "./Windows";
import WorksFilters from "./WorksFilters";
import { setQueryString } from "./helpers/helpers.js";
import "./css/Frame.css";
import "./css/Artist.css";

export default function Works(props) {
    const queryString = setQueryString(props.location.search, props.match.params);
    const queryIds = querystring.parse(queryString.substring(1));

    return (
        <Frame>
            <div className="Artist">
                <div className="Artist-content">
                    <div>
                        {/* <div className="Navigation-row Navigation-row-small">
                            <Link
                                to="/works"
                                className="Navigation-button-small Navigation-button-small-active"
                            >
                                All works</Link>
                            <Link
                                to="/catalogues"
                                className="Navigation-button-small"
                            >
                                Catalogues
                            </Link>
                        </div> */}
                        <WorksFilters
                            history={props.history}
                            paramsURL={props.match.params}
                            queryIds={queryIds}
                            queryString={queryString}
                        />
                        {/* <Sort
                            queryIds={queryIds}
                            choices={["Sort by name", "Sort by number of albums"]}
                        /> */}
                        <Windows>
                            <PiecesTable
                                queryString={queryString}
                                showComposer={true}
                            />
                        </Windows>
                    </div>
                </div>
                <div className="Artist-info"></div>
            </div>
        </Frame>
    );
}
