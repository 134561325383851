import React from "react";
import Select from "./Select";

export default function SetOccupationsFromList(props) {
  const { occupations, setOccupations, list } = props;

  return (
    <div className="Edit-input-container">
      <label>Occupations</label>
      <Select
        placeholder="occupations"
        options={list}
        isSearchable={true}
        isMultiple={true}
        value={occupations}
        onClear={e => setOccupations([])}
        onChange={e => {
          setOccupations(e);
        }}
      />
    </div>
  );
}
