import React from "react";
import Select from "./Select";
import "./css/Edit.css";

export default function PerformersFilter(props) {
  const { performer, setPerformer } = props;

  return (
    <Select
      placeholder="Other"
      options={[
        "Alto",
        // "Arranger",
        // "Bandoneon player",
        "Baritone",
        // "Bas-dessus",
        "Bass-baritone",
        "Bass",
        // "Basse-contre",
        // "Basse-taille",
        "Bassoonist",
        // "Biwa player",
        // "Cadenzor",
        // "Celesta player",
        "Cellist",
        // "Chitarrone player",
        "Choirmaster",
        "Choral conductor",
        "Clarinetist",
        // "Composer",
        // "Conductor",
        "Contralto",
        "Countertenor",
        // "Dessus",
        // "Double-bassist",
        // "Drummer",
        "Flutist",
        "Fortepianist",
        "Guitarist",
        // "Harmonica player",
        "Harpist",
        "Harpsichordist",
        // "Haute-contre",
        "Horn player",
        "Keyboardist",
        // "Koto player",
        // "Librettist",
        "Lutenist",
        // "Lyricist",
        "Mandolinist",
        "Mezzo-soprano",
        // "Narrator",
        "Oboist",
        "Ondist",
        // "Opera singer",
        "Organist",
        "Percussionist",
        // "Pianist",
        "Recorder player",
        "Saxophonist",
        "Serpent player",
        // "Shakuhachi player",
        "Singer",
        "Soprano",
        // "Speaker",
        // "Taille",
        "Tenor",
        "Theorbist",
        // "Theorist",
        "Trombonist",
        "Trumpeter",
        "Vibraphonist",
        "Viol player",
        // "Violinist",
        "Violist",
        "Violone player",
        "Zither player"
      ]}
      isSearchable={true}
      isMultiple={false}
      value={performer}
      onClear={() => setPerformer(null)}
      onChange={newValue => setPerformer(newValue)}
    />
  );
}
