import React, { useContext } from "react";
import UserContext from "./UserContext";
import querystring from "querystring";
import Frame from "./Frame";
import FavoritesHeaderNavigation from "./FavoritesHeaderNavigation.js";
import FavoritesEmpty from "./FavoritesEmpty.js";
import AlbumsList from "./AlbumsList";
import Windows from "./Windows";
import RecordingsTable from "./RecordingsTable";
import FavoritesRecordingsFilters from "./FavoritesRecordingsFilters";
import FavoritesAlbumsFilters from "./FavoritesAlbumsFilters";
import { setQueryString } from "./helpers/helpers.js";

export default function Favorite(props) {
  const user = useContext(UserContext);

  let page = "albums";
  if (props.match.path === "/favorites/recordings") {
    page = "recordings";
  }

  const queryString = setQueryString(props.location.search, {
    ...props.match.params,
    favorites: true
  });
  const queryIds = querystring.parse(queryString.substring(1));

  document.title = "Favorites - Lyra digital music library";

  return (
    <Frame>
      <div className="Artist">
        <div className="Artist-header Header">
          <div className="Header-big-title">Favorites</div>
          <FavoritesHeaderNavigation page={page} />
        </div>
        <div className="Artist-content">{renderFavorites()}</div>
        <div className="Artist-info"></div>
      </div>
    </Frame>
  );

  function renderFavorites() {
    if (page === "albums") {
      if (user && user.user && user.user._id) {
        return renderAlbums();
      } else {
        return <FavoritesEmpty type="album" typePlural="albums" />;
      }
    } else {
      if (user && user.user && user.user._id) {
        return renderRecordings();
      } else {
        return <FavoritesEmpty type="recording" typePlural="recordings" />;
      }
    }
  }

  function renderAlbums() {
    return (
      <div>
        <FavoritesAlbumsFilters
          history={props.history}
          paramsURL={props.match.params}
          queryIds={queryIds}
          queryString={queryString}
        />
        <AlbumsList queryString={queryString} />
      </div>
    );
  }

  function renderRecordings() {
    return (
      <div>
        <FavoritesRecordingsFilters
          history={props.history}
          paramsURL={props.match.params}
          queryIds={queryIds}
          queryString={queryString}
        />
        <Windows>
          <RecordingsTable queryString={queryString} />
        </Windows>
      </div>
    );
  }
}
