import React, { useState, useEffect } from "react";
import EditText from "./EditText";
import "./css/Edit.css";

export default function LabelToBeAdded(props) {
  const {
    defaultName,
    handleShowMessageBox,
    refetchAlbums,
    setLabel,
  } = props;
  const [name, setName] = useState("");

  useEffect(() => {
    setName(defaultName);
  }, [defaultName]);

  return (
    <div>
      <div>Insert new person</div>
      <EditText value={name} setValue={setName} placeholder="Name" />
      <button className="Button Edit-button" onClick={() => insertLabel()}>
        Insert
      </button>
    </div>
  );

  async function insertLabel() {
    const body = JSON.stringify({ name });
    try {
      const resp = await fetch("/api/labels/insert", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body
      });
      // const respJSON = await resp.json();
      if (resp.status === 201) {
        handleShowMessageBox("Inserted successfully", 3000);
        setLabel(null);
        refetchAlbums();
      }
    } catch (err) {
      console.log(err);
    }
  }
}
