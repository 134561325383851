import React from "react";
import PiecesTable from "./PiecesTable.js";
import PiecesListsNavigation from "./PiecesListsNavigation.js";
import BrowseNavigation from "./BrowseNavigation.js";
import ComposerPiecesFilters from "./ComposerPiecesFilters.js";
import Navbar from "./Navbar.js";
import Sort from "./Sort";
import FavoritesPageNavigation from "./FavoritesPageNavigation.js";
import SidebarLinks from "./SidebarLinks.js";
import SidebarFooter from "./SidebarFooter.js";
import { throttle } from "./helpers/helpers.js";
import "./css/Pieces.css";
import "./css/Sidebar.css";

export default class Pieces extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      piecesIsLoaded: false,
      pieces: [],
      composer: {},
      category: null,
      subcategory: null,
      era: null,
      musicalForm: null,
      tonality: null,
      instruments: [],
      piecesCounter: null,
      page: 1,
      limit: 50,
      showFilters: false
    };
  }

  async fetchPieces() {
    try {
      let url = `/api/pieces${this.props.queryString}&limit=${
        this.state.limit
      }&page=${this.state.page}`;

      const resp = await fetch(url);
      const pieces = await resp.json();

      const piecesCounter = await resp.headers.get("Result-Count");
      this.setState({
        piecesIsLoaded: true,
        pieces: this.state.pieces.concat(pieces),
        piecesCounter
      });
      return resp;
    } catch (err) {
      console.log(err);
    }
  }

  handleOnScroll() {
    if (
      window.innerHeight + window.scrollY + 2000 >=
      document.body.offsetHeight
    ) {
      if (
        this.state.page * this.state.limit <
        parseInt(this.state.piecesCounter) + 50
      ) {
        this.loadMore();
      }
    }
  }

  handleShowFilters() {
    const { showFilters } = this.state;
    this.setState({ showFilters: !showFilters });
  }

  loadMore() {
    if (this._isMounted) {
      this.setState({ page: this.state.page + 1 });
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchPieces();
    window.addEventListener(
      "scroll",
      throttle(this.handleOnScroll.bind(this), 300)
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.queryString !== this.props.queryString) {
      this.setState({ pieces: [], page: 1 }); // Restart at page one after filters update

      this.setState(
        {
          pieces: [],
          piecesIsLoaded: false
        },
        () => this.fetchPieces()
      );
    } else if (
      prevState.page !== this.state.page &&
      this.state.pieces.length !== 0
    ) {
      this.fetchPieces();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  renderFavoritesPageNavigation() {
    const { headerContent, path } = this.props;
    if (!headerContent && path === "/favorites/pieces") {
      return <FavoritesPageNavigation path={path} contentIsLoaded={true} />;
    }
  }

  renderBrowseNavigation() {
    const { path, queryIds, hasHeader } = this.props;
    const { albumsIsLoaded } = this.state;
    if (path !== "/favorites/pieces" && !hasHeader) {
      return (
        <BrowseNavigation
          path={path}
          albumsIsLoaded={albumsIsLoaded}
          queryIds={queryIds}
        />
      );
    }
  }

  // renderPiecesNavigation() {
  //   const { path, queryIds, headerContent } = this.props;
  //   const { piecesIsLoaded } = this.state;
  //   if (path !== "/favorites/pieces" && !headerContent) {
  //     return (
  //       <PiecesNavigation
  //         path={path}
  //         piecesIsLoaded={piecesIsLoaded}
  //         queryIds={queryIds}
  //       />
  //     );
  //   }
  // }

  render() {
    const {
      // apiUrls,
      queryString,
      paramsURL,
      headerContent,
      headerContentIsLoaded,
      path,
      isComposer,
      // fetchIsInFavorites,
      // isInFavorites,
      queryIds,
      history
    } = this.props;

    const {
      error,
      piecesIsLoaded,
      piecesCounter,
      pieces,
      showFilters
    } = this.state;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else {
      return (
        <div className="Pieces">
          <div
            className={showFilters ? "Sidebar Sidebar-full-height" : "Sidebar"}
          >
            <SidebarLinks />
            <SidebarFooter />
          </div>
          <div className="Pieces-content">
            <Navbar className="hidden-mobile" />
            {/* <Header
              id={apiUrls ? apiUrls[3] : null}
              fetchIsInFavorites={fetchIsInFavorites}
              isInFavorites={isInFavorites}
              paramsURL={paramsURL}
              queryString={queryString}
              headerContent={headerContent}
              headerContentIsLoaded={headerContentIsLoaded}
              page="works"
            /> */}
            {this.renderFavoritesPageNavigation()}
            {this.renderBrowseNavigation()}
            <PiecesListsNavigation
              piecesIsLoaded={piecesIsLoaded}
              headerContent={headerContent}
              headerContentIsLoaded={headerContentIsLoaded}
              path={path}
              paramsURL={paramsURL}
            />
            <ComposerPiecesFilters
              // counter={albumsCounter}
              // handleShowFilters={this.handleShowFilters.bind(this)}
              history={history}
              paramsURL={paramsURL}
              queryIds={queryIds}
              queryString={queryString}
              showFilters={showFilters}
            />
            {path !== "/artist/:composerId/works/:catalogueId" ? <Sort
              queryIds={queryIds}
              choices={["Sort by name", "Sort by number of albums"]}
            /> : null}
            <PiecesTable
              pieces={pieces}
              piecesIsLoaded={piecesIsLoaded}
              piecesCounter={piecesCounter}
              isComposer={isComposer}
              headerContentIsLoaded={headerContentIsLoaded}
              path={path}
              paramsURL={paramsURL}
            />
          </div>
        </div>
      );
    }
  }
}
