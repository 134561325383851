import React from "react";
import Filter from "./Filter.js";
import "./css/Filters.css";

export default function PieceAlbumsFilters(props) {
  return (
    <div className="Filters">
      <Filter
        queryString={props.queryString}
        queryIds={props.queryIds}
        history={props.history}
        name="Performer"
        queryParameter="performers"
        apiURL="/api/albums/distinct/performers.invertedName"
        key="performer"
        isSearchable={true}
        isMultiple={true}
        paramsURL={props.paramsURL}
      />
      <Filter
        queryString={props.queryString}
        queryIds={props.queryIds}
        history={props.history}
        name="Ensemble"
        queryParameter="ensemble"
        apiURL="/api/albums/distinct/ensembles.name"
        key="ensemble"
        isSearchable={true}
        paramsURL={props.paramsURL}
      />
      <Filter
        queryString={props.queryString}
        queryIds={props.queryIds}
        history={props.history}
        name="Label"
        queryParameter="label"
        apiURL="/api/albums/distinct/label.name"
        key="label"
        isSearchable={true}
        paramsURL={props.paramsURL}
      />
    </div>
  );
}
