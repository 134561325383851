import React, { useState } from "react";
import MessageBox from "./MessageBox";
import Frame from "./Frame";
import "./css/Contact.css";

export default function Contact(props) {
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState(null);
  const [response, setResponse] = useState(null);
  return (
    <Frame>
      <div className="Contact">
        <h1 className="Page-title">Contact us</h1>
        <form className="Contact-form" onSubmit={e => handleSubmit(e)}>
          <input
            type="text"
            placeholder="Name"
            onChange={e => setName(e.currentTarget.value)}
          />
          <input
            type="email"
            placeholder="Email"
            autoComplete="email"
            onChange={e => setEmail(e.currentTarget.value)}
          />
          <textarea
            placeholder="Message"
            onChange={e => setMessage(e.currentTarget.value)}
          />
          <br />
          <button className="Button" type="submit">
            Send
          </button>
          <MessageBox message={response} />
        </form>
      </div>
    </Frame>
  );

  function handleSubmit(e) {
    e.preventDefault();
    const body = JSON.stringify({ name, message, email });
    if (name && message && email) {
      send(body);
    } else {
      setResponse("Please fill all the field");
      setTimeout(() => {
        setResponse("");
      }, 2000);
    }
  }

  async function send(body) {
    try {
      const rawResponse = await fetch("/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body
      });
      const response = await rawResponse.json();
      setResponse(response.message || response.error);
      setTimeout(() => {
        setResponse("");
        window.location = "/";
      }, 1000);
    } catch (err) {
      console.log(err);
    }
  }
}
