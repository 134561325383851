import React, { useState } from "react";
import "./css/ReviewEditRating.css";

export default function ReviewEditRating(props) {
  const [ratingHover, setRatingHover] = useState(null);

  return (
    <div className="ReviewEditRating">
      {renderStar(1)}
      {renderStar(2)}
      {renderStar(3)}
      {renderStar(4)}
      {renderStar(5)}
      {renderStar(6)}
      {renderStar(7)}
      {renderStar(8)}
      {renderStar(9)}
      {renderStar(10)}
      {ratingHover ? (
        <div className="ReviewEditRating-number">
          <span className="ReviewEditRating-number-1">{ratingHover}</span>
          <span className="ReviewEditRating-number-2">/10</span>
        </div>
      ) : props.rating ? (
        <div className="ReviewEditRating-number">
          <span className="ReviewEditRating-number-1">{props.rating}</span>
          <span className="ReviewEditRating-number-2">/10</span>
        </div>
      ) : null}
    </div>
  );

  function renderStar(number) {
    return (
      <div
        onMouseEnter={() => setRatingHover(number)}
        onMouseLeave={() => setRatingHover(null)}
        onClick={() => props.setRating(number)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 512 512"
        >
          <path
            d="M480,208H308L256,48,204,208H32l140,96L118,464,256,364,394,464,340,304Z"
            className={
              ratingHover >= number || (!ratingHover && props.rating >= number)
                ? "ReviewEditRating-icon ReviewEditRating-icon-full"
                : "ReviewEditRating-icon"
            }
          />
        </svg>
      </div>
    );
  }
}
