import React, { useState, useEffect, useRef } from "react";
import InfiniteScrolling from "./InfiniteScrolling.js";
import AlbumsTableItemUpdate from "./AlbumsTableItemUpdate.js";
import "./css/TracksTable.css";

export default function AlbumTableUpdate(props) {
  const [albums, setAlbums] = useState([]);
  const [newAlbums, setNewAlbums] = useState([]);
  const [albumsCounter, setAlbumsCounter] = useState(0);
  const { queryString } = props;
  const [selectedResult, setSelectedResult] = useState(0);
  console.log(albums);
  async function fetchAlbums(limit, page) {
    try {
      let url = `/api/albums/update/list${queryString}&limit=${limit}&page=${page}`;
      const resp = await fetch(url);
      const newAlbums = await resp.json();
      const newAlbumsCounter = await resp.headers.get("Result-Count");
      setAlbumsCounter(newAlbumsCounter);
      setNewAlbums(newAlbums);
      return resp;
    } catch (err) {
      console.log(err);
    }
  }

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevNewAlbums = usePrevious(newAlbums);

  // Show first items
  useEffect(() => {
    if (albums.length === 0 && newAlbums.length !== 0) setAlbums(newAlbums);
  }, [newAlbums, albums]);

  // add when changes
  useEffect(() => {
    if (newAlbums !== prevNewAlbums) {
      setAlbums(albums.concat(newAlbums));
    }
  }, [newAlbums, prevNewAlbums, albums]);

  useEffect(() => {
    setAlbums([]);
    setNewAlbums([]);
    setAlbumsCounter(0);
    async function fetchFirstAlbums() {
      try {
        let url = `/api/albums/update/list${queryString}&limit=50&page=1`;
        console.log(url);
        const resp = await fetch(url);
        const newAlbums = await resp.json();
        const newAlbumsCounter = await resp.headers.get("Result-Count");
        setAlbumsCounter(newAlbumsCounter);
        setNewAlbums(newAlbums);
        return resp;
      } catch (err) {
        console.log(err);
      }
    }
    fetchFirstAlbums();
  }, [queryString]);

  document.onkeydown = function(e) {
    e = e || window.event;
    switch (e.which || e.keyCode) {
      case 38: // up
        setSelectedResult(selectedResult - 1);
        if (document.getElementById(selectedResult - 1))
          document.getElementById(selectedResult - 1).focus();
        break;

      case 40: // down
        setSelectedResult(selectedResult + 1);
        if (document.getElementById(selectedResult + 1))
          document.getElementById(selectedResult + 1).focus();
        break;

      default:
        return; // exit this handler for other keys
    }
    e.preventDefault(); // prevent the default action (scroll / move caret)
  };

  if (albums && albums.length > 0) {
    return (
      <InfiniteScrolling
        items={albums}
        counter={albumsCounter}
        limit={50}
        queryString={queryString}
        onScrollBottom={(limit, page) => {
          fetchAlbums(limit, page);
        }}
      >
        <div className="TracksTable">
          {`${albumsCounter} albums`}
          {albums.map((album, i) => {
            return <AlbumsTableItemUpdate key={i} i={i} album={album} />;
          })}
        </div>
      </InfiniteScrolling>
    );
  } else {
    return <div className="TracksTable-empty"></div>;
  }
}
