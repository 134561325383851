import React from "react";
import Select from "./Select";
import "./css/Edit.css";

export default function InsertArrangements(props) {
  const { instruments, setInstruments } = props;

  return (
    <div>
      <div className="Edit-input-container">
        <label>Instruments</label>
        <Select
          placeholder="Instruments"
          options={[
            "Piano",
            "Violin",
            "Cello",
            "Alto",
            "Alto recorder",
            "Arpeggione",
            "Baritone",
            "Bass",
            "Bass (voice)",
            "Bass clarinet",
            "Bass drum",
            "Bass trombone",
            "Bass-baritone",
            "Basset horn",
            "Bassoon",
            "Bongos",
            "Brass instruments",
            "Celesta",
            "Chalumeau",
            "Children chorus",
            "Chimes",
            "Choir",
            "Choir (SATB)",
            "Chorus",
            "Clarinet",
            "Clavichord",
            "Claviorgan",
            "Continuo",
            "Contrabass clarinet",
            "Contrabassoon",
            "Contralto",
            "Cornet",
            "Counterteno ",
            "Cross-strung harp",
            "Cymbals",
            "Double bass",
            "Drums",
            "E flat clarinet",
            "English horn",
            "Female chorus",
            "Flautino",
            "Flute",
            "Fortepiano",
            "French horn",
            "Glass harmonica",
            "Glockenspiel",
            "Guitar",
            "Harmonium",
            "Harp",
            "Harpsichord",
            "Horn",
            "Keyboard",
            "Lute",
            "Lute-harpsichord",
            "Male chorus",
            "Maracas",
            "Marimba",
            "Mechanical organ",
            "Mezzo-soprano",
            "Mixed choir",
            "Musical clock",
            "Narrator",
            "Oboe",
            "Orchestra",
            "Organ",
            "Pedal piano",
            "Percussion",
            "Percussions",
            "Pianoforte",
            "Piccolo",
            "Saxophone",
            "Snare drum",
            "Soli",
            "Soloists",
            "Soloists (SATB)",
            "Soprano",
            "Strings",
            "Synthesizers",
            "Tambourine",
            "Tenor",
            "Theorbo",
            "Timpani",
            "Toy instrument",
            "Triangle",
            "Trombone",
            "Trumpet",
            "Tuba",
            "Vibraphone",
            "Viol",
            "Viola",
            "Viola d'amore",
            "Viola da gamba",
            "Violoncello piccolo",
            "Voice",
            "Woodblocks",
            "Woodwind instruments",
            "Xylophone",
            "Zither"
          ]}
          isSearchable={true}
          isMultiple={true}
          value={instruments.map(instrument => instrument.name)}
          onClear={e => setInstruments([])}
          onChange={listInstruments => {
            setInstruments(
              listInstruments.map(instrument => {
                return {
                  name: instrument
                };
              })
            );
          }}
        />
      </div>
      <div>
        {instruments && instruments.length > 0 ? (
          <span>Instruments Numbers</span>
        ) : null}
        {instruments.map((instrument, i) => {
          return (
            <div className="Edit-input-container" key={i}>
              <label>{`#${i + 1} ${instrument.name}`}</label>
              <input
                type="text"
                placeholder="Number"
                className="Edit-input"
                value={instrument.number}
                onChange={e => {
                  const newInstruments = [...instruments];
                  newInstruments[i].number = e.currentTarget.value;
                  setInstruments(newInstruments);
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
