import React from "react";
import { convertDate, convertDateWithPrecision } from "./helpers/helpers.js";
import ExpandableList from "./ExpandableList";
import { Link } from "react-router-dom";
import ArtistAuthorities from "./ArtistAuthorities";
import ArtistExternalLinks from "./ArtistExternalLinks";
import "./css/ArtistMoreInfo.css";

export default function PieceMoreInfo(props) {
  const { piece, user } = props;

  return <div className="ArtistMoreInfo">{renderWorkInformation(piece)}</div>;

  function renderWorkInformation(piece) {
    if (piece) {
      return (
        <div className="ArtistMoreInfo-container">
          {renderSubtitle(piece)}
          {renderCompositionDate(piece)}
          {renderPublicationDate(piece)}
          {renderFirstPerformance(piece)}
          {renderGenre(piece)}
          {renderMusicalForm(piece)}
          {renderDedication(piece)}
          {renderSoloists(piece)}
          {renderCatalogues(piece)}
          {renderInstrumentation(piece)}
          {renderTonality(piece)}
          {renderIncipit(piece)}
          {renderTempo(piece)}
          {renderNotes(piece)}
          {renderExternalLinks(piece)}
          {renderArrangements(piece)}
          {renderNameOtherLanguages(piece)}
          {renderAliases(piece)}
          {renderAuthorities(piece)}
          {user && user.user && user.user.admin ? renderEdit(piece) : null}
        </div>
      );
    }
  }

  function renderArrangements(piece) {
    if (piece.arrangements && piece.arrangements.length > 0) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">Arrangement</div>
          <div className="ArtistMoreInfo-column-right">
            {piece.arrangements.map((arrangement, i) => {
              return (
                <div key={i}>
                  <Link to={"/piece/" + arrangement.id + "/recordings"}>
                    {arrangement.title}
                    <br />
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  }

  function renderNameOtherLanguages(piece) {
    if (piece.namesList && piece.namesList.length > 1) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">
            Name in other languages
          </div>
          <div className="ArtistMoreInfo-column-right">
            <ExpandableList list={piece.namesList} />
          </div>
        </div>
      );
    }
  }

  function renderFirstPerformance(piece) {
    if (piece && piece.creation) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">First Performance</div>
          <div className="ArtistMoreInfo-column-right">
            {piece.creation.date ? (
              <span>{convertDate(piece.creation.date)}</span>
            ) : null}
            {piece.creation.date && piece.creation.place ? ", " : null}
            {piece.creation.place ? (
              <span>{piece.creation.place.name}</span>
            ) : null}
            {piece.creation.place && piece.creation.performers ? ", " : null}
            {piece.creation.date &&
            !piece.creation.place &&
            piece.creation.performers
              ? ", "
              : null}
            {piece.creation.performers ? (
              <span>by {piece.creation.performers}</span>
            ) : null}
          </div>
        </div>
      );
    } else if (piece.dateOfFirstPerformance) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">First Performance</div>
          <div className="ArtistMoreInfo-column-right">
            {piece.dateOfFirstPerformance
              ? convertDateWithPrecision(
                  piece.dateOfFirstPerformance.time,
                  piece.dateOfFirstPerformance.precision
                )
              : null}
          </div>
        </div>
      );
    }
  }

  function renderPublicationDate(piece) {
    if (piece && piece.publicationDate) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">Publication Date</div>
          <div className="ArtistMoreInfo-column-right">
            {piece.publicationDate
              ? convertDateWithPrecision(
                  piece.publicationDate.time,
                  piece.publicationDate.precision
                )
              : null}
          </div>
        </div>
      );
    }
  }

  function renderCompositionDate(piece) {
    if (piece && (piece.compositionStart || piece.compositionEnd)) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">Date of Composition</div>
          <div className="ArtistMoreInfo-column-right">
            {getCompositionStart(piece) +
              (piece.compositionStart && piece.compositionEnd ? " - " : "") +
              getCompositionEnd(piece)}
          </div>
        </div>
      );
    } else if (piece && piece.dateOfComposition) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">Date of Composition</div>
          <div className="ArtistMoreInfo-column-right">
            {piece.dateOfComposition && piece.dateOfComposition.start
              ? convertDateWithPrecision(
                  piece.dateOfComposition.start.time,
                  piece.dateOfComposition.start.precision
                ) + " - "
              : null}
            {piece.dateOfComposition
              ? convertDateWithPrecision(
                  piece.dateOfComposition.time,
                  piece.dateOfComposition.precision
                )
              : null}
          </div>
        </div>
      );
    }
  }

  function getCompositionStart(piece) {
    if (piece && piece.compositionStart && piece.compositionStart.date)
      return convertDate(piece.compositionStart.date);
    else return "";
  }

  function getCompositionEnd(piece) {
    if (piece && piece.compositionEnd && piece.compositionEnd.date)
      return convertDate(piece.compositionEnd.date);
    else return "";
  }

  function renderMusicalForm(piece) {
    if (piece && piece.musicalForm) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">Musical Form</div>
          <div className="ArtistMoreInfo-column-right">{piece.musicalForm}</div>
        </div>
      );
    }
  }

  function renderGenre(piece) {
    if (piece && piece.category) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">Genre</div>
          <div className="ArtistMoreInfo-column-right">
            {piece.category}
            {piece.subcategory ? (
              <span>
                <span> | </span>
                {piece.subcategory}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    }
  }

  function renderDedication(piece) {
    if (piece && piece.dedication) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">Dedication</div>
          <div className="ArtistMoreInfo-column-right">{piece.dedication}</div>
        </div>
      );
    }
  }

  function renderCatalogues(piece) {
    if (piece && piece.catalogues && piece.catalogues.length > 0) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">Catalogue</div>
          <div className="ArtistMoreInfo-column-right">
            {piece.catalogues.map(function(catalogue, i) {
              return (
                <span key={i}>
                  {catalogue}
                  {piece.catalogues.length - 1 === i ? "" : <span>, </span>}
                </span>
              );
            })}
          </div>
        </div>
      );
    }
  }

  function renderSoloists(piece) {
    if (piece && piece.soloists) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">Soloist</div>
          <div className="ArtistMoreInfo-column-right">{piece.soloists}</div>
        </div>
      );
    }
  }

  function renderInstrumentation(piece) {
    if (piece && piece.instruments && piece.instruments.length > 0) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">Instrumentation</div>
          <div className="ArtistMoreInfo-column-right">
            {piece.instruments.map(function(instrument, i) {
              return (
                <span key={i}>
                  {instrument.name}
                  {instrument.number ? ` (${instrument.number})` : null}
                  {instrument.optional ? " (opt.)" : null}
                  {renderInstrumentsOr(instrument)}
                  {piece.instruments.length - 1 === i ? "" : ", "}
                </span>
              );
            })}
          </div>
        </div>
      );
    }
  }

  function renderInstrumentsOr(instrument) {
    if (instrument.or) {
      return (
        <span>
          {instrument.or.map(function(instrumentOr, i) {
            return (
              <span key={i}>
                <span> or </span>
                {instrumentOr.name}
              </span>
            );
          })}
        </span>
      );
    }
  }

  function renderTonality(piece) {
    if (piece && piece.tonalities && piece.tonalities.length > 0) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">Tonality</div>
          <div className="ArtistMoreInfo-column-right">
            {piece.tonalities.map(function(tonality, i) {
              return (
                <span key={i}>
                  {tonality}
                  {piece.tonalities.length - 1 === i ? "" : ", "}
                </span>
              );
            })}
          </div>
        </div>
      );
    }
  }

  function renderIncipit(piece) {
    if (piece && piece.incipit) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">Incipit</div>
          <div className="ArtistMoreInfo-column-right ArtistMoreInfo-italic">
            {piece.incipit}
          </div>
        </div>
      );
    }
  }

  function renderTempo(piece) {
    if (piece && piece.tempo) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">Tempo</div>
          <div className="ArtistMoreInfo-column-right">{piece.tempo}</div>
        </div>
      );
    }
  }

  function renderNotes(piece) {
    if (piece && piece.notes) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">Notes</div>
          <div className="ArtistMoreInfo-column-right">
            {piece.notes}
          </div>
        </div>
      );
    }
  }

  function renderSubtitle(piece) {
    if (piece && piece.subtitle) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">Subtitle</div>
          <div className="ArtistMoreInfo-column-right">{piece.subtitle}</div>
        </div>
      );
    }
  }

  function renderAliases(piece) {
    if (piece.aliasesList && piece.aliasesList.length > 0) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">Aliases</div>
          <div className="ArtistMoreInfo-column-right">
            <ExpandableList list={piece.aliasesList} />
          </div>
        </div>
      );
    }
  }

  function renderExternalLinks(piece) {
    if (piece && piece.externalLinks) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">External links</div>
          <div className="ArtistMoreInfo-column-right">
            <ArtistExternalLinks
              type="piece"
              externalLinks={piece.externalLinks}
              wikipedia={piece.wikipedia}
            />
          </div>
        </div>
      );
    }
  }

  function renderAuthorities(piece) {
    if (piece && piece.authorities) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">Authorities</div>
          <div className="ArtistMoreInfo-column-right">
            <ArtistAuthorities authorities={piece.authorities} />
          </div>
        </div>
      );
    }
  }

  function renderEdit(piece) {
    return (
      <div className="ArtistMoreInfo-row">
        <div className="ArtistMoreInfo-column-left">Edit</div>
        <div className="ArtistMoreInfo-column-right">
          <Link to={`/piece/${piece._id}/edit`}>Edit this piece</Link>
          <br />
          <Link to={`/piece/${piece._id}/edit?page=movements`}>
            Insert/Edit movements
          </Link>
          <br />
          <Link to={`/piece/${piece._id}/edit?page=arrangements`}>
            Insert/Edit arrangements
          </Link>
          <br />
          <Link to={`/piece/${piece._id}/edit?page=versions`}>
            Insert/Edit versions
          </Link>
          <br />
          <Link to={`/piece/${piece._id}/edit?page=loadMovements`}>
            Load movements
          </Link>
          <br />
          <Link to={`/piece/${piece._id}/edit?page=loadInfo`}>Load info</Link>
        </div>
      </div>
    );
  }
}
