import React from "react";
import { Link } from "react-router-dom";
import "./css/HeaderMoreInfo.css";
import {
  convertListToString,
  convertDate,
  msToTime
} from "./helpers/helpers.js";

export default function RecordingMoreInfo(props) {
  const { recording } = props;

  return (
    <div className="HeaderMoreInfo">
      {renderComposers(recording)}
      {renderPerformers(recording)}
      {renderEnsembles(recording)}
      {renderPieces(recording)}
      {renderDateAndPlace(recording)}
      {renderLength(recording)}
      {renderLinkedRecordings(recording)}
    </div>
  );

  function renderPerformers(recording) {
    if (recording && recording.performers && recording.performers.length > 0) {
      return (
        <div className="HeaderMoreInfo-row">
          <div className="HeaderMoreInfo-td-1">Performer</div>
          <div className="HeaderMoreInfo-td-2">
            {recording.performers.map((performer, i) => {
              return (
                <div key={i} className="HeaderMoreInfo-td-2-item">
                  <Link to={`/artist/${performer.id}`}>{performer.name}</Link>
                  <span className="HeaderMoreInfo-td-2-item-grey">
                    {performer.occupations
                      ? ` ${convertListToString(performer.occupations)}`
                      : null}
                  </span>
                  {i + 1 < recording.performers.length ? <br /> : ""}
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  }

  function renderComposers(recording) {
    if (recording && recording.composers && recording.composers.length > 0) {
      return (
        <div className="HeaderMoreInfo-row">
          <div className="HeaderMoreInfo-td-1">Composer</div>
          <div className="HeaderMoreInfo-td-2">
            {recording.composers.map((composer, i) => {
              return (
                <span key={i}>
                  <Link to={`/artist/${composer.id}`}>{composer.name}</Link>
                  <span>
                    {composer.occupations
                      ? ` (${convertListToString(composer.occupations)})`
                      : null}
                  </span>
                  {i + 1 < recording.composers.length ? <br /> : ""}
                </span>
              );
            })}
          </div>
        </div>
      );
    }
  }

  function renderEnsembles(recording) {
    if (recording && recording.ensembles && recording.ensembles.length > 0) {
      return (
        <div className="HeaderMoreInfo-row">
          <div className="HeaderMoreInfo-td-1">Ensemble</div>
          <div className="HeaderMoreInfo-td-2">
            {recording.ensembles.map((ensemble, i) => {
              return (
                <span key={ensemble.id}>
                  <Link to={`/ensemble/${ensemble.id}`}>{ensemble.name}</Link>
                  {i + 1 < recording.ensembles.length ? ", " : ""}
                </span>
              );
            })}
          </div>
        </div>
      );
    }
  }

  function renderPieces(recording) {
    if (recording && recording.pieces && recording.pieces.length > 0) {
      return (
        <div className="HeaderMoreInfo-row">
          <div className="HeaderMoreInfo-td-1">Pieces</div>
          <div className="HeaderMoreInfo-td-2">
            {recording.pieces.map((piece, i) => {
              if (!piece.isParent)
                return (
                  <div key={i}>
                    <Link to={`/piece/${piece.id}/recordings`}>
                      {piece.title}
                    </Link>
                  </div>
                );
              else return null;
            })}
          </div>
        </div>
      );
    } else if (recording && recording.title) {
      return (
        <div className="HeaderMoreInfo-row">
          <div className="HeaderMoreInfo-td-1">Pieces</div>
          <div className="HeaderMoreInfo-td-2">
            <Link
              className="HeaderMoreInfo-flash"
              to={`/recording/${recording._id}/edit`}
            >
              + Add piece
            </Link>
          </div>
        </div>
      );
    }
  }

  function renderDateAndPlace(recording) {
    if (recording && recording.place) {
      return (
        <div className="HeaderMoreInfo-row">
          <div className="HeaderMoreInfo-td-1">Recorded at</div>
          <div className="HeaderMoreInfo-td-2">
            {recording.place.name}
            {recording.place.area ? `, ${recording.place.area.name}` : ""}
            {recording.place.area && recording.place.area.country
              ? `, ${recording.place.area.country.name}`
              : ""}
            {renderRecordingDates()}
          </div>
        </div>
      );
    }
  }

  function renderRecordingDates() {
    let dates = "";
    if (recording.begin && recording.end && recording.begin !== recording.end) {
      dates += ` (${convertDate(recording.begin)} - ${convertDate(
        recording.end
      )})`;
    } else if (
      recording.begin &&
      recording.end &&
      recording.begin === recording.end
    ) {
      dates += ` (${convertDate(recording.begin)})`;
    } else if (convertDate(recording.begin) && !convertDate(recording.end)) {
      dates = ` (${convertDate(recording.begin)})`;
    } else if (!convertDate(recording.begin) && convertDate(recording.end)) {
      dates = ` (${convertDate(recording.end)})`;
    }
    return dates;
  }

  function renderLength(recording) {
    if (recording && recording.durationMs) {
      return (
        <div className="HeaderMoreInfo-row">
          <div className="HeaderMoreInfo-td-1">Length</div>
          <div className="HeaderMoreInfo-td-2">
            {msToTime(recording.durationMs)}
          </div>
        </div>
      );
    }
  }

  function renderLinkedRecordings(recording) {
    if (
      recording &&
      recording.linkedRecordings &&
      recording.linkedRecordings.length > 0
    ) {
      return (
        <div className="HeaderMoreInfo-row">
          <div className="HeaderMoreInfo-td-1">Linked recordings</div>
          <div className="HeaderMoreInfo-td-2">
            {recording.linkedRecordings.map((linkedRecording, i) => {
              if (recording._id === linkedRecording.id) {
                return (
                  <div key={i}>
                    <span className="HeaderMoreInfo-flash">
                      {linkedRecording.title}
                    </span>
                  </div>
                );
              } else {
                return (
                  <div key={i}>
                    <Link to={`/recording/${linkedRecording.id}`}>
                      {linkedRecording.title}
                    </Link>
                  </div>
                );
              }
            })}
          </div>
        </div>
      );
    }
  }
}
