import React, { useState, useEffect } from "react";
import querystring from "querystring";
import Frame from "./Frame";
import { Link, withRouter } from "react-router-dom";
import EditAlbumBasic from "./EditAlbumBasic";
import Windows from "./Windows";
import "./css/Edit.css";

function EditAlbum(props) {
  return (
    <Windows>
      <EditAlbumContent {...props} />
    </Windows>
  );
}

function EditAlbumContent(props) {
  const { location, handleShowMessageBox } = props;
  let queryIds = null;
  if (location.search)
    queryIds = querystring.parse(location.search.substring(1));
  const albumId = props.match.params.albumId;

  const [page, setPage] = useState("basic");
  const [album, setAlbum] = useState({});

  useEffect(() => {
    if (queryIds && queryIds.page) setPage(queryIds.page);
  }, [queryIds]);

  async function fetchAlbum(albumId) {
    if (albumId)
      try {
        const resp = await fetch("/api/albums/" + albumId);
        const album = await resp.json();
        setAlbum(album);
        return resp;
      } catch (err) {
        console.log(err);
      }
    else return null;
  }

  useEffect(() => {
    if (albumId) fetchAlbum(albumId);
  }, [albumId]);

  return (
    <Frame>
      <div className="Edit">
        {renderButtons()}
        {album && album._id ? renderSection() : null}
      </div>
    </Frame>
  );

  function renderButtons() {
    return (
      <div>
        <Link
          className={"Button Button-margin-right"}
          to={`/album/${album._id}`}
        >
          Return to album
        </Link>
        <Link
          className={
            page === "basic"
              ? "Button Button-flash Button-margin-right"
              : "Button Button-margin-right"
          }
          to={`?page=basic`}
        >
          Basic info
        </Link>
      </div>
    );
  }

  function renderSection() {
    if (page === "basic") {
      return (
        <EditAlbumBasic
          album={album}
          onSubmit={async updatedAlbum => {
            const updatedalbumId = await upsertAlbum(album._id, updatedAlbum);
            reload(updatedalbumId);
          }}
        />
      );
    } else {
      return null;
    }
  }

  function reload(updatedalbumId) {
    if (updatedalbumId) {
      fetchAlbum(updatedalbumId);
      props.history.push(`/album/${updatedalbumId}/edit`);
    } else {
      fetchAlbum(albumId);
    }
  }

  async function upsertAlbum(updatedalbumId, updatedAlbum) {
    const body = JSON.stringify(updatedAlbum);
    try {
      const resp = await fetch("/api/albums/update/" + updatedalbumId, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body
      });
      if (resp.status === 201) {
        handleShowMessageBox("Updated", 1000);
        return updatedalbumId;
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export default withRouter(EditAlbum);
