import React from "react";
import { Link } from "react-router-dom";
import "./css/PiecesListsNavigation.css";

export default React.memo(function PiecesListsNavigation(props) {
  const { artist, path, catalogueId } = props;
  if (artist && artist.listsOfPieces) {
    return (
      <div className="PiecesListsNavigation">
        <Link
          className={
            path === "/artist/:artistId/works"
              ? "PiecesListsNavigation-link PiecesListsNavigation-link-active"
              : "PiecesListsNavigation-link"
          }
          to={"/artist/" + artist._id + "/works"}
        >
          List of all works
        </Link>
        {artist.listsOfPieces.map((listOfPieces, i) => {
          return (
            <Link
              key={i}
              className={
                path === "/artist/:artistId/works/:catalogueId" &&
                catalogueId === listOfPieces.id
                  ? "PiecesListsNavigation-link PiecesListsNavigation-link-active"
                  : "PiecesListsNavigation-link"
              }
              to={"/artist/" + artist._id + "/works/" + listOfPieces.id}
            >
              {listOfPieces.name}
            </Link>
          );
        })}
      </div>
    );
  } else {
    return null;
  }
});
