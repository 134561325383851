import React from "react";
import "./css/ButtonPlayOnSpotify.css";
import spotify from "./spotify.png";
import spotifySmall from "./spotify-small.png";

export default function ButtonPlayOnSpotify(props) {
  const {
    albumIdSpotify,
    trackPosition,
    trackTitle,
    small,
    handleShowMessageBox,
    handleShowLoginToSpotify,
    handleShowSpotifyDevicesWindow
  } = props;

  if (albumIdSpotify) {
    if (small) {
      return (
        <div
          className="ButtonPlayOnSpotify-small"
          onClick={() =>
            playOnSpotify(albumIdSpotify, trackTitle, trackPosition)
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="ButtonPlayOnSpotify-icon"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zM10.622 8.415a.4.4 0 0 0-.622.332v6.506a.4.4 0 0 0 .622.332l4.879-3.252a.4.4 0 0 0 0-.666l-4.88-3.252z"
              fill="rgba(255,255,255,1)"
            />
          </svg>
        </div>
      );
    } else {
      return (
        <div
          className="ButtonPlayOnSpotify"
          onClick={() =>
            playOnSpotify(albumIdSpotify, trackTitle, trackPosition)
          }
        >
          <div className="ButtonPlayOnSpotify-text">Play on</div>
          <img
            src={small ? spotifySmall : spotify}
            width={small ? "26px" : "70px"}
            alt="spotify"
          />
        </div>
      );
    }
  } else {
    return null;
  }

  async function playOnSpotify(albumIdSpotify, trackTitle, trackPosition = 1) {
    try {
      const url = `/api/spotify/play/${albumIdSpotify}/${trackPosition}?trackTitle=${trackTitle}`;
      const resp = await fetch(url);
      const respJSON = await resp.json();
      console.log(url)
      console.log(respJSON)
      handleShowMessageBox(respJSON.message, 4000);
      if (resp.status === 200 && respJSON.reason === "NO_ACTIVE_DEVICE") {
        // Show the device selection dialog
        handleShowSpotifyDevicesWindow(url);
      }
      if (resp.status === 403 && respJSON.reason === "NOT_CONNECTED_TO_SPOTIFY") {
        // Show the login to Spotify dialog
        handleShowLoginToSpotify();
      }
    } catch (err) {
      console.log(err);
    }
  }
}
