import React from "react";
import { Link } from "react-router-dom";
import "./css/BrowsePeriods.css";
import "./css/Sidebar.css";

export default function BrowsePeriods(props) {
  return (
    <div className="BrowsePeriods">
      <Link to="/period/Contemporary" className="BrowsePeriods-button">
        Contemporary (1960-2000)
      </Link>
      <Link to="/period/Modern" className="BrowsePeriods-button">
        Modern (1880-1960)
      </Link>
      <Link to="/period/Romantic" className="BrowsePeriods-button">
        Romantic (1780-1910)
      </Link>
      <Link to="/period/Classical" className="BrowsePeriods-button">
        Classical (1730-1820)
      </Link>
      <Link to="/period/Baroque" className="BrowsePeriods-button">
        Baroque (1600-1750)
      </Link>
      <Link to="/period/Renaissance" className="BrowsePeriods-button">
        Renaissance (1400-1600)
      </Link>
      <Link to="/period/Medieval" className="BrowsePeriods-button">
        Medieval (500-1400)
      </Link>
    </div>
  );
}
