import React, { useState, useEffect } from "react";
import OutsideAlerter from "./OutsideAlerter.js";
import { withRouter } from "react-router-dom";
import spotifySmall from "./spotify-small.png";
// import facebookSmall from "./facebook-small.png";
import "./css/Login.css";

function Login(props) {
  const { postReview, setShowLoginWindow, defaultWindow } = props;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [error, setError] = useState("");
  const [showRegister, setShowRegister] = useState(
    defaultWindow === "register" ? true : false
  );

  

  useEffect(() => {
    document.title = "Lyra digital music library";
    document.getElementById("email").focus();
  }, []);

  return (
    <div className="Window">
      <OutsideAlerter
        onOutsideClick={() => setShowLoginWindow()}
        className="Window-window"
      >
        {!showRegister ? renderLogin() : null}
        {showRegister ? renderRegister() : null}
      </OutsideAlerter>
      <div className="Window-background"></div>
    </div>
  );

  async function login() {
    try {
      const resp = await fetch("/login", {
        method: "POST",
        body: JSON.stringify({ email, password }),
        headers: {
          "Content-Type": "application/json"
        }
      });
      const respJSON = await resp.json();

      if (respJSON.err) {
        setError(respJSON.err);
      } else {
        // publish comment and then reload
        postReview(respJSON);
      }
    } catch (err) {
      console.log(err);
      document.location.reload(true);
    }
  }

  function handleSubmitLogin(e) {
    e.preventDefault();
    login();
  }

  function handleSubmitRegister(e) {
    e.preventDefault();
    register();
  }

  function handleChange(e) {
    if (e.currentTarget.name === "newEmail") {
      setEmail(e.currentTarget.value);
    } else if (e.currentTarget.name === "newUsername") {
      setUsername(e.currentTarget.value);
    } else if (e.currentTarget.name === "newPassword") {
      setPassword(e.currentTarget.value);
    }
  }

  function renderLogin() {
    return (
      <div>
        <h2>Log in</h2>
        <form className="Window-form" onSubmit={e => handleSubmitLogin(e)}>
          <div className="Login-with-buttons">
            {/* <div className="Login-with-button Login-with-spotify">
              <img
                src={spotifySmall}
                className="Login-with-spotify-icon"
                width="24px"
                alt="spotify"
              />
              <a href="/auth/spotify">Log in with Spotify</a>
            </div> */}
          </div>
          <span>Or Log in with your account</span>
          <input
            className="Login-input"
            value={email}
            onChange={e => handleChange(e)}
            type="text"
            name="newEmail"
            id="email"
            placeholder="Email"
            autoComplete="email"
          />
          <input
            className="Login-input"
            value={password}
            onChange={e => handleChange(e)}
            type="password"
            name="newPassword"
            autoComplete="current-password"
            placeholder="Password"
          />
          <span className="Login-error">{error}</span>
          <div className="Login-buttons">
            <button className="Login-button" type="submit">
              Log in
            </button>
            <div
              className="Login-button Login-button-2"
              onClick={() => {
                setEmail("");
                setPassword("");
                setUsername("");
                setError("");
                setShowRegister(true);
              }}
            >
              Register
            </div>
          </div>
        </form>
      </div>
    );
  }

  function renderRegister() {
    return (
      <div>
        <h2>Register</h2>
        <form
          className="Window-form"
          onSubmit={e => handleSubmitRegister(e)}
          autoComplete="new-password"
        >
          <div className="Login-with-buttons">
            <div className="Login-with-button Login-with-spotify">
              <img
                src={spotifySmall}
                className="Login-with-spotify-icon"
                width="24px"
                alt="spotify"
              />
              <a href="/auth/spotify">Register with Spotify</a>
            </div>
            {/* <div className="Login-with-button Login-with-facebook">
              <img
                src={facebookSmall}
                className="Login-with-spotify-icon"
                width="24px"
                alt="spotify"
              />
              <a href="/auth/facebook">Register with Facebook</a>
            </div> */}
          </div>
          <span>Or create an account</span>
          <input
            className="Login-input"
            value={email}
            onChange={e => handleChange(e)}
            type="text"
            name="newEmail"
            id="email"
            placeholder="Email*"
            autoComplete="new-password"
          />
          <input
            className="Login-input"
            value={username}
            onChange={e => handleChange(e)}
            type="text"
            name="newUsername"
            placeholder="Username*"
            autoComplete="new-password"
          />
          <input
            className="Login-input"
            value={password}
            onChange={e => handleChange(e)}
            type="password"
            name="newPassword"
            placeholder="Password*"
            autoComplete="new-password"
          />
          <span className="Login-error">{error}</span>
          <div className="Login-buttons">
            <button className="Login-button" type="submit">
              Register
            </button>
            <div
              className="Login-button Login-button-2"
              onClick={() => {
                setShowRegister(false);
              }}
            >
              Log in
            </div>
          </div>
        </form>
      </div>
    );
  }

  async function register() {
    try {
      const body = { email, username, password };
      const resp = await fetch("/register", {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json"
        }
      });
      const respJSON = await resp.json();
      if (respJSON) {
        if (respJSON.message) {
          setError(respJSON.message);
        } else {
          window.location = "/";
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export default withRouter(Login);
