import React, { useState, useEffect, useRef } from "react";
import PiecesTableRow from "./PiecesTableRow.js";
import InfiniteScrolling from "./InfiniteScrolling.js";
// import FavoritesEmpty from "./FavoritesEmpty.js";
import "./css/PiecesTable.css";

export default function PiecesTable(props) {
  const { queryString, catalogueId, showComposer } = props;
  const [pieces, setPieces] = useState([]);
  const [newPieces, setNewPieces] = useState([]);
  const [piecesCounter, setPiecesCounter] = useState(0);

  async function fetchPieces(limit, page) {
    try {
      let url = `/api/pieces${queryString}&limit=${limit}&page=${page}`;
      const resp = await fetch(url);
      const newPieces = await resp.json();
      const newPiecesCounter = resp.headers.get("Result-Count");
      setPiecesCounter(newPiecesCounter);
      setNewPieces(newPieces);
    } catch (err) {
      console.log(err);
    }
  }

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevNewPieces = usePrevious(newPieces);

  // Show first items
  useEffect(() => {
    if (pieces.length === 0 && newPieces.length !== 0) setPieces(newPieces);
  }, [newPieces, pieces]);

  // add when changes
  useEffect(() => {
    if (newPieces !== prevNewPieces) {
      setPieces(pieces.concat(newPieces));
    }
  }, [newPieces, prevNewPieces, pieces]);

  useEffect(() => {
    setPieces([]);
    setNewPieces([]);
    setPiecesCounter(0);
    async function fetchFirstPieces() {
      try {
        let url = `/api/pieces${queryString}&limit=50&page=1`;
        const resp = await fetch(url);
        const newPieces = await resp.json();
        const newPiecesCounter = resp.headers.get("Result-Count");
        setPiecesCounter(newPiecesCounter);
        setNewPieces(newPieces);
      } catch (err) {
        console.log(err);
      }
    }
    fetchFirstPieces();
  }, [queryString]);

  if (pieces && pieces.length > 0) {
    return (
      <InfiniteScrolling
        items={pieces}
        counter={piecesCounter}
        limit={50}
        queryString={queryString}
        onScrollBottom={(limit, page) => {
          fetchPieces(limit, page);
        }}
      >
        <div className="PiecesTable">
          {pieces.map((piece, i) => {
            return (
              <PiecesTableRow
                key={i}
                piece={piece}
                prevPiece={pieces[i - 1]}
                catalogueId={catalogueId}
                showComposer={showComposer}
              />
            );
          })}
        </div>
      </InfiniteScrolling>
    );
    // } else if (pieces && pieces.length > 0 && path === "/favorites/pieces") {
    //   return (
    //     <FavoritesEmpty type="piece" typePlural="pieces" redirect="/pieces" />
    //   );
  } else {
    return null;
  }
}
