import React from "react";
import { Link } from "react-router-dom";
import "./css/HeaderNavigation.css";

export default function HeaderArtistNavigation(props) {
  const { artist, page } = props;
  if (artist) {
    return (
      <div className="HeaderNavigation">
        {renderButtonAlbums()}
        {renderButtonRecordings()}
        {renderButtonWorks()}
        {renderButtonAbout()}
      </div>
    );
  } else {
    return null;
  }

  function renderButtonAlbums() {
    return (
      <Link
        to={"/artist/" + artist._id}
        className={
          page === "albums"
            ? "HeaderNavigation-button HeaderNavigation-button-active"
            : "HeaderNavigation-button"
        }
      >
        Albums
      </Link>
    );
  }

  function renderButtonRecordings() {
    return (
      <Link
        to={"/artist/" + artist._id + "/recordings"}
        className={
          page === "recordings"
            ? "HeaderNavigation-button HeaderNavigation-button-active"
            : "HeaderNavigation-button"
        }
      >
        Recordings
      </Link>
    );
  }

  function renderButtonWorks() {
    if (artist.numberPiecesAsComposer > 0) {
      return (
        <Link
          to={"/artist/" + artist._id + "/works"}
          className={
            page === "works"
              ? "HeaderNavigation-button HeaderNavigation-button-active"
              : "HeaderNavigation-button"
          }
        >
          Works
        </Link>
      );
    }
  }

  function renderButtonAbout() {
    return (
      <Link
        to={"/artist/" + artist._id + "/about"}
        className={
          page === "about"
            ? "HeaderNavigation-button HeaderNavigation-button-active"
            : "HeaderNavigation-button"
        }
      >
        About
      </Link>
    );
  }
}
