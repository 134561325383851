import React, { useState, useEffect, useContext } from "react";
import querystring from "querystring";
import Frame from "./Frame";
import ButtonPlayOnSpotify from "./ButtonPlayOnSpotify";
import PieceMoreInfo from "./PieceMoreInfo.js";
import PieceHeaderMoreInfo from "./PieceHeaderMoreInfo.js";
import PieceHeaderNavigation from "./PieceHeaderNavigation.js";
import AlbumsList from "./AlbumsList";
import Windows from "./Windows";
import PieceRecordingsTable from "./PieceRecordingsTable";
import PieceRecordingsFilters from "./PieceRecordingsFilters";
import PieceAlbumsFilters from "./PieceAlbumsFilters";
import { setQueryString } from "./helpers/helpers.js";
import { format } from "./helpers/helpers.js";
import UserContext from "./UserContext";

export default function Piece(props) {
  return (
    <Windows>
      <PieceContent {...props} />
    </Windows>
  );
}

function PieceContent(props) {
  const {
    handleShowMessageBox,
    handleShowSpotifyDevicesWindow,
    handleShowLoginToSpotify,
    handleShowLoginWindow,
    location,
    match
  } = props;
  const [piece, setPiece] = useState(null);
  const [lastRecording, setLastRecording] = useState(null);
  const [queryStringState, setQueryStringState] = useState(null);
  const user = useContext(UserContext);

  let page = "albums";
  if (match.path === "/piece/:pieceId/recordings") page = "recordings";
  else if (match.path === "/piece/:pieceId/about") page = "about";

  let pieceId = "";
  if (match.params.pieceId) {
    pieceId = match.params.pieceId;
  }

  // function usePrevious(value) {
  //   const ref = useRef();
  //   useEffect(() => {
  //     ref.current = value;
  //   });
  //   return ref.current;
  // }

  // const prevPiece = usePrevious(piece);

  useEffect(() => {
    async function fetchPiece(pieceId) {
      try {
        const resp = await fetch("/api/pieces/" + pieceId);
        const piece = await resp.json();
        setPiece(piece);
        let queryString = setQueryString(location.search, match.params);
        queryString +=
          piece && piece.movements && piece.movements.length > 0
            ? "&groupAlbums=true"
            : "";
        setQueryStringState(queryString);
        return resp;
      } catch (err) {
        console.log(err);
      }
    }
    fetchPiece(pieceId);
  }, [pieceId, setPiece, location.search, match.params]);

  useEffect(() => {
    setQueryStringState(null);
  }, [pieceId]);

  useEffect(() => {
    if (piece && piece.title) {
      document.title = piece.title + " - Lyra digital music library";
    }
  }, [piece]);

  const queryIds = queryStringState
    ? querystring.parse(queryStringState.substring(1))
    : null;

  return (
    <Frame>
      {piece ? (
        <div className="Artist">
          <div className="Artist-header Header">
            <div className="Header-category">Work</div>
            <div className="Header-big-title">{piece.title}</div>
            {piece.profile ? (
              <div className="Header-subtitle">{format(piece.profile)}</div>
            ) : null}
            {/* <div className="Header-buttons">{renderButtonPlayOnSpotify()}</div> */}
            <PieceHeaderMoreInfo
              piece={piece}
              lastRecording={lastRecording}
              handleShowMessageBox={handleShowMessageBox}
              handleShowSpotifyDevicesWindow={handleShowSpotifyDevicesWindow}
              handleShowLoginToSpotify={handleShowLoginToSpotify}
              handleShowLoginWindow={handleShowLoginWindow}
            />
            <PieceHeaderNavigation piece={piece} page={page} />
          </div>
          <div className="Artist-content">
            {page === "albums" ? renderAlbums() : null}
            {page === "recordings" ? renderRecordings() : null}
            {page === "about" ? renderAbout() : null}
          </div>
          <div className="Artist-info"></div>
        </div>
      ) : null}
    </Frame>
  );

  function renderAlbums() {
    if (piece && queryStringState && queryIds)
      return (
        <div>
          <PieceAlbumsFilters
            history={props.history}
            paramsURL={props.match.params}
            queryIds={queryIds}
            queryString={queryStringState}
          />
          <AlbumsList queryString={queryStringState} headerContent={piece} />
        </div>
      );
  }

  function renderRecordings() {
    if (piece && queryStringState && queryIds)
      return (
        <div>
          <PieceRecordingsFilters
            history={props.history}
            paramsURL={props.match.params}
            queryIds={queryIds}
            queryString={queryStringState}
          />
          <Windows>
            <PieceRecordingsTable
              queryString={queryStringState}
              showArtistsAsTitle={true}
              setLastRecording={setLastRecording}
              piece={piece}
            />
          </Windows>
        </div>
      );
    else return null;
  }

  // function renderButtonPlayOnSpotify() {
  //   if (
  //     piece &&
  //     lastRecording &&
  //     lastRecording.albums &&
  //     lastRecording.albums.length > 0 &&
  //     lastRecording.albums[0].externalLinks &&
  //     lastRecording.albums[0].externalLinks.spotify
  //   ) {
  //     const album = lastRecording.albums[0];
  //     return (
  //       <ButtonPlayOnSpotify
  //         albumIdSpotify={album.externalLinks.spotify}
  //         trackTitle={lastRecording.title}
  //         trackPosition={album.trackPosition}
  //         handleShowMessageBox={handleShowMessageBox}
  //         handleShowSpotifyDevicesWindow={handleShowSpotifyDevicesWindow}
  //         handleShowLoginToSpotify={handleShowLoginToSpotify}
  //       />
  //     );
  //   }
  // }

  // function renderButtonEdit() {
  //   return (
  //     <div>
  //       <Link className=" Button Header-button" to={`/piece/${piece._id}/edit`}>
  //         Edit
  //       </Link>
  //     </div>
  //   );
  // }

  function renderAbout() {
    if (piece)
      return (
        <div>
          <PieceMoreInfo
            piece={piece}
            lastRecording={lastRecording}
            handleShowMessageBox={handleShowMessageBox}
            handleShowSpotifyDevicesWindow={handleShowSpotifyDevicesWindow}
            handleShowLoginToSpotify={handleShowLoginToSpotify}
            handleShowLoginWindow={handleShowLoginWindow}
            user={user}
          />
        </div>
      );
  }
}
