import React from "react";
import Frame from "./Frame";
import "./css/Page.css";

export default function TermsOfUse(props) {
  return (
    <Frame>
      <div className="Page">
        <h1 className="Page-title">Terms of Use</h1>
        <p>
          Please read these Terms of Use ("Terms", "Terms of Use") carefully
          before using the www.classicalmusicatabase.com website (the "Service")
          operated by Lyra digital music library ("us", "we", or "our"). Your
          access to and use of the Service is conditioned on your acceptance of
          and compliance with these Terms. These Terms apply to all visitors,
          users and others who access or use the Service. By accessing or using
          the Service you agree to be bound by these Terms. If you disagree with
          any part of the terms then you may not access the Service. This Terms
          of Use agreement for Lyra digital music library is managed by{" "}
          <a href="https://termsfeed.com/terms-conditions/generator/">
            the Terms and Conditions Generator
          </a>
          .
        </p>
        <h2>Intellectual Property</h2>
        <p>
          Lyra digital music library and included content (and any derivative
          works or enhancements of the same) including, but not limited to, all
          text, data, files, scripts, information, content, materials, services,
          and interactive features (collectively, the "Website Content") and all
          intellectual property rights to the same are owned by us, our
          licensors, or both. Additionally, all trademarks, service marks, trade
          names and trade dress that may appear on our Websites are owned by us,
          our licensors, or both. You shall not acquire any right, title or
          interest in our Websites or any Website Content. Any rights not
          expressly granted in these Terms of Use are expressly reserved.
        </p>
        <h2>Access and Use</h2>
        <ol>
          <li>
            <p>
              {" "}
              When accessing Lyra digital music library, including without
              limitation to the Website Content, you agree to comply with all
              applicable federal, state, and local laws including, without
              limitation copyright law. Except as expressly permitted in these
              Terms of Use, you may not use, reproduce, distribute, create
              derivative works based upon, publicly display, publicly perform,
              publish, transmit, or otherwise exploit Website Content for any
              purpose whatsoever without obtaining prior written consent from us
              or, in the case of third-party content, its respective owner. You
              acknowledge that you do not acquire any ownership rights by
              downloading or printing Website Content.{" "}
            </p>
          </li>
          <li>
            <p>
              {" "}
              Furthermore, except as expressly permitted in these Terms of Use,
              you may not:
            </p>
            <ol>
              <li>
                <p>
                  remove, alter, cover, or distort any copyright, trademark, or
                  other proprietary rights notice on Lyra digital music library or
                  Website Content;
                </p>
              </li>
              <li>
                <p>
                  circumvent, disable or otherwise interfere with
                  security-related features of our Websites including, without
                  limitation, any features that prevent or restrict use or
                  copying of any content or enforce limitations on the use of
                  our Websites or Website Content;{" "}
                </p>
              </li>
              <li>
                <p>
                  use an automatic device (such as a robot or spider) or manual
                  process to copy or "scrape" our Websites or Website Content
                  for any purpose without the express written permission of
                  Lyra digital music library. Notwithstanding the foregoing,
                  Lyra digital music library grants public search engine operators
                  permission to use automatic devices (such as robots or
                  spiders) to copy Website Content from our Websites for the
                  sole purpose of creating (and only to the extent necessary to
                  create) a searchable index of Website Content that is
                  available to the public. Lyra digital music library reserves the
                  right to revoke this permission (generally or specifically) at
                  any time;{" "}
                </p>
              </li>
              <li>
                <p>
                  collect or harvest any personally identifiable information
                  from our Websites including, without limitation, user names,
                  passwords, email addresses;{" "}
                </p>
              </li>
              <li>
                <p>
                  solicit other users to join or become members of any
                  commercial online service or other organization without our
                  prior written approval;{" "}
                </p>
              </li>
              <li>
                <p>
                  attempt to or interfere with the proper working of our
                  Websites or impair, overburden, or disable the same;
                </p>
              </li>
              <li>
                <p>
                  decompile, reverse engineer, or disassemble any portion of any
                  our Websites;{" "}
                </p>
              </li>
              <li>
                <p>
                  use network-monitoring software to determine architecture of
                  or extract usage data from any of our Websites;
                </p>
              </li>
              <li>
                <p>
                  encourage conduct that violates any local, state or federal
                  law, either civil or criminal, or impersonate another user,
                  person, or entity (e.g., using another person's Membership (as
                  defined in Section B) without permission, etc.);{" "}
                </p>
              </li>
              <li>
                <p>
                  violate U.S. export laws, including, without limitation,
                  violations of the Export Administration Act and the Export
                  Administration Regulations administered by the Department of
                  Commerce; or
                </p>
              </li>
              <li>
                <p>
                  engage in any conduct that restricts or inhibits any other
                  user from using or enjoying our Websites.{" "}
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              You agree to cooperate fully with Lyra digital music library to
              investigate any suspected or actual activity that is in breach of
              these Terms of Use.
            </p>
          </li>
        </ol>
        <h2>Accounts</h2>
        <p>
          When you create an account with us, you must provide us information
          that is accurate, complete, and current at all times. Failure to do so
          constitutes a breach of the Terms, which may result in immediate
          termination of your account on our Service.
        </p>
        <p>
          You are responsible for safeguarding the password that you use to
          access the Service and for any activities or actions under your
          password, whether your password is with our Service or a third-party
          service.
        </p>
        <p>
          You agree not to disclose your password to any third party. You must
          notify us immediately upon becoming aware of any breach of security or
          unauthorized use of your account.
        </p>
        <h2>Links To Other Web Sites</h2>
        <p>
          Our Service may contain links to third-party web sites or services
          that are not owned or controlled by Lyra digital music library.
        </p>
        <p>
          Lyra digital music library has no control over, and assumes no
          responsibility for, the content, privacy policies, or practices of any
          third party web sites or services. You further acknowledge and agree
          that Lyra digital music library shall not be responsible or liable,
          directly or indirectly, for any damage or loss caused or alleged to be
          caused by or in connection with use of or reliance on any such
          content, goods or services available on or through any such web sites
          or services.
        </p>
        <p>
          We strongly advise you to read the terms and conditions and privacy
          policies of any third-party web sites or services that you visit.
        </p>
        <h2>Termination</h2>
        <p>
          We may terminate or suspend access to our Service immediately, without
          prior notice or liability, for any reason whatsoever, including
          without limitation if you breach the Terms.
        </p>
        <p>
          All provisions of the Terms which by their nature should survive
          termination shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity and limitations
          of liability.
        </p>
        <p>
          We may terminate or suspend your account immediately, without prior
          notice or liability, for any reason whatsoever, including without
          limitation if you breach the Terms.
        </p>
        <p>
          Upon termination, your right to use the Service will immediately
          cease. If you wish to terminate your account, you may simply
          discontinue using the Service.
        </p>
        <p>
          All provisions of the Terms which by their nature should survive
          termination shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity and limitations
          of liability.
        </p>
        <h2>Governing Law</h2>
        <p>
          These Terms shall be governed and construed in accordance with the
          laws of France, without regard to its conflict of law provisions.
        </p>
        <p>
          Our failure to enforce any right or provision of these Terms will not
          be considered a waiver of those rights. If any provision of these
          Terms is held to be invalid or unenforceable by a court, the remaining
          provisions of these Terms will remain in effect. These Terms
          constitute the entire agreement between us regarding our Service, and
          supersede and replace any prior agreements we might have between us
          regarding the Service.
        </p>
        <h2>Changes</h2>
        <p>
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. If a revision is material we will try to
          provide at least 30 days notice prior to any new terms taking effect.
          What constitutes a material change will be determined at our sole
          discretion.
        </p>
        <p>
          By continuing to access or use our Service after those revisions
          become effective, you agree to be bound by the revised terms. If you
          do not agree to the new terms, please stop using the Service.
        </p>
        <h2>Contact Us</h2>
        <p>If you have any questions about these Terms, please contact us.</p>
      </div>
    </Frame>
  );
}
