import React from "react";
import querystring from "querystring";
import { Link } from "react-router-dom";

function msToTime(s) {
  function pad(n, z) {
    z = z || 2;
    return ("00" + n).slice(-z);
  }
  var ms = s % 1000;
  s = (s - ms) / 1000;
  var secs = s % 60;
  s = (s - secs) / 60;
  var mins = s % 60;
  var hrs = (s - mins) / 60;
  return pad(hrs) + ":" + pad(mins) + ":" + pad(secs);
}

function msToMinutes(s) {
  function pad(n, z) {
    z = z || 2;
    return ("00" + n).slice(-z);
  }
  var ms = s % 1000;
  s = (s - ms) / 1000;
  var secs = s % 60;
  s = (s - secs) / 60;
  var mins = s % 60;
  var hrs = (s - mins) / 60;
  if (hrs === 0) {
    return pad(mins) + ":" + pad(secs);
  } else {
    return pad(hrs) + ":" + pad(mins) + ":" + pad(secs);
  }
}

function msToTimeLong(s) {
  var ms = s % 1000;
  s = (s - ms) / 1000;
  var secs = s % 60;
  s = (s - secs) / 60;
  var mins = s % 60;
  var hrs = (s - mins) / 60;
  if (hrs === 0) {
    return mins + " min";
  } else {
    return hrs + " hr " + (mins !== 0 ? mins + " min" : "");
  }
}

function convertDate(date) {
  let options = {};
  if (date) {
    if (date.length === 10) {
      options = { year: "numeric", month: "long", day: "numeric" };
    } else if (date.length === 7) {
      options = { year: "numeric", month: "long" };
    } else if (date.length === 4) {
      options = { year: "numeric" };
    }
    return new Date(date).toLocaleDateString("en-EN", options);
  } else {
    return "";
  }
}

function convertDateFromArrayToString(dates, precision) {
  if (Array.isArray(dates)) {
    let dateString = precision === "between" ? "between " : "";
    dates.forEach((date, i) => {
      if (precision === "or") {
        dateString +=
          convertDateToYear(date) +
          (dates.length - 1 === i ? "" : " " + precision + " ");
      } else if (precision === "between") {
        dateString +=
          convertDateToYear(date) + (dates.length - 1 === i ? "" : " and ");
      } else {
        dateString +=
          (dates.length - 1 === i ? "" : precision + " ") +
          convertDateToYear(date) +
          (dates.length - 1 === i ? "" : "-");
      }
    });
    return dateString;
  }
}

function convertDateToYear(date, precision = "") {
  if (date) {
    let options = {};
    if (date.length === 10) {
      options = { year: "numeric" };
    } else if (date.length === 7) {
      options = { year: "numeric" };
    } else if (date.length === 4) {
      options = { year: "numeric" };
    }

    if (precision && precision !== "?") {
      return (
        precision + " " + new Date(date).toLocaleDateString("en-EN", options)
      );
    } else if (precision && precision === "?") {
      return new Date(date).toLocaleDateString("en-EN", options) + " (?)";
    } else {
      return new Date(date).toLocaleDateString("en-EN", options);
    }
  }
}

function convertDateToYearAndToString(dates, precision, includeIn = false) {
  let precisionString = "";
  if (
    includeIn &&
    precision !== "between" &&
    precision !== "c." &&
    precision !== "after"
  ) {
    precisionString = "in ";
  }
  return Array.isArray(dates)
    ? precisionString + convertDateFromArrayToString(dates, precision)
    : precisionString + convertDateToYear(dates, precision);
}

function removeURLParameter(url, parameter) {
  let queryAsObject = querystring.parse(url.substring(1));
  delete queryAsObject[parameter];
  const queryAsString = "?" + querystring.stringify(queryAsObject);
  return queryAsString;
}

function sortByField(field = "name") {
  return function(a, b) {
    const resultA = a[field];
    const resultB = b[field];

    let comparison = 0;
    if (resultA > resultB) {
      comparison = 1;
    } else if (resultA < resultB) {
      comparison = -1;
    }
    return comparison;
  };
}

function sortByName(a, b) {
  const resultA = a.name;
  const resultB = b.name;

  let comparison = 0;
  if (resultA > resultB) {
    comparison = 1;
  } else if (resultA < resultB) {
    comparison = -1;
  }
  return comparison;
}

function convertQueryStringToObject(query, key) {
  let queryIds = [];
  let list = {};
  if (query != null) {
    queryIds = query.split(",");
    list = queryIds.map(queryId => ({ [key]: queryId }));
  }
  return list;
}

function convertQueryStringToArray(string) {
  let array = [];
  if (string != null) {
    array = string.split("_");
  }
  return array;
}

function returnUniqueArrayOfObjects(array, key) {
  return array.filter(function(obj, index, self) {
    return (
      index ===
      self.findIndex(function(t) {
        return t[key] === obj[key];
      })
    );
  });
}

function filtersExists(queryIds) {
  if (queryIds)
    return Object.entries(queryIds).length === 0 &&
      queryIds.constructor === Object
      ? false
      : true;
}

// Return a string with ', ' between items and ' and ' between last two items
function convertListToString(array) {
  let string = "";
  array.forEach(function(item, i) {
    string += item;
    if (i + 2 < array.length) {
      string += ", ";
    } else if (i + 2 === array.length) string += " and ";
  });
  return string;
}

// Return a string with ', ' between items
function convertListToStringWithComma(array) {
  let string = "";
  array.forEach(function(item, i) {
    string += item;
    if (i + 1 < array.length) {
      string += ", ";
    }
  });
  return string;
}

// Return a string with ', ' between items and ' and ' between last two items
function convertListToStringWithSlash(array) {
  let string = "";
  array.forEach(function(item, i) {
    string += item;
    if (i + 1 < array.length) string += "/";
  });
  return string;
}

// Return a string with ', ' between items and ' and ' between last two items for objects (with field in parameter)
function convertListOfObjectToString(array, field) {
  let string = "";
  array.forEach(function(item, i) {
    string += item[field];
    if (i + 2 < array.length) {
      string += ", ";
    } else if (i + 2 === array.length) string += " and ";
  });
  return string;
}

function throttle(callback, delay) {
  var last;
  var timer;
  return function() {
    var context = this;
    var now = +new Date();
    var args = arguments;
    if (last && now < last + delay) {
      clearTimeout(timer);
      timer = setTimeout(function() {
        last = now;
        callback.apply(context, args);
      }, delay);
    } else {
      last = now;
      callback.apply(context, args);
    }
  };
}

// function throttle(func, wait, options) {
//   var context, args, result;
//   var timeout = null;
//   var previous = 0;
//   if (!options) options = {};
//   var later = function() {
//     previous = options.leading === false ? 0 : Date.now();
//     timeout = null;
//     result = func.apply(context, args);
//     if (!timeout) context = args = null;
//   };
//   return function() {
//     var now = Date.now();
//     if (!previous && options.leading === false) previous = now;
//     var remaining = wait - (now - previous);
//     context = this;
//     args = arguments;
//     if (remaining <= 0 || remaining > wait) {
//       if (timeout) {
//         clearTimeout(timeout);
//         timeout = null;
//       }
//       previous = now;
//       result = func.apply(context, args);
//       if (!timeout) context = args = null;
//     } else if (!timeout && options.trailing !== false) {
//       timeout = setTimeout(later, remaining);
//     }
//     return result;
//   };
// };

function filterItems(arr, query, field = null) {
  if (field) {
    return arr.filter(function(el) {
      return el.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
  } else {
    return arr.filter(function(el) {
      return el.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
  }
}

function cutString(string, index = 12) {
  if (typeof string === "string")
    return string.substring(0, index) + (string.length > index ? "..." : "");
  else return string;
}

function convertLongDateToString(longDate) {
  const date = new Date(longDate.replace("+", ""));
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString("en-GB", options);
}

function convertDateWithPrecision(date, precision) {
  const dateObject = new Date(date.replace("+", ""));
  let newDate = "";
  let options = {};
  switch (precision) {
    case 11:
      options = { year: "numeric", month: "long", day: "numeric" };
      newDate = dateObject.toLocaleDateString("en-GB", options);
      break;
    case 10:
      options = { year: "numeric", month: "long" };
      newDate = dateObject.toLocaleDateString("en-GB", options);
      break;
    case 9:
      newDate = date.replace("+", "");
      newDate = newDate.slice(0, 4);
      break;
    case 8:
      newDate = dateObject.getFullYear();
      newDate += "s";
      break;
    case 7:
      newDate = date.replace("+", "");
      newDate = newDate.slice(0, 2);
      newDate = parseInt(newDate) + 1 + "th century";
      break;
    default:
  }
  return newDate;
}

function format(text) {
  if (text) {
    const array = text.split(/\]|\[/);
    const uArray = array.map(item => {
      if (/artist;/.test(item)) {
        const artist = item.split(";");
        return (
          <Link key={item} to={"/artist/" + artist[2]}>
            {artist[1]}
          </Link>
        );
      } else if (/ensemble;/.test(item)) {
        const ensemble = item.split(";");
        return (
          <Link key={item} to={"/ensemble/" + ensemble[2]}>
            {ensemble[1]}
          </Link>
        );
      } else {
        return item;
      }
    });
    return uArray;
  }
}

function setQueryString(search, params) {
  let queryString = "?";
  if (search) {
    queryString = search;
    // Params URL exists
    if (!(Object.keys(params).length === 0 && params.constructor === Object)) {
      queryString += "&" + querystring.stringify(params);
    }
  } else {
    if (!(Object.keys(params).length === 0 && params.constructor === Object)) {
      queryString += querystring.stringify(params);
    }
  }
  return queryString;
}

function copyParentData(movement, parent) {
  const newMovement = { ...movement };
  newMovement.parent = {
    id: parent._id.toString(),
    title: parent.title
  };
  if (parent.composers) newMovement.composers = parent.composers;
  if (parent.eras) newMovement.eras = parent.eras;
  if (parent.category) newMovement.category = parent.category;
  if (parent.subcategory) newMovement.subcategory = parent.subcategory;
  if (parent.instruments) newMovement.instruments = parent.instruments;
  if (parent.catalogues) newMovement.catalogues = parent.catalogues;
  if (parent.compositionStart)
    newMovement.compositionStart = parent.compositionStart;
  if (parent.compositionEnd) newMovement.compositionEnd = parent.compositionEnd;
  if (parent.musicalForm) newMovement.musicalForm = parent.musicalForm;
  if (parent.creation) newMovement.creation = parent.creation;
  if (parent.numberAlbums) newMovement.numberAlbums = parent.numberAlbums;
  if (parent.wikipedia) newMovement.wikipedia = parent.wikipedia;
  if (parent.imslp) newMovement.imslp = parent.imslp;
  if (parent.groupsPieces && parent.groupsPieces.length > 0)
    newMovement.groupsPieces = parent.groupsPieces;

  newMovement.movementNumber =
    parent.movements && parent.movements.length > 0
      ? parent.movements.length + 1
      : 1;

  return newMovement;
}

function setPersonSearchNames(person) {
  let searchNames = [];
  if (person.name) {
    searchNames = searchNames.concat(person.name.split(" "));
  }
  if (person.alternativeName) {
    searchNames = searchNames.concat(person.alternativeName.split(" "));
  }
  if (person.aliasesList) {
    let aliases = [];
    person.aliasesList.forEach(alias => {
      aliases = aliases.concat(alias.split(" "));
    });
    aliases = [...new Set(aliases)];
    searchNames = searchNames.concat(aliases);
  }
  if (person.namesList) {
    let names = [];
    person.namesList.forEach(name => {
      names = names.concat(name.split(" "));
    });
    names = [...new Set(names)];
    searchNames = searchNames.concat(names);
  }
  searchNames = [...new Set(searchNames)];
  return searchNames;
}

function setProfile(person) {
  let newProfile = "";
  if (person.nationalities && person.nationalities.length > 0) {
    newProfile += convertListToStringWithSlash(person.nationalities);
  }
  if (
    person.nationalities &&
    person.nationalities.length > 0 &&
    person.occupations &&
    person.occupations.length > 0
  ) {
    newProfile += " ";
  }
  if (person.occupations && person.occupations.length > 0) {
    newProfile += convertListToString(person.occupations).toLowerCase();
  }
  newProfile = capitalize(newProfile);
  return newProfile;
}

function capitalize(s) {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
}

function addDurationMs(tracks) {
  console.log(tracks);
  let durationMs = 0;
  if (tracks) {
    tracks.forEach(track => {
      durationMs += track.durationMs;
    });
  }
  return durationMs;
}

function addInstruments(performers) {
  const instruments = [];
  if (performers) {
    performers.forEach(performer => {
      if (performer.occupations && performer.occupations.length > 0) {
        performer.occupations.forEach(occupation => {
          let instrument = null;
          if (occupation === "Pianist") instrument = "Piano";
          if (occupation === "Violinist") instrument = "Violin";
          if (occupation === "Violist") instrument = "Viola";
          if (occupation === "Cellist") instrument = "Cello";
          if (occupation === "Flutist") instrument = "Flute";
          if (occupation === "Soprano") instrument = "Soprano";
          if (occupation === "Mezzo-soprano") instrument = "Mezzo-soprano";
          if (occupation === "Alto") instrument = "Alto";
          if (occupation === "Contralto") instrument = "Contralto";
          if (occupation === "Countertenor") instrument = "Countertenor";
          if (occupation === "Tenor") instrument = "Tenor";
          if (occupation === "Bandoneon player") instrument = "Bandoneon";
          if (occupation === "Baritone") instrument = "Baritone";
          if (occupation === "Bass-baritone") instrument = "Bass-baritone";
          if (occupation === "Bass") instrument = "Bass";
          if (occupation === "Bassoonist") instrument = "Bassoon";
          if (occupation === "Biwa player") instrument = "Biwa";
          if (occupation === "Celesta player") instrument = "Celesta";
          if (occupation === "Chitarrone player") instrument = "Chitarrone";
          if (occupation === "Choirmaster") instrument = "Choir";
          if (occupation === "Choral conductor") instrument = "Choir";
          if (occupation === "Clarinetist") instrument = "Clarinet";
          if (occupation === "Claviorgan") instrument = "Claviorgan player";
          if (occupation === "Cornettist") instrument = "Cornett";
          if (occupation === "Drummer") instrument = "Drums";
          if (occupation === "Clarinetist") instrument = "Clarinet";
          if (occupation === "Bass clarinetist") instrument = "Bass clarinet";
          if (occupation === "Fortepianist") instrument = "Fortepiano";
          if (occupation === "Harmonica player") instrument = "Harmonica";
          if (occupation === "Shakuhachi player") instrument = "Shakuhachi";
          if (occupation === "Guitarist") instrument = "Guitar";
          if (occupation === "Harpist") instrument = "Harp";
          if (occupation === "Koto player") instrument = "Koto";
          if (occupation === "Harpsichordist") instrument = "Harpsichord";
          if (occupation === "Horn player") instrument = "Horn";
          if (occupation === "Keyboardist") instrument = "Keyboard";
          if (occupation === "Lutenist") instrument = "Lute";
          if (occupation === "Mandolinist") instrument = "Mandolin";
          if (occupation === "Oboist") instrument = "Oboe";
          if (occupation === "Ondist") instrument = "Ondes Martenot";
          if (occupation === "Organist") instrument = "Organ";
          if (occupation === "Percussionist") instrument = "Percussion";
          if (occupation === "Recorder Player") instrument = "Recorder";
          if (occupation === "Saxophonist") instrument = "Saxophone";
          if (occupation === "Serpent player") instrument = "Serpent";
          if (occupation === "Singer") instrument = "Voice";
          if (occupation === "Opera singer") instrument = "Voice";
          if (occupation === "Theorbist") instrument = "Theorbo";
          if (occupation === "Trombonist") instrument = "Trombone";
          if (occupation === "Trumpeter") instrument = "Trumpet";
          if (occupation === "Vibraphone player") instrument = "Vibraphone";
          if (occupation === "Vibraphonist") instrument = "Vibraphone";
          if (occupation === "Viol player") instrument = "Viol";
          if (occupation === "Violone player") instrument = "Violone";
          if (occupation === "Zither player") instrument = "Zither";
          if (occupation === "Dessus") instrument = "Dessus";
          if (occupation === "Bas-dessus") instrument = "Bas-dessus";
          if (occupation === "Haute-contre") instrument = "Haute-contre";
          if (occupation === "Taille") instrument = "Taille";
          if (occupation === "Basse-taille") instrument = "Basse-taille";
          if (occupation === "Basse-contre") instrument = "Basse-contre";

          if (instrument !== null) {
            let shouldBeAdded = true;
            instruments.forEach(instrumentAlbum => {
              if (instrumentAlbum.name === instrument) {
                shouldBeAdded = false;
              }
            });
            if (shouldBeAdded) {
              instruments.push({ name: instrument });
            }
          }
        });
      }
    });
  }
  return instruments;
}

export {
  convertDate,
  convertDateToYear,
  convertDateToYearAndToString,
  convertLongDateToString,
  convertDateWithPrecision,
  convertListToString,
  convertListToStringWithComma,
  convertListToStringWithSlash,
  convertListOfObjectToString,
  convertQueryStringToArray,
  convertQueryStringToObject,
  copyParentData,
  cutString,
  filterItems,
  filtersExists,
  msToTime,
  msToMinutes,
  msToTimeLong,
  removeURLParameter,
  returnUniqueArrayOfObjects,
  sortByField,
  sortByName,
  throttle,
  format,
  setQueryString,
  setPersonSearchNames,
  setProfile,
  addDurationMs,
  addInstruments
};
