import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Frame from "./Frame";
import ButtonPlayOnSpotify from "./ButtonPlayOnSpotify";
import ButtonFavorite from "./ButtonFavorite.js";
import RecordingAlbums from "./RecordingAlbums";
import RecordingMoreInfo from "./RecordingMoreInfo";
import Windows from "./Windows";
import "./css/Recording.css";
import "./css/Button.css";

export default function Recording(props) {
  return (
    <Windows>
      <RecordingContent {...props} />
    </Windows>
  );
}

function RecordingContent(props) {
  const [recording, setRecording] = useState({});
  const [recordingIsLoaded, setRecordingIsLoaded] = useState(false);
  const [isInFavorites, setIsInFavorites] = useState(false);

  const {
    handleShowMessageBox,
    handleShowSpotifyDevicesWindow,
    handleShowLoginToSpotify,
    handleShowLoginWindow
  } = props;

  const recordingId = props.match.params.id;

  useEffect(() => {
    if (recording && recording.title) {
      document.title = recording.title + " - Lyra digital music library";
    }
  }, [recording]);

  async function fetchRecording(recordingId) {
    try {
      const resp = await fetch("/api/recordings/" + recordingId);
      const recording = await resp.json();
      setRecording(recording);
      setRecordingIsLoaded(true);
      return resp;
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchIsInFavorites(recordingId) {
    try {
      const resp = await fetch("/favorites/recording/" + recordingId);
      const respJSON = await resp.json();
      setIsInFavorites(respJSON.isInFavorites);
      return resp;
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchRecording(recordingId);
    fetchIsInFavorites(recordingId);
  }, [recordingId]);

  return (
    <Frame>
      <div className="Artist">
        <div className="Artist-header Header">
          <div className="Header-category">Recording</div>
          <div className="Header-big-title">{recording.title}</div>
          <div className="Header-buttons">
            {/* {renderButtonPlayOnSpotify()} */}
            <ButtonFavorite
              recordingId={recording && recording._id}
              type="recording"
              isInFavorites={isInFavorites}
              callbackAfterAdd={() => setIsInFavorites(true)}
              callbackAfterRemove={() => setIsInFavorites(false)}
              handleShowLoginWindow={handleShowLoginWindow}
            />
            {recording.edit ? renderLinkEdit(recording) : null}
          </div>
          <RecordingMoreInfo recording={recording} />
        </div>
        <div className="Artist-content">
          <div className="Header-list-title">
            <span>Appears in</span>
          </div>
          <RecordingAlbums albums={recording.albums} />
        </div>
        <div className="Artist-info"></div>
      </div>
    </Frame>
  );

  // function renderButtonPlayOnSpotify() {
  //   if (
  //     recordingIsLoaded &&
  //     recording &&
  //     recording.albums &&
  //     recording.albums.length > 0 &&
  //     recording.albums[0].externalLinks &&
  //     recording.albums[0].externalLinks.spotify
  //   ) {
  //     const album = recording.albums[0];

  //     return (
  //       <ButtonPlayOnSpotify
  //         albumIdSpotify={album.externalLinks.spotify}
  //         trackTitle={recording.title}
  //         trackPosition={album.trackPosition}
  //         handleShowMessageBox={handleShowMessageBox}
  //         handleShowSpotifyDevicesWindow={handleShowSpotifyDevicesWindow}
  //         handleShowLoginToSpotify={handleShowLoginToSpotify}
  //       />
  //     );
  //   }
  // }

  function renderLinkEdit() {
    return (
      <Link
        className=" Button Header-button"
        to={`/recording/${recording._id}/edit`}
      >
        Edit
      </Link>
    );
  }
}
