import React from "react";
import querystring from "querystring";
import Frame from "./Frame";
import AlbumsTableUpdate from "./AlbumsTableUpdate";
import FavoritesAlbumsFilters from "./FavoritesAlbumsFilters";
import { setQueryString } from "./helpers/helpers.js";
export default function UpdateRecordings(props) {
  const queryString = setQueryString(props.location.search, {
    ...props.match.params,
    update: true
  });
  const queryIds = querystring.parse(queryString.substring(1));

  return (
    <Frame>
      <div>
        <FavoritesAlbumsFilters
          history={props.history}
          paramsURL={props.match.params}
          queryIds={queryIds}
          queryString={queryString}
        />
        <AlbumsTableUpdate queryString={queryString} update={true} />
      </div>
    </Frame>
  );
}
