import React, { useState } from "react";
import EditAward from "./EditAward";
import EditDate from "./EditDate";
import Select from "./Select";

export default function EditAwards(props) {
  const { awards, setAwards } = props;
  const [name, setName] = useState("");
  const [date, setDate] = useState("");

  return (
    <div className="Edit-input-container">
      <label>Awards</label>
      {awards && awards.length > 0
        ? awards.map((award, i) => (
            <EditAward award={award} removeAward={removeAward} i={i} key={i} />
          ))
        : null}
      <Select
        placeholder="Add Award"
        options={["Gramophone Editor's Choice","Gramophone Recording of the Month", "Diapason d'or"]}
        isSearchable={true}
        isMultiple={false}
        value={name}
        onClear={e => setName("")}
        onChange={e => setName(e)}
      />
      <div className="Edit-input-date">
        <EditDate value={date} setValue={setDate} placeholder="Award Date" />
      </div>
      <button
        className="Edit-button-small"
        onClick={() => {
          addAward();
          setName("");
          setDate("");
        }}
        type="button"
      >
        Add
      </button>
    </div>
  );

  function addAward() {
    const newAwards = [...awards];
    const newAward = {};
    if (name) {
      newAward.name = name;
    }
    if (date) {
      newAward.date = date;
    }
    newAwards.push(newAward);
    setAwards(newAwards);
  }

  function removeAward(i) {
    const newAwards = [...awards];
    newAwards.splice(i, 1);
    setAwards(newAwards);
  }
}
