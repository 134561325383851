import React from "react";

export default function ArtistAuthorities(props) {
  const authorities = props.authorities;
  const list = [];

  link(
    "bne",
    "BNE",
    "http://catalogo.bne.es/uhtbin/authoritybrowse.cgi?action=display&authority_id="
  );
  link(
    "bibsys",
    "BIBSYS",
    "https://authority.bibsys.no/authority/rest/authorities/html/"
  );
  link("bnf", "BNF", "https://catalogue.bnf.fr/ark:/12148/cb");
  link("gnd", "GND", "https://d-nb.info/gnd/");
  link(
    "iccu",
    "ICCU",
    "https://opac.sbn.it/opacsbn/opac/iccu/scheda_authority.jsp?bid="
  );
  link("idref", "IdRef", "https://www.idref.fr/");
  link("isni", "ISNI", "http://www.isni.org/");
  link("lccn", "LCCN", "http://id.loc.gov/authorities/names/");
  link("ndl", "NDL", "https://id.ndl.go.jp/auth/ndlna/");
  link(
    "nkc",
    "NKC",
    "https://aleph.nkp.cz/F/?func=find-c&local_base=aut&ccl_term=ica="
  );
  link(
    "nla",
    "NLA",
    "https://librariesaustralia.nla.gov.au/search/display?dbid=auth&id="
  );
  link("nlg", "NLG", "https://catalogue.nlg.gr/Authority/Record?id=au.");
  link(
    "nli",
    "NLI",
    "http://uli.nli.org.il/F/?func=direct&local_base=nlx10&doc_number="
  );
  link("nlk", "NLK", "https://nl.go.kr/authorities/resource/");
  link(
    "nlr",
    "NLR",
    "http://aleph.bibnat.ro:8991/F/?func=direct&local_base=NLR10&doc_number="
  );
  link("nta", "NTA", "https://data.bibliotheken.nl/doc/thes/p");
  link("rkd", "RKD", "https://rkd.nl/nl/explore/artists/");
  link("selibr", "LIBRIS", "https://libris.kb.se/auth/");
  link("viaf", "VIAF", "https://viaf.org/viaf/");
  link("wikidata", "Wikidata", "https://www.wikidata.org/wiki/");
  link("worldcat", "WorldCat", "https://www.worldcat.org/identities/");

  function renderList(list) {
    return list.map(function(item, i) {
      return (
        <span key={i}>
          {item}
          {i + 1 < list.length ? ", " : ""}
        </span>
      );
    });
  }

  function link(key, name, link) {
    if (authorities[key]) {
      list.push(
        <a
          href={link + authorities[key]}
          target="_blank"
          rel="noopener noreferrer"
          className="link-blue"
        >
          <span>{name}</span>
        </a>
      );
    }
  }

  if (authorities) {
    return <div>{renderList(list)}</div>;
  } else {
    return null;
  }
}
