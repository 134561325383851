import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import OutsideAlerter from "./OutsideAlerter";
import "./css/SearchResults.css";

export default function SearchResults(props) {
  const [selectedResult, setSelectedResult] = useState(0);

  const {
    q,
    searchPeople,
    searchPieces,
    searchEnsembles,
    searchResultsIsLoaded,
    showSearchResults,
    setShowSearchResults,
    numberResults
  } = props;

  useEffect(() => {
    setSelectedResult(0);
    if (q && searchResultsIsLoaded && numberResults > 0) {
      setShowSearchResults(true);
    } else {
      setShowSearchResults(false);
    }
  }, [q, searchResultsIsLoaded, numberResults, setShowSearchResults]);

  document.onkeydown = function(e) {
    e = e || window.event;
    switch (e.which || e.keyCode) {
      case 38: // up
        if (selectedResult === 1 || selectedResult === 0) {
          setSelectedResult(numberResults);
          if (document.getElementById(numberResults))
            document.getElementById(numberResults).focus();
        } else {
          setSelectedResult(selectedResult - 1);
          if (document.getElementById(selectedResult - 1))
            document.getElementById(selectedResult - 1).focus();
        }
        break;

      case 40: // down
        if (
          selectedResult <
          searchPeople.length + searchPieces.length + searchEnsembles.length
        ) {
          setSelectedResult(selectedResult + 1);
          if (document.getElementById(selectedResult + 1))
            document.getElementById(selectedResult + 1).focus();
        } else {
          setSelectedResult(1);
          if (document.getElementById(1)) document.getElementById(1).focus();
        }
        break;

      default:
        return; // exit this handler for other keys
    }
    e.preventDefault(); // prevent the default action (scroll / move caret)
  };

  if (showSearchResults) {
    return (
      <OutsideAlerter onOutsideClick={() => setShowSearchResults(false)}>
        <div className="SearchResults">
          {resultsPeople()}
          {resultsPieces()}
          {resultsEnsembles()}
        </div>
      </OutsideAlerter>
    );
  } else {
    return null;
  }

  function resultsPeople() {
    const { q, searchPeople } = props;
    if (q && searchPeople.length > 0) {
      return (
        <div>
          <div className="SearchResults-title">Artists</div>
          <div className="SearchResults-suggestions">
            {searchPeople.map((person, i) => {
              return (
                <Link
                  key={i}
                  id={i + 1}
                  to={"/artist/" + person._id}
                  className="SearchResults-suggestion"
                  onClick={() => setShowSearchResults(false)}
                >
                  <span>{person ? person.name : null}</span>
                  {/* <span className="SearchResults-suggestion-type">Artist</span> */}
                </Link>
              );
            })}
          </div>
        </div>
      );
    }
  }

  function resultsPieces() {
    const { q, searchPieces } = props;
    if (q && searchPieces.length > 0) {
      return (
        <div>
          <div className="SearchResults-title">Pieces</div>
          <div className="SearchResults-suggestions">
            {searchPieces.map((piece, i) => {
              const type = piece && piece.type ? piece.type : "piece";
              return (
                <Link
                  key={i}
                  id={i + 1 + searchPeople.length}
                  to={"/" + type + "/" + piece._id + "/recordings"}
                  className="SearchResults-suggestion"
                  onClick={() => setShowSearchResults(false)}
                >
                  <span>{piece ? piece.title : null}</span>
                  <span className="SearchResults-suggestion-grey">
                    {/* <span>{piece && piece.parent ? " from " : null}</span> */}
                    {/* <span className="SearchResults-suggestion-white">
                      {piece && piece.parent ? piece.parent.title : null}
                    </span> */}
                    {/* <br/> */}
                    <span>
                      {piece && piece.composers
                        ? piece.composers[0].name
                        : null}
                    </span>
                  </span>
                </Link>
              );
            })}
          </div>
        </div>
      );
    }
  }

  function resultsEnsembles() {
    const { q, searchEnsembles } = props;
    if (q && searchEnsembles.length > 0) {
      return (
        <div>
          <div className="SearchResults-title">Ensembles</div>
          <div className="SearchResults-suggestions">
            {searchEnsembles.map((ensemble, i) => {
              return (
                <Link
                  key={i}
                  id={i + 1 + searchPeople.length + searchPieces.length}
                  to={"/ensemble/" + ensemble._id}
                  className="SearchResults-suggestion"
                  onClick={() => setShowSearchResults(false)}
                >
                  <span>
                    {ensemble ? ensemble.englishName || ensemble.name : null}
                  </span>
                </Link>
              );
            })}
          </div>
        </div>
      );
    }
  }
}
