import React, { useState, useEffect } from "react";
import EditSearchResults from "./EditSearchResults";
import "./css/EditSearch.css";

export default function EditSearch(props) {
  const { collection, handleClick, placeholder, title } = props;
  const [q, setQ] = useState();
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultsIsLoaded, setSearchResultsIsLoaded] = useState(false);

  useEffect(() => {
    if (title) setQ(title);
  }, [title]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (collection === "pieces") {
        setSearchResultsIsLoaded(false);
        fetchSearchPieces(q);
      } else if (collection === "people") {
        setSearchResultsIsLoaded(false);
        fetchSearchPeople(q);
      } else if (collection === "labels") {
        setSearchResultsIsLoaded(false);
        fetchSearchLabels(q);
      } else if (collection === "ensembles") {
        setSearchResultsIsLoaded(false);
        fetchSearchEnsembles(q);
      }
    }, 300);
    return () => clearTimeout(timeOutId);
  }, [q, collection]);

  return (
    <div className="EditSearch-container">
      <div className="EditSearch-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="16"
          height="16"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z"
            fill="var(--text-2)"
          />
        </svg>
      </div>
      <input
        // id="search"
        type="text"
        placeholder={placeholder || "Search"}
        className="EditSearch"
        autoComplete="off"
        value={q}
        onChange={e => setQ(e.target.value)}
      />
      <EditSearchResults
        q={q}
        searchResults={searchResults}
        searchResultsIsLoaded={searchResultsIsLoaded}
        handleClick={result => {
          handleClick(result);
          setQ("");
        }}
      />
    </div>
  );

  // async function fetchSearchEnsembles(q) {
  //   try {
  //     const resp = await fetch("/api/search/ensembles?q=" + q);
  //     const searchEnsemblesJSON = await resp.json();
  //     setSearchResults(searchEnsemblesJSON);
  //     setSearchResultsIsLoaded(true);
  //     return resp;
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  async function fetchSearchPieces(q) {
    try {
      const resp = await fetch("/api/search/pieces?q=" + q + "&limit=10");
      const searchPiecesJSON = await resp.json();
      setSearchResults(searchPiecesJSON);
      setSearchResultsIsLoaded(true);
      return resp;
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchSearchPeople(q) {
    try {
      const resp = await fetch("/api/search/people?q=" + q + "&limit=10");
      const searchPeople = await resp.json();
      setSearchResults(searchPeople);
      setSearchResultsIsLoaded(true);
      return resp;
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchSearchLabels(q) {
    try {
      const resp = await fetch("/api/search/labels?q=" + q + "&limit=10");
      const searchLabels = await resp.json();
      setSearchResults(searchLabels);
      setSearchResultsIsLoaded(true);
      return resp;
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchSearchEnsembles(q) {
    try {
      const resp = await fetch("/api/search/ensembles?q=" + q + "&limit=10");
      const searchEnsembles = await resp.json();
      setSearchResults(searchEnsembles);
      setSearchResultsIsLoaded(true);
      return resp;
    } catch (err) {
      console.log(err);
    }
  }
}
