import React, { useState } from "react";
import InsertLabel from "./InsertLabel";
import EditSearch from "./EditSearch";
import "./css/Edit.css";

export default function LabelToBeAdded(props) {
  const {
    defaultName,
    handleShowMessageBox,
    refetchAlbums,
    searchResults
  } = props;

  const [label, setLabel] = useState(null);
  const [showSearch, setShowSearch] = useState(false);

  return (
    <div className="Edit-box">
      <div className="Edit-box-title-small">Add label</div>
      <div className="Edit-box-title">
        <a
          href={`https://www.google.com/search?q=${defaultName}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {defaultName}
        </a>
      </div>
      {!label ? (
        <div>
          <button className="Button Edit-button" onClick={() => setLabel({})}>
            Insert new label
          </button>
          <button
            className="Button Edit-button"
            onClick={() => setShowSearch(true)}
          >
            Search label
          </button>
        </div>
      ) : null}
      {searchResults && searchResults.length > 0 && !label && !showSearch ? (
        <div>
          <div>or select existing label in database</div>
          {renderSearchResults()}
        </div>
      ) : null}
      {label ? (
        <InsertLabel
          defaultLabel={label}
          defaultName={defaultName}
          // nameSpotify={nameSpotify}
          handleShowMessageBox={handleShowMessageBox}
          refetchAlbums={refetchAlbums}
          setLabel={setLabel}
        />
      ) : null}
      {showSearch ? (
        <div>
          <EditSearch
            collection={"labels"}
            handleClick={async label => {
              const newLabel = await handleAddSpotify(label._id, defaultName);
              console.log(newLabel);
              refetchAlbums();
            }}
          />
        </div>
      ) : null}
    </div>
  );

  function renderSearchResults() {
    if (searchResults && searchResults.length > 0) {
      return searchResults.map((searchResult, i) => (
        <div key={i}>
          <button
            className="Button Edit-button Edit-button-small"
            onClick={async () => {
              const newLabel = await handleAddSpotify(
                searchResult._id,
                defaultName
              );
              console.log(newLabel);
              refetchAlbums();
            }}
          >
            Match ?
          </button>
          <span>{searchResult.name}</span>
        </div>
      ));
    }
  }

  async function handleAddSpotify(id, nameSpotify) {
    const body = JSON.stringify({ nameSpotify: nameSpotify });
    try {
      const resp = await fetch("/api/labels/update/" + id, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body
      });
      const respJSON = await resp.json();
      if (resp.status === 201 && respJSON) {
        return respJSON;
      } else {
        return null;
      }
    } catch (err) {
      console.log(err);
    }
  }
}
