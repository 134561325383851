import React from "react";

export default function EditPiecesItem(props) {
  const { handleRemovePiece, i, piece } = props;

  return (
    <div key={i}>
      {`${i + 1}. ${piece.title}`}
      <button
        className="EditSearch-button"
        onClick={() => handleRemovePiece(i)}
        type="button"
      >
        Remove
      </button>
    </div>
  );
}
