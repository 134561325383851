import React, { useState } from "react";
import "./css/ReportReview.css";

export default function ReportReview(props) {
  const [reportMessage, setReportMessage] = useState(null);
  return (
    <div>
      <h3>Report comment</h3>
      <form className="Contact-form" onSubmit={e => handleSubmit(e)}>
        <textarea
          placeholder="Add a message"
          onChange={e => setReportMessage(e.currentTarget.value)}
        />
        <br />
        <div className="ReportReview-buttons">
          <button className="ReportReview-button" type="submit">
            Send
          </button>
          <button
            className="ReportReview-button"
            onClick={() => props.setShowReportReview(false)}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );

  function handleSubmit(e) {
    e.preventDefault();
    const body = JSON.stringify({ reportMessage, reviewId: props.reviewId });
    send(body);
  }

  async function send(body) {
    try {
      const rawResponse = await fetch("/send/report", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body
      });
      const response = await rawResponse.json();
      props.setMessage(response.message || response.error);
      setTimeout(() => {
        props.setMessage("");
      }, 2000);
      props.setShowReportReview(false);
    } catch (err) {
      console.log(err);
    }
  }
}
