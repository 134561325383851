import React, { useState, useEffect } from "react";
// import InfiniteScrolling from "./InfiniteScrolling.js";
import PieceRecordingsTableItem from "./PieceRecordingsTableItem.js";
import "./css/RecordingsTable.css";

export default function PieceRecordingTable(props) {
  const [recordings, setRecordings] = useState([]);
  const [newRecordings, setNewRecordings] = useState([]);
  const [recordingsCounter, setRecordingsCounter] = useState(0);
  const {
    handleShowLoginToSpotify,
    handleShowMessageBox,
    handleShowSpotifyDevicesWindow,
    queryString,
    setLastRecording,
    piece
  } = props;

  // async function fetchRecordings(limit, page) {
  //   try {
  //     let url = `/api/recordings${queryString}&limit=${limit}&page=${page}&groupAlbums=true`;
  //     const resp = await fetch(url);
  //     const newRecordings = await resp.json();
  //     const newRecordingsCounter = await resp.headers.get("Result-Count");
  //     setRecordingsCounter(newRecordingsCounter);
  //     setNewRecordings(newRecordings);
  //     return resp;
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  // function usePrevious(value) {
  //   const ref = useRef();
  //   useEffect(() => {
  //     ref.current = value;
  //   });
  //   return ref.current;
  // }

  // const prevNewRecordings = usePrevious(newRecordings);

  // Show first items
  // useEffect(() => {
  //   if (recordings.length === 0 && newRecordings.length !== 0)
  //     setRecordings(newRecordings);
  // }, [newRecordings, recordings]);

  // add when changes
  // useEffect(() => {
  //   if (newRecordings !== prevNewRecordings) {
  //     setRecordings(recordings.concat(newRecordings));
  //   }
  // }, [newRecordings, prevNewRecordings, recordings]);

  useEffect(() => {
    setRecordings([]);
    // setNewRecordings([]);
    setRecordingsCounter(0);
    async function fetchRecordings() {
      try {
        let url = `/api/recordings${queryString}&limit=500&page=1&groupAlbums=true`;
        const resp = await fetch(url);
        const newRecordings = await resp.json();
        const newRecordingsCounter = await resp.headers.get("Result-Count");
        setRecordingsCounter(newRecordingsCounter);
        setRecordings(newRecordings);
        if (piece && piece._id && newRecordings && newRecordings.length > 0)
          setLastRecording(newRecordings[0]);
        return resp;
      } catch (err) {
        console.log(err);
      }
    }
    fetchRecordings();
  }, [queryString, setLastRecording, piece]);

  if (recordings && recordings.length > 0) {
    return (
      // <InfiniteScrolling
      //   items={recordings}
      //   counter={recordingsCounter}
      //   limit={50}
      //   queryString={queryString}
      //   onScrollBottom={(limit, page) => {
      //     fetchRecordings(limit, page);
      //   }}
      // >
      // <div className="AlbumsList-counter">
      //   {recordingsCounter > 0 ? recordingsCounter + " recordings" : ""}
      // </div>
      <div className="RecordingsTable">
        {recordings.map((recording, i) => {
          return (
            <PieceRecordingsTableItem
              key={i}
              recording={recording}
              handleShowLoginToSpotify={handleShowLoginToSpotify}
              handleShowMessageBox={handleShowMessageBox}
              handleShowSpotifyDevicesWindow={handleShowSpotifyDevicesWindow}
              piece={piece}
            />
          );
        })}
      </div>
      // </InfiniteScrolling>
    );
  } else {
    return <div className="RecordingsTable-empty"></div>;
  }
}
