import React from "react";
import { Link } from "react-router-dom";
import "./css/HeaderNavigation.css";

export default function EnsembleHeaderNavigation(props) {
  const { ensemble, page } = props;
  if (ensemble && ensemble._id) {
    return (
      <div className="HeaderNavigation">
        {renderButtonAlbums()}
        {/* {renderButtonRecordings()} */}
        {renderButtonAbout()}
      </div>
    );
  } else {
    return null;
  }

  function renderButtonAlbums() {
    return (
      <Link
        to={"/ensemble/" + ensemble._id}
        className={
          page === "albums"
            ? "HeaderNavigation-button HeaderNavigation-button-active"
            : "HeaderNavigation-button"
        }
      >
        Albums
      </Link>
    );
  }

  function renderButtonRecordings() {
    return (
      <Link
        to={"/ensemble/" + ensemble._id + "/recordings"}
        className={
          page === "recordings"
            ? "HeaderNavigation-button HeaderNavigation-button-active"
            : "HeaderNavigation-button"
        }
      >
        Recordings
      </Link>
    );
  }

  function renderButtonAbout() {
    return (
      <Link
        to={"/ensemble/" + ensemble._id + "/about"}
        className={
          page === "about"
            ? "HeaderNavigation-button HeaderNavigation-button-active"
            : "HeaderNavigation-button"
        }
      >
        About
      </Link>
    );
  }
}
