import React from "react";
import Select from "./Select";

export default function EditCategory(props) {
  const { category, setCategory } = props;

  return (
    <div className="Edit-input-container">
      <label>Category</label>
      <Select
        placeholder="Category"
        options={["Keyboard", "Chamber", "Orchestral", "Vocal"]}
        isSearchable={true}
        isMultiple={false}
        value={category}
        onClear={e => setCategory("")}
        onChange={e => setCategory(e)}
      />
    </div>
  );
}
