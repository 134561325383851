import React from "react";
import { Link } from "react-router-dom";
import "./css/BrowsePeriods.css";
import "./css/Sidebar.css";

export default function BrowseGenres(props) {
  return (
    <div className="BrowsePeriods">
      <Link to="/genre/Orchestral" className="BrowsePeriods-button">
        Orchestral
      </Link>
      <Link to="/genre/Keyboard" className="BrowsePeriods-button">
        Keyboard
      </Link>
      <Link to="/genre/Chamber" className="BrowsePeriods-button">
        Chamber
      </Link>
      <Link to="/genre/Vocal" className="BrowsePeriods-button">
        Vocal
      </Link>
    </div>
  );
}
