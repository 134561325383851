import React from "react";
import { Link } from "react-router-dom";
import "./css/ListItem.css";

export default function ListItem(props) {
  return (
    <div
      className={"ListItem "}
      onClick={() => (props.onClick ? props.onClick() : null)}
    >
      <Link className="ListItem-link" to={props.to}>
        <div className="ListItem-link-text">
          <div className="ListItem-titles">
            {props.leftTitle ? (
              <div className={"ListItem-leftTitle"}>{props.leftTitle}</div>
            ) : null}
            <div
              className={
                props.leftTitle
                  ? "ListItem-title ListItem-title-leftTitle"
                  : "ListItem-title"
              }
            >
              {props.title}
            </div>
          </div>
          {props.subtitle ? (
            <div className="ListItem-subtitle">{props.subtitle}</div>
          ) : null}
        </div>
        <svg
          className="ListItem-icon hidden-desktop"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          width="20"
          height="20"
        >
          <path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z" />
        </svg>
        {/* <span className="hidden-mobile">{props.length - 1 === props.i ? '' : ', '}</span> */}
      </Link>
    </div>
  );
}
