import React, { useState } from "react";
import { Link } from "react-router-dom";
import EditText from "./EditText.js";
import AlbumsTableItemUpdateRecording from "./AlbumsTableItemUpdateRecording.js";
import "./css/AlbumsTableUpdate.css";

export default function Album(props) {
  const [id, setId] = useState("");
  const [tracksM, setTracksM] = useState(null);
  const [recordings, setRecordings] = useState(null);
  const { album, i } = props;

  return (
    <div key={album._id} className="AlbumsTableUpdate-row">
      {renderAlbumCover(album)}
      <div className="AlbumsTableUpdate-row-right">
        <Link to={`/album/${album._id}`}>{album.title}</Link>
        <EditText value={id} setValue={setId} placeholder={null} />
        <div className="AlbumsTableUpdate-buttons">
          {renderButtonMatch(album, i)}
        </div>
        <div>{renderRecordings()}</div>
      </div>
    </div>
  );

  function renderAlbumCover(album) {
    if (album) {
      return (
        <div className="AlbumsTableUpdate-row-image">
          <img
            width="50px"
            src={`https://d31zararll8aqx.cloudfront.net/albums/300/${album._id.toString()}.jpg`}
            alt="cover"
          />
        </div>
      );
    }
  }

  async function handleMatch() {
    if (id) {
      try {
        const resp = await fetch(
          `/api/albums/update/list/fetch/${album._id.toString()}/${id}`
        );
        const respJSON = await resp.json();
        if (resp.status === 201) {
          console.log(respJSON);
          // setAlbumM(respJSON);
          setRecordings(respJSON.recordings);
          setTracksM(respJSON.tracksM);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  function renderButtonMatch(album, i) {
    return (
      <button
        id={i + 1}
        className="Button AlbumsTableUpdate-button"
        onClick={() => handleMatch()}
      >
        Fetch
      </button>
    );
  }

  function renderRecordings() {
    if (
      recordings &&
      recordings.length > 0 &&
      tracksM &&
      tracksM.length === recordings.length
    ) {
      return recordings.map((recording, i) => (
        <AlbumsTableItemUpdateRecording
          recording={recording}
          trackM={tracksM[i]}
          i={i}
          mapped={recording.pieces && recording.pieces.length > 0}
          key={i}
        />
      ));
    }
  }
}
