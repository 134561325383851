import React from "react";
import Select from "./Select";

export default function EditEras(props) {
  const { eras, setEras } = props;

  return (
    <div className="Edit-input-container">
      <label>Eras</label>
      <Select
        placeholder="Eras"
        options={[
          "Contemporary",
          "20th Century",
          "Romantic",
          "Baroque",
          "Classical",
          "Modern",
          "Renaissance",
          "Medieval"
        ]}
        isSearchable={true}
        isMultiple={true}
        value={eras}
        onClear={e => setEras([])}
        onChange={e => {
          setEras(e);
        }}
      />
    </div>
  );
}
