import React from "react";
import { Link } from "react-router-dom";
import NumberAlbums from "./NumberAlbums.js";
import "./css/ArtistsTable.css";

export default function EnsemblesTable(props) {
  const { ensemblesIsLoaded } = props;
  if (ensemblesIsLoaded) {
    return <div className="ArtistsTable">{renderEnsembles()}</div>;
  } else {
    return null;
  }

  function renderEnsembles() {
    const { ensembles } = props;
    return ensembles.map((ensemble, i) => (
      <Link
        to={"/ensemble/" + (ensemble.englishName || ensemble.name)}
        key={i}
        className="ArtistsTable-table-row"
      >
        <div className="ArtistsTable-table-row-data">
          <div className="ArtistsTable-table-row-data-name">
            <div>{ensemble.englishName || ensemble.name}</div>
          </div>
          {renderNumberAlbums(ensemble)}
        </div>
      </Link>
    ));
  }

  function renderNumberAlbums(ensemble) {
    return (
      <div className="ArtistsTable-table-row-data-numberAlbums">
        <NumberAlbums numberAlbums={ensemble.numberAlbums} />
      </div>
    );
  }
}
