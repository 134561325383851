import React, { useState, useEffect } from "react";
import querystring from "querystring";
import Frame from "./Frame";
import LabelHeaderNavigation from "./LabelHeaderNavigation";
import AlbumsList from "./AlbumsList";
import Windows from "./Windows";
import RecordingsTable from "./RecordingsTable";
import LabelRecordingsFilters from "./LabelRecordingsFilters";
import LabelAlbumsFilters from "./LabelAlbumsFilters";
import { setQueryString } from "./helpers/helpers.js";
export default function Label(props) {
  const [label, setLabel] = useState(null);

  let page = "albums";
  if (props.match.path === "/label/:labelId/recordings") {
    page = "recordings";
  }

  let labelId = "";
  if (props.match.params.labelId) {
    labelId = props.match.params.labelId;
  }

  const queryString = setQueryString(props.location.search, props.match.params);

  useEffect(() => {
    async function fetchLabel(labelId) {
      try {
        const resp = await fetch("/api/labels/" + labelId);
        const label = await resp.json();
        setLabel(label);
        return resp;
      } catch (err) {
        console.log(err);
      }
    }
    fetchLabel(labelId);
  }, [labelId, setLabel]);

  const queryIds = querystring.parse(queryString.substring(1));

  useEffect(() => {
    if (label && label.name) {
      document.title = label.name + " - Lyra digital music library";
    }
  }, [label]);

  return (
    <Frame>
      {label ? (
        <div className="Artist">
          <div className="Artist-header Header">
            <div className="Header-category">Label</div>
            <div className="Header-big-title">{label.name}</div>
            <LabelHeaderNavigation label={label} page={page} />
          </div>
          <div className="Artist-content">
            {page === "albums" ? renderAlbums() : renderRecordings()}
          </div>
          <div className="Artist-info"></div>
        </div>
      ) : null}
    </Frame>
  );

  function renderAlbums() {
    return (
      <div>
        <LabelAlbumsFilters
          history={props.history}
          paramsURL={props.match.params}
          queryIds={queryIds}
          queryString={queryString}
        />
        <AlbumsList queryString={queryString} headerContent={label} />
      </div>
    );
  }

  function renderRecordings() {
    return (
      <div>
        <LabelRecordingsFilters
          history={props.history}
          paramsURL={props.match.params}
          queryIds={queryIds}
          queryString={queryString}
        />
        <Windows>
          <RecordingsTable queryString={queryString} />
        </Windows>
      </div>
    );
  }
}
