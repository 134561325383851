import React from "react";
import Select from "./Select";

export default function EditTonalities(props) {
  const { tonalities, setTonalities } = props;

  return (
    <div className="Edit-input-container">
            <label>Tonalities</label>
            <Select
              placeholder="Tonalities"
              options={[
                "A major",
                "A minor",
                "A♭ major",
                "A♭ minor",
                "A♯ minor",
                "B major",
                "B minor",
                "B♭ major",
                "B♭ minor",
                "C major",
                "C minor",
                "C♭ major",
                "C♯ major",
                "C♯ minor",
                "D major",
                "D minor",
                "D♭ major",
                "D♯ minor",
                "E major",
                "E minor",
                "E♭ major",
                "E♭ minor",
                "F major",
                "F minor",
                "F♯ major",
                "F♯ minor",
                "G major",
                "G minor",
                "G♭ major",
                "G♯ minor"
              ]}
              isSearchable={true}
              isMultiple={false}
              value={tonalities}
              onClear={e => setTonalities([])}
              onChange={e => {
                setTonalities(e);
              }}
            />
          </div>
  );
}
