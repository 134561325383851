import React, { useState } from "react";
import OutsideAlerter from "./OutsideAlerter";
import Select from "./Select";
import EditDate from "./EditDate";
import EditText from "./EditText";
import "./css/EditWindow.css";

export default function EditPressReviewWindow(props) {
  const {
    album,
    setShowAddReview,
    showMessage,
    fetchAlbumPressReviews
  } = props;
  const [magazine, setMagazine] = useState(null);
  const [review, setReview] = useState("");
  const [date, setDate] = useState("");
  const [link, setLink] = useState("");
  const [rating, setRating] = useState("");
  const [ratingScale, setRatingScale] = useState("");

  return (
    <div className="EditWindow">
      <OutsideAlerter
        onOutsideClick={() => setShowAddReview(false)}
        className="EditWindow-window"
      >
        <h2>Add review</h2>
        <div>
          <Select
            placeholder="Magazine"
            options={["Gramophone", "Diapason", "The Guardian", "The Sunday Times"]}
            isSearchable={true}
            isMultiple={false}
            value={magazine}
            onChange={e => setMagazine(e)}
            onClear={e => setMagazine(null)}
          />
          <EditDate value={date} setValue={setDate} placeholder="Date" />
          <EditText
            value={rating}
            setValue={setRating}
            placeholder="Rating (optional)"
            hideLabel={true}
          />
          <EditText
            value={ratingScale}
            setValue={setRatingScale}
            placeholder="Out of (5, 10, 20...)"
            hideLabel={true}
          />
          <EditText
            value={link}
            setValue={setLink}
            placeholder="Link"
            hideLabel={true}
          />
          <textarea
            placeholder="Review"
            onChange={e => setReview(e.currentTarget.value)}
            defaultValue={review}
            className="Edit-textarea"
          />
          <br />
          <button
            className="Button Edit-button"
            onClick={() => postPressReview()}
          >
            Add
          </button>
        </div>
      </OutsideAlerter>
      <div className="EditWindow-background"></div>
    </div>
  );

  async function postPressReview() {
    try {
      const resp = await fetch("/api/pressReviews", {
        method: "POST",
        body: JSON.stringify({
          magazine,
          review,
          date,
          link,
          rating,
          ratingScale,
          album: { id: album._id, title: album.title }
        }),
        headers: {
          "Content-Type": "application/json"
        }
      });
      if (resp.status === 200) {
        fetchAlbumPressReviews();
        showMessage("Press review added");
        setShowAddReview(false);
      }
    } catch (err) {
      console.log(err);
    }
  }
}
