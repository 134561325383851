import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PerformersFilter from "./PerformersFilter";
import "./css/Navigation.css";

export default function PerformersNavigation(props) {
  const { queryIds, history } = props;
  const [performer, setPerformer] = useState(null);

  useEffect(() => {
    if (performer) history.push("/performers?occupation=" + performer);
    else history.push("/performers?occupation=Conductor");
  }, [performer, history]);

  return (
    <div className="Navigation">
      <div className="Navigation-row Navigation-row-small">
        <Link
          to="/performers?occupation=Conductor"
          className={
            queryIds && queryIds.occupation === "Conductor"
              ? "Navigation-button-small Navigation-button-small-active"
              : "Navigation-button-small"
          }
        >
          Conductors
        </Link>
        <Link
          to="/performers?occupation=Pianist"
          className={
            queryIds && queryIds.occupation === "Pianist"
              ? "Navigation-button-small Navigation-button-small-active"
              : "Navigation-button-small"
          }
        >
          Pianists
        </Link>
        <Link
          to="/performers?occupation=Violinist"
          className={
            queryIds && queryIds.occupation === "Violinist"
              ? "Navigation-button-small Navigation-button-small-active"
              : "Navigation-button-small"
          }
        >
          Violinists
        </Link>
        <PerformersFilter performer={performer} setPerformer={setPerformer} />
      </div>
    </div>
  );
}
