import React from "react";
import { Link } from "react-router-dom";
import OutsideAlerter from "./OutsideAlerter";
import "./css/AccountMenu.css";

export default function AccountMenu(props) {
  const { setShowAccountMenu, user } = props;
  return (
    <OutsideAlerter onOutsideClick={() => setShowAccountMenu(false)}>
      <div className="AccountMenu" onClick={() => setShowAccountMenu(false)}>
        <div className="AccountMenu-links">
          <Link className="AccountMenu-link" to="/account">
            Account
          </Link>
          <div className="AccountMenu-link" onClick={() => logout()}>
            Sign out
          </div>
          {user && user.user && user.user.admin ? (
            <Link className="AccountMenu-link" to="/insert/piece">
              Add piece
            </Link>
          ) : null}
          {user && user.user && user.user.admin ? (
            <Link className="AccountMenu-link" to="/insert/albums">
              Add albums
            </Link>
          ) : null}
          {user && user.user && user.user.admin ? (
            <Link className="AccountMenu-link" to="/recordings/update">
              Update recordings
            </Link>
          ) : null}
        </div>
      </div>
    </OutsideAlerter>
  );

  async function logout() {
    try {
      await fetch("/logout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        }
      });
      document.location.reload(true);
    } catch (err) {
      console.log(err);
    }
  }
}
