import React from "react";
import SidebarLinks from "./SidebarLinks";
import SidebarFooter from "./SidebarFooter";
import Navbar from "./Navbar";
import "./css/Frame.css"

export default function Frame(props) {
  return (
    <div className="Frame">
      <div className="Sidebar">
        <SidebarLinks />
        <SidebarFooter />
      </div>
      <div className="Frame-content">
        <Navbar className="hidden-mobile" />
        {props.children}
      </div>
    </div>
  );
}
