import React, { useState, useEffect } from "react";
import EditText from "./EditText";
import EditTypes from "./EditTypes";
import "./css/Edit.css";

export default function InsertEnsemble(props) {
  const {
    defaultEnsemble,
    defaultName,
    handleShowMessageBox,
    refetchAlbums,
    setEnsemble,
    idSpotify,
    setType
  } = props;
  const [name, setName] = useState("");
  const [types, setTypes] = useState([]);

  useEffect(() => {
    if (defaultEnsemble && defaultEnsemble.name && defaultEnsemble.names.en) {
      setName(defaultEnsemble.names.en);
    } else {
      setName(defaultName);
    }
  }, [defaultEnsemble, defaultName]);

  return (
    <div>
      <div>Insert new ensemble</div>
      <EditText value={name} setValue={setName} placeholder="Name" />
      <EditTypes types={types} setTypes={setTypes} />
      <button className="Button Edit-button" onClick={() => insertEnsemble()}>
        Insert
      </button>
    </div>
  );

  async function insertEnsemble() {
    const newEnsemble = {
      ...defaultEnsemble,
      name,
      types
    };
    if (newEnsemble.externalLinks)
      newEnsemble.externalLinks.spotify = idSpotify;
    else newEnsemble.externalLinks = { spotify: idSpotify };

    const body = JSON.stringify(newEnsemble);
    try {
      const resp = await fetch("/api/ensembles/insert", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body
      });
      if (resp.status === 201) {
        console.log("inserted");
        handleShowMessageBox("Inserted successfully", 3000);
        setEnsemble(null);
        refetchAlbums();
        setType(null);
      }
    } catch (err) {
      console.log(err);
    }
  }
}
