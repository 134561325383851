import React from "react";
import { Link, withRouter } from "react-router-dom";
import ListArtists from "./ListArtists";
import ButtonFavorite from "./ButtonFavorite";
import ButtonPlayOnSpotify from "./ButtonPlayOnSpotify.js";
import "./css/AlbumTable.css";

function AlbumTable(props) {
  const {
    album,
    handleShowMessageBox,
    handleShowLoginToSpotify,
    handleShowSpotifyDevicesWindow,
    recordingsInFavorites,
    handleShowLoginWindow,
    setRecordingsInFavorites
  } = props;

  if (album && album._id) {
    return (
      <div className="AlbumTable">
        <div className="AlbumTable-table">
          {renderTracks()}
          {album.edit ? (
            <div className="AlbumTable-links">
              <Link to={`/album/${album._id}/edit`}>Edit album</Link>
              <br />
              <Link to={`/recordings/update?albumId=${album._id}`}>
                Edit recordings
              </Link>
              <br />
              <span
                className="AlbumTable-link"
                onClick={() => {
                  if (
                    window.confirm(
                      "Are you sure you want to delete this album?"
                    )
                  )
                    deleteAlbum();
                }}
              >
                Delete
              </span>
            </div>
          ) : null}
        </div>
      </div>
    );
  } else {
    return null;
  }

  function renderTracks() {
    if (album && album.tracks) {
      return album.tracks.map((track, i) => {
        return (
          <div className="AlbumTable-table-row" key={i}>
            {renderTrackNumber(i)}
            {/* {renderButtonPlay(album, track, i)} */}
            {/* {renderButtonFavorite(track, track.recordingId)} */}
            {renderTrackTitle(track, i)}
          </div>
        );
      });
    }
  }

  function renderTrackNumber(i) {
    return <div className="AlbumTable-table-data-number">{i + 1}</div>;
  }

  function renderTrackTitle(track, i) {
    const { composers, performers, ensembles } = track;
    if (track.recordingId) {
      return (
        <Link
          to={`/recording/${track.recordingId}`}
          className="AlbumTable-table-data-title"
        >
          <div>{track.title}</div>
          <div className="AlbumTable-table-data-title-composers">
            <ListArtists
              composers={composers}
              performers={performers}
              ensembles={ensembles}
              link={false}
            />
          </div>
        </Link>
      );
    } else {
      return (
        <div className="AlbumTable-table-data-title">
          <div>{track.title}</div>
          <div className="AlbumTable-table-data-title-composers">
            <ListArtists
              composers={composers}
              performers={performers}
              ensembles={ensembles}
              link={false}
            />
          </div>
        </div>
      );
    }
  }

  // function renderButtonPlay(album, track, i) {
  //   return (
  //     <div className="AlbumTable-table-data-play">
  //       <ButtonPlayOnSpotify
  //         albumIdSpotify={album.idSpotify}
  //         trackTitle={track.title}
  //         trackPosition={i + 1}
  //         handleShowMessageBox={handleShowMessageBox}
  //         handleShowSpotifyDevicesWindow={handleShowSpotifyDevicesWindow}
  //         handleShowLoginToSpotify={handleShowLoginToSpotify}
  //         small={true}
  //       />
  //     </div>
  //   );
  // }

  // function renderButtonFavorite(track, recordingId) {
  //   return (
  //     <div className="AlbumTable-table-data-collection">
  //       <ButtonFavorite
  //         albumId={album && album._id}
  //         recordingId={track && track.recordingId}
  //         type="recording"
  //         isInFavorites={recordingsInFavorites.includes(recordingId)}
  //         callbackAfterAdd={() => {
  //           setRecordingsInFavorites(oldArray => [...oldArray, recordingId]);
  //         }}
  //         callbackAfterRemove={() =>
  //           setRecordingsInFavorites(
  //             recordingsInFavorites.filter(item => item !== recordingId)
  //           )
  //         }
  //         handleShowLoginWindow={handleShowLoginWindow}
  //         small={true}
  //       />
  //     </div>
  //   );
  // }

  async function deleteAlbum() {
    if (album && album._id && album._id.toString()) {
      try {
        const resp = await fetch(
          "/api/albums/" + album._id.toString() + "/delete"
        );
        if (resp.status === 200) window.location = "/";
      } catch (err) {
        console.log(err);
      }
    }
  }
}

export default withRouter(AlbumTable);
