import React from "react";
import EditSearch from "./EditSearch";
import EditPeopleItem from "./EditPeopleItem";

export default function EditPeople(props) {
  const { people, setPeople, placeholder, editOccupations } = props;

  return (
    <div className="Edit-input-container">
      <label>{placeholder}</label>
      {people && people.length > 0
        ? people.map((person, i) => (
            <EditPeopleItem
              person={person}
              handleRemovePerson={handleRemovePerson}
              handleUpdateOccupations={handleUpdateOccupations}
              i={i}
              key={i}
            />
          ))
        : null}
      <EditSearch
        collection={"people"}
        handleClick={person => addPerson(person)}
        placeholder="Add person"
      />
    </div>
  );

  function addPerson(person) {
    const newPeople = [...people];
    const newPerson = {
      id: person._id.toString(),
      name: person.name,
      invertedName: person.invertedName
    };
    if (editOccupations) newPerson.occupations = person.occupations;

    newPeople.push(newPerson);
    setPeople(newPeople);
  }

  function handleRemovePerson(i) {
    const newPeople = [...people];
    newPeople.splice(i, 1);
    setPeople(newPeople);
  }

  function handleUpdateOccupations(personWithOccupations, i) {
    const newPeople = [...people];
    newPeople[i] = personWithOccupations;
    setPeople(newPeople);
  }
}
