import React from "react";
import querystring from "querystring";
import Frame from "./Frame";
import GenreHeader from "./GenreHeader";
import AlbumsList from "./AlbumsList";
import Windows from "./Windows";
import RecordingsTable from "./RecordingsTable";
import GenreRecordingsFilters from "./GenreRecordingsFilters";
import GenreAlbumsFilters from "./GenreAlbumsFilters";
import { setQueryString } from "./helpers/helpers.js";
export default function Genre(props) {
  let page = "albums";
  if (props.match.path === "/genre/:categoryId/recordings") {
    page = "recordings";
  }

  const queryString = setQueryString(props.location.search, props.match.params);

  const genre = props.match.params.categoryId
  const queryIds = querystring.parse(queryString.substring(1));

  return (
    <Frame>
      <GenreHeader genre={genre} page={page} />
      {page === "albums" ? renderAlbums() : renderRecordings()}
    </Frame>
  );

  function renderAlbums() {
    return (
      <div>
        <GenreAlbumsFilters
          history={props.history}
          paramsURL={props.match.params}
          queryIds={queryIds}
          queryString={queryString}
        />
        <AlbumsList queryString={queryString} headerContent={genre} />
      </div>
    );
  }

  function renderRecordings() {
    return (
      <div>
        <GenreRecordingsFilters
          history={props.history}
          paramsURL={props.match.params}
          queryIds={queryIds}
          queryString={queryString}
        />
        <Windows>
          <RecordingsTable queryString={queryString} />
        </Windows>
      </div>
    );
  }
}
