import React from "react";
import EnsemblesTable from "./EnsemblesTable.js";
import EnsemblesFilters from "./EnsemblesFilters.js";
import Navbar from "./Navbar.js";
// import SearchFilter from "./SearchFilter";
import SidebarLinks from "./SidebarLinks.js";
import { throttle } from "./helpers/helpers.js";
import "./css/Ensembles.css";
import "./css/Sidebar.css";

export default class Ensembles extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      ensemblesIsLoaded: false,
      ensembles: [],
      page: 1,
      limit: 50
    };
  }

  handleSearchFilter(q) {
    this.setState(
      {
        page: 1,
        ensembles: []
      },
      () => this.fetchEnsembles(q)
    );
  }

  async fetchEnsembles(q = null) {
    try {
      let url =
        "/api/ensembles" +
        this.props.queryString +
        "&limit=" +
        this.state.limit +
        "&page=" +
        this.state.page;
      if (q) {
        url += "&q=" + q;
      }

      const resp = await fetch(url);
      const ensembles = await resp.json();
      const ensemblesCounter = await resp.headers.get("Result-Count");
      this.setState({
        ensemblesIsLoaded: true,
        // ensembles: ensembles,
        ensembles: q ? ensembles : this.state.ensembles.concat(ensembles),
        ensemblesCounter
      });
      return resp;
    } catch (err) {
      console.log(err);
    }
  }

  handleOnScroll() {
    if (
      window.innerHeight + window.scrollY + 2000 >=
      document.body.offsetHeight
    ) {
      if (
        this.state.page * this.state.limit <
        parseInt(this.state.ensemblesCounter) + 50
      ) {
        this.loadMore();
      }
    }
  }

  loadMore() {
    if (this._isMounted) {
      this.setState({ page: this.state.page + 1 });
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchEnsembles();
    const { country, city, type } = this.props.queryIds;

    if (country) this.setState({ country });
    if (city) this.setState({ city });
    if (type) this.setState({ type });
    window.addEventListener(
      "scroll",
      throttle(this.handleOnScroll.bind(this), 300)
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.queryString !== this.props.queryString) {
      // Restart at page one after filters update
      this.setState({ ensembles: [], page: 1 });
      const { type } = this.props.queryIds;

      if (type) {
        this.setState({ type });
      } else {
        this.setState({ type: null });
      }

      this.setState(
        {
          ensembles: [],
          ensemblesIsLoaded: false
        },
        () => this.fetchEnsembles()
      );
    } else if (
      prevState.page !== this.state.page &&
      this.state.ensembles.length !== 0
    ) {
      this.fetchEnsembles();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { error, ensemblesIsLoaded, ensembles } = this.state;
    // console.log(ensembles);
    if (error) {
      return <div>Error: {error.message}</div>;
    } else {
      return (
        <div className="Ensembles">
          <div className="Sidebar">
            <SidebarLinks />
          </div>
          <div className="Ensembles-content">
            <Navbar />
            <EnsemblesFilters
              history={this.props.history}
              queryIds={this.props.queryIds}
            />
            {/* <div className="Artists-sort-search"> */}
            {/* <SearchFilter fetchData={this.handleSearchFilter.bind(this)} /> */}
            {/* </div> */}
            <EnsemblesTable
              ensembles={ensembles}
              ensemblesIsLoaded={ensemblesIsLoaded}
            />
          </div>
        </div>
      );
    }
  }
}
