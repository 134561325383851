import React, { useState } from "react";
import LoginToSpotify from "./LoginToSpotify.js";
import SpotifyDevicesWindow from "./SpotifyDevicesWindow.js";
import MessageBox from "./MessageBox.js";
import LoginWindow from "./LoginWindow.js";
import "./css/MessageBox.css";

export default function Windows(props) {
  const [message, setMessage] = useState(null);
  const [showSpotifyDevicesWindow, setShowSpotifyDevicesWindow] = useState(
    null
  );
  const [showLoginToSpotify, setShowLoginToSpotify] = useState(false);
  const [showLoginWindow, setShowLoginWindow] = useState(false);
  const [nextUrl, setNextUrl] = useState(null);

  return (
    <div className="Windows">
      {React.cloneElement(props.children, {
        handleShowLoginToSpotify: () =>
          setShowLoginToSpotify(!showLoginToSpotify),
        handleShowSpotifyDevicesWindow: url => {
          setNextUrl(url);
          setShowSpotifyDevicesWindow(!showSpotifyDevicesWindow);
        },
        handleShowLoginWindow: () => setShowLoginWindow(!showLoginWindow),
        handleShowMessageBox: handleShowMessageBox
      })}
      {showLoginToSpotify ? (
        <LoginToSpotify
          handleShowLoginToSpotify={() =>
            setShowLoginToSpotify(!showLoginToSpotify)
          }
        />
      ) : null}
      {showSpotifyDevicesWindow ? (
        <SpotifyDevicesWindow
          handleShowSpotifyDevicesWindow={() =>
            setShowSpotifyDevicesWindow(!showSpotifyDevicesWindow)
          }
          handleShowMessageBox={handleShowMessageBox}
          nextUrl={nextUrl}
        />
      ) : null}
      {showLoginWindow ? (
        <LoginWindow setShowLoginWindow={setShowLoginWindow} />
      ) : null}
      <MessageBox message={message} />
    </div>
  );

  function handleShowMessageBox(message, delay = 3000) {
    setMessage(message);
    setTimeout(() => {
      setMessage(null);
    }, delay);
  }
}
