import React, { useState } from "react";
import OutsideAlerter from "./OutsideAlerter";
import SelectInput from "./SelectInput.js";
import SelectMenu from "./SelectMenu.js";
import { filterItems } from "./helpers/helpers.js";
import "./css/Filters.css";

export default function Select(props) {
  const {
    options,
    onClear,
    placeholder,
    value,
    onChange,
    isSearchable,
    isMultiple
  } = props;

  const [showOptions, setShowOptions] = useState(false);
  const [search, setSearch] = useState("");
  const [optionsAfterSearch, setOptionsAfterSearch] = useState(null);

  return (
    <OutsideAlerter onOutsideClick={() => setShowOptions(false)}>
      <div>
        <SelectInput
          showOptions={showOptions}
          handleClickClear={() => {
            setSearch("");
            setShowOptions(false);
            onClear();
          }}
          handleSearchInOptions={handleSearchInOptions}
          handleShowOptions={() => setShowOptions(!showOptions)}
          search={search}
          placeholder={placeholder}
          value={value}
        />
        {showOptions ? (
          <SelectMenu
            options={options}
            setShowOptions={setShowOptions}
            search={search}
            handleSearchInOptions={handleSearchInOptions}
            optionsAfterSearch={optionsAfterSearch}
            handleShowOptions={() => setShowOptions(!showOptions)}
            handleClickValue={selectedValue => {
              handleClickValue(selectedValue);
              setSearch("");
            }}
            isSearchable={isSearchable}
            value={value}
          />
        ) : null}
      </div>
    </OutsideAlerter>
  );

  function handleSearchInOptions(e) {
    setSearch(e.currentTarget.value);
    setOptionsAfterSearch(filterItems(options, e.currentTarget.value));
  }

  function handleClickValue(selectedValue) {
    if (isMultiple) {
      if (value && value.length > 0) {
        const index = value.indexOf(selectedValue);
        const newValues = [...value];
        if (index > -1) {
          newValues.splice(index, 1);
        } else {
          newValues.push(selectedValue);
        }
        onChange(newValues);
      } else {
        onChange([selectedValue]);
      }
      setShowOptions(false);
    } else {
      setShowOptions(false);
      onChange(selectedValue);
    }
  }
}
