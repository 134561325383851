import React from "react";
import { Link } from "react-router-dom";
import "./css/AlbumSubtitle.css";
import { msToTimeLong } from "./helpers/helpers.js";

export default function AlbumSubtitle(props) {
  return (
    <div className="AlbumSubtitle">
      {renderReleaseDate(props.album)}
      {renderAlbumLabel(props.album)}
      <br />
      {renderTracksNumber(props.album)}
      {renderAlbumDuration(props.album)}
    </div>
  );

  function renderTracksNumber(album) {
    if (album && album.tracks) {
      return (
        <span>
          <span>{`${album.tracks.length} tracks`} </span>
          <span className="AlbumSubtitle-text-grey"> • </span>
        </span>
      );
    }
  }

  function renderReleaseDate(album) {
    if (album && album.releaseDate) {
      let options = {};
      if (album.releaseDate.length === 10) {
        options = { year: "numeric", month: "long", day: "numeric" };
      } else if (album.releaseDate.length === 7) {
        options = { year: "numeric", month: "long" };
      } else if (album.releaseDate.length === 4) {
        options = { year: "numeric" };
      }
      const date = new Date(album.releaseDate).toLocaleDateString(
        "en-GB",
        options
      );
      return (
        <span>
          <span>{date}</span>
          <span className="AlbumSubtitle-text-grey"> • </span>
        </span>
      );
    }
  }

  function renderAlbumLabel(album) {
    if (album && album.label) {
      return (
        <span>
          <Link to={"/label/" + album.label.id}>{album.label.name}</Link>
        </span>
      );
    }
  }

  function renderAlbumDuration(album) {
    if (album && album.durationMs) {
      return (
        <span>
          <span>{msToTimeLong(album.durationMs)}</span>
        </span>
      );
    }
  }
}
