import React from "react";
import Labels from './Labels';
import querystring from 'querystring';

export default class LabelsQueryBuilder extends React.Component {

  render() {
    let queryString = "?";

    // Add query strings
    const search = this.props.location.search;
    queryString = search ? queryString += search.substr(1) : queryString;

    // Create object from query strings
    const queryIds = querystring.parse(queryString.substring(1))

    document.title = 'Labels - Lyra digital music library';

    return <Labels
      queryString={queryString}
      queryIds={queryIds}
      paramsURL={this.props.match.params}
      history={this.props.history}
      />
  }
}
