import React, { useState, useEffect } from "react";
import querystring from "querystring";
import Frame from "./Frame";
import OfficialLinks from "./OfficialLinks";
import EnsembleHeaderNavigation from "./EnsembleHeaderNavigation";
import EnsembleMoreInfo from "./EnsembleMoreInfo";
import AlbumsList from "./AlbumsList";
import Windows from "./Windows";
import ArtistRecordingsTable from "./ArtistRecordingsTable";
import EnsembleRecordingsFilters from "./EnsembleRecordingsFilters";
import EnsembleAlbumsFilters from "./EnsembleAlbumsFilters";
import { format, setQueryString } from "./helpers/helpers.js";

export default function Ensemble(props) {
  const [ensemble, setEnsemble] = useState(null);

  let page = "albums";
  if (props.match.path === "/ensemble/:ensembleId/recordings")
    page = "recordings";
  else if (props.match.path === "/ensemble/:ensembleId/about") page = "about";

  let ensembleId = "";
  if (props.match.params.ensembleId) {
    ensembleId = props.match.params.ensembleId;
  }

  const queryString = setQueryString(props.location.search, props.match.params);

  useEffect(() => {
    async function fetchEnsemble(ensembleId) {
      try {
        const resp = await fetch("/api/ensembles/" + ensembleId);
        const ensemble = await resp.json();
        setEnsemble(ensemble);
        return resp;
      } catch (err) {
        console.log(err);
      }
    }
    fetchEnsemble(ensembleId);
  }, [ensembleId, setEnsemble]);

  useEffect(() => {
    if (ensemble && ensemble.name) {
      document.title =
        ensemble.englishName || ensemble.name + " - Lyra digital music library";
    }
  }, [ensemble]);

  const queryIds = querystring.parse(queryString.substring(1));

  return (
    <Frame>
      {ensemble ? (
        <div className="Artist">
          <div className="Artist-header Header">
            <div className="Header-category">Ensemble</div>
            <div className="Header-big-title">
              {ensemble.englishName || ensemble.name}
            </div>
            {ensemble.profile ? (
              <div className="Header-subtitle">{format(ensemble.profile)}</div>
            ) : null}
            <OfficialLinks externalLinks={ensemble.externalLinks} />
            <EnsembleHeaderNavigation ensemble={ensemble} page={page} />
          </div>
          <div className="Artist-content">
            {page === "albums" ? renderAlbums() : null}
            {page === "recordings" ? renderRecordings() : null}
            {page === "about" ? renderAbout() : null}
          </div>
          <div className="Artist-info"></div>
        </div>
      ) : null}
    </Frame>
  );

  function renderAlbums() {
    if (ensemble && queryString)
      return (
        <div>
          <EnsembleAlbumsFilters
            history={props.history}
            paramsURL={props.match.params}
            queryIds={queryIds}
            queryString={queryString}
          />
          <AlbumsList queryString={queryString} headerContent={ensemble} />
        </div>
      );
  }

  function renderRecordings() {
    if (ensemble && queryString && queryIds)
      return (
        <div>
          <EnsembleRecordingsFilters
            history={props.history}
            paramsURL={props.match.params}
            queryIds={queryIds}
            queryString={queryString}
          />
          <Windows>
            <ArtistRecordingsTable queryString={queryString} />
          </Windows>
        </div>
      );
  }

  function renderAbout() {
    if (ensemble && queryString)
      return (
        <div>
          <EnsembleMoreInfo ensemble={ensemble} />
        </div>
      );
  }
}
