import React from "react";
import Filter from "./Filter.js";
import "./css/Filters.css";

export default function PerformerAlbumsFilters(props) {
  return (
    <div className="Filters">
        <Filter
          queryString={props.queryString}
          queryIds={props.queryIds}
          history={props.history}
          name="Composer"
          queryParameter="composer"
          apiURL="/api/albums/distinct/composers.invertedName"
          key="composer"
          isSearchable={true}
          paramsURL={props.paramsURL}
        />
        {/* <Filter
          queryString={props.queryString}
          queryIds={props.queryIds}
          history={props.history}
          name="Category"
          queryParameter="category"
          apiURL="/api/albums/distinct/categories"
          key="category"
          isSearchable={false}
          paramsURL={props.paramsURL}
        /> */}
        <Filter
          queryString={props.queryString}
          queryIds={props.queryIds}
          history={props.history}
          name="Ensemble"
          queryParameter="ensemble"
          apiURL="/api/albums/distinct/ensembles.name"
          key="ensemble"
          isSearchable={true}
          paramsURL={props.paramsURL}
        />
        {/* <Filter
          queryString={props.queryString}
          queryIds={props.queryIds}
          history={props.history}
          name="Instruments"
          queryParameter="instruments"
          apiURL="/api/albums/distinct/instruments.name"
          key="instrument"
          isSearchable={true}
          paramsURL={props.paramsURL}
          isMultiple={true}
        /> */}
        <Filter
          queryString={props.queryString}
          queryIds={props.queryIds}
          history={props.history}
          name="Label"
          queryParameter="label"
          apiURL="/api/albums/distinct/label.name"
          key="label"
          isSearchable={true}
          paramsURL={props.paramsURL}
        />
  </div>
  )
}
