import React from "react";
import AlbumCard from "./AlbumCard";
import "./css/Home.css";

export default function NewReleases(props) {
  const { newReleases } = props;

  return (
    <div className="Home-list-container">
      <div className="Home-list">
        {newReleases.map((album, i) => {
          return <AlbumCard key={i} album={album} albumId={album._id} />;
        })}
      </div>
    </div>
  );
}
