import React from "react";
import Filter from "./Filter.js";
import "./css/Filters.css";

export default function ComposerPiecesFilters(props) {
  return (
    <div className="Filters">
      <Filter
        queryString={props.queryString}
        queryIds={props.queryIds}
        history={props.history}
        name="Category"
        queryParameter="category"
        apiURL="/api/pieces/distinct/category"
        key="category"
        isSearchable={true}
        paramsURL={props.paramsURL}
      />
      <Filter
        queryString={props.queryString}
        queryIds={props.queryIds}
        history={props.history}
        name="Musical Form"
        queryParameter="musicalForm"
        apiURL="/api/pieces/distinct/musicalForm"
        key="musicalForm"
        isSearchable={true}
        paramsURL={props.paramsURL}
      />
      <Filter
        queryString={props.queryString}
        queryIds={props.queryIds}
        history={props.history}
        name="Tonality"
        queryParameter="tonality"
        apiURL="/api/pieces/distinct/tonalities"
        key="tonality"
        isSearchable={true}
        paramsURL={props.paramsURL}
      />
      <Filter
        queryString={props.queryString}
        queryIds={props.queryIds}
        history={props.history}
        name="Instruments"
        queryParameter="instruments"
        apiURL="/api/pieces/distinct/instruments.name"
        key="instrument"
        isSearchable={true}
        paramsURL={props.paramsURL}
        isMultiple={true}
      />
    </div>
  );
}
