import React from "react";
import { Link } from "react-router-dom";
import "./css/SidebarFooter.css";

export default class SidebarFooter extends React.Component {
  render() {
    return (
      <div className="SidebarFooter">
        <Link className="SidebarFooter-link" to="/about">About</Link> • <Link className="SidebarFooter-link" to="/contact">Contact us</Link>
        <br />
        <div>© Lyra digital music library - 2021</div>
        <Link className="SidebarFooter-link-grey" to="/terms">Terms of use</Link> • <Link className="SidebarFooter-link-grey" to="/privacy">Privacy Policy</Link>
      </div>
    );
  }
}
