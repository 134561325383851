import React from "react";
import "./css/EnsemblesFilters.css";

export default function EnsemblesFilters(props) {
  const { history, queryIds } = props;
  return (
    <div className="Navigation">
      <div className="Navigation-row Navigation-row-small">
        <div
          className={
            !queryIds.type
              ? "Navigation-button-small Navigation-button-small-active"
              : "Navigation-button-small"
          }
          onClick={() => history.push("/ensembles")}
        >
          All ensembles
        </div>
        {renderFilter("Orchestra")}
        {renderFilter("Choir")}
        {renderFilter("Piano Trio")}
        {renderFilter("String Quartet")}
      </div>
    </div>
  );

  function renderFilter(name) {
    return (
      <div
        className={
          queryIds && queryIds.type === name
            ? "Navigation-button-small Navigation-button-small-active"
            : "Navigation-button-small"
        }
        onClick={() => redirect(name)}
      >
        {name}
      </div>
    );
  }

  function redirect(name) {
    // let queryAsObject = querystring.parse(queryString.substring(1));
    history.push("?type=" + name);
  }
}
