import React from "react";
import { Link } from "react-router-dom";
import "./css/HeaderNavigation.css";

export default function LabelHeaderNavigation(props) {
  const { label, page } = props;
  if (label && label._id) {
    return (
      <div className="HeaderNavigation">
        {renderButtonAlbums()}
        {/* {renderButtonRecordings()} */}
      </div>
    );
  } else {
    return null;
  }

  function renderButtonAlbums() {
    return (
      <Link
        to={"/label/" + label._id}
        className={
          page === "albums"
            ? "HeaderNavigation-button HeaderNavigation-button-active"
            : "HeaderNavigation-button"
        }
      >
        Albums
      </Link>
    );
  }

  // function renderButtonRecordings() {
  //   return (
  //     <Link
  //       to={"/label/" + label._id + "/recordings"}
  //       className={
  //         page === "recordings"
  //           ? "HeaderNavigation-button HeaderNavigation-button-active"
  //           : "HeaderNavigation-button"
  //       }
  //     >
  //       Recordings
  //     </Link>
  //   );
  // }
}
