import React from "react";
import AlbumCard from "./AlbumCard.js";
import "./css/AlbumsList.css";

export default function RecordingAlbums(props) {
  const {
    albums
    //  albumsIsLoaded
  } = props;

  if (albums) {
    return (
      <div className="AlbumsList">
        {albums.map(album => {
          return <AlbumCard album={album} key={album.id} albumId={album.id} />;
        })}
      </div>
    );
  } else {
    return null;
  }
}
