import React from "react";
import querystring from "querystring";
import Frame from "./Frame";
import RecordingsTableUpdate from "./RecordingsTableUpdate";
import FavoritesRecordingsFilters from "./FavoritesRecordingsFilters";
import { setQueryString } from "./helpers/helpers.js";
import "./css/UpdateRecordings.css"

export default function UpdateRecordings(props) {
  const queryString = setQueryString(props.location.search, {
    ...props.match.params,
    update: true
  });
  const queryIds = querystring.parse(queryString.substring(1));

  return (
    <Frame>
      <div className="UpdateRecordings">
        <FavoritesRecordingsFilters
          history={props.history}
          paramsURL={props.match.params}
          queryIds={queryIds}
          queryString={queryString}
        />
        <RecordingsTableUpdate queryString={queryString} update={true} />
      </div>
    </Frame>
  );
}
