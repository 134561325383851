import React from "react";
import Artists from './Artists';
import querystring from 'querystring';

export default class ArtistsQueryBuilder extends React.Component {

  render() {
    let queryString = "?";

    // Add query strings
    const search = this.props.location.search;
    queryString = search ? queryString += search.substr(1) : queryString;
    const { path, params } = this.props.match
    const { pathname } = this.props.location
    if (path === '/composers') {
      document.title = 'Composers - Lyra digital music library'
      if (pathname === '/composers') {
        queryString += "&isComposer=true";
      } else {
        queryString += "&isComposer=true&popular=1";
      }
    }
    if (path === '/performers') {
      document.title = 'Performers - Lyra digital music library'
      // queryString += "&isPerformer=true";
    }
    if (path === '/favorites/artists') {
      document.title = 'Favorite Artists - Lyra digital music library'
      queryString += "&favorites=" + true;
    }
    
        // Create object from query strings
        const queryIds = querystring.parse(queryString.substring(1))

    return <Artists
      queryString={queryString}
      queryIds={queryIds}
      paramsURL={params}
      path={path}
      history={this.props.history}
    />
  }
}
