import React from "react";
import Pieces from './Pieces';
import querystring from 'querystring';

export default class PiecesQueryBuilder extends React.Component {

  render() {
    let queryString = "?";
    if (this.props.location.search) {
      queryString = this.props.location.search
      // Params URL exists
      if (!(Object.keys(this.props.match.params).length === 0 && this.props.match.params.constructor === Object)) {
        queryString += "&" + querystring.stringify(this.props.match.params)
      }
    } else {
      if (!(Object.keys(this.props.match.params).length === 0 && this.props.match.params.constructor === Object)) {
        queryString += querystring.stringify(this.props.match.params)
      }
    }
    
    // // Add id parameters of URL redirects (for fetching albums data)
    // const { artistId } = this.props.match.params;
    // if (artistId) queryString+="&composer="+artistId;
    
    
    const { path } = this.props.match
    if (path === '/pieces') {
      document.title = 'Pieces - Lyra digital music library';
    } else if (path === '/favorites/pieces') {
      queryString += "&favorites=" + true;
      document.title = 'Favorite Pieces - Lyra digital music library';
    }
    
        // Create object from query strings
        const queryIds = querystring.parse(queryString.substring(1))

    return <Pieces
      queryString={queryString}
      queryIds={queryIds}
      paramsURL={this.props.match.params}
      history={this.props.history}
      path={path}
      headerContentIsLoaded={true}
      />
  }
}
