import React from "react";
import querystring from "querystring";
import Frame from "./Frame";
import PeriodHeader from "./PeriodHeader";
import AlbumsList from "./AlbumsList";
import Windows from "./Windows";
import RecordingsTable from "./RecordingsTable";
import PeriodRecordingsFilters from "./PeriodRecordingsFilters";
import PeriodAlbumsFilters from "./PeriodAlbumsFilters";
import { setQueryString } from "./helpers/helpers.js";
export default function Period(props) {
  let page = "albums";
  if (props.match.path === "/period/:eraId/recordings") {
    page = "recordings";
  }

  const period = props.match.params.eraId;
  const queryString = setQueryString(props.location.search, props.match.params);
  const queryIds = querystring.parse(queryString.substring(1));

  if (period) {
    document.title = period + " - Lyra digital music library";
  }

  return (
    <Frame>
      <PeriodHeader period={period} page={page} />
      {page === "albums" ? renderAlbums() : renderRecordings()}
    </Frame>
  );

  function renderAlbums() {
    return (
      <div>
        <PeriodAlbumsFilters
          history={props.history}
          paramsURL={props.match.params}
          queryIds={queryIds}
          queryString={queryString}
        />
        <AlbumsList queryString={queryString} headerContent={period} />
      </div>
    );
  }

  function renderRecordings() {
    return (
      <div>
        <PeriodRecordingsFilters
          history={props.history}
          paramsURL={props.match.params}
          queryIds={queryIds}
          queryString={queryString}
        />
        <Windows>
          <RecordingsTable queryString={queryString} />
        </Windows>
      </div>
    );
  }
}
