import React from "react";
import { Link } from "react-router-dom";
import NumberAlbums from "./NumberAlbums.js";
import "./css/PiecesTableRow.css";

export default function PiecesTableRow(props) {
  const { prevPiece, piece, catalogueId, showComposer } = props;
  let list = null;
  if (piece && piece.lists && catalogueId) {
    list = piece.lists.find(list => list.catalogueId === catalogueId);
  }

  let prevList = null;
  if (prevPiece && prevPiece.lists && catalogueId) {
    prevList = prevPiece.lists.find(list => list.catalogueId === catalogueId);
  }

  return (
    <div className="PiecesTableRow">
      {list ? renderCategory(list) : null}
      {list ? renderSubcategory(list) : null}
      {list ? renderGroup(list) : null}
      {renderPiece(list)}
    </div>
  );

  function renderCategory(list) {
    if ((list && list.category) !== (prevList && prevList.category)) {
      if (list.category)
        return <h2 className="PiecesTableRow-category">{list.category}</h2>;
    }
  }

  function renderSubcategory(list) {
    if ((list && list.subcategory) !== (prevList && prevList.subcategory)) {
      if (list.subcategory)
        return <h3 className="PiecesTableRow-category">{list.subcategory}</h3>;
    }
  }

  function renderGroup(list) {
    if ((list && list.group) !== (prevList && prevList.group)) {
      if (list.group)
        return <h4 className="PiecesTableRow-category">{list.group}</h4>;
    }
  }

  function renderPiece(list) {
    if (piece.numberAlbums > 0) {
      return (
        <div className="PiecesTableRow-piece">
          {renderCatalogueNumberOrDate(list)}
          <Link
            to={`/piece/${piece._id}/recordings`}
            className="PiecesTableRow-piece-title"
          >
            {renderTitle(piece)}
          </Link>
          {/* <div className="PiecesTableRow-piece-composers">
            {renderComposers(piece)}
          </div> */}
          {renderNumberAlbums(piece)}
        </div>
      );
    } else {
      return (
        <div className="PiecesTableRow-piece">
          {renderCatalogueNumberOrDate(list)}
          <span className="PiecesTableRow-piece-title PiecesTableRow-piece-title-grey">
            {renderTitle(piece)}
          </span>
          {/* <div className="PiecesTableRow-piece-composers">
            {renderComposers(piece)}
          </div> */}
          {renderNumberAlbums(piece)}
        </div>
        // <div className="PiecesTableRow-piece">
        //   <div className="PiecesTableRow-piece-title-grey">
        //     {renderTitle(piece)}
        //   </div>
        // </div>
      );
    }
  }

  function renderCatalogueNumberOrDate(list) {
    if (list) {
      if (list.catalogueNumber) {
        return (
          <div className="PiecesTableRow-piece-catalogue">
            {list.catalogueNumber}
            <span className="PiecesTableRow-piece-catalogue-2">
              {list.oldCatalogueNumber
                ? ` (=${list.oldCatalogueNumber})`
                : null}
            </span>
          </div>
        );
      } else if (list.date) {
        return (
          <div className="PiecesTableRow-piece-catalogue">{list.date}</div>
        );
      }
    }
  }

  // function renderComposers(piece) {
  //   const { isComposer } = props;
  //   if (piece && piece.composers && !isComposer) {
  //     return piece.composers.map((composer, i) => {
  //       return (
  //         <span key={i}>
  //           <Link
  //             className="PiecesTableRow-piece-composers-link"
  //             to={"/artist/" + composer.id}
  //           >
  //             {composer.name}
  //           </Link>
  //           {piece.composers.length - 1 === i ? "" : ", "}
  //         </span>
  //       );
  //     });
  //   }
  // }

  function renderTitle(piece) {
    const parent = piece.parent ? piece.parent.title + ". " : "";
    const title = catalogueId
      ? piece.lists && piece.lists[0]
        ? piece.lists[0].title
        : piece.title
      : piece.title;
    return (
      <div>
        <div>
          {parent}
          {title}
          <span className="PiecesTableRow-piece-title-status">
            {piece.status ? ` (${piece.status})` : null}
          </span>
        </div>
        {/* {renderParent(piece)} */}
        {showComposer ? renderComposer(piece) : null}
      </div>
    );
  }

  function renderComposer(piece) {
    return (
      <div className="PiecesTableRow-piece-title-status">{piece.composers && piece.composers.length > 0 ? piece.composers[0].name : ""}</div>)
  }

  // function renderParent(piece) {
  //   if (piece.parent)
  //     return (
  //       <div className="PiecesTableRow-piece-title-parent">
  //         <span>&#x21B3; </span>
  //         <Link to={`/piece/${piece.parent.id}`}>{piece.parent.title}</Link>
  //       </div>
  //     );
  // }

  function renderNumberAlbums(piece) {
    if (piece.numberAlbums > 0) {
      return (
        <div className="PiecesTableRow-piece-numberAlbums">
          <NumberAlbums numberAlbums={piece.numberAlbums} />
        </div>
      );
    } else {
      return <div className="PiecesTableRow-piece-numberAlbums"></div>;
    }
  }
}
