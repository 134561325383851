import React, { useState } from "react";
import { convertListToStringWithComma } from "./helpers/helpers.js";
import "./css/ExpandableList.css";

export default function ExpandableList(props) {
  const [expand, setExpand] = useState(false);
  const { list } = props;

  if (list.length < 10) {
    return <span>{convertListToStringWithComma(list)}</span>;
  } else {
    const shortList = [...list];
    shortList.length = 10;
    return (
      <div>
        {expand ? (
          <span>
            <span>{convertListToStringWithComma(list)}</span>
            <span
              className="ExpandableList-button"
              onClick={() => setExpand(false)}
            >
              (less)
            </span>
          </span>
        ) : (
          <span>
            <span>{convertListToStringWithComma(shortList)}</span>
            <span
              className="ExpandableList-button"
              onClick={() => setExpand(true)}
            >
              (more)
            </span>
          </span>
        )}
      </div>
    );
  }
}
