import React from "react";
import { Link } from "react-router-dom";
import "./css/HeaderMoreInfo.css";

export default function PieceMoreInfo(props) {
  const { piece } = props;

  return (
    <div className="HeaderMoreInfo">
      {renderParentPiece(piece)}
      {renderArrangementOf(piece)}
      {renderComposer(piece)}
      {renderMovements(piece)}
      {renderVersionTitle(piece)}
      {renderArrangementTitle(piece)}
      {renderVersions(piece)}
      {renderVersionOf(piece)}
      {renderEditionOf(piece)}
      {renderEditions(piece)}
      {renderOtherVersions(piece)}
      {renderArranger(piece)}
      {renderInstrumentation(piece)}
      {renderStatus(piece)}
    </div>
  );

  function renderMovements(piece) {
    if (piece.movements && piece.movements.length > 0) {
      return (
        <div className="HeaderMoreInfo-row">
          <div className="HeaderMoreInfo-td-1">Movements</div>
          <div className="HeaderMoreInfo-td-2">
            {piece.movements.map((movement, j) => {
              return (
                <div key={j}>
                  {/* <span className="ArtistMoreInfo-grey">{j + 1}. </span> */}
                  <Link to={"/piece/" + movement.id + "/recordings"}>
                    {movement.title}
                  </Link>
                  <br />
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  }

  function renderComposer(piece) {
    if (piece.composers && piece.composers.length > 0) {
      return (
        <div className="HeaderMoreInfo-row">
          <div className="HeaderMoreInfo-td-1">Composer</div>
          <div className="HeaderMoreInfo-td-2">
            {piece.composers
              ? piece.composers.map(function(composer, i) {
                  return (
                    <span key={i}>
                      <Link to={"/artist/" + composer.id}>{composer.name}</Link>
                      {piece.composers.length - 1 === i ? "" : <span>, </span>}
                    </span>
                  );
                })
              : null}
          </div>
        </div>
      );
    }
  }

  function renderArranger(piece) {
    if (piece.arrangers && piece.arrangers.length > 0) {
      return (
        <div className="HeaderMoreInfo-row">
          <div className="HeaderMoreInfo-td-1">Arranger</div>
          <div className="HeaderMoreInfo-td-2">
            {piece.arrangers
              ? piece.arrangers.map(function(person, i) {
                  return (
                    <span key={i}>
                      <Link to={"/artist/" + person.id}>{person.name}</Link>
                      {piece.arrangers.length - 1 === i ? "" : <span>, </span>}
                    </span>
                  );
                })
              : null}
          </div>
        </div>
      );
    }
  }

  function renderParentPiece(piece) {
    if (piece.parent) {
      return (
        <div className="HeaderMoreInfo-row">
          <div className="HeaderMoreInfo-td-1">Part of</div>
          <div className="HeaderMoreInfo-td-2">
            <Link to={"/piece/" + piece.parent.id + "/recordings"}>
              {piece.parent.title}
            </Link>
            <span>{` (mvt. ${piece.movementNumber})`}</span>
          </div>
        </div>
      );
    }
  }

  function renderEditionOf(piece) {
    if (piece.editionOf) {
      return (
        <div className="HeaderMoreInfo-row">
          <div className="HeaderMoreInfo-td-1">Edition of</div>
          <div className="HeaderMoreInfo-td-2">
            <Link to={"/piece/" + piece.editionOf.id + "/recordings"}>
              {piece.editionOf.title}
            </Link>
          </div>
        </div>
      );
    }
  }

  function renderEditions(piece) {
    if (piece.editions && piece.editions.length > 0) {
      return (
        <div className="HeaderMoreInfo-row">
          <div className="HeaderMoreInfo-td-1">Editions</div>
          <div className="HeaderMoreInfo-td-2">
            {piece.editions.map((edition, i) => {
              return (
                <Link key={i} to={"/piece/" + edition.id + "/recordings"}>
                  {edition.title}
                  <br />
                </Link>
              );
            })}
          </div>
        </div>
      );
    }
  }

  function renderOtherVersions(piece) {
    if (piece.otherVersions && piece.otherVersions.length > 0) {
      return (
        <div className="HeaderMoreInfo-row">
          <div className="HeaderMoreInfo-td-1">Other versions</div>
          <div className="HeaderMoreInfo-td-2">
            {piece.otherVersions.map((otherVersion, i) => {
              return (
                <Link key={i} to={"/piece/" + otherVersion.id + "/recordings"}>
                  {otherVersion.title}
                  <br />
                </Link>
              );
            })}
          </div>
        </div>
      );
    }
  }

  function renderVersionTitle(piece) {
    if (piece.versionTitle) {
      return (
        <div className="HeaderMoreInfo-row">
          <div className="HeaderMoreInfo-td-1">Version</div>
          <div className="HeaderMoreInfo-td-2">{piece.versionTitle}</div>
        </div>
      );
    }
  }

  function renderStatus(piece) {
    if (piece.status) {
      return (
        <div className="HeaderMoreInfo-row">
          <div className="HeaderMoreInfo-td-1">Status</div>
          <div className="HeaderMoreInfo-td-2">{piece.status}</div>
        </div>
      );
    }
  }

  function renderArrangementTitle(piece) {
    if (piece.arrangementTitle) {
      return (
        <div className="HeaderMoreInfo-row">
          <div className="HeaderMoreInfo-td-1">Arranged</div>
          <div className="HeaderMoreInfo-td-2">{piece.arrangementTitle}</div>
        </div>
      );
    }
  }

  function renderVersions(piece) {
    if (piece.versions && piece.versions.length > 0) {
      return (
        <div className="HeaderMoreInfo-row">
          <div className="HeaderMoreInfo-td-1">Versions</div>
          <div className="HeaderMoreInfo-td-2">
            {piece.versions.map((version, i) => {
              return (
                <Link key={i} to={"/piece/" + version.id + "/recordings"}>
                  {version.title}
                  <br />
                </Link>
              );
            })}
          </div>
        </div>
      );
    }
  }

  function renderVersionOf(piece) {
    if (piece.versionOf) {
      return (
        <div className="HeaderMoreInfo-row">
          <div className="HeaderMoreInfo-td-1">Version of</div>
          <div className="HeaderMoreInfo-td-2">
            <Link to={"/piece/" + piece.versionOf.id + "/recordings"}>
              {piece.versionOf.title}
            </Link>
          </div>
        </div>
      );
    }
  }

  function renderArrangementOf(piece) {
    if (piece.arrangementOf) {
      return (
        <div className="HeaderMoreInfo-row">
          <div className="HeaderMoreInfo-td-1">Arrangement of</div>
          <div className="HeaderMoreInfo-td-2">
            <Link to={"/piece/" + piece.arrangementOf.id + "/recordings"}>
              {piece.arrangementOf.title}
            </Link>
          </div>
        </div>
      );
    }
  }

  function renderInstrumentation(piece) {
    if (piece && piece.instruments && piece.instruments.length > 0) {
      return (
        <div className="HeaderMoreInfo-row">
          <div className="HeaderMoreInfo-td-1">Instrumentation</div>
          <div className="HeaderMoreInfo-td-2">
            {piece.instruments.map(function(instrument, i) {
              return (
                <span key={i}>
                  {instrument.name}
                  {instrument.number ? ` (${instrument.number})` : null}
                  {instrument.optional ? " (opt.)" : null}
                  {renderInstrumentsOr(instrument)}
                  {piece.instruments.length - 1 === i ? "" : ", "}
                </span>
              );
            })}
          </div>
        </div>
      );
    }
  }

  function renderInstrumentsOr(instrument) {
    if (instrument.or) {
      return (
        <span>
          {instrument.or.map(function(instrumentOr, i) {
            return (
              <span key={i}>
                <span> or </span>
                {instrumentOr.name}
              </span>
            );
          })}
        </span>
      );
    }
  }
}
