import React from "react";
import Select from "./Select";

export default function EditSubcategory(props) {
  const { subcategory, setSubcategory } = props;

  return (
    <div className="Edit-input-container">
      <label>Subcategory</label>
      <Select
        placeholder="Subcategory"
        options={[
          "1 instrument",
          "1 instrument with continuo",
          "10 instruments",
          "11 instruments",
          "12 instruments",
          "13 instruments",
          "16 instruments",
          "2 instruments",
          "2 instruments with continuo",
          "2 keyboards",
          "2 pianos",
          "3 instruments",
          "3 instruments with continuo",
          "4 instruments",
          "4 instruments with continuo",
          "5 instruments",
          "6 instruments",
          "6 instruments with continuo",
          "7 instruments",
          "8 instruments",
          "9 instruments",
          "Chorus with continuo",
          "Chorus with instruments",
          "Chorus with keyboard",
          "Chorus with orchestra",
          "Chorus with strings",
          "Harmonium",
          "Harpsichord",
          "Keyboard",
          "Mechanical organ",
          "Narrator with keyboard",
          "Narrator with orchestra",
          "Narrator with orchestra and keyboard",
          "Open instrumentation",
          "Orchestra",
          "Orchestra with soloist",
          "Organ",
          "Organ 4 hands",
          "Piano",
          "Piano 4 hands",
          "Piano 6 hands",
          "Piano 8 hands",
          "Piano left hand",
          "Strings",
          "Strings with continuo",
          "Strings with soloist",
          "Unaccompanied chorus",
          "Unaccompanied voices",
          "Unaccompanied voices and chorus",
          "Voice with keyboard",
          "Voices and chorus with continuo",
          "Voices and chorus with instruments",
          "Voices and chorus with keyboard",
          "Voices and chorus with orchestra",
          "Voices and chorus with solo instruments",
          "Voices and chorus with strings",
          "Voices with continuo",
          "Voices with instruments",
          "Voices with keyboard",
          "Voices with orchestra",
          "Voices with strings",
          "Winds"
        ]}
        isSearchable={true}
        isMultiple={false}
        value={subcategory}
        onClear={e => setSubcategory("")}
        onChange={e => setSubcategory(e)}
      />
    </div>
  );
}
