import React, { useState, useEffect } from "react";
import EditText from "./EditText";
import EditPeople from "./EditPeople";
import EditEnsembles from "./EditEnsembles";
import EditAwards from "./EditAwards";
import "./css/Edit.css";

export default function EditAlbumBasic(props) {
  const { album, onSubmit } = props;

  const [title, setTitle] = useState("");
  const [composers, setComposers] = useState([]);
  const [performers, setPerformers] = useState([]);
  const [ensembles, setEnsembles] = useState([]);
  const [description, setDescription] = useState("");
  const [updatedAlbum, setUpdatedAlbum] = useState({});
  const [awards, setAwards] = useState([]);

  // Default value
  useEffect(() => {
    if (album.title) {
      setTitle(album.title);
    } else {
      setTitle("");
    }
    if (album.composers) setComposers(album.composers);
    if (album.performers) setPerformers(album.performers);
    if (album.ensembles) setEnsembles(album.ensembles);
    if (album.description) setDescription(album.description);
    if (album.awards) setAwards(album.awards);
  }, [album]);

  // Update album when input change
  useEffect(() => {
    const newAlbum = {};
    if (title) newAlbum.title = title;
    if (composers) newAlbum.composers = composers;
    if (performers) newAlbum.performers = performers;
    if (ensembles) newAlbum.ensembles = ensembles;
    if (description) newAlbum.description = description;
    if (awards) newAlbum.awards = awards;
    setUpdatedAlbum(newAlbum);
  }, [
    composers,
    performers,
    title,
    ensembles,
    description,
    awards,
    setUpdatedAlbum
  ]);
console.log(awards)
  return (
    <div>
      <form
        onSubmit={e => {
          e.preventDefault();
          onSubmit(updatedAlbum);
        }}
      >
        <EditText value={title} setValue={setTitle} placeholder="Title" />
        <EditPeople
          people={composers}
          setPeople={setComposers}
          placeholder="Composers"
        />
        <EditPeople
          people={performers}
          setPeople={setPerformers}
          placeholder="Performers"
          editOccupations={true}
        />
        <EditEnsembles
          ensembles={ensembles}
          setEnsembles={setEnsembles}
          placeholder="Ensembles"
        />
        <div className="Edit-input-container">
          <label>Description</label>
          <textarea
            placeholder="Description"
            onChange={e => setDescription(e.currentTarget.value)}
            defaultValue={description}
            className="Edit-textarea"
          />
        </div>
        <EditAwards awards={awards} setAwards={setAwards} />
        {/* <div className="Edit-input-container">
          <label>Awards</label>
          <Select
            placeholder="Awards"
            options={["Gramophone Editor's Choice", "Diapason d'or"]}
            isSearchable={true}
            isMultiple={true}
            value={awards}
            onChange={e => setAwards(e)}
            onClear={e => setAwards([])}
          />
        </div> */}
        <button className="Button Edit-button">Update</button>
      </form>
    </div>
  );
}
