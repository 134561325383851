import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ListArtists from "./ListArtists";
import ButtonPlayOnSpotify from "./ButtonPlayOnSpotify.js";
import { convertDateToYear } from "./helpers/helpers";
import "./css/RecordingsTable.css";

export default function RecordingsTableItem(props) {
  const [recordings, setRecordings] = useState(null);
  const [showRecordings, setShowRecordings] = useState(false);
  const {
    recording,
    handleShowLoginToSpotify,
    handleShowMessageBox,
    handleShowSpotifyDevicesWindow,
    piece
  } = props;

  useEffect(() => {
    if (showRecordings) {
      async function fetchAllRecordings() {
        if (recording && recording.albums && recording.albums.length > 0) {
          const recordingAlbum = recording.albums[0];
          try {
            let url = `/api/recordings?albumId=${recordingAlbum.id}`;
            if (piece) url += `&pieceId=${piece._id}`;
            const resp = await fetch(url);

            const recordings = await resp.json();
            setRecordings(recordings);
            return resp;
          } catch (err) {
            console.log(err);
          }
        }
      }
      fetchAllRecordings();
    }
  }, [showRecordings, setShowRecordings]);

  return piece && piece.movements && piece.movements.length > 0 ? (
    <div className="RecordingsTable-table-row">
      <div className="RecordingsTable-table-row-data">
        {renderPlayButton(recording)}
        {renderAlbumCover(recording)}
        <div
          className="RecordingsTable-table-row-right"
          onClick={() => {
            setShowRecordings(!showRecordings);
          }}
        >
          {renderText(recording)}
        </div>
      </div>
      {showRecordings &&
      recording &&
      recording._id &&
      recording._id.albumId &&
      recording._id.albumId.length > 0 ? (
        <div className="RecordingsTable-table-row-list">
          {renderAllRecordings()}
        </div>
      ) : null}
    </div>
  ) : (
    <div className="RecordingsTable-table-row">
      <div className="RecordingsTable-table-row-data">
        {renderPlayButton(recording)}
        {renderAlbumCover(recording)}
        <Link
          className="RecordingsTable-table-row-right"
          to={`/recording/${recording._id}`}
        >
          {renderText(recording)}
        </Link>
      </div>
    </div>
  );

  function renderAlbumCover(recording) {
    if (recording && recording.albums && recording.albums.length > 0) {
      return (
        <div className="RecordingsTable-table-row-data-image">
          <Link to={`/album/${recording.albums[0].id}`}>
            <img
              width="50px"
              src={`https://d31zararll8aqx.cloudfront.net/albums/300/${recording.albums[0].id}.jpg`}
              alt="cover"
            />
          </Link>
        </div>
      );
    }
  }

  function renderText(recording) {
    const { performers, ensembles } = recording;
    if (recording && recording.albums && recording.albums.length > 0) {
      return (
        <div className="RecordingsTable-table-row-data-text">
          <div className="RecordingsTable-table-row-data-text-title">
            {/* <ListArtists
              performers={performers}
              ensembles={ensembles}
              link={false}
            /> */}
            {recording.albums[0].title}
          </div>
          <div className="RecordingsTable-table-row-data-text-date">
            {recording.albums[0] && recording.albums[0].label ? (
              <span>
                {recording.albums[0].label.name}
                <br />
              </span>
            ) : null}
            {recording.albums[0] && recording.albums[0].releaseDate
              ? convertDateToYear(recording.albums[0].releaseDate)
              : null}
            {recording.end
              ? ` (recorded in ${convertDateToYear(recording.end)})`
              : null}
          </div>
        </div>
      );
    }
  }

  function renderPlayButton(recordingItem) {
    if (
      recordingItem &&
      recordingItem.albums &&
      recordingItem.albums.length > 0
    ) {
      const recordingAlbum = recordingItem.albums[0];
      if (
        recordingAlbum.externalLinks &&
        recordingAlbum.externalLinks.spotify
      ) {
        return (
          <div className="RecordingsTable-table-row-data-play">
            <ButtonPlayOnSpotify
              albumIdSpotify={recordingAlbum.externalLinks.spotify}
              trackTitle={recordingItem.title}
              trackPosition={recordingAlbum.trackPosition}
              handleShowMessageBox={handleShowMessageBox}
              handleShowSpotifyDevicesWindow={handleShowSpotifyDevicesWindow}
              handleShowLoginToSpotify={handleShowLoginToSpotify}
              small={true}
            />
          </div>
        );
      }
    }
  }

  function renderAllRecordings() {
    if (recordings && recordings.length > 0)
      return recordings.map((recordingItem, i) => (
        <div className="RecordingsTable-recording" key={i}>
          {renderPlayButton(recordingItem)}
          <Link
            to={`/recording/${recordingItem._id}`}
            className="RecordingsTable-recording-link"
          >
            {`${i + 1}. ${recordingItem.title}`}
          </Link>
          {/* <div className="RecordingsTable-recording-button"></div> */}
        </div>
      ));
  }
}
