import React from "react";
import "./css/Logo.css";

export default class Logo extends React.Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 194.31 77.14"
        className="Logo"
      >
        <title>Fichier 122</title>
        <g id="Calque_2" data-name="Calque 2">
          <g id="Calque_1-2" data-name="Calque 1">
            <path
              d="M12.84,4V62.69H5V4Z"
              transform="translate(-4.97 -3.98)"
              className="Logo-1"
            />
            <path
              d="M34.18,56.4,18.86,28.83H28L38.41,48.3l9.73-19.47H57L29.88,81.12h-9Z"
              transform="translate(-4.97 -3.98)"
              className="Logo-1"
            />
            <path
              d="M62.37,28.83h7.87v3a14.83,14.83,0,0,1,3.85-3.09,8.87,8.87,0,0,1,4.06-.87,12.82,12.82,0,0,1,6.51,2l-3.61,7.16a7.58,7.58,0,0,0-4.37-1.6q-6.44,0-6.44,9.67V62.69H62.37Z"
              transform="translate(-4.97 -3.98)"
              className="Logo-1"
            />
            <path
              d="M113.8,28.83h7.9V62.69h-7.9V59.15q-4.87,4.51-10.46,4.52a15.11,15.11,0,0,1-11.69-5.08,18.81,18.81,0,0,1-4.58-12.93A18.25,18.25,0,0,1,91.65,33a14.83,14.83,0,0,1,11.48-5.07,14.55,14.55,0,0,1,10.67,4.86ZM95.12,45.66a11.76,11.76,0,0,0,2.62,7.93,8.62,8.62,0,0,0,6.79,3.09,9.06,9.06,0,0,0,7.06-3,11.51,11.51,0,0,0,2.7-7.86,11.5,11.5,0,0,0-2.7-7.85,8.91,8.91,0,0,0-7-3A8.73,8.73,0,0,0,97.81,38,11.22,11.22,0,0,0,95.12,45.66Z"
              transform="translate(-4.97 -3.98)"
              className="Logo-1"
            />
            <path
              d="M141.05,39.67a3.17,3.17,0,0,1-1.34,1.22,4.09,4.09,0,0,1-1.78.43,4.41,4.41,0,0,1-1.82-.35,3.83,3.83,0,0,1-1.39-1,4.41,4.41,0,0,1-.89-1.46,5.09,5.09,0,0,1-.33-1.8,4.85,4.85,0,0,1,.3-1.79,4.21,4.21,0,0,1,.88-1.47,4.09,4.09,0,0,1,1.37-1,4.32,4.32,0,0,1,1.78-.36,3.6,3.6,0,0,1,1.86.43A4,4,0,0,1,141,33.65h0l0-6.48h1.73L142.77,41H141.1V39.67Zm-5.75-2.93a3.87,3.87,0,0,0,.19,1.17,3,3,0,0,0,.56,1,2.88,2.88,0,0,0,.91.7,2.71,2.71,0,0,0,1.25.25,2.78,2.78,0,0,0,1.2-.26,2.92,2.92,0,0,0,.92-.7,3,3,0,0,0,.59-1,3.48,3.48,0,0,0,0-2.38,3.27,3.27,0,0,0-.61-1,2.9,2.9,0,0,0-.93-.69,2.77,2.77,0,0,0-1.2-.25,3,3,0,0,0-1.25.27,2.8,2.8,0,0,0-.89.7,3,3,0,0,0-.55,1A3.87,3.87,0,0,0,135.3,36.74Z"
              transform="translate(-4.97 -3.98)"
              className="Logo-2"
            />
            <path
              d="M147.7,29.15a1,1,0,0,1-.33.79,1.19,1.19,0,0,1-1.61,0,1,1,0,0,1-.33-.78,1.06,1.06,0,0,1,.32-.8,1.08,1.08,0,0,1,.8-.33,1.12,1.12,0,0,1,.81.32A1,1,0,0,1,147.7,29.15ZM147.53,41H145.8l-.07-8.7,1.73,0Z"
              transform="translate(-4.97 -3.98)"
              className="Logo-2"
            />
            <path
              d="M151.23,42.45a4.05,4.05,0,0,0,1.43,1,4.19,4.19,0,0,0,1.76.37,3.65,3.65,0,0,0,1.45-.26,2.48,2.48,0,0,0,.94-.69,2.57,2.57,0,0,0,.5-1,5.14,5.14,0,0,0,.15-1.27V39.48h0a3,3,0,0,1-1.29,1.13,4,4,0,0,1-1.76.4,4.46,4.46,0,0,1-1.81-.33,4.16,4.16,0,0,1-1.4-.94,4.43,4.43,0,0,1-.89-1.42,5,5,0,0,1-.33-1.75,5.22,5.22,0,0,1,.3-1.77,4.37,4.37,0,0,1,.87-1.44,4,4,0,0,1,1.37-1,4.35,4.35,0,0,1,1.8-.37,4,4,0,0,1,1.79.39,3.08,3.08,0,0,1,1.36,1.22h0l0-1.4h1.68l.07,8.26a6.9,6.9,0,0,1-.23,1.83,3.84,3.84,0,0,1-2.27,2.63,5.62,5.62,0,0,1-2.28.43,6.91,6.91,0,0,1-2.29-.4,5.89,5.89,0,0,1-2-1.15Zm.47-5.93a3.39,3.39,0,0,0,.2,1.15,2.73,2.73,0,0,0,.58,1,2.7,2.7,0,0,0,.92.67,2.76,2.76,0,0,0,1.21.25,3,3,0,0,0,1.22-.25,2.71,2.71,0,0,0,.93-.66,2.76,2.76,0,0,0,.59-1,3.15,3.15,0,0,0,.2-1.2,3,3,0,0,0-.22-1.17,3,3,0,0,0-.61-1,3,3,0,0,0-.95-.66,2.67,2.67,0,0,0-1.21-.24,2.76,2.76,0,0,0-1.21.26,2.66,2.66,0,0,0-.91.67,2.74,2.74,0,0,0-.56,1A3.37,3.37,0,0,0,151.7,36.52Z"
              transform="translate(-4.97 -3.98)"
              className="Logo-2"
            />
            <path
              d="M164.07,29a1,1,0,0,1-.34.8,1.12,1.12,0,0,1-.8.31,1.08,1.08,0,0,1-.81-.31,1,1,0,0,1-.33-.78,1.07,1.07,0,0,1,.32-.8,1.16,1.16,0,0,1,.8-.33,1.13,1.13,0,0,1,.81.32A1.08,1.08,0,0,1,164.07,29Zm-.18,11.85-1.73,0-.07-8.71h1.73Z"
              transform="translate(-4.97 -3.98)"
              className="Logo-2"
            />
            <path
              d="M171.1,33.51l-2.31,0,0,4.52a1.7,1.7,0,0,0,.31,1.12,1.14,1.14,0,0,0,.94.33,2.32,2.32,0,0,0,.51,0,2,2,0,0,0,.5-.16l.06,1.42a4.4,4.4,0,0,1-1.46.25,2.5,2.5,0,0,1-1.89-.66,2.86,2.86,0,0,1-.67-2l0-4.72-1.67,0V32.14h1.67l0-2.5h1.7l0,2.5,2.32,0Z"
              transform="translate(-4.97 -3.98)"
              className="Logo-2"
            />
            <path
              d="M178.31,35.1a1.69,1.69,0,0,0-.6-1.43,2.43,2.43,0,0,0-1.56-.46,3.28,3.28,0,0,0-1.39.3,3.54,3.54,0,0,0-1.08.73l-.93-1.09a4.54,4.54,0,0,1,1.56-1,5.67,5.67,0,0,1,2-.37,4.5,4.5,0,0,1,1.63.26,2.89,2.89,0,0,1,1.8,1.79A4.14,4.14,0,0,1,180,35.2l0,3.8q0,.44,0,.93a4.54,4.54,0,0,0,.11.79l-1.56,0a6,6,0,0,1-.16-1.25h0a3.38,3.38,0,0,1-1.2,1.09,3.54,3.54,0,0,1-1.71.4,4.64,4.64,0,0,1-1.08-.12,3.12,3.12,0,0,1-1-.45,2.39,2.39,0,0,1-1.08-2.08,2.23,2.23,0,0,1,.52-1.58,3.44,3.44,0,0,1,1.39-.89,7.82,7.82,0,0,1,1.94-.41c.72-.07,1.45-.1,2.18-.11Zm-.41,1.49c-.43,0-.87,0-1.33.07a6.47,6.47,0,0,0-1.25.23,2.33,2.33,0,0,0-.92.5,1.17,1.17,0,0,0-.36.89,1.24,1.24,0,0,0,.16.64,1.4,1.4,0,0,0,.42.42,1.58,1.58,0,0,0,.57.22,3.39,3.39,0,0,0,.64.06,2.35,2.35,0,0,0,1.86-.74,2.67,2.67,0,0,0,.64-1.83v-.46Z"
              transform="translate(-4.97 -3.98)"
              className="Logo-2"
            />
            <path
              d="M184.66,40.69h-1.73l-.12-13.88,1.73,0Z"
              transform="translate(-4.97 -3.98)"
              className="Logo-2"
            />
            <path
              d="M136.89,62.67h-1.73l-.11-13.88,1.72,0Z"
              transform="translate(-4.97 -3.98)"
              className="Logo-2"
            />
            <path
              d="M141.82,50.78a1,1,0,0,1-.33.79,1.13,1.13,0,0,1-.81.32,1.12,1.12,0,0,1-.8-.31,1.11,1.11,0,0,1,.78-1.91,1.16,1.16,0,0,1,.82.31A1.09,1.09,0,0,1,141.82,50.78Zm-.18,11.85h-1.72l-.07-8.7,1.72,0Z"
              transform="translate(-4.97 -3.98)"
              className="Logo-2"
            />
            <path
              d="M146.35,61.21v1.38h-1.67l-.11-13.88,1.72,0,.06,6.49h0a3.63,3.63,0,0,1,3.08-1.57,4.23,4.23,0,0,1,1.77.33,4.12,4.12,0,0,1,1.39,1,4.71,4.71,0,0,1,.9,1.46,5,5,0,0,1,.32,1.78,5.17,5.17,0,0,1-.29,1.81,4.69,4.69,0,0,1-.87,1.47,4.18,4.18,0,0,1-1.37,1,4.5,4.5,0,0,1-1.81.37,4.09,4.09,0,0,1-1.78-.4,3.33,3.33,0,0,1-1.36-1.19Zm5.76-3a3.77,3.77,0,0,0-.19-1.17,3.2,3.2,0,0,0-.56-1,2.63,2.63,0,0,0-.92-.68,2.92,2.92,0,0,0-1.26-.25,2.8,2.8,0,0,0-2.12,1,3.3,3.3,0,0,0-.79,2.2,3.35,3.35,0,0,0,.22,1.19,3.11,3.11,0,0,0,.61,1,2.73,2.73,0,0,0,.93.68,3,3,0,0,0,2.46,0,2.63,2.63,0,0,0,.9-.71,2.83,2.83,0,0,0,.55-1A3.85,3.85,0,0,0,152.11,58.18Z"
              transform="translate(-4.97 -3.98)"
              className="Logo-2"
            />
            <path
              d="M156.25,54.77c0-.36,0-.69-.06-1h1.63a7.58,7.58,0,0,1,.06.76c0,.29,0,.52,0,.7H158A3,3,0,0,1,159,54a2.79,2.79,0,0,1,1.58-.49,3.16,3.16,0,0,1,.68.07l-.06,1.6a2.9,2.9,0,0,0-.79-.08,2.39,2.39,0,0,0-1.08.23,2.33,2.33,0,0,0-.76.62,2.52,2.52,0,0,0-.44.9,3.72,3.72,0,0,0-.14,1.06l0,4.57h-1.73l-.05-6.83C156.27,55.44,156.27,55.14,156.25,54.77Z"
              transform="translate(-4.97 -3.98)"
              className="Logo-2"
            />
            <path
              d="M168.11,56.77a1.71,1.71,0,0,0-.6-1.44,2.37,2.37,0,0,0-1.56-.45,3.46,3.46,0,0,0-1.39.29,3.56,3.56,0,0,0-1.08.74l-.93-1.1a4.7,4.7,0,0,1,1.56-1,5.67,5.67,0,0,1,2-.36,4.47,4.47,0,0,1,1.63.25,3,3,0,0,1,1.13.72,2.74,2.74,0,0,1,.67,1.08,3.88,3.88,0,0,1,.24,1.33l0,3.8q0,.45,0,.93a4.67,4.67,0,0,0,.11.8h-1.56a5.86,5.86,0,0,1-.16-1.25h-.05a3.39,3.39,0,0,1-1.2,1.09,3.66,3.66,0,0,1-1.71.4,4.53,4.53,0,0,1-1.07-.13,3.27,3.27,0,0,1-1-.44,2.63,2.63,0,0,1-.77-.82,2.46,2.46,0,0,1-.31-1.27,2.21,2.21,0,0,1,.52-1.57,3.24,3.24,0,0,1,1.39-.89,7.39,7.39,0,0,1,1.94-.41c.72-.07,1.45-.11,2.18-.11Zm-.41,1.49c-.43,0-.87,0-1.33.06a6.47,6.47,0,0,0-1.25.23,2.47,2.47,0,0,0-.92.51,1.15,1.15,0,0,0-.36.88,1.24,1.24,0,0,0,.16.64,1.4,1.4,0,0,0,.42.42,1.59,1.59,0,0,0,.57.23,3.39,3.39,0,0,0,.64.06,2.41,2.41,0,0,0,1.86-.74,2.68,2.68,0,0,0,.64-1.84v-.46Z"
              transform="translate(-4.97 -3.98)"
              className="Logo-2"
            />
            <path
              d="M172.61,54.64c0-.37,0-.7-.06-1h1.63a7.58,7.58,0,0,1,.06.76c0,.29,0,.52,0,.71h0a3,3,0,0,1,1.07-1.25,2.73,2.73,0,0,1,1.58-.48,2.25,2.25,0,0,1,.68.07l-.06,1.59a3.91,3.91,0,0,0-.79-.08,2.27,2.27,0,0,0-1.08.24,2.19,2.19,0,0,0-.76.62,2.65,2.65,0,0,0-.44.89,4.11,4.11,0,0,0-.14,1.06l0,4.57-1.73,0-.05-6.83Q172.64,55.18,172.61,54.64Z"
              transform="translate(-4.97 -3.98)"
              className="Logo-2"
            />
            <path
              d="M182.63,60.41h0L185,53.56l1.83,0-4.13,10.8a6.52,6.52,0,0,1-.47,1,3.14,3.14,0,0,1-.6.74,2.5,2.5,0,0,1-.81.47,3.36,3.36,0,0,1-1.11.17,3.5,3.5,0,0,1-.56,0,3.88,3.88,0,0,1-.58-.09l.15-1.53a2.67,2.67,0,0,0,.86.14,1.27,1.27,0,0,0,1-.38,3.84,3.84,0,0,0,.6-1.08l.54-1.46L178,53.62l1.93,0Z"
              transform="translate(-4.97 -3.98)"
              className="Logo-2"
            />
            <path
              d="M193.16,55.27h-.58l0-4.31h-1.32v-.6l3.22,0V51h-1.32Z"
              transform="translate(-4.97 -3.98)"
              className="Logo-2"
            />
            <path
              d="M197.14,54.1h0l1.19-3.78h.89l0,4.91h-.57l0-4.11h0l-1.33,4.12H197l-1.39-4.09h0l0,4.1H195l0-4.91h.9Z"
              transform="translate(-4.97 -3.98)"
              className="Logo-2"
            />
          </g>
        </g>
      </svg>
    );
  }
}
