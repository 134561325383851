import React, { useState, useEffect } from "react";
import querystring from "querystring";
import Frame from "./Frame";
import { Link, withRouter } from "react-router-dom";
import EditRecordingBasic from "./EditRecordingBasic";
import Windows from "./Windows";

import "./css/Edit.css";

function EditRecording(props) {
  return (
    <Windows>
      <EditRecordingContent {...props} />
    </Windows>
  );
}

function EditRecordingContent(props) {
  const { location, handleShowMessageBox } = props;
  let queryIds = null;
  if (location.search)
    queryIds = querystring.parse(location.search.substring(1));

  const recordingId = props.match.params.recordingId;
  // const artistId = props.match.params.artistId;
  const [page, setPage] = useState("basic");
  const [recording, setRecording] = useState({});

  useEffect(() => {
    if (queryIds && queryIds.page) setPage(queryIds.page);
  }, [queryIds]);

  async function fetchRecording(recordingId) {
    if (recordingId)
      try {
        const resp = await fetch("/api/recordings/" + recordingId);
        const recording = await resp.json();
        setRecording(recording);
        return resp;
      } catch (err) {
        console.log(err);
      }
    else return null;
  }

  useEffect(() => {
    if (recordingId) fetchRecording(recordingId);
  }, [recordingId]);

  return (
    <Frame>
      <div className="Edit">
        {renderButtons()}
        {recording && recording._id ? renderSection() : null}
      </div>
    </Frame>
  );

  function renderButtons() {
    return (
      <div>
        <Link
          className={"Button Button-margin-right"}
          to={`/recording/${recording._id}`}
        >
          Return to recording
        </Link>
      </div>
    );
  }

  function renderSection() {
    if (page === "basic") {
      return (
        <EditRecordingBasic
          recording={recording}
          onSubmit={async updatedRecording => {
            const updatedrecordingId = await upsertRecording(
              recording._id,
              updatedRecording
            );
            reload(updatedrecordingId);
          }}
        />
      );
    } else {
      return null;
    }
  }

  function reload(updatedrecordingId) {
    if (updatedrecordingId) {
      fetchRecording(updatedrecordingId);
      props.history.push(`/recording/${updatedrecordingId}/edit`);
    } else {
      fetchRecording(recordingId);
    }
  }

  async function upsertRecording(updatedrecordingId, updatedRecording) {
    const body = JSON.stringify(updatedRecording);
    try {
      const resp = await fetch("/api/recordings/update/" + updatedrecordingId, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body
      });
      if (resp.status === 201) {
        handleShowMessageBox("Updated", 1000);
        return updatedrecordingId;
      } else {
        
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export default withRouter(EditRecording);
