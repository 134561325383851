import React from "react";
import { Link } from "react-router-dom";
import "./css/Navigation.css";

export default function ComposersNavigation(props) {
  const { queryIds } = props;
  return (
    <div className="Navigation">
      <div className="Navigation-row Navigation-row-small">
        <Link
          to="/composers/popular"
          className={
            queryIds && queryIds.popular === "1"
              ? "Navigation-button-small Navigation-button-small-active"
              : "Navigation-button-small"
          }
        >
          Popular
        </Link>
        <Link
          to="/composers"
          className={
            queryIds.popular !== "1"
              ? "Navigation-button-small Navigation-button-small-active"
              : "Navigation-button-small"
          }
        >
          All composers
        </Link>
      </div>
    </div>
  );
}
