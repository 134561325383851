import React, { useContext, useState } from "react";
import "./css/Settings.css";
import ListItem from "./ListItem";
// import Login from "./Login";
// import Register from "./Register";
import Navbar from "./Navbar";
import UserContext from "./UserContext";

export default function Settings(props) {
  const user = useContext(UserContext);
  const [showSettings, setShowSettings] = useState(true);
  // const [showSignin, setShowSignin] = useState(false);
  // const [showRegister, setShowRegister] = useState(false);

  if (showSettings) {
    if (user && user.userId) {
      return (
        <div className="Settings">
          <Navbar
            title="More"
            showBackArrow={true}
            handleBackArrow={() => props.handleShowSettings()}
          />
          <div className="Settings-content">
            <ListItem
              title="Account"
              className="Settings-link"
              to={"/account"}
            />
            <ListItem
              title="Sign out"
              className="Settings-link"
              onClick={() => logout()}
              to="#"
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="Settings">
          <Navbar
            title="More"
            showBackArrow={true}
            handleBackArrow={() => props.handleShowSettings()}
          />
          <div className="Settings-content">
            <ListItem
              title="Log in"
              className="Settings-link"
              onClick={() => {
                // setShowSignin(true);
                setShowSettings(false);
              }}
            />
            <ListItem
              title="Terms of Use"
              className="Settings-link"
              to={"/terms"}
            />
            <ListItem
              title="Privacy Policy"
              className="Settings-link"
              to={"/privacy"}
            />
          </div>
        </div>
      );
    }
  // } else if (showSignin) {
  //   return (
  //     <div className="Settings">
  //       <Navbar />
  //     </div>
  //   );
  // } else if (showRegister) {
  //   return (
  //     <div className="Settings">
  //       <Navbar
  //         title="Login"
  //         showBackArrow={true}
  //         handleBackArrow={() => setShowSettings(false)}
  //       />
  //       <div className="Settings-content">
  //         <Register
  //           handleBackArrow={() => {
  //             setShowSignin(true);
  //             setShowSettings(false);
  //             setShowRegister(false);
  //           }}
  //           handleShowSignin={() => {
  //             setShowSignin(true);
  //             setShowRegister(false);
  //           }}
  //         />
  //       </div>
  //     </div>
  //   );
  } else {
    return null;
  }

  async function logout() {
    try {
      await fetch("/logout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        }
      });
      document.location.reload(true);
    } catch (err) {
      console.log(err);
    }
  }
}
