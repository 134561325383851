import React from "react";
import Filter from "./Filter.js";
// import FilterSearch from "./FilterSearch.js";
import "./css/Filters.css";

export default function GenreRecordingsFilters(props) {
  return (
    <div className="Filters">
      {/* <FilterSearch
        queryString={props.queryString}
        queryIds={props.queryIds}
        history={props.history}
        paramsURL={props.paramsURL}
      /> */}
      <Filter
        queryString={props.queryString}
        queryIds={props.queryIds}
        history={props.history}
        name="Composer"
        queryParameter="composer"
        apiURL="/api/recordings/distinct/composers.invertedName"
        key="composer"
        isSearchable={true}
        paramsURL={props.paramsURL}
      />
      <Filter
        queryString={props.queryString}
        queryIds={props.queryIds}
        history={props.history}
        name="Performer"
        queryParameter="performers"
        apiURL="/api/recordings/distinct/performers.invertedName"
        key="performer"
        isSearchable={true}
        isMultiple={true}
        paramsURL={props.paramsURL}
      />
      <Filter
        queryString={props.queryString}
        queryIds={props.queryIds}
        history={props.history}
        name="Ensemble"
        queryParameter="ensemble"
        apiURL="/api/recordings/distinct/ensembles.name"
        key="ensemble"
        isSearchable={true}
        paramsURL={props.paramsURL}
      />
    </div>
  );
}
