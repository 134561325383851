import React from "react";
import Select from "./Select";

export default function EditNationalities(props) {
  const { nationalities, setNationalities } = props;

  return (
    <div className="Edit-input-container">
      <label>Nationalities</label>
      <Select
        placeholder="Nationalities"
        options={[
          "Austrian",
          "French",
          "Italian",
          "German",
          "American",
          "Dutch",
          "Russian",
          "Soviet",
          "Japanese",
          "Argentinian",
          "Armenian",
          "Australian",
          "Bohemian",
          "Belgian",
          "Brazilian",
          "British",
          "Bulgarian",
          "Canadian",
          "Chinese",
          "Colombian",
          "Croatian",
          "Cuban",
          "Curaçaoan",
          "Czech",
          "Danish",
          "English",
          "Estonian",
          "Finnish",
          "Flemish",
          "Franco-Flemish",
          "Georgian",
          "Greek",
          "Hungarian",
          "Icelandic",
          "Indian",
          "Irish",
          "Israeli",
          "Kazakhstani",
          "Latvian",
          "Lithuanian",
          "Luxembourgish",
          "Mexican",
          "Moldovan",
          "Monegasque",
          "New Zealand",
          "Norwegian",
          "Peruvian",
          "Polish",
          "Portuguese",
          "Romanian",
          "Scottish",
          "Serbian",
          "Slovak",
          "Slovenian",
          "South Korean",
          "Spanish",
          "Swedish",
          "Swiss",
          "Turkish",
          "Ukrainian",
          "Uzbek",
          "Venezuelan",
          "Welsh"
        ]}
        isSearchable={true}
        isMultiple={true}
        value={nationalities}
        onClear={e => setNationalities([])}
        onChange={e => {
          setNationalities(e);
        }}
      />
    </div>
  );
}
