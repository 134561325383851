import React, { useState } from "react";
import Frame from "./Frame";
import { Link, withRouter } from "react-router-dom";
import ArtistToBeAdded from "./ArtistToBeAdded";
import OccupationToSet from "./OccupationToSet";
import LabelToBeAdded from "./LabelToBeAdded";
import Windows from "./Windows";
import { addDurationMs, addInstruments } from "./helpers/helpers";
import "./css/Edit.css";

function EditPiece(props) {
  return (
    <Windows>
      <EditAlbumsContent {...props} />
    </Windows>
  );
}

function EditAlbumsContent(props) {
  const { handleShowMessageBox } = props;
  const [ids, setIds] = useState("");
  const [albums, setAlbums] = useState([]);
  const [albumsSp, setAlbumsSp] = useState([]);
  const [recordings, setRecordings] = useState([]);
  const [toBeAdded, setToBeAdded] = useState([]);
  const [occupationsToSet, setOccupationsToSet] = useState([]);
  const [saved, setSaved] = useState(false);

  return (
    <Frame>
      <div className="Edit">
        {returnInputs()}
        {returnToBeAdded()}
        {returnOccupationsToSet()}
        {returnSave()}
        {returnSavedAlbums()}
      </div>
    </Frame>
  );

  function returnInputs() {
    if (albums && albums.length === 0) {
      return (
        <div className="Edit-column">
          <textarea
            placeholder="Ids"
            onChange={e => setIds(e.currentTarget.value)}
            defaultValue={ids}
          />
          <button className="Button Edit-button" onClick={() => fetchAlbums()}>
            Update
          </button>
        </div>
      );
    } else {
      return null;
    }
  }

  function returnToBeAdded() {
    if (toBeAdded && toBeAdded.length > 0) {
      return toBeAdded.map((item, i) => {
        if (item.type === "artist") {
          return (
            <ArtistToBeAdded
              defaultName={item.name}
              idSpotify={item.idSpotify}
              searchResults={item.searchResults}
              refetchAlbums={refetchAlbums}
              handleShowMessageBox={handleShowMessageBox}
              setToBeAdded={setToBeAdded}
              key={i}
              i={i}
            />
          );
        } else if (item.type === "label") {
          return (
            <LabelToBeAdded
              defaultName={item.name}
              searchResults={item.searchResults}
              handleShowMessageBox={handleShowMessageBox}
              refetchAlbums={refetchAlbums}
              key={i}
              i={i}
            />
          );
        } else {
          return null;
        }
      });
    } else {
      return null;
    }
  }

  async function fetchAlbums() {
    handleShowMessageBox("Fetching albums...", 5000);
    const newIds = ids.split("\n").join(",");
    try {
      const resp = await fetch("/api/spotify/fetch/albums/" + newIds);
      const respJSON = await resp.json();
      if (resp.status === 200) {
        handleShowMessageBox("Fetched", 2000);
        setAlbums(respJSON.albums);
        setAlbumsSp(respJSON.albumsSp);
        setRecordings(respJSON.recordings);
        setToBeAdded(respJSON.toBeAdded);
        setOccupationsToSet(respJSON.occupationsToSet);
      }
    } catch (err) {
      console.log(err);
    }
  }
  async function refetchAlbums() {
    setToBeAdded([]);
    const body = JSON.stringify(albumsSp);
    try {
      const resp = await fetch("/api/albums/refetch", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body
      });
      const respJSON = await resp.json();
      if (resp.status === 200) {
        console.log(respJSON);
        setAlbums(respJSON.albums);
        setRecordings(respJSON.recordings);
        setToBeAdded(respJSON.toBeAdded);
        setOccupationsToSet(respJSON.occupationsToSet);
      }
    } catch (err) {
      console.log(err);
    }
  }

  function returnOccupationsToSet() {
    if (occupationsToSet && occupationsToSet.length > 0) {
      return occupationsToSet.map((occupationToSet, i) => {
        return (
          <OccupationToSet
            occupationToSet={occupationToSet}
            // handleSetOccupationsAll={handleSetOccupationsAll}
            handleSetOccupationsThisTrack={handleSetOccupationsThisTrack}
            handleSetOccupationsThisAlbum={handleSetOccupationsThisAlbum}
            key={i}
            i={i}
          />
        );
      });
    } else {
      return null;
    }
  }

  // function handleSetOccupationsAll(occupationToSet, occupations, i) {
  //   const newOccupationsToSet = [...occupationsToSet];
  //   newOccupationsToSet.splice(i, 1);
  //   setOccupationsToSet(newOccupationsToSet);

  //   console.log(occupationToSet);
  //   console.log(occupations);
  // }

  function handleSetOccupationsThisTrack(occupationToSet, occupations, i) {
    const newOccupationsToSet = [...occupationsToSet];
    newOccupationsToSet.splice(i, 1);
    setOccupationsToSet(newOccupationsToSet);

    const newAlbums = [...albums];
    const newRecordings = [...recordings];
    // recordings.forEach(recording => {
    //   if (recording.externalLinks.spotify.includes(occupationToSet.trackId))
    //     newRecordings.push(recording);
    //   else return null;
    // });

    const person = {
      id: occupationToSet.id,
      name: occupationToSet.name,
      invertedName: occupationToSet.invertedName
    };

    updateAlbumAndRecordings(
      person,
      occupations,
      newAlbums,
      newRecordings,
      occupationToSet
    );
  }

  function handleSetOccupationsThisAlbum(occupationToSet, occupations, i) {
    const newOccupationsToSet = [];
    occupationsToSet.forEach(item => {
      if (
        item.albumId !== occupationToSet.albumId ||
        item.id !== occupationToSet.id
      )
        newOccupationsToSet.push(item);
    });
    setOccupationsToSet(newOccupationsToSet);

    const newAlbums = [...albums];
    const newRecordings = [...recordings];

    // recordings.forEach(recording => {
    //   const isInAlbum = recording.albums.findIndex(
    //     album => album.id === occupationToSet.albumId
    //   );
    //   if (isInAlbum !== -1) newRecordings.push(recording);
    // });
    // mettre à jour seulement les albums et recordings de cet album

    const person = {
      id: occupationToSet.id,
      name: occupationToSet.name,
      invertedName: occupationToSet.invertedName
    };

    updateAlbumAndRecordings(
      person,
      occupations,
      newAlbums,
      newRecordings,
      occupationToSet
    );
  }

  function updateAlbumAndRecordings(
    person,
    occupations,
    newAlbums,
    newRecordings,
    occupationToSet
  ) {
    if (occupations.includes("Composer") && occupations.length === 1) {
      newAlbums[occupationToSet.indexAlbum].composers.push(person);
      newRecordings.forEach(newRecording => {
        newRecording.albums.forEach(album => {
          if (
            occupationToSet.albumId === album.id &&
            newRecording.artistsIds.includes(occupationToSet.idSpotify)
          )
            newRecording.composers.push(person);
        });
      });
    } else if (occupations.includes("Composer") && occupations.length > 1) {
      newAlbums[occupationToSet.indexAlbum].composers.push(person);
      newRecordings.forEach(newRecording => {
        newRecording.albums.forEach(album => {
          if (
            occupationToSet.albumId === album.id &&
            newRecording.artistsIds.includes(occupationToSet.idSpotify)
          )
            newRecording.composers.push(person);
        });
      });
      const newPerson = { ...person };
      newPerson.occupations = occupations.filter(item => item !== "Composer");
      newAlbums[occupationToSet.indexAlbum].performers.push(newPerson);
      newRecordings.forEach(newRecording => {
        newRecording.albums.forEach(album => {
          if (
            occupationToSet.albumId === album.id &&
            newRecording.artistsIds.includes(occupationToSet.idSpotify)
          )
            newRecording.performers.push(person);
        });
      });
      // newRecordings.map(newRecording =>
      //   newRecording.performers.push(newPerson)
      // );
    } else {
      person.occupations = occupations;
      newAlbums[occupationToSet.indexAlbum].performers.push(person);
      // newRecordings.map(newRecording => newRecording.performers.push(person));
      newRecordings.forEach(newRecording => {
        newRecording.albums.forEach(album => {
          if (
            occupationToSet.albumId === album.id &&
            newRecording.artistsIds.includes(occupationToSet.idSpotify)
          )
            newRecording.performers.push(person);
        });
      });
    }
    setAlbums(newAlbums);
    setRecordings(newRecordings);
  }

  function returnSave() {
    if (
      albums &&
      albums.length > 0 &&
      occupationsToSet &&
      occupationsToSet.length === 0 &&
      toBeAdded &&
      toBeAdded.length === 0 &&
      !saved
    ) {
      return (
        <div>
          <div>{`${albums.length} album(s) ready to be imported`}</div>
          <button className="Button Edit-button" onClick={() => saveAlbum()}>
            Save
          </button>
        </div>
      );
    } else {
      return null;
    }
  }

  function returnSavedAlbums() {
    if (saved) {
      return (
        <div>
          <div>Albums saved</div>
          <div>
            {albums.map((album, i) => (
              <div key={i}>
                <Link to={`/album/${album._id.toString()}`}>{album.title}</Link>
              </div>
            ))}
          </div>
          <br />
          <Link
            to={`/recordings/update?albumId=${albums
              .map(album => album._id.toString())
              .join(",")}`}
          >
            Edit albums' recordings
          </Link>
        </div>
      );
    } else {
      return null;
    }
  }

  async function saveAlbum() {
    const newAlbums = [...albums];
    newAlbums.map(album => {
      album.instruments = addInstruments(album.performers);
      album.durationMs = addDurationMs(album.tracks);
      const newAlbum = { ...album };
      newAlbum.composers.map(composer => delete composer.idSpotify);
      newAlbum.performers.map(performer => delete performer.idSpotify);
      newAlbum.ensembles.map(ensemble => delete ensemble.idSpotify);
      return newAlbum;
    });

    const newRecordings = [...recordings];
    newRecordings.map(recording => {
      recording.instruments = addInstruments(recording.performers);
      const newRecording = { ...recording };
      newRecording.composers.map(composer => delete composer.idSpotify);
      newRecording.performers.map(performer => delete performer.idSpotify);
      newRecording.ensembles.map(ensemble => delete ensemble.idSpotify);
      return newRecording;
    });

    await insertRecordings(newRecordings);
    await insertAlbums(newAlbums);
    setSaved(true);
  }

  async function insertRecordings(newRecordings) {
    const body = JSON.stringify(newRecordings);
    try {
      const resp = await fetch("/api/recordings/insert", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body
      });
      if (resp.status === 201) {
        handleShowMessageBox("Recordings inserted", 3000);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function insertAlbums(newAlbums) {
    const body = JSON.stringify(newAlbums);
    try {
      const resp = await fetch("/api/albums/insert", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body
      });
      // const respJSON = await resp.json();
      if (resp.status === 201) {
        handleShowMessageBox("Albums inserted", 3000);
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export default withRouter(EditPiece);
