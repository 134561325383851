import React from "react";
import InsertMovement from "./InsertMovement";
import "./css/Edit.css";

export default function EditMovements(props) {
  const { piece, upsertPiece, reload, handleShowMessageBox } = props;

  return (
    <div className="Edit-sections">
      <div className="Edit-section-left">
        <InsertMovement
          piece={piece}
          upsertPiece={upsertPiece}
          reload={reload}
        />
      </div>
      {showMovements()}
    </div>
  );

  function showMovements() {
    if (piece.movements && piece.movements.length > 0) {
      return (
        <div className="Edit-input-container">
          <h2>Existing movements</h2>
          {piece.movements.map((movement, i) => {
            return (
              <div key={i}>
                <div className="Edit-input-container">
                  <label>{`#${i + 1}`}</label>
                  <input
                    type="text"
                    placeholder="Title"
                    className="Edit-input"
                    value={movement.title}
                    onChange={e => {
                      const newMovementsInParent = [...piece.movements];
                      newMovementsInParent[i].title = e.currentTarget.value;
                    }}
                  />
                </div>
                <button
                  onClick={() => {
                    upsertPiece(piece._id, { movements: piece.movements }, () =>
                      handleShowMessageBox("Updated", 1000)
                    );
                  }}
                >
                  Update
                </button>
                <span> | </span>
                <a
                  href={`/piece/${movement.id}/edit`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Edit
                </a>
                <span> | </span>
                <button
                  onClick={e => {
                    e.preventDefault();
                    removeMovement(i);
                  }}
                >
                  {" "}
                  Remove
                </button>
              </div>
            );
          })}
        </div>
      );
    } else {
      return null;
    }
  }

  function removeMovement(i) {
    if (window.confirm("Delete piece ?")) {
      const newMovements = [...piece.movements];
      newMovements.splice(i, 1);

      upsertPiece(piece._id, { movements: newMovements }, async () => {
        try {
          const resp = await fetch(
            "/api/pieces/" + piece.movements[i].id + "/delete",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              }
            }
          );
          if (resp.status === 200) {
            reload();
          }
        } catch (err) {
          console.log(err);
        }
      });
    }
  }
}
