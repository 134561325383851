import React from "react";
import Select from "./Select";

export default function EditTypes(props) {
  const { types, setTypes } = props;

  return (
    <div className="Edit-input-container">
      <label>Type</label>
      <Select
        placeholder="Type"
        options={[
          "Orchestra",
          "Symphony Orchestra",
          "Chamber Orchestra",
          "Broadcast Orchestra",
          "Baroque Orchestra",
          "Period Instrument Orchestra",
          "String Quartet",
          "Chamber Choir",
          "Baroque Choir",
          "Renaissance Choir",
          "Choir",
          "Piano Trio",
          "Vocal Ensemble",
          "Baroque Ensemble",
          "Early Music Ensemble"
        ]}
        isSearchable={true}
        isMultiple={true}
        value={types}
        onClear={e => setTypes([])}
        onChange={e => {
          setTypes(e);
        }}
      />
    </div>
  );
}
