import React from "react";

export default function ArtistExternalLinks(props) {
  const { type, externalLinks } = props;
  const list = [];

  if (type === "piece") {
    link("allmusic", "Allmusic", "https://www.allmusic.com/composition/");
    link("imslp", "IMSLP", "https://imslp.org/wiki/");
    link("musicBrainz", "MusicBrainz", "https://musicbrainz.org/work/");
  } else {
    link("spotify", "Spotify", "https://open.spotify.com/artist/");
    link("discogs", "Discogs", "https://www.discogs.com/artist/");
    link("allmusic", "Allmusic", "https://www.allmusic.com/artist/");
    link("musicBrainz", "MusicBrainz", "https://musicbrainz.org/artist/");
  }

  link("cpdl", "CPDL", "http://www1.cpdl.org/wiki/index.php/");
  link("wikipedia", "Wikipedia", "https://en.wikipedia.org/wiki/");

  function renderList(list) {
    return list.map(function(item, i) {
      return (
        <span key={i}>
          {item}
          {i + 1 < list.length ? <br /> : ""}
        </span>
      );
    });
  }

  function link(key, name, link) {
    if (externalLinks[key]) {
      list.push(
        <a
          href={link + externalLinks[key]}
          target="_blank"
          rel="noopener noreferrer"
          className="link-blue"
        >
          {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16"><path fill="none" d="M0 0h24v24H0z"/><path d="M21.543 6.498C22 8.28 22 12 22 12s0 3.72-.457 5.502c-.254.985-.997 1.76-1.938 2.022C17.896 20 12 20 12 20s-5.893 0-7.605-.476c-.945-.266-1.687-1.04-1.938-2.022C2 15.72 2 12 2 12s0-3.72.457-5.502c.254-.985.997-1.76 1.938-2.022C6.107 4 12 4 12 4s5.896 0 7.605.476c.945.266 1.687 1.04 1.938 2.022zM10 15.5l6-3.5-6-3.5v7z" fill="rgba(255,255,255,1)"/></svg> */}
          {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 2C6.477 2 2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.879V14.89h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.989C18.343 21.129 22 16.99 22 12c0-5.523-4.477-10-10-10z" fill="rgba(255,255,255,1)"/></svg> */}
          {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16"><path fill="none" d="M0 0h24v24H0z"/><path d="M22.162 5.656a8.384 8.384 0 0 1-2.402.658A4.196 4.196 0 0 0 21.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 0 0-7.126 3.814 11.874 11.874 0 0 1-8.62-4.37 4.168 4.168 0 0 0-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 0 1-1.894-.523v.052a4.185 4.185 0 0 0 3.355 4.101 4.21 4.21 0 0 1-1.89.072A4.185 4.185 0 0 0 7.97 16.65a8.394 8.394 0 0 1-6.191 1.732 11.83 11.83 0 0 0 6.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.496 8.496 0 0 0 2.087-2.165z" fill="rgba(255,255,255,1)"/></svg> */}
          <span>{name}</span>
        </a>
      );
    }
  }

  // function linkSpotify(idSpotify) {
  //   if (idSpotify) {
  //     list.push(
  //       <a
  //         href={"https://open.spotify.com/artist/" + idSpotify}
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         <span>Spotify</span>
  //       </a>
  //     );
  //   }
  // }

  if (externalLinks) {
    return <div>{renderList(list)}</div>;
  } else {
    return null;
  }
}
