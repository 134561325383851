function createTitle(piece) {
  let title = "";
  if (piece.parent) {
    title += piece.parent.title + ": ";
    title += piece.movementTitle;
  }
  return title;
}

// Construct and return the searchTitle of a piece
function createSearchTitle(piece) {
  let searchTitle = "";
  if (piece.title) {
    searchTitle +=
      piece.composers && piece.composers.length > 0
        ? piece.composers[0].name + ": "
        : "";
    searchTitle += piece.title;
  }
  return searchTitle;
}

export { createTitle, createSearchTitle };
