import React, { useState } from "react";
import ReviewEditRating from "./ReviewEditRating.js";
import "./css/CreateReview.css";

export default function EditReview(props) {
  const [rating, setRating] = useState(
    props.userReview ? props.userReview.rating : null
  );
  const [text, setText] = useState(
    props.userReview ? props.userReview.text : null
  );

  return (
    <div>
      <form className="CreateReview-form" onSubmit={e => handleSubmit(e)}>
        <ReviewEditRating setRating={setRating} rating={rating} />
        <textarea
          placeholder="Add a review"
          onChange={e => setText(e.currentTarget.value)}
          defaultValue={text}
        />
        <div className="CreateReview-buttons">
          <button className="CreateReview-button" type="submit">
            Publish
          </button>
          <button
            type="button"
            className="CreateReview-button"
            onClick={() => {
              props.setShowEditReview(false);
            }}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );

  function handleSubmit(e) {
    e.preventDefault();
    if (props.userReview) {
      editReview();
    } else {
      postReview();
    }
  }

  async function postReview() {
    try {
      const resp = await fetch("/api/reviews", {
        method: "POST",
        body: JSON.stringify({
          rating: rating,
          text: text,
          album: { id: props.album._id, title: props.album.title },
          user: { id: props.user._id, name: props.user.username }
        }),
        headers: {
          "Content-Type": "application/json"
        }
      });
      const respJSON = await resp.json();
      if (resp.status === 200) {
        props.setShowEditReview(false);
        props.setUserReview(respJSON.userReview);
        props.fetchAlbumReviews(props.album._id);
        updateAlbumUsersRating(respJSON.usersRating);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function editReview() {
    try {
      const resp = await fetch(
        "/api/reviews/" + props.userReview._id + "/edit",
        {
          method: "POST",
          body: JSON.stringify({
            rating: rating,
            text: text,
            user: { id: props.user._id, name: props.user.username }
          }),
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      const respJSON = await resp.json();
      if (resp.status === 200) {
        console.log(respJSON)
        props.setShowEditReview(false);
        props.setUserReview(respJSON.userReview);
        props.fetchAlbumReviews(props.album._id);
        updateAlbumUsersRating(respJSON.usersRating);
      }
    } catch (err) {
      console.log(err);
    }
  }

  function updateAlbumUsersRating(usersRating) {
    let newAlbum = Object.assign({}, props.album);
    newAlbum.usersRating = usersRating;
    props.setAlbum(newAlbum);
  }
}
