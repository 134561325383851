import React from "react";
import { Link } from "react-router-dom";
import { convertDateWithPrecision } from "./helpers/helpers.js";
import ExpandableList from "./ExpandableList";
import ArtistAuthorities from "./ArtistAuthorities";
import ArtistExternalLinks from "./ArtistExternalLinks";
import "./css/ArtistMoreInfo.css";

export default function ArtistMoreInfo(props) {
  const { artist, user } = props;

  return <div className="ArtistMoreInfo">{renderArtistInformation()}</div>;

  function renderArtistInformation() {
    return (
      <div className="ArtistMoreInfo-container">
        {renderBirth(artist)}
        {renderBaptism(artist)}
        {renderDeath(artist)}
        {renderExternalLinks(artist)}
        {renderMemberOf(artist)}
        {renderNameNativeLanguage(artist)}
        {renderNameOtherLanguages(artist)}
        {renderAliases(artist)}
        {renderAuthorities()}
        {user && user.user && user.user.admin ? renderEdit(artist) : null}
      </div>
    );
  }

  function renderMemberOf(artist, memberType, memberTypeName) {
    if (artist && artist.memberOf && artist.memberOf.length > 0) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">Member of</div>
          <div className="ArtistMoreInfo-column-right">
            {artist.memberOf.map((ensemble, i) => {
              return (
                <div key={i}>
                  {ensemble.id ? (
                    <Link
                      to={"/ensemble/" + ensemble.id}
                      className="ArtistMoreInfo-link"
                    >
                      {ensemble.name}
                    </Link>
                  ) : (
                    ensemble.name
                  )}
                  <span className="ArtistMoreInfo-grey">
                    {ensemble.text ? ` (${ensemble.text})` : null}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  }

  // function renderMemberOf() {
  //   if (artist && artist.memberOf && artist.memberOf.length > 0)
  //     return (
  //       <div className="ArtistMoreInfo-container">
  //         <div className="ArtistMoreInfo-title">Member of</div>
  //         <div>
  //           {artist.memberOf.map((artist, i) => {
  //             return (
  //               <div key={i} className="ArtistMoreInfo-row">
  //                 {artist.id ? (
  //                   <div>
  //                     <Link
  //                       to={"/artist/" + artist.id}
  //                       className="ArtistMoreInfo-link"
  //                     >
  //                       {artist.name}
  //                     </Link>
  //                   </div>
  //                 ) : (
  //                   artist.name
  //                 )}
  //                 <span className="ArtistMoreInfo-grey">
  //                   {artist.text ? ` (${artist.text})` : null}
  //                 </span>
  //               </div>
  //             );
  //           })}
  //         </div>
  //       </div>
  //     );
  //   else return null;
  // }

  function renderNameNativeLanguage(artist) {
    if (artist.nameNativeLanguage) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">{`Name in native language (${artist.nameNativeLanguage.language})`}</div>
          <div className="ArtistMoreInfo-column-right">
            {artist.nameNativeLanguage.name}
          </div>
        </div>
      );
    }
  }

  function renderAuthorities(artist) {
    if (artist && artist.authorities) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">Authorities</div>
          <div className="ArtistMoreInfo-column-right">
            <ArtistAuthorities authorities={artist.authorities} />
          </div>
        </div>
      );
    }
  }

  function renderBirth(artist) {
    if (artist.dateOfBirth) {
      console.log(artist);
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">Birth</div>
          <div className="ArtistMoreInfo-column-right">
            {artist.dateOfBirth
              ? convertDateWithPrecision(
                  artist.dateOfBirth.time,
                  artist.dateOfBirth.precision
                )
              : null}
            {artist.dateOfBirth && artist.placeOfBirth ? ", " : null}
            {artist.placeOfBirth ? artist.placeOfBirth.name : null}
            {artist.placeOfBirth && artist.placeOfBirth.country
              ? ", " + artist.placeOfBirth.country.name
              : null}
          </div>
        </div>
      );
    }
  }

  function renderBaptism(artist) {
    if (artist.dateOfBaptism && !artist.dateOfBirth) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">Baptism</div>
          <div className="ArtistMoreInfo-column-right">
            {artist.dateOfBaptism
              ? convertDateWithPrecision(
                  artist.dateOfBaptism.time,
                  artist.dateOfBaptism.precision
                )
              : null}
          </div>
        </div>
      );
    }
  }

  function renderDeath(artist) {
    if (artist.dateOfDeath) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">Death</div>
          <div className="ArtistMoreInfo-column-right">
            {artist.dateOfDeath
              ? convertDateWithPrecision(
                  artist.dateOfDeath.time,
                  artist.dateOfDeath.precision
                )
              : null}
            {artist.dateOfDeath && artist.placeOfDeath ? ", " : null}
            {artist.placeOfDeath ? artist.placeOfDeath.name : null}
            {artist.placeOfDeath && artist.placeOfDeath.country
              ? ", " + artist.placeOfDeath.country.name
              : null}
          </div>
        </div>
      );
    }
  }

  function renderExternalLinks(artist) {
    if (
      artist.externalLinks &&
      !(
        Object.keys(artist.externalLinks).length < 2 &&
        (artist.externalLinks.facebook ||
          artist.externalLinks.twitter ||
          artist.externalLinks.officialWebsite)
      )
    ) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">External links</div>
          <div className="ArtistMoreInfo-column-right">
            <ArtistExternalLinks
              externalLinks={artist.externalLinks}
              wikipedia={artist.wikipedia}
            />
          </div>
        </div>
      );
    }
  }

  function renderNameOtherLanguages(artist) {
    if (artist.namesList && artist.namesList.length > 1) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">
            Name in other languages
          </div>
          <div className="ArtistMoreInfo-column-right">
            <ExpandableList list={artist.namesList} />
          </div>
        </div>
      );
    }
  }

  function renderAliases(artist) {
    if (artist.aliasesList && artist.aliasesList.length > 0) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">Aliases</div>
          <div className="ArtistMoreInfo-column-right">
            <ExpandableList list={artist.aliasesList} />
          </div>
        </div>
      );
    }
  }

  function renderEdit(artist) {
    return (
      <div className="ArtistMoreInfo-row">
        <div className="ArtistMoreInfo-column-left">Edit</div>
        <div className="ArtistMoreInfo-column-right">
          <Link to={`/artist/${artist._id}/edit`}>Edit this artist</Link>
        </div>
      </div>
    );
  }
}
