import querystring from "querystring";
import React, { useEffect, useState } from "react";
import Select from "./Select.js";
import "./css/Filters.css";
import { convertQueryStringToArray } from "./helpers/helpers.js";

export default function Filter(props) {
  const [options, setOptions] = useState([]);
  const {
    apiURL,
    queryString,
    queryParameter,
    history,
    queryIds,
    isSearchable,
    isMultiple,
    name
  } = props;

  useEffect(() => {
    async function fetchOptions() {
      try {
        const resp = await fetch(apiURL + queryString);
        if (resp.status !== 401) {
          const result = await resp.json();
          if (result)
            setOptions(
              result
                .filter((v, i, a) => a.indexOf(v) === i) // remove duplicates
                .sort()
            );
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchOptions();
  }, [apiURL, queryString]);

  if (options.length > 1 || props.queryIds[props.queryParameter]) {
    const value = props.queryIds[queryParameter];
    return (
      <Select
        placeholder={name}
        options={options}
        isSearchable={isSearchable}
        isMultiple={isMultiple}
        value={isMultiple ? (value ? value.split("_") : null) : value}
        onClear={clearFilter}
        onChange={redirect}
      />
    );
  } else {
    return null;
    // return <ButtonFilterEmpty name={props.name} key={props.name} />;
  }

  function clearFilter() {
    let queryAsObject = querystring.parse(queryString.substring(1));
    delete queryAsObject[queryParameter];
    delete queryAsObject["artistId"];
    delete queryAsObject["pieceId"];
    delete queryAsObject["composerId"];
    delete queryAsObject["groupId"];
    delete queryAsObject["labelId"];
    delete queryAsObject["ensembleId"];
    delete queryAsObject["isComposer"];
    delete queryAsObject["eraId"];
    delete queryAsObject["instrumentId"];
    delete queryAsObject["categoryId"];
    delete queryAsObject["catalogueId"];
    delete queryAsObject["favorites"];
    delete queryAsObject["update"];
    const queryAsString = "?" + querystring.stringify(queryAsObject);
    history.push(queryAsString);
  }

  function redirect(selectedOption) {
    let newQueryIds = Object.assign({}, queryIds);
    if (selectedOption) {
      if (!isMultiple) {
        newQueryIds[queryParameter] = selectedOption;
      } else {
        // Query parameter is already in query url (e.g. 'instruments')
        if (queryIds.hasOwnProperty(queryParameter)) {
          // Query parameter is already in query url (e.g. 'instruments')
          // Selected option already exists
          if (queryIds[queryParameter].includes(selectedOption)) {
            // -> check if other options already exist
            const fieldAsArray = convertQueryStringToArray(
              newQueryIds[queryParameter]
            );

            // Query parameter is already in query url (e.g. 'instruments')
            // Selected option already exists
            // Query parameter include other options
            if (fieldAsArray.length > 1) {
              // -> remove the selected option
              // fieldAsArray.splice(fieldAsArray.indexOf(selectedOption), 1);
              newQueryIds[queryParameter] = selectedOption.join("_");

              // Query parameter is already in query url (e.g. 'instruments')
              // Selected option already exists
              // Query parameter does not include other options (selected option is the only one)
            } else {
              // -> remove the full query parameter
              delete newQueryIds[queryParameter];
            }
            // Query parameter is already in query url (e.g. 'instruments')
            // Selected option does not exist yet
          } else {
            // -> add selected option to existing query parameter
            newQueryIds[queryParameter] = selectedOption.join("_");
            // newQueryIds[queryParameter] + "_" + selectedOption;
          }
          // Query parameter is not in query url
        } else {
          // -> add it to the query url with the selected option
          newQueryIds[queryParameter] = selectedOption;
        }
      }
    }

    delete newQueryIds["artistId"];
    delete newQueryIds["pieceId"];
    delete newQueryIds["composerId"];
    delete newQueryIds["groupId"];
    delete newQueryIds["labelId"];
    delete newQueryIds["ensembleId"];
    delete newQueryIds["isComposer"];
    delete newQueryIds["eraId"];
    delete newQueryIds["instrumentId"];
    delete newQueryIds["categoryId"];
    delete newQueryIds["catalogueId"];
    delete newQueryIds["favorites"];
    delete newQueryIds["update"];
    history.push("?" + querystring.stringify(newQueryIds));
  }
}

export function ButtonFilterEmpty(props) {
  return (
    <div className="SelectInput SelectInput-empty">
      {/* <span className="SelectInput-filter-name hidden-desktop">
        {props.name}
      </span> */}
      <div className="hidden-mobile">{props.name}</div>
      <div className="SelectInput-icon-container">
        <svg
          className="SelectInput-icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          width="16"
          height="16"
        >
          <path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z" />
        </svg>
      </div>
    </div>
  );
}
