import React from "react";
import "./css/Review.css";
import { convertDate } from "./helpers/helpers.js";

export default function Review(props) {
  const { pressReview, fetchAlbumPressReviews, showMessage, album } = props;

  return (
    <div className="Review">
      <div className="Review-subheader">
        <div className="Review-user">{pressReview.magazine}</div>
        <div className="Review-date">{convertDate(pressReview.date)}</div>
        {album.edit ? (
          <div className="Review-header-more" onClick={() => deleteReview()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="16"
              height="16"
              className="Review-icon"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm1 2H6v12h12V8zm-9 3h2v6H9v-6zm4 0h2v6h-2v-6zM9 4v2h6V4H9z" />
            </svg>
          </div>
        ) : null}
      </div>
      <div className="Review-header">
        {pressReview.rating ? renderRating() : null}
      </div>
      <div className="Review-text">{pressReview.review}</div>
      {pressReview.link ? (
        <div className="Review-link">
          <a href={pressReview.link} target="_blank" rel="noopener noreferrer">
            Read the full review
          </a>
        </div>
      ) : null}
    </div>
  );

  async function deleteReview() {
    try {
      const resp = await fetch(
        "/api/pressReviews/" + pressReview._id + "/delete",
        {
          method: "POST"
        }
      );
      if (resp.status === 200) {
        fetchAlbumPressReviews();
        showMessage("Press review deleted");
      }
    } catch (err) {
      console.log(err);
    }
  }

  function renderRating() {
    return (
      <div className="Review-rating-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 512 512"
        >
          <path
            d="M480,208H308L256,48,204,208H32l140,96L118,464,256,364,394,464,340,304Z"
            className="Review-rating-icon"
          />
        </svg>
        <div className="Review-rating">
          <span className="Review-rating-1">{pressReview.rating}</span>
          <span className="Review-rating-2">/{pressReview.ratingScale}</span>
        </div>
      </div>
    );
  }
}
