import React from "react";

export default function EditAward(props) {
  const { removeAward, i, award } = props;

  return (
    <div key={i}>
      {`${i + 1}. ${award.name} ${award.date ? award.date : ""}`}
      <button
        className="EditSearch-button"
        onClick={() => removeAward(i)}
        type="button"
      >
        Remove
      </button>
    </div>
  );
}
