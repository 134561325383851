import React from "react";
import "./css/NumberAlbums.css";

export default function NumberAlbums(props) {
  return (
    <div className="NumberAlbums">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="16"
        height="16"
        className="NumberAlbums-icon"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zm0 14c2.213 0 4-1.787 4-4s-1.787-4-4-4-4 1.787-4 4 1.787 4 4 4zm0-5c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1z" />
      </svg>
      {props.numberAlbums}
    </div>
  );
}
