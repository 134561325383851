import React, { useState } from "react";
import EditPressReviewWindow from "./EditPressReviewWindow";
import PressReview from "./PressReview";
import "./css/Reviews.css";

export default function PressReviews(props) {
  const {
    album,
    pressReviews,
    handleShowMessageBox,
    fetchAlbumPressReviews
  } = props;
  const [showAddReview, setShowAddReview] = useState(false);

  if (pressReviews) {
    return (
      <div>
        {returnPressReviews()}
        {showAddReview ? (
          <EditPressReviewWindow
            album={album}
            setShowAddReview={setShowAddReview}
            showMessage={showMessage}
            fetchAlbumPressReviews={fetchAlbumPressReviews}
          />
        ) : null}
        {album.edit ? (
          <div className="Reviews-links">
            <span
              className="Reviews-links-item"
              onClick={() => setShowAddReview(true)}
            >
              Add press review
            </span>
          </div>
        ) : null}
      </div>
    );
  } else {
    return null;
  }

  function returnPressReviews() {
    return pressReviews.map((pressReview, i) => {
      return (
        <PressReview
          album={album}
          key={i}
          pressReview={pressReview}
          fetchAlbumPressReviews={fetchAlbumPressReviews}
          showMessage={showMessage}
        />
      );
    });
  }

  function showMessage(message) {
    handleShowMessageBox(message, 4000);
  }
}
