import React, { useState, useEffect } from "react";
import EditText from "./EditText";
import "./css/Edit.css";

export default function EditArtist(props) {
  const { artist, onSubmit } = props;
  const [name, setName] = useState("");
  const [updatedArtist, setUpdatedArtist] = useState({});
  console.log(updatedArtist);
  // Default value
  useEffect(() => {
    if (artist.name) setName(artist.name);
    else setName("");
  }, [artist]);

  // Update artist when input change
  useEffect(() => {
    const newArtist = {};
    if (name) newArtist.name = name;
    setUpdatedArtist(newArtist);
  }, [name]);

  return (
    <div>
      <form
        onSubmit={e => {
          e.preventDefault();
          onSubmit(updatedArtist);
        }}
      >
        <EditText value={name} setValue={setName} placeholder="Name" />
        <button className="Button Edit-button">Update</button>
      </form>
    </div>
  );
}
