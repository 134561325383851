import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Frame from "./Frame.js";
import Navbar from "./Navbar.js";
import InfiniteScrolling from "./InfiniteScrolling.js";
import "./css/LabelsTable.css";
import "./css/Sidebar.css";

export default function Labels(props) {
  const [labels, setLabels] = useState([]);
  const [newLabels, setNewLabels] = useState([]);
  const [labelsCounter, setLabelsCounter] = useState(0);

  async function fetchLabels(limit, page) {
    try {
      let url = `/api/labels?limit=${limit}&page=${page}`;
      const resp = await fetch(url);
      console.log(resp.status)
      if (resp.status !== 401) {
        const newLabels = await resp.json();
        const newLabelsCounter = resp.headers.get("Result-Count");
        setLabelsCounter(newLabelsCounter);
        setNewLabels(newLabels);
      }
    } catch (err) {
      console.log(err);
    }
  }

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevNewLabels = usePrevious(newLabels);

  // Show first items
  useEffect(() => {
    if (labels.length === 0 && newLabels.length !== 0) setLabels(newLabels);
  }, [newLabels, labels]);

  // add when changes
  useEffect(() => {
    if (newLabels !== prevNewLabels) {
      setLabels(labels.concat(newLabels));
    }
  }, [newLabels, prevNewLabels, labels]);

  // Reset values when queryStrings changes
  useEffect(() => {
    setLabels([]);
    setNewLabels([]);
    setLabelsCounter(0);
    async function fetchFirstLabels() {
      try {
        let url = `/api/labels?limit=50&page=1`;
        const resp = await fetch(url);
        if (resp.status !== 401) {
          const newLabels = await resp.json();
          const newLabelsCounter = resp.headers.get("Result-Count");
          setLabelsCounter(newLabelsCounter);
          setNewLabels(newLabels);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchFirstLabels();
  }, []);

  return (
    <Frame>
      <div className="Labels-content">
        <Navbar />
        <InfiniteScrolling
          items={labels}
          counter={labelsCounter}
          limit={50}
          queryString={null}
          onScrollBottom={(limit, page) => {
            fetchLabels(limit, page);
          }}
        >
          <div className="LabelsTable">
            {labels.map((label, i) => (
              <div key={i} className="LabelsTable-table-row">
                <Link
                  className="LabelsTable-table-data-name"
                  to={"/label/" + label._id}
                >
                  {label.name}
                </Link>
              </div>
            ))}
          </div>
        </InfiniteScrolling>
      </div>
    </Frame>
  );
}
