import React from "react";
import GenreHeaderNavigation from "./GenreHeaderNavigation.js";
import "./css/Header.css";

export default function ArtistHeader(props) {
  const { genre, page } = props;
  if (genre) {
    return (
      <div className="Header">
        <div className="Header-big-title">{genre}</div>
        <GenreHeaderNavigation genre={genre} page={page} />
      </div>
    );
  } else {
    return null;
  }
}
