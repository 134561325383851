import React from "react";
import "./css/OfficialLinks.css";
// import fb from "./fb.png";
// import instagram from "./instagram.png";

export default function OfficialLinks(props) {
  if (props.externalLinks) {
    return (
      <div className="OfficialLinks">
        {props.externalLinks.officialWebsite
          ? renderWebsite(props.externalLinks.officialWebsite)
          : null}
        {props.externalLinks.facebook
          ? renderFacebook(props.externalLinks.facebook)
          : null}
        {props.externalLinks.twitter
          ? renderTwitter(props.externalLinks.twitter)
          : null}
        {props.externalLinks.instagram
          ? renderInstagram(props.externalLinks.instagram)
          : null}
        {props.externalLinks.youtube
          ? renderYoutube(props.externalLinks.youtube, false)
          : null}
        {props.externalLinks.youtubeChannel
          ? renderYoutube(props.externalLinks.youtubeChannel, true)
          : null}
      </div>
    );
  } else {
    return null;
  }

  function renderWebsite(id) {
    return (
      <a
        className="OfficialLinks-link"
        href={id}
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="20"
          height="20"
          className="OfficialLinks-link-icon"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-2.29-2.333A17.9 17.9 0 0 1 8.027 13H4.062a8.008 8.008 0 0 0 5.648 6.667zM10.03 13c.151 2.439.848 4.73 1.97 6.752A15.905 15.905 0 0 0 13.97 13h-3.94zm9.908 0h-3.965a17.9 17.9 0 0 1-1.683 6.667A8.008 8.008 0 0 0 19.938 13zM4.062 11h3.965A17.9 17.9 0 0 1 9.71 4.333 8.008 8.008 0 0 0 4.062 11zm5.969 0h3.938A15.905 15.905 0 0 0 12 4.248 15.905 15.905 0 0 0 10.03 11zm4.259-6.667A17.9 17.9 0 0 1 15.973 11h3.965a8.008 8.008 0 0 0-5.648-6.667z"
            className="OfficialLinks-link-icon"
          />
        </svg>
        <span className="OfficialLinks-link-text">Official website</span>
      </a>
    );
  }

  function renderFacebook(id) {
    return (
      <a
        className="OfficialLinks-link"
        href={"https://www.facebook.com/" + id}
        target="_blank"
        rel="noopener noreferrer"
      >
        {/* <img
          src={fb}
          className="OfficialLinks-link-icon"
          width="18px"
          alt="spotify"
        /> */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="20"
          height="20"
          className="OfficialLinks-link-icon"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M12 2C6.477 2 2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.879V14.89h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.989C18.343 21.129 22 16.99 22 12c0-5.523-4.477-10-10-10z"
            className="OfficialLinks-link-icon"
          />
        </svg>
        <span className="OfficialLinks-link-text">Facebook</span>
        {/* <span className="OfficialLinks-link-text">{id}</span> */}
      </a>
    );
  }

  function renderTwitter(id) {
    return (
      <a
        className="OfficialLinks-link"
        href={"https://twitter.com/" + id}
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="20"
          height="20"
          className="OfficialLinks-link-icon"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M22.162 5.656a8.384 8.384 0 0 1-2.402.658A4.196 4.196 0 0 0 21.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 0 0-7.126 3.814 11.874 11.874 0 0 1-8.62-4.37 4.168 4.168 0 0 0-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 0 1-1.894-.523v.052a4.185 4.185 0 0 0 3.355 4.101 4.21 4.21 0 0 1-1.89.072A4.185 4.185 0 0 0 7.97 16.65a8.394 8.394 0 0 1-6.191 1.732 11.83 11.83 0 0 0 6.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.496 8.496 0 0 0 2.087-2.165z"
            className="OfficialLinks-link-icon"
          />
        </svg>
        {/* <span className="OfficialLinks-link-text">@{id}</span> */}
        <span className="OfficialLinks-link-text">Twitter</span>
      </a>
    );
  }

  function renderInstagram(id) {
    return (
      <a
        className="OfficialLinks-link"
        href={"https://instagram.com/" + id}
        target="_blank"
        rel="noopener noreferrer"
      >
        {/* <img
          src={instagram}
          className="OfficialLinks-link-icon"
          width="18px"
          alt="spotify"
        /> */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="20"
          height="20"
          className="OfficialLinks-link-icon"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm0-2a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm6.5-.25a1.25 1.25 0 0 1-2.5 0 1.25 1.25 0 0 1 2.5 0zM12 4c-2.474 0-2.878.007-4.029.058-.784.037-1.31.142-1.798.332-.434.168-.747.369-1.08.703a2.89 2.89 0 0 0-.704 1.08c-.19.49-.295 1.015-.331 1.798C4.006 9.075 4 9.461 4 12c0 2.474.007 2.878.058 4.029.037.783.142 1.31.331 1.797.17.435.37.748.702 1.08.337.336.65.537 1.08.703.494.191 1.02.297 1.8.333C9.075 19.994 9.461 20 12 20c2.474 0 2.878-.007 4.029-.058.782-.037 1.309-.142 1.797-.331.433-.169.748-.37 1.08-.702.337-.337.538-.65.704-1.08.19-.493.296-1.02.332-1.8.052-1.104.058-1.49.058-4.029 0-2.474-.007-2.878-.058-4.029-.037-.782-.142-1.31-.332-1.798a2.911 2.911 0 0 0-.703-1.08 2.884 2.884 0 0 0-1.08-.704c-.49-.19-1.016-.295-1.798-.331C14.925 4.006 14.539 4 12 4zm0-2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2z"
            className="OfficialLinks-link-icon"
          />
        </svg>
        {/* <span className="OfficialLinks-link-text">{id}</span> */}
        <span className="OfficialLinks-link-text">Instagram</span>
      </a>
    );
  }

  function renderYoutube(id, channel=false) {
    return (
      <a
        className="OfficialLinks-link"
        href={channel ? "https://www.youtube.com/channel/" + id : "https://www.youtube.com/user/" + id}
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="20"
          height="20"
          className="OfficialLinks-link-icon"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M21.543 6.498C22 8.28 22 12 22 12s0 3.72-.457 5.502c-.254.985-.997 1.76-1.938 2.022C17.896 20 12 20 12 20s-5.893 0-7.605-.476c-.945-.266-1.687-1.04-1.938-2.022C2 15.72 2 12 2 12s0-3.72.457-5.502c.254-.985.997-1.76 1.938-2.022C6.107 4 12 4 12 4s5.896 0 7.605.476c.945.266 1.687 1.04 1.938 2.022zM10 15.5l6-3.5-6-3.5v7z"
            className="OfficialLinks-link-icon"
          />
        </svg>
        {/* <span className="OfficialLinks-link-text">{id}</span> */}
        <span className="OfficialLinks-link-text">Youtube</span>
      </a>
    );
  }
}
