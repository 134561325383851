import React from "react";
import { convertDateWithPrecision } from "./helpers/helpers.js";
import { Link } from "react-router-dom";
import ExpandableList from "./ExpandableList";
import ArtistAuthorities from "./ArtistAuthorities";
import ArtistExternalLinks from "./ArtistExternalLinks";
import "./css/ArtistMoreInfo.css";
import { convertListToStringWithComma } from "./helpers/helpers";

export default function EnsembleMoreInfo(props) {
  const { ensemble } = props;

  return (
    <div className="ArtistMoreInfo">
      {renderDateOfFoundation(ensemble)}
      {renderDateOfEnd(ensemble)}
      {renderBasedIn(ensemble)}
      {renderType(ensemble)}
      {renderExternalLinks(ensemble)}
      {renderFoundedBy(ensemble)}
      {renderMembers(ensemble, "principalConductors", "Principal conductor")}
      {renderMembers(ensemble, "members", "Members")}
      {renderMembers(ensemble, "artisticDirectors", "Artistic director")}
      {renderMembers(ensemble, "chiefConductors", "Chief conductor")}
      {renderMembers(ensemble, "conductorsLaureate", "Conductor laureate")}
      {renderMembers(ensemble, "choirMasters", "Choir master")}
      {renderMembers(ensemble, "chorusDirectors", "Chorus director")}
      {renderMembers(ensemble, "musicDirectors", "Music director")}
      {renderMembers(ensemble, "artistsInResidence", "Artist in residence")}
      {renderMembers(
        ensemble,
        "principalGuestConductors",
        "Principal guest conductor"
      )}
      {renderOriginalName(ensemble)}
      {renderNameOtherLanguages(ensemble)}
      {renderAliases(ensemble)}
      {renderAuthorities(ensemble)}
    </div>
  );

  function renderOriginalName(ensemble) {
    if (
      ensemble &&
      ensemble.name &&
      ensemble.englishName &&
      ensemble.name !== ensemble.englishName
    ) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">
            Name in native language
          </div>
          <div className="ArtistMoreInfo-column-right ArtistMoreInfo-pre">
            {ensemble.name}
          </div>
        </div>
      );
    }
  }

  function renderType(ensemble) {
    if (ensemble && ensemble.type && ensemble.type.length > 0) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">Type</div>
          <div className="ArtistMoreInfo-column-right">
            {convertListToStringWithComma(ensemble.type)}
          </div>
        </div>
      );
    }
  }

  function renderMembers(ensemble, memberType, memberTypeName) {
    if (ensemble[memberType] && ensemble[memberType].length > 0) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">
            {memberTypeName || "Members"}
          </div>
          <div className="ArtistMoreInfo-column-right">
            {ensemble[memberType].map((member, i) => {
              return (
                <div key={i}>
                  <span>
                    {member.id ? (
                      <Link to={"/artist/" + member.id}>{member.name}</Link>
                    ) : (
                      member.name
                    )}{" "}
                    {member.text ? `(${member.text})` : null}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  }

  function renderDateOfFoundation(ensemble) {
    if (ensemble.dateOfFoundation) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">Founded in</div>
          <div className="ArtistMoreInfo-column-right">
            {ensemble.dateOfFoundation
              ? convertDateWithPrecision(
                  ensemble.dateOfFoundation.time,
                  ensemble.dateOfFoundation.precision
                )
              : null}
          </div>
        </div>
      );
    }
  }

  function renderDateOfEnd(ensemble) {
    if (ensemble.dateOfEnd) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">Ended in</div>
          <div className="ArtistMoreInfo-column-right">
            {ensemble.dateOfEnd
              ? convertDateWithPrecision(
                  ensemble.dateOfEnd.time,
                  ensemble.dateOfEnd.precision
                )
              : null}
          </div>
        </div>
      );
    }
  }

  function renderFoundedBy(ensemble) {
    if (ensemble.foundedBy && ensemble.foundedBy.length > 0) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">Founded by</div>
          <div className="ArtistMoreInfo-column-right">
            {ensemble.foundedBy.map(person => (
              <div key={person.id}>
                {person.id ? (
                  <Link to={"/artist/" + person.id}>{person.name}</Link>
                ) : (
                  <span>{person.name}</span>
                )}
              </div>
            ))}
          </div>
        </div>
      );
    }
  }

  function renderBasedIn(ensemble) {
    if (ensemble && ensemble.basedIn) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">Based in</div>
          <div className="ArtistMoreInfo-column-right">
            {ensemble.basedIn.name}
            {ensemble.basedIn.country
              ? ", " + ensemble.basedIn.country.name
              : null}
          </div>
        </div>
      );
    }
  }

  function renderNameOtherLanguages(ensemble) {
    if (ensemble.namesList && ensemble.namesList.length > 1) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">
            Name in other languages
          </div>
          <div className="ArtistMoreInfo-column-right">
            <ExpandableList list={ensemble.namesList} />
          </div>
        </div>
      );
    }
  }

  function renderAliases(ensemble) {
    if (ensemble && ensemble.aliasesList && ensemble.aliasesList.length > 0) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">Aliases</div>
          <div className="ArtistMoreInfo-column-right">
            <ExpandableList list={ensemble.aliasesList} />
          </div>
        </div>
      );
    }
  }

  function renderExternalLinks(ensemble) {
    if (
      ensemble.externalLinks &&
      !(
        Object.keys(ensemble.externalLinks).length < 2 &&
        (ensemble.externalLinks.facebook ||
          ensemble.externalLinks.twitter ||
          ensemble.externalLinks.officialWebsite)
      )
    ) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">External links</div>
          <div className="ArtistMoreInfo-column-right">
            <ArtistExternalLinks
              externalLinks={ensemble.externalLinks}
              wikipedia={ensemble.wikipedia}
            />
          </div>
        </div>
      );
    }
  }

  function renderAuthorities(ensemble) {
    if (ensemble && ensemble.authorities) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">Authorities</div>
          <div className="ArtistMoreInfo-column-right">
            <ArtistAuthorities authorities={ensemble.authorities} />
          </div>
        </div>
      );
    }
  }
}
