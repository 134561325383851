import React from "react";
import './css/MessageBox.css';

export default class MessageBox extends React.Component {

  render() {
    const { message } = this.props;
    if (message) {
      return (
        <div className="MessageBox">
          <div className="MessageBox-text">
            {/* <span>Start playing on </span> */}
            <span className="MessageBox-text-message">{message}</span>
          </div>
        </div>
      )
    } else {
      return null;
    }
  }
}
