import React, { useState } from "react";
import { Link } from "react-router-dom";
import EditRecordingQuick from "./EditRecordingQuick.js";
import "./css/RecordingsTableUpdate.css";

export default function Recording(props) {
  const [showMore, setShowMore] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [matched, setMatched] = useState(false);
  const { recording, i } = props;

  let classRow = "RecordingsTableUpdate-row";
  if (matched) {
    classRow = "RecordingsTableUpdate-row RecordingsTableUpdate-row-green";
  }
  return (
    <div key={recording._id} className={classRow}>
      {renderAlbumCover(recording)}
      <div className="RecordingsTableUpdate-row-right">
        {renderText(recording)}
        {showSearch ? (
          <EditRecordingQuick
            recording={recording}
            callbackAfterClick={() => {
              setMatched(true);
              setShowSearch(false);
              setShowMore(false);
            }}
          />
        ) : null}
        {isSameComposer(recording)}
        <div className="RecordingsTableUpdate-buttons">
          {renderButtonMatch(recording, i)}
          {renderButtonMore(recording)}
          {renderButtonSearch(recording)}
        </div>
      </div>
    </div>
  );

  function isSameComposer(recording) {
    if (recording.composers && recording.composers[0]) {
      const composerRecording = recording.composers[0].id;
      let composerSearchResult = null;
      if (recording.searchResults) {
        if (
          recording.searchResults[0] &&
          recording.searchResults[0].composers
        ) {
          composerSearchResult = recording.searchResults[0].composers[0].id;
        }
      }
      return composerRecording === composerSearchResult;
    } else {
      return true;
    }
  }

  function renderAlbumCover(recording) {
    if (recording && recording.albums && recording.albums.length > 0) {
      return (
        <div className="RecordingsTableUpdate-row-image">
          <img
            width="50px"
            src={`https://d31zararll8aqx.cloudfront.net/albums/300/${recording.albums[0].id}.jpg`}
            alt="cover"
          />
        </div>
      );
    }
  }

  function renderText(recording) {
    if (recording && recording.albums && recording.albums.length > 0) {
      return (
        <div>
          <div>
            <div>Recording title</div>
            <Link to={`/recording/${recording._id}`}>
              {recording.composers && recording.composers.length > 0
                ? recording.composers[0].name + ": "
                : ""}
              {recording.title}
            </Link>
          </div>
          <div>
            {showMore
              ? renderSearchResults(recording)
              : renderSearchResult(recording)}
          </div>
        </div>
      );
    }
  }

  function renderSearchResult(recording) {
    if (recording.searchResults && recording.searchResults.length > 0) {
      return (
        <div>
          <div>Proposed piece</div>
          <Link
            className={
              !isSameComposer(recording) ? "RecordingsTableUpdate-text-red" : ""
            }
            to={`/piece/${recording.searchResults[0]._id}`}
          >
            {recording.searchResults[0].searchTitle}
          </Link>
        </div>
      );
    }
  }

  function renderSearchResults(recording) {
    if (recording.searchResults) {
      return (
        <div>
          <div>Proposed piece</div>
          {recording.searchResults.map((searchResult, i) => {
            return (
              <div className="RecordingsTableUpdate-searchResult">
                <button
                  className="RecordingsTableUpdate-button-small"
                  onClick={() => {
                    handleMatch(recording, i);
                  }}
                >
                  Match ?
                </button>
                <Link to={`/piece/${searchResult._id}`}>
                  {searchResult.searchTitle}
                </Link>
              </div>
            );
          })}
        </div>
      );
    }
  }

  async function handleMatch(recording, indexSearchResult) {
    if (recording.searchResults && recording.searchResults[indexSearchResult]) {
      try {
        const pieces = [];
        const pieceDB = recording.searchResults[indexSearchResult];
        const newPiece = {
          id: pieceDB._id,
          title: pieceDB.title
        };
        if (pieceDB.movements && pieceDB.movements.length > 0) {
          newPiece.isParent = true;
        }
        if (pieceDB.parent) {
          newPiece.isMovement = true;
        }
        pieces.push(newPiece);
        if (pieceDB.parent)
        pieces.push({
          id: pieceDB.parent.id,
          title: pieceDB.parent.title,
          isParent: true
        });
        const newRecording = {};
        newRecording.pieces = pieces;
        if (pieceDB.composers) {
          newRecording.composers = pieceDB.composers;
        }
        const resp = await fetch(`/api/recordings/update/${recording._id}`, {
          method: "POST",
          body: JSON.stringify(newRecording),
          headers: {
            "Content-Type": "application/json"
          }
        });
        if (resp.status === 201) {
          setMatched(true);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  async function handleUnmatch(recording) {
    try {
      const newRecording = { pieces: [] };

      const resp = await fetch(`/api/recordings/update/${recording._id}`, {
        method: "POST",
        body: JSON.stringify(newRecording),
        headers: {
          "Content-Type": "application/json"
        }
      });
      if (resp.status === 201) {
        setMatched(false);
      }
    } catch (err) {
      console.log(err);
    }
  }

  function renderButtonMatch(recording, i) {
    if (!showMore) {
      if (!matched) {
        return (
          <button
            id={i + 1}
            className="Button RecordingsTableUpdate-button"
            onClick={() => handleMatch(recording, 0)}
          >
            Match ?
          </button>
        );
      } else {
        return (
          <div
            className="Button RecordingsTableUpdate-button Button-margin-right"
            onClick={() => handleUnmatch(recording)}
          >
            Unmatch
          </div>
        );
      }
    }
  }

  function renderButtonMore(recording) {
    return (
      <div
        className="Button RecordingsTableUpdate-button RecordingsTableUpdate-button-grey"
        onClick={() => setShowMore(!showMore)}
      >
        More
      </div>
    );
  }

  function renderButtonSearch(recording) {
    return (
      <div
        className="Button RecordingsTableUpdate-button RecordingsTableUpdate-button-grey"
        onClick={() => setShowSearch(!showSearch)}
      >
        Search
      </div>
    );
  }
}
