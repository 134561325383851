import React, { useState, useEffect } from "react";
import EditText from "./EditText";
import EditNationalities from "./EditNationalities";
import EditOccupations from "./EditOccupations";
import EditEras from "./EditEras";
import { setPersonSearchNames, setProfile } from "./helpers/helpers";
import "./css/Edit.css";

export default function PersonToBeAdded(props) {
  const {
    defaultPerson,
    defaultName,
    handleShowMessageBox,
    refetchAlbums,
    setPerson,
    idSpotify,
    setAdditionalDataOptions,
    setType
  } = props;
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [invertedName, setInvertedName] = useState("");
  const [name, setName] = useState("");
  const [occupations, setOccupations] = useState([]);
  const [nationalities, setNationalities] = useState([]);
  const [eras, setEras] = useState([]);

  console.log(defaultPerson);

  useEffect(() => {
    let splitName = "";
    if (defaultPerson && defaultPerson.names && defaultPerson.names.en) {
      splitName = defaultPerson.names.en.split(" ");
      setName(defaultPerson.names.en);
    } else if (defaultName) {
      splitName = defaultName.split(" ");
      setName(defaultName);
    }
    const proposedFirstname = splitName.length > 0 ? splitName[0] : "";
    const proposedLastname = splitName.length > 1 ? splitName[1] : "";
    setFirstname(proposedFirstname);
    setLastname(proposedLastname);
    setInvertedName(`${proposedLastname}, ${proposedFirstname}`);
  }, [defaultPerson, defaultName]);

  return (
    <div>
      <div>Insert new person</div>
      <EditText
        value={firstname}
        setValue={setFirstname}
        placeholder="Firstname"
      />
      <EditText
        value={lastname}
        setValue={setLastname}
        placeholder="Lastname"
      />
      <EditText value={name} setValue={setName} placeholder="Name" />
      <EditText
        value={invertedName}
        setValue={setInvertedName}
        placeholder="Inverted name"
      />
      <EditNationalities
        nationalities={nationalities}
        setNationalities={setNationalities}
      />
      <EditOccupations
        occupations={occupations}
        setOccupations={setOccupations}
      />
      <EditEras eras={eras} setEras={setEras} />
      <button className="Button Edit-button" onClick={() => insertPerson()}>
        Insert
      </button>
    </div>
  );

  async function insertPerson() {
    const newPerson = {
      ...defaultPerson,
      name,
      invertedName,
      firstname,
      lastname,
      nationalities,
      occupations,
      eras
    };
    newPerson.profile = setProfile(newPerson);
    newPerson.searchNames = setPersonSearchNames(newPerson);
    if (newPerson.externalLinks) newPerson.externalLinks.spotify = idSpotify;
    else newPerson.externalLinks = { spotify: idSpotify };

    const body = JSON.stringify(newPerson);
    try {
      const resp = await fetch("/api/artists/insert", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body
      });
      if (resp.status === 201) {
        console.log("inserted");
        handleShowMessageBox("Inserted successfully", 3000);
        setPerson(null);
        setAdditionalDataOptions([]);
        refetchAlbums();
        setType(null);
      }
    } catch (err) {
      console.log(err);
    }
  }
}
