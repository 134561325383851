import React from "react";
import { Link, withRouter } from "react-router-dom";
import "./css/SidebarLinks.css";
import Logo from "./Logo";
// import beta from "./beta.png";

function SidebarLinks(props) {
  const { pathname } = props.location;
  return (
    <div className="SidebarLinks">
      {/* <img className="hidden-mobile" src={beta} alt="cover" style={{position: "absolute", top: 0, left: 0, zIndex: 3000}} width="45px" /> */}
      <Link className="SidebarLinks-logo" to="/" aria-label="logo">
        <Logo />
      </Link>
      <div className="SidebarLinks-links">
        <Link
          className={
            props.location.pathname === "/"
              ? "SidebarLinks-link SidebarLinks-link-active"
              : "SidebarLinks-link"
          }
          to="/"
        >
          <svg
            className="SidebarLinks-link-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="16"
            height="16"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M20 20a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-9H1l10.327-9.388a1 1 0 0 1 1.346 0L23 11h-3v9zm-9-7v6h2v-6h-2z" />
          </svg>
          <span>Home</span>
        </Link>
        <Link
          className={
            props.location.pathname === "/albums" ||
              props.location.pathname === "/composers/popular" ||
              props.location.pathname === "/composers" ||
              props.location.pathname === "/performers" ||
              props.location.pathname === "/genres" ||
              props.location.pathname === "/labels" ||
              props.location.pathname === "/ensembles" ||
              props.location.pathname === "/periods"
              ? "SidebarLinks-link SidebarLinks-link-active"
              : "SidebarLinks-link"
          }
          to="/composers/popular"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className="SidebarLinks-link-icon"
          >
            <path d="M460.9 161H51.1C31.8 161 16 176.8 16 196.1V428c0 19.3 15.8 35.1 35.1 35.1H461c19.3 0 35.1-15.8 35.1-35.1V196.1c-.1-19.3-15.9-35.1-35.2-35.1zM434 133H78c-7.7 0-14-6.3-14-14s6.3-14 14-14h356c7.7 0 14 6.3 14 14s-6.3 14-14 14zM403.2 77H108.8c-7 0-12.8-5.8-12.8-12.8v-2.4c0-7 5.8-12.8 12.8-12.8h294.4c7 0 12.8 5.8 12.8 12.8v2.4c0 7-5.8 12.8-12.8 12.8z" />
          </svg>
          <span>Browse</span>
        </Link>
        <Link
          className={
            props.location.pathname === "/composers/popular"
              ? "SidebarLinks-link-small hidden-mobile SidebarLinks-link-small-active"
              : "SidebarLinks-link-small hidden-mobile"
          }
          to="/composers/popular"
        >
          Composers
        </Link>
        <Link
          className={
            props.location.pathname === "/performers?occupation=Conductor"
              ? "SidebarLinks-link-small hidden-mobile SidebarLinks-link-small-active"
              : "SidebarLinks-link-small hidden-mobile"
          }
          to="/performers?occupation=Conductor"
        >
          Performers
        </Link>
        <Link
          className={
            props.location.pathname === "/works"
              ? "SidebarLinks-link-small hidden-mobile SidebarLinks-link-small-active"
              : "SidebarLinks-link-small hidden-mobile"
          }
          to="/works"
        >
          Works
        </Link>
        <Link
          className={
            props.location.pathname === "/ensembles"
              ? "SidebarLinks-link-small hidden-mobile SidebarLinks-link-small-active"
              : "SidebarLinks-link-small hidden-mobile"
          }
          to="/ensembles"
        >
          Ensembles
        </Link>
        {/* <Link
          className={
            props.location.pathname === "/periods"
              ? "SidebarLinks-link-small hidden-mobile SidebarLinks-link-small-active"
              : "SidebarLinks-link-small hidden-mobile"
          }
          to="/periods"
        >
          Periods
        </Link> */}
        {/* <Link
          className={
            props.location.pathname === "/genres"
              ? "SidebarLinks-link-small hidden-mobile SidebarLinks-link-small-active"
              : "SidebarLinks-link-small hidden-mobile"
          }
          to="/genres"
        >
          Genres
        </Link> */}
        <Link
          className={
            props.location.pathname === "/labels"
              ? "SidebarLinks-link-small hidden-mobile SidebarLinks-link-small-active"
              : "SidebarLinks-link-small hidden-mobile"
          }
          to="/labels"
        >
          Labels
        </Link>
        <Link
          className={
            pathname === "/favorites" ||
              pathname === "/favorites/recordings"
              ? "SidebarLinks-link SidebarLinks-link-active"
              : "SidebarLinks-link"
          }
          to="/favorites"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className="SidebarLinks-link-icon"
          >
            <path d="M352,48H160a48,48,0,0,0-48,48V464L256,336,400,464V96A48,48,0,0,0,352,48Z" />
          </svg>
          <span>Collection</span>
        </Link>
        <Link className="SidebarLinks-link-small hidden-mobile" to="/favorites">
          Albums
        </Link>
        <Link className="SidebarLinks-link-small hidden-mobile" to="/favorites/recordings">
          Recordings
        </Link>
      </div>
    </div>
  );
}

export default withRouter(SidebarLinks);
