import React from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Artist from "./Artist";
import About from "./About";
import Piece from "./Piece";
import Label from "./Label";
import Period from "./Period";
import Genre from "./Genre";
import Ensemble from "./Ensemble";
import ArtistsQueryBuilder from "./ArtistsQueryBuilder";
import PiecesQueryBuilder from "./PiecesQueryBuilder";
import EnsemblesQueryBuilder from "./EnsemblesQueryBuilder";
import LabelsQueryBuilder from "./LabelsQueryBuilder";
import Home from "./Home";
import Browse from "./Browse";
import Album from "./Album";
import EditArtist from "./EditArtist";
import EditPiece from "./EditPiece";
import EditAlbums from "./EditAlbums";
import EditAlbum from "./EditAlbum";
import EditRecording from "./EditRecording";
import Recording from "./Recording";
import Works from "./Works";
import Account from "./Account";
import Favorites from "./Favorites";
import TermsOfUse from "./TermsOfUse";
import Privacy from "./Privacy";
import Contact from "./Contact";
import UpdateRecordings from "./UpdateRecordings";
import UpdateAlbums from "./UpdateAlbums";
import Catalogues from "./Catalogues";
import "./css/Main.css";

class Main extends React.Component {
  render() {
    return (
      <div className="Main">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/albums" component={Label} />
          <Route exact path="/artist/:artistId" component={Artist} />
          <Route exact path="/artist/:artistId/edit" component={EditArtist} />
          <Route exact path="/artist/:artistId/works" component={Artist} />
          <Route exact path="/artist/:artistId/about" component={Artist} />
          <Route exact path="/artist/:artistId/recordings" component={Artist} />
          <Route
            exact
            path="/artist/:artistId/piece/insert"
            component={EditPiece}
          />
          <Route
            exact
            path="/artist/:artistId/works/:catalogueId"
            component={Artist}
          />
          <Route exact path="/label/:labelId" component={Label} />
          <Route exact path="/label/:labelId/recordings" component={Label} />
          <Route exact path="/ensemble/:ensembleId" component={Ensemble} />
          <Route
            exact
            path="/ensemble/:ensembleId/recordings"
            component={Ensemble}
          />
          <Route
            exact
            path="/ensemble/:ensembleId/about"
            component={Ensemble}
          />
          <Route exact path="/piece/:pieceId" component={Piece} />
          <Route exact path="/piece/:pieceId/edit" component={EditPiece} />
          <Route exact path="/piece/:pieceId/recordings" component={Piece} />
          <Route exact path="/piece/:pieceId/about" component={Piece} />
          <Route exact path="/period/:eraId" component={Period} />
          <Route exact path="/period/:eraId/recordings" component={Period} />
          <Route exact path="/genre/:categoryId" component={Genre} />
          <Route exact path="/genre/:categoryId/recordings" component={Genre} />
          <Route exact path="/recordings/update" component={UpdateRecordings} />
          <Route exact path="/albums/update" component={UpdateAlbums} />
          <Route exact path="/album/:albumId" component={Album} />
          <Route exact path="/album/:albumId/edit" component={EditAlbum} />
        </Switch>
        <Route exact path="/insert/piece" component={EditPiece} />
        <Route exact path="/insert/albums" component={EditAlbums} />
        <Route exact path="/favorites" component={Favorites} />
        <Route exact path="/favorites/recordings" component={Favorites} />
        <Route exact path="/recording/:id" component={Recording} />
        <Route
          exact
          path="/recording/:recordingId/edit"
          component={EditRecording}
        />
        <Route path="/artists" component={ArtistsQueryBuilder} />
        <Route path="/composers" component={ArtistsQueryBuilder} />
        <Route path="/performers" component={ArtistsQueryBuilder} />
        <Route path="/conductors" component={ArtistsQueryBuilder} />
        <Route path="/pieces" component={PiecesQueryBuilder} />
        <Route path="/periods" component={Browse} />
        <Route path="/works" component={Works} />
        <Route path="/genres" component={Browse} />
        <Route path="/instruments" component={Browse} />
        <Route path="/labels" component={LabelsQueryBuilder} />
        <Route path="/ensembles" component={EnsemblesQueryBuilder} />
        <Route path="/catalogues" component={Catalogues} />
        <Route path="/account" component={Account} />
        <Route path="/terms" component={TermsOfUse} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/contact" component={Contact} />
        <Route path="/about" component={About} />
      </div>
    );
  }
}

export default withRouter(Main);
