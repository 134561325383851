import React from "react";
// import Rating from "./Rating";
import { Link } from "react-router-dom";
import "./css/ArtistMoreInfo.css";
import {
  // convertListOfObjectToString,
  convertListToString
} from "./helpers/helpers.js";

export default function AlbumMoreInfo(props) {
  return <div className="ArtistMoreInfo">{renderArtists(props.album)}</div>;

  // function renderUsersRating(album) {
  //   if (album && album.usersRating) {
  //     return (
  //       <div className="ArtistMoreInfo-row">
  //         <div className="ArtistMoreInfo-column-right">Users rating</div>
  //         <div className="ArtistMoreInfo-column-right">
  //           <Rating
  //             rating={Math.round(album.usersRating * 10) / 10}
  //             numberRating={props.numberRating}
  //           />
  //         </div>
  //       </div>
  //     );
  //   }
  // }

  function renderArtists(album) {
    if (album && album.composers && album.composers.length > 0) {
      return (
        <div className="ArtistMoreInfo-container">
          {renderComposers(album)}
          {renderPerformers(album)}
          {renderEnsembles(album)}
        </div>
      );
    }
  }

  function renderComposers(album) {
    if (album && album.composers && album.composers.length > 0) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">Composers</div>
          <div className="ArtistMoreInfo-column-right">
            {album.composers.map((composer, i) => {
              return (
                <div key={i} className="ArtistMoreInfo-column-right-item">
                  <Link to={"/artist/" + composer.id}>{composer.name}</Link>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  }

  function renderEnsembles(album) {
    if (album && album.ensembles && album.ensembles.length > 0) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">Ensembles</div>
          <div className="ArtistMoreInfo-column-right">
            {album.ensembles.map((ensemble, i) => {
              return (
                <div key={i} className="ArtistMoreInfo-column-right-item">
                  <Link to={"/ensemble/" + ensemble.id}>{ensemble.name}</Link>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  }

  function occupations(performer) {
    if (performer && performer.occupations) {
      return (
        <span className="ArtistMoreInfo-grey">
          {` ${convertListToString(performer.occupations)}`}
        </span>
      );
    }
  }

  function renderPerformers(album) {
    if (album && album.performers && album.performers.length > 0) {
      return (
        <div className="ArtistMoreInfo-row">
          <div className="ArtistMoreInfo-column-left">Performers</div>
          <div className="ArtistMoreInfo-column-right">
            {album.performers.map((performer, i) => {
              return (
                <div key={i} className="ArtistMoreInfo-column-right-item">
                  <Link to={"/artist/" + performer.id}>{performer.name}</Link>
                  {occupations(performer)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  }
}
