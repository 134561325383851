import React, { useState, useEffect } from "react";
import OutsideAlerter from "./OutsideAlerter.js";
import "./css/Window.css";
import "./css/Sidebar.css";

export default function SpotifyDevicesWindow(props) {
  const [devices, setDevices] = useState(null);
  const [devicesIsLoaded, setDevicesIsLoaded] = useState(false);

  const {
    handleShowSpotifyDevicesWindow,
    handleShowMessageBox,
    nextUrl
  } = props;

  /**
   * Call the Spotify API and try to retrieve the devices
   * of the current user
   */
  useEffect(() => {
    async function getDevices() {
      const resp = await fetch("/api/spotify/devices/");
      const respJSON = await resp.json();
      if (resp.status === 200 && respJSON.devices) {
        setDevices(respJSON.devices);
      }
    }
    getDevices();
  }, []);

  /**
   * Set devicesIsLoaded to true after API response
   * to avoid rendering window before response
   */
  useEffect(() => {
    if (devices) {
      setDevicesIsLoaded(true);
    }
  }, [devices]);

  if (devicesIsLoaded) {
    return (
      <div className="Window">
        <OutsideAlerter
          onOutsideClick={() => handleShowSpotifyDevicesWindow()}
          className="Window-window"
        >
          <h2>Choose a Spotify Device</h2>
          {devices && devices[0] && devices[0].name ? (
            <div>
              <p>
                We found the below Spotify devices. Please select the one you
                want to use.
              </p>
              <div className="Window-buttons">{renderOptions()}</div>
              <small>
                If your device is not in the list, make sure the Spotify
                application is open on the device and refresh this page.
              </small>
            </div>
          ) : (
            <p>
              No active Spotify device has been found.
              <br />
              <br />
              <a
                href="https://open.spotify.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here to open the Spotify Web Player
              </a>
            </p>
          )}
        </OutsideAlerter>
        <div className="Window-background"></div>
        {/* </div> */}
      </div>
    );
  } else {
    return null;
  }

  function renderOptions() {
    if (devices) {
      return devices.map((device, i) => {
        return (
          <button
            key={i}
            className={
              device.is_active
                ? "Window-button Window-button-active"
                : "Window-button"
            }
            onClick={() => changeSpotifyDevice(device.id)}
          >
            {device.name}
          </button>
        );
      });
    }
  }

  /**
   * Set deviceId as active device
   * @param {String} deviceId
   */
  async function changeSpotifyDevice(deviceId) {
    const resp = await fetch(`/api/spotify/devices/${deviceId}`);
    // console.log(`/api/spotify/devices/${deviceId}`);
    console.log(resp.status);
    // If status code = 204, device has been successfully selected
    if (resp.status === 204) {
      handleShowSpotifyDevicesWindow();
      // handleShowMessageBox("Device is now active", 2000);
      // console.log(nextUrl);
      setTimeout(async () => {
        // console.log(nextUrl);
        const resp = await fetch(nextUrl);
        // console.log(resp.status);
        const respJSON = await resp.json();
        // console.log(respJSON);
        handleShowMessageBox(respJSON.message, 3000);
      }, 1000);
    }
  }
}
