import React from "react";
import { Link } from "react-router-dom";
import FavoritesEmpty from "./FavoritesEmpty.js";
// import NumberAlbums from "./NumberAlbums.js";
import "./css/ArtistsTable.css";

export default function ArtistsTable(props) {
  const { artists, artistsCounter, artistsIsLoaded, path } = props;
  if (parseInt(artistsCounter) > 0 && artistsIsLoaded) {
    return <div className="ArtistsTable">{renderArtists(artists)}</div>;
  } else if (
    parseInt(artistsCounter) === 0 &&
    artistsIsLoaded &&
    path === "/favorites/artists"
  ) {
    return (
      <FavoritesEmpty
        type="artist"
        typePlural="artists"
        redirect="/composers"
      />
    );
  } else {
    return null;
  }

  function renderArtists(artists) {
    return artists.map((artist, i) => (
      <Link to={setLink(artist)} key={i} className="ArtistsTable-table-row">
        <div className="ArtistsTable-table-row-data">
          <div className="ArtistsTable-table-row-data-name">
            {artist.invertedName}
            {/* <span className="ArtistsTable-table-row-data-name-date">{artist.datesString}</span> */}
          </div>
          {/* {renderNumberAlbums(artist)} */}
        </div>
      </Link>
    ));
  }

  // function renderNumberAlbums(artist) {
  //   return (
  //     <div className="ArtistsTable-table-row-data-numberAlbums">
  //       <NumberAlbums
  //         numberAlbums={
  //           props.path === "/composers"
  //             ? artist.numberAlbumsAsComposer
  //             : artist.numberAlbumsAsPerformer
  //         }
  //       />
  //     </div>
  //   );
  // }

  function setLink(artist) {
    let link = "/artist/" + artist._id;
    if (
      !(artist.numberAlbumsAsComposer > 0) &&
      !(artist.numberAlbumsAsPerformer > 0) &&
      artist.numberPiecesAsComposer > 0
    ) {
      link = "/artist/" + artist._id + "/works";
    }
    return link;
  }
}
