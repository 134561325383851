import React from "react";
import OutsideAlerter from "./OutsideAlerter.js";
import { withRouter } from "react-router-dom";
import "./css/Window.css";
import "./css/Sidebar.css";
import UserContext from "./UserContext";
import spotifySmall from "./spotify-small.png";

class LoginToSpotify extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      currentSpotifyUser: null
    };
  }

  componentDidUpdate(prevState) {
    if (!this.state.currentSpotifyUser && prevState !== this.state) {
      this.fetchCurrentSpotifyUser();
    }
  }

  async fetchCurrentSpotifyUser() {
    if (this.context && this.context.user && this.context.user.spotify_tokens) {
      const acc = this.context.user.spotify_tokens.access_token;
      try {
        const resp = await fetch(`https://api.spotify.com/v1/me`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${acc}`
          }
        });
        const respJSON = await resp.json();
        if (respJSON.error) {
          this.setState({
            currentSpotifyUser: "no current user",
            errorSpotifyUser: respJSON.error
          });
        } else {
          this.setState({ currentSpotifyUser: respJSON });
        }
      } catch (err) {
        console.log(err);
      }
    } else if (
      this.context &&
      this.context.user &&
      !this.context.user.spotify_tokens
    ) {
      this.setState({ currentSpotifyUser: "no token" });
    }
  }

  render() {
    return (
      <div className="Window">
        <OutsideAlerter
          onOutsideClick={() => this.props.handleShowLoginToSpotify()}
          className="Window-window"
        >
          <h2>Login with Spotify</h2>
          {this.renderSpotifyLogin()}
        </OutsideAlerter>
        <div className="Window-background"></div>
      </div>
    );
  }

  renderSpotifyLogin() {
    const { currentSpotifyUser } = this.props;
    if (!currentSpotifyUser || currentSpotifyUser === "no token") {
      return (
        <div className="Window-text">
          <p>
            Login with Spotify to be able to play music to Spotify directly from
            Lyra digital music library
            <br />
            <br />
          </p>
          <div className="Login-with-button Login-with-spotify">
            <img
              src={spotifySmall}
              className="Login-with-spotify-icon"
              width="24px"
              alt="spotify"
            />
            <a href="/auth/spotify">Log in with Spotify</a>
          </div>
        </div>
      );
    } else if (currentSpotifyUser === "no current user") {
      return (
        <div className="Window-text">
          <span>
            Your Spotify session has expired, please login again
            <br />
            <br />
          </span>
          <button
            className="Window-button Window-button-active"
            onClick={() => this.handleAuthorizeSpotify()}
          >
            Login with Spotify
          </button>
        </div>
      );
    } else {
      return (
        <div className="Window-text">
          You are currently logged in to Spotify as{" "}
          <span className="Account-link">
            {currentSpotifyUser.display_name}
          </span>
          .
          <br />
          <br />
          You can revoke this access at any moment in the 'App' section of your
          Spotify Account (
          <a
            href="https://www.spotify.com/us/account/apps/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.spotify.com/us/account/apps/
          </a>
          ).
          <br />
          <br />
        </div>
      );
    }
  }
}

export default withRouter(LoginToSpotify);
