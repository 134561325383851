import React from "react";
import { Link } from "react-router-dom";
import './css/FavoritesEmpty.css';

export default function FavoritesEmpty(props) {

  return (
    <div className="FavoritesEmpty">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="64" height="64" className="FavoritesEmpty-icon"><path fill="none" d="M0 0h24v24H0z" /><path d="M21.179 12.794l.013.014L12 22l-9.192-9.192.013-.014A6.5 6.5 0 0 1 12 3.64a6.5 6.5 0 0 1 9.179 9.154z" /></svg>
      </div>
      <h2>No {props.type} in your favorites yet !</h2>
        <Link to="/composers/popular">
          Start exploring
        </Link>
    </div>
  )
}