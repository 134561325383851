import React, { useState, useEffect, useContext } from "react";
import AlbumCover from "./AlbumCover";
import { withRouter } from "react-router-dom";
import AlbumMoreInfo from "./AlbumMoreInfo";
import AlbumTable from "./AlbumTable";
import AlbumSubtitle from "./AlbumSubtitle";
import AlbumHeaderNavigation from "./AlbumHeaderNavigation";
// import ButtonRating from "./ButtonRating";
import Reviews from "./Reviews";
import PressReviews from "./PressReviews";
import ButtonFavorite from "./ButtonFavorite.js";
import ButtonPlayOnSpotify from "./ButtonPlayOnSpotify.js";
import Frame from "./Frame";
import UserContext from "./UserContext";
import Windows from "./Windows";
// import gm from "./gm.png";
import "./css/Album.css";
import "./css/Sidebar.css";

function Album(props) {
  return (
    <Windows>
      <AlbumContent {...props} />
    </Windows>
  );
}
function AlbumContent(props) {
  const [album, setAlbum] = useState(null);
  const [page, setPage] = useState("tracks");
  const [isInFavorites, setIsInFavorites] = useState(false);
  const [recordingsInFavorites, setRecordingsInFavorites] = useState([]);
  const [userReview, setUserReview] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [pressReviews, setPressReviews] = useState([]);
  const {
    handleShowLoginToSpotify,
    handleShowMessageBox,
    handleShowSpotifyDevicesWindow,
    handleShowLoginWindow
  } = props;

  const user = useContext(UserContext);

  let albumId = "";
  if (props.match.params.albumId) {
    albumId = props.match.params.albumId;
  }

  async function fetchIsInFavorites(albumId) {
    try {
      const resp = await fetch("/favorites/album/" + albumId);
      const respJSON = await resp.json();
      if (respJSON && respJSON.isAlbumInFavorites) {
        setIsInFavorites(respJSON.isAlbumInFavorites);
      }
      if (
        respJSON &&
        respJSON.recordingsInFavorites &&
        respJSON.recordingsInFavorites.length > 0
      ) {
        setRecordingsInFavorites(respJSON.recordingsInFavorites);
      }
      return resp;
    } catch (err) {
      console.log(err);
    }
  }
  async function fetchAlbumReviews(albumId) {
    try {
      const resp = await fetch("/api/reviews/album/" + albumId);
      const reviews = await resp.json();
      setReviews(reviews);
      return resp;
    } catch (err) {
      console.log(err);
    }
  }
  async function fetchAlbumPressReviews() {
    try {
      const resp = await fetch("/api/pressReviews/album/" + albumId);
      const pressReviews = await resp.json();
      setPressReviews(pressReviews);
      return resp;
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchUserAlbumReview(userId, albumId) {
    try {
      const resp = await fetch(
        "/api/reviews/album/" + albumId + "/user/" + userId
      );
      const review = await resp.json();
      if (review) {
        setUserReview(review);
        // setUserRating(review.rating);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    async function fetchAlbum(albumId) {
      try {
        const resp = await fetch("/api/albums/" + albumId);
        const album = await resp.json();
        setAlbum(album);
        return resp;
      } catch (err) {
        console.log(err);
      }
    }
    fetchAlbum(albumId);
    fetchIsInFavorites(albumId);
    fetchAlbumReviews(albumId);
    fetchAlbumPressReviews(albumId);
  }, [albumId]);

  useEffect(() => {
    if (album && album.title) {
      document.title = album.title + " - Lyra digital music library";
    }
  }, [album]);

  useEffect(() => {
    if (user && albumId) {
      fetchUserAlbumReview(user.userId, albumId);
    }
  }, [user, albumId]);

  return (
    <Frame>
      {album ? (
        <div className="Album">
          <div className="Album-header Header">
            <div className="Album-header-left">
              <div className="Header-category">Album</div>
              <div className="Header-big-title Header-big-title-center">
                {album.title}
              </div>
              <AlbumSubtitle album={album} />
              <div className="Album-buttons">
                {/* <ButtonPlayOnSpotify
                  albumIdSpotify={album.idSpotify}
                  trackTitle={album.tracks ? album.tracks[0].title : ""}
                  trackPosition={1}
                  handleShowMessageBox={handleShowMessageBox}
                  handleShowSpotifyDevicesWindow={
                    handleShowSpotifyDevicesWindow
                  }
                  handleShowLoginToSpotify={handleShowLoginToSpotify}
                /> */}
                <ButtonFavorite
                  albumId={album && album._id}
                  type="album"
                  isInFavorites={isInFavorites}
                  callbackAfterAdd={() => setIsInFavorites(true)}
                  callbackAfterRemove={() => setIsInFavorites(false)}
                  handleShowLoginWindow={handleShowLoginWindow}
                />
                {/* <ButtonRating
                  album={album}
                  setAlbum={setAlbum}
                  user={user.user}
                  userReview={userReview}
                  setUserReview={setUserReview}
                  handleShowLoginWindow={handleShowLoginWindow}
                /> */}
              </div>
              {album.awards && album.awards.length > 0 ? (
                <div className="Album-awards">
                  {album.awards.map((award, i) => {
                    return (
                      <div key={i} className="Album-award">
                        {/* <img src={gm} width="20" alt="gm" /> */}
                        {award.name}
                        {/* {award.date ? " - " +convertDate(award.date) : null} */}
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
            <div className="Album-header-right">
              <AlbumCover album={album} />
            </div>
            <AlbumHeaderNavigation
              album={album}
              page={page}
              setPage={setPage}
              pressReviews={pressReviews}
            />
          </div>
          <div className="Album-content">
            {page === "tracks" ? renderTracks() : null}
            {page === "artists" ? renderArtists() : null}
            {page === "description" ? renderDescription() : null}
            {page === "usersReviews" ? renderUsersReviews() : null}
            {page === "pressReviews" ? renderPressReviews() : null}
          </div>
          <div></div>
          <div className="Album-info"></div>
          {/* <div className="Album-content-section-4">
            {window.innerWidth <= 576 ? (
              <AlbumMoreInfo album={album} numberRating={reviews.length} />
            ) : null}
          </div> */}
        </div>
      ) : null}
    </Frame>
  );

  function renderTracks() {
    return (
      <AlbumTable
        album={album}
        recordingsInFavorites={recordingsInFavorites}
        setRecordingsInFavorites={setRecordingsInFavorites}
        handleShowMessageBox={handleShowMessageBox}
        handleShowSpotifyDevicesWindow={handleShowSpotifyDevicesWindow}
        handleShowLoginToSpotify={handleShowLoginToSpotify}
        handleShowLoginWindow={handleShowLoginWindow}
      />
    );
  }

  function renderArtists() {
    return <AlbumMoreInfo album={album} numberRating={reviews.length} />;
  }

  function renderDescription() {
    return <div className="Album-description">{album.description}</div>;
  }

  function renderUsersReviews() {
    return (
      <Reviews
        album={album}
        setAlbum={setAlbum}
        user={user.user}
        reviews={reviews}
        userReview={userReview}
        setUserReview={setUserReview}
        fetchAlbumReviews={fetchAlbumReviews}
        handleShowLoginWindow={handleShowLoginWindow}
      />
    );
  }

  function renderPressReviews() {
    return (
      <PressReviews
        album={album}
        pressReviews={pressReviews}
        handleShowMessageBox={handleShowMessageBox}
        fetchAlbumPressReviews={fetchAlbumPressReviews}
      />
    );
  }
}

export default withRouter(Album);
