import React from "react";
import { Link } from "react-router-dom";
import "./css/HeaderNavigation.css";

export default function FavoritesHeaderNavigation(props) {
  const { page } = props;

  return (
    <div className="HeaderNavigation">
      {renderButtonAlbums()}
      {renderButtonRecordings()}
    </div>
  );

  function renderButtonAlbums() {
    return (
      <Link
        to={"/favorites"}
        className={
          page === "albums"
            ? "HeaderNavigation-button HeaderNavigation-button-active"
            : "HeaderNavigation-button"
        }
      >
        Albums
      </Link>
    );
  }

  function renderButtonRecordings() {
    return (
      <Link
        to={"/favorites/recordings"}
        className={
          page === "recordings"
            ? "HeaderNavigation-button HeaderNavigation-button-active"
            : "HeaderNavigation-button"
        }
      >
        Recordings
      </Link>
    );
  }
}
